import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading = new Subject<boolean>();
    isLoadingService = new Subject<boolean>();
    preloaderProgressSubject: BehaviorSubject<number> = new BehaviorSubject(0);
    /** 
     *  для відображення індиктору завантаження
    */
    updatePreloaderProgress(value: number): void {
        this.preloaderProgressSubject.next(value);
    }
    
    show() {
        this.isLoading.next(true);
    }
    hide() {
        this.isLoading.next(false);
    }

    showLoadingService() {
        this.isLoadingService.next(true);
    }
    hideLoadingService() {
        this.isLoadingService.next(false);
    }
}