(function () {
  "use strict";

  var dependencies = [];

  var service = function () {
    function truncateUtf8Bytes(string, byteLength) {
      function isHighSurrogate(codePoint) {
        return codePoint >= 0xd800 && codePoint <= 0xdbff;
      }

      function isLowSurrogate(codePoint) {
        return codePoint >= 0xdc00 && codePoint <= 0xdfff;
      }

      if (typeof string !== "string") {
        throw new Error("Input must be string");
      }

      var charLength = string.length;
      var curByteLength = 0;
      var codePoint;
      var segment;

      for (var i = 0; i < charLength; i += 1) {
        codePoint = string.charCodeAt(i);
        segment = string[i];

        if (
          isHighSurrogate(codePoint) &&
          isLowSurrogate(string.charCodeAt(i + 1))
        ) {
          i += 1;
          segment += string[i];
        }

        curByteLength += new Blob([segment]).size;

        if (curByteLength === byteLength) {
          return string.slice(0, i + 1);
        } else if (curByteLength > byteLength) {
          return string.slice(0, i - segment.length + 1);
        }
      }

      return string;
    }
    return {
      sanitizeFilename: function (input) {
        var illegalRe = /[\/\?<>\\:\*\|"]/g;
        var controlRe = /[\x00-\x1f\x80-\x9f]/g;
        var reservedRe = /^\.+$/;
        var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
        var windowsTrailingRe = /[\. ]+$/;
        if (typeof input !== "string") {
          throw new Error("Input must be string");
        }
        var sanitized = input
          .replace(illegalRe, "")
          .replace(controlRe, "")
          .replace(reservedRe, "")
          .replace(windowsReservedRe, "")
          .replace(windowsTrailingRe, "");
        var truncated = truncateUtf8Bytes(sanitized, 200);
        return truncated;
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("sanitizeSrvc", service);
})();
