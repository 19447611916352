import Drag2D from './Drag2D';
import * as THREE from '../../libs/three';

var DragSuite2D = function (dependencyContainer) {
    Drag2D.prototype.constructor.call(this, dependencyContainer);

    this._constants = dependencyContainer.getService('constants');
    this._roomStuffFactory = dependencyContainer.getService('roomStuffFactory');
    this._roomStuff = dependencyContainer.getService('roomStuff');
    this._Scene2d = dependencyContainer.getService('Scene2d');
    this._step3Helper = dependencyContainer.getService('step3Helper');
    this._step3DataSrv = dependencyContainer.getService('step3DataSrv');
    this._roomStateManager = dependencyContainer.getService('roomStateManager');
    this._collisionSrv = dependencyContainer.getService('collisionSrvc');
    this._obbBuilder = dependencyContainer.getService('obbBuilder');
    this._objectGapHelperSrvc = dependencyContainer.getService('objectGapHelperSrvc');
    this._suiteUtils2DSrvc = dependencyContainer.getService('suiteUtils2DSrvc');
    this._sceneHelper = dependencyContainer.getService('sceneHelper');
    this._ghostObject = null;
    this._lastValidPosition = new THREE.Vector3();
    this._initialDrag = true;
    this._staticObjs = null;
};

function placeSuiteObjects(objects) {
    var zPositions = _.map(objects, function (o) {
        return o.position.z;
    });
    for (var i = 0; i < objects.length; i++) {
        objects[i].position.copy(this._ghostObject.localToWorld(objects[i].position.clone()));
        this._Scene2d.scene.add(objects[i]);
        objects[i].rotation.z = this._ghostObject.rotation.z;
        objects[i].position.z = zPositions[i];
    }
    this._Scene2d.scene.remove(this._ghostObject);
    this._ghostObject = null;
}

DragSuite2D.prototype = Object.create(Drag2D.prototype);

DragSuite2D.prototype.constructor = DragSuite2D;

DragSuite2D.prototype.dragStart = function (position, draggedObject) {
    var suiteId = draggedObject.userData.suiteId;
    var suiteEntity = this._suiteUtils2DSrvc.getSuiteEntityById(suiteId);
    var center = this._suiteUtils2DSrvc.getSuiteCenter(suiteId, draggedObject.userData.groupId);
    this._ghostObject = this._roomStuffFactory.buildRoomItem(suiteEntity);
    this._ghostObject.position.copy(center);
    this._ghostObject.position.setZ(0);
    this._ghostObject.children[0].material.opacity = 0.2;
    this._ghostObject.rotation.z = draggedObject.rotation.z;
    this._Scene2d.scene.add(this._ghostObject);
    var objects = this._suiteUtils2DSrvc.getObjectsBySuiteId(
        suiteId,
        draggedObject.userData.groupId
    );
    for (var i = 0; i < objects.length; i++) {
        var localPosition = objects[i].position
            .clone()
            .sub(this._ghostObject.position)
            .applyAxisAngle(new THREE.Vector3(0, 0, 1), -objects[i].rotation.z);
        objects[i].position.copy(localPosition);
        objects[i].rotation.z = 0;
        this._ghostObject.add(objects[i]);
    }
};

DragSuite2D.prototype.dragging = function (newPosition, draggedObject) {
    this._step3Helper.highlightWallIfDefined(
        this._selectedWall,
        this._constants.HighlightState.DEFAULT
    );

    this._ghostObject.updateMatrixWorld();
    this._ghostObject.updateMatrix();

    this._selectedWall = this.getIntersectedWall(this._ghostObject);

    if (this._selectedWall) {
        this._ghostObject.rotation.z = this._selectedWall.rotation.y;
        this._step3Helper.highlightWallIfDefined(
            this._selectedWall,
            this._constants.HighlightState.SELECTED
        );
    }

    this._lastValidPosition.copy(this._ghostObject.position);

    this._staticObjs = this._staticObjs || this.getStaticObjects(this._ghostObject);

    this._ghostObject.position.copy(newPosition);

    var collide = this.isCollide(this._ghostObject, this._staticObjs);

    if (collide) {
        console.log(collide);
        this._ghostObject.position.copy(this._lastValidPosition);
    } else {
        this._lastValidPosition.copy(this._ghostObject.position);
    }
};

DragSuite2D.prototype.dragCanceled = function (draggedObject) {
    if (!this._ghostObject) return;
    var suiteId = draggedObject.userData.suiteId;
    var objects = this._suiteUtils2DSrvc.getObjectsBySuiteId(
        suiteId,
        draggedObject.userData.groupId
    );
    placeSuiteObjects.call(this, objects);
};

DragSuite2D.prototype.dragEnd = function (draggedObject) {
    if (!this._ghostObject) return;
    if (this._selectedWall) {
        var suiteId = draggedObject.userData.suiteId;
        var objects = this._suiteUtils2DSrvc.getObjectsBySuiteId(
            suiteId,
            draggedObject.userData.groupId
        );
        this._step3Helper.snapToWallWithGap(this._ghostObject, this._selectedWall);
        this._ghostObject.updateMatrixWorld();
        placeSuiteObjects.call(this, objects);
    } else {
        this._Scene2d.scene.remove(this._ghostObject);
        this._ghostObject = null;
    }
};

DragSuite2D.prototype.isKnownObject = function (userData) {
    return userData.suiteId && !userData.isCornerSuite;
};

DragSuite2D.prototype.isEnabled = function () {
    return (
        Drag2D.prototype.isEnabled.call(this) &&
        this.isKnownObject(this.getSelectedObject().userData)
    );
};
export default DragSuite2D