import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'app-terms-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent {
  constructor(public dialogRef: MatDialogRef<TermsModalComponent>) {}
}

angular.module('valleyCraftApp').directive(
  'appTermsModal',
  downgradeComponent({
    component: TermsModalComponent,
  }) as angular.IDirectiveFactory
);
