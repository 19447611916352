"use strict";
import * as THREE from "../libs/three";
// import OBJLoader from "../libs/OBJLoader.js";
// import MTLLoader from "../libs/MTLLoader.js";
import DrawCustomRoom from "../component/2D/DrawCustomRoom";

angular.module("valleyCraftApp").controller("MainCtrl", [
  "$scope",
  "$http",
  "$rootScope",
  "Scene2d",
  "$timeout",
  "scene3D",
  "AuthenticationService",
  "ResourceService",
  "constants",
  "roomStateManager",
  "localStorageService",
  "$location",
  "$routeParams",
  "shoppingListSrvc",
  "screenShotStoreSrvc",
  "googleAnalitycs",
  "toastr",
  "$window",
  "ngDialog",
  "engine2DSrvc",
  "roomStuff",
  "SaveStateService",
  "engine3DSrvc",
  "room2DBuilder",
  "objects2DSerializer",
  "configuratorService",
  "scene2DSyncSrvc",
  "object3DSelectionBox",
  "AngularService",
  function (
    $scope,
    $http,
    $rootScope,
    Scene2d,
    $timeout,
    scene3D,
    AuthenticationService,
    ResourceService,
    constants,
    roomStateManager,
    localStorageService,
    $location,
    $routeParams,
    shoppingListSrvc,
    screenShotStoreSrvc,
    googleAnalitycs,
    toastr,
    $window,
    ngDialog,
    engine2DSrvc,
    roomStuff,
    SaveStateService,
    engine3DSrvc,
    room2DBuilder,
    objects2DSerializer,
    configuratorService,
    scene2DSyncSrvc,
    object3DSelectionBox,
    AngularService
  ) {
    var previousStep = null;
    $scope.sortType = "dt";
    $rootScope.userLogged = false;
    $rootScope.templateMaster = false;
    var userName = AuthenticationService.userLogged();
    var userNameScene = "MasterScene";
    if (userName) {
      userNameScene = userName.firstName + userName.lastName;
    }

    var newProjConfirmMsg =
      "This will start a new project. Do you wish to continue?";
    var customRoomFlag = false;
    var abilities = constants.ItemOption;
    // show loader before restoring project
    var savedProject = localStorageService.get(
      AuthenticationService.userId() || "unauthorized"
    );
    if (savedProject) {
      $scope.isLoadingBg = true;
    }

    $window.addEventListener("message", function (event) {
      // "https://138.197.30.187"
      if (event.origin !== "" && event.origin !== "") {
        return;
      }
      var payload = JSON.parse(event.data);
      console.warn("### payload ### ", payload);
      localStorageService.set("ce_sid_name", payload.data.ce_sid_name);
      localStorageService.set("ce_sid_id", payload.data.ce_sid_id);
    });

    if (userName) {
      // console.log("email", AuthenticationService.mail());
      if (AuthenticationService.publisher()) {
        $rootScope.templateMaster = true;
      } else {
        $rootScope.templateMaster = false;
      }
      // console.log("is templ master", $rootScope.templateMaster);
      $rootScope.userLogged = true;
      $rootScope.labelUserLogged = userName.firstName + " " + userName.lastName;
      googleAnalitycs.setUser(
        AuthenticationService.userId(),
        $rootScope.labelUserLogged
      );
    }

    $rootScope.allowMoveTouchPoint = true;

    $scope.suits = [];
    $scope.Tmplts = [];

    ResourceService.getAllShownTemplates().then(
      function (data) {
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].titleProject.indexOf("Template") < 0 &&
            data[i].titleProject.indexOf("template") < 0
          ) {
            $scope.suits.push(data[i]);
          } else {
            $scope.Tmplts.push(data[i]);
          }
        }
        //  $scope.Tmplts = data;
        // console.log(data[1]);
        // console.debug(data);
      },
      function (data) {
        toastr.error(data.message);
      }
    );

    $http.get("assets/json/steps.json").then(function (response) {
      $scope.menu = response.data.menu;
      $scope.step1 = response.data.steps[0];
      $scope.step2 = response.data.steps[1];
      $scope.step3 = response.data.steps[2];
      $scope.step4 = response.data.steps[3];
      $scope.step5 = response.data.steps[4];
    });

    if ($rootScope.notStep) {
      $scope.isLoading = true;
      $scope.VisibleStep1 =
        $scope.VisibleStep2 =
        $scope.VisibleStep3 =
        $scope.VisibleStep4 =
        $scope.VisibleStep5 =
          false;
    } else {
      $scope.VisibleStep1 = true;
      $scope.VisibleStep2 = false;
      $scope.VisibleStep3 = false;
      $scope.VisibleStep5 = false;
    }
    $scope.$on("changeStep", function (events, data) {
      data.index = $rootScope.configurator ? 3 : data.index; // STEP
      var stepNumber = data.index + 1;
      var step = "step" + stepNumber;
      var roomExist = roomStateManager.hasPoints();

      $rootScope.$broadcast("hiddenPopupInfo");

      if (stepNumber !== 1 && !roomExist && !customRoomFlag) {
        ngDialog.open({
          disableAnimation: true,
          className: "ngdialog-theme-default screenshots-popup",
          template: "Please choose Shape",
          plain: true,
        });
        return;
      }

      switch (stepNumber) {
        case 1:
          {
            if (roomExist && previousStep != "step1") {
              if (!confirm(newProjConfirmMsg)) return;
              configuratorService.saveSceneJson(JSON.stringify([]));
              AngularService.clear();
              $scope.menuActiveStep = data.index;
              $scope.VisibleStep1 = true;
              $scope.VisibleStep3 = false;
              $scope.VisibleStep2 = false;
              $scope.VisibleStep4 = false;
              $scope.VisibleStep5 = false;
              step = "step1";
              previousStep = step;
              $location.path("planning");
            } else {
              $scope.menuActiveStep = data.index;
              $scope.VisibleStep1 = true;
              $scope.VisibleStep3 = false;
              $scope.VisibleStep2 = false;
              $scope.VisibleStep4 = false;
              $scope.VisibleStep5 = false;
              step = "step1";
              previousStep = step;
            }
          }
          break;
        case 2:
          {
            $scope.menuActiveStep = data.index;
            $scope.VisibleStep1 = false;
            $scope.VisibleStep3 = false;
            $scope.VisibleStep2 = true;
            $scope.VisibleStep4 = false;
            $scope.VisibleStep5 = false;
            step = "step2";
            previousStep = step;
          }
          break;
        case 3:
          {
            $scope.menuActiveStep = data.index;
            $scope.VisibleStep1 = false;
            $scope.VisibleStep3 = true;
            $scope.VisibleStep2 = false;
            $scope.VisibleStep4 = false;
            $scope.VisibleStep5 = false;
            step = "step3";
            previousStep = step;
          }
          break;
        case 4:
          {
            $scope.menuActiveStep = data.index;
            $scope.VisibleStep1 = false;
            $scope.VisibleStep2 = false;
            $scope.VisibleStep3 = false;
            $scope.VisibleStep4 = true;
            $scope.VisibleStep5 = false;
            step = "step4";
            previousStep = step;
          }
          break;
        case 5:
          {
            $scope.menuActiveStep = data.index;
            $scope.VisibleStep1 = false;
            $scope.VisibleStep2 = false;
            $scope.VisibleStep3 = false;
            $scope.VisibleStep4 = false;
            $scope.VisibleStep5 = true;
            $rootScope.$broadcast("calculateSPL", {
              objects: Scene2d.getEntityObjects(),
            });
            // console.log("$rootScope.editedRoomData", $rootScope.editedRoomData);
            $scope.projectData = $rootScope.editedRoomData
              ? $rootScope.editedRoomData.projectDetails.project
              : shoppingListSrvc.getProductData();
            step = "step5";
            previousStep = step;
          }
          break;
      }

      step = step ? step : previousStep;
      $rootScope.$broadcast("stepActive", step);
      applyScope();
    });
    $scope.menuActiveStep = 0;
    $scope.visibleSteppp = function (step, index) {
      //$scope.menuActiveStep = index;
      $rootScope.$broadcast("changeStep", { index: index, step: step });
      $rootScope.$broadcast("hidePrintPopup", "");
    };

    $scope.stepByStepArray = $scope.menu;

    $scope.stepByStepProgress = function (progress) {
      var index = $scope.menuActiveStep + progress;
      var minIndex = 0;
      var maxIndex = $scope.menu.length - 1;
      if (index < 0 || index > maxIndex) {
        return;
      } else {
        var activeStep = $scope.menu[index].name;
        $rootScope.$broadcast("changeStep", { index: index, step: activeStep });
      }
    };

    var stepActive = $rootScope.$on("stepActive", function (events, step) {
      engine2DSrvc.activateStepRelatedComponents(step);
      engine3DSrvc.activateStepRelatedComponents(step);
    });

    $scope.$on("SelectRoom", function (events, room) {
      var key = AuthenticationService.userId() || "unauthorized";
      localStorageService.remove(key);
      if (
        $location.$$path === "/browsing/" + $routeParams.room_id ||
        $location.$$path === "/planning/" + $routeParams.room_id
        /*  */
      ) {
        $location.path("/planning", false);
        $scope.projectDetails.title = "";
        $scope.projectDetails.saveAsNewProject = true;
      }

      $rootScope.userSave_RoomData = null;

      Scene2d.clean();
      Scene2d.render();

      engine2DSrvc.get().findComponentByType(DrawCustomRoom).enable();

      $scope.room = room;

      $scope.dataUnitFT = constants.wallUnitLength.FT = true;
      $scope.dataUnitM = constants.wallUnitLength.M = false;

      customRoomFlag = true;

      $rootScope.$broadcast("changeStep", { index: 1, step: "Size" });

      // reset var root scope
      $rootScope.allowMoveTouchPoint = true;

      roomStateManager.clearCurrentRoomState();
      screenShotStoreSrvc.clearScreenshotObject();
      $rootScope.$broadcast("reset");
    });

    var updateCanvas2d = $rootScope.$on(
      "updateCanvas2d",
      function (event, args) {
        var side = args.result.side;
        var exist_depth = args.result.depth;
        $scope.changeFigureSide(side, exist_depth);
      }
    );

    var updateTableCM = $rootScope.$on("updateTableCM", function () {
      $scope.$apply();
    });

    // change checkbox item and set $rootScope.allowMoveTouchPoint
    $scope.changeAllowMoveTouchPoint = function () {
      $rootScope.allowMoveTouchPoint = !$rootScope.allowMoveTouchPoint;
      var popupPoint = angular.element(".popup-info-point");
      popupPoint.fadeOut("slow");
    };

    $scope.changeZoom = function (zoomDirection) {
      engine2DSrvc
        .get()
        .findComponentByType(ZoomCamera)
        .zoomInOut(zoomDirection);
      $rootScope.$broadcast("updateWallSizeDialog");
    };

    var wallBeyondCamera = $rootScope.$on(
      "wallBeyondCamera",
      function (events, params) {
        engine2DSrvc.get().findComponentByType(ZoomCamera).zoomInOut(1);
      }
    );

    function applyScope() {
      $timeout(function () {
        $scope.$apply();
      }, 180);
    }

    function restoreProjectSettings() {
      if ($scope.projectDetails) {
        if ($rootScope.editedRoomData) {
          $scope.projectDetails.title = $rootScope.editedRoomData.titleProject;
          $scope.projectDetails.address =
            $rootScope.editedRoomData.projectDetails.address;
          $scope.projectDetails.contact =
            $rootScope.editedRoomData.projectDetails.contact;
          AngularService.setDataConfiguration($rootScope.isTemplate);
        }
        if ($rootScope.isTemplate && !AuthenticationService.publisher()) {
          $scope.projectDetails.saveAsNewProject = true;
        } else {
          $scope.projectDetails.saveAsNewProject = false;
        }
      }
    }

    var loadRoom = $rootScope.$on("loadRoom", handleLoadRoomEvent);

    /**
     * функція додає ідентифікатор необхідний при перетворенні моделі з 3D в 2D
     * @param {*} entity
     * @returns
     */
    /* function setInstanceofEntity(entity) {
      switch (entity.left_menu_alias) {
        case "Configurator": {
          return angular.extend(
            new constants.RoomObject.Configurator(),
            entity
          );
        }
        case "Akro-Mils": {
          return angular.extend(new constants.RoomObject.AkroMils(), entity);
        }
        case "Tool Storage": {
          return angular.extend(new constants.RoomObject.Cabinet(), entity);
        }
        case "High Capacity": {
          return angular.extend(new constants.RoomObject.Cabinet(), entity);
        }
        case "Windows": {
          return angular.extend(new constants.RoomObject.Window(), entity);
        }
        case "Doors - Entry": {
          return angular.extend(new constants.RoomObject.Door(), entity);
        }
        case "Stairs": {
          return angular.extend(new constants.RoomObject.Stairs(), entity);
        }
        case "Healthcare": {
          return angular.extend(new constants.RoomObject.Healthcare(), entity);
        }
        case "MRO": {
          return angular.extend(new constants.RoomObject.MRO(), entity);
        }
        case "Vehicles": {
          return angular.extend(new constants.RoomObject.Vehicle(), entity);
        }
        case "Misc": {
          return angular.extend(new constants.RoomObject.Misc(), entity);
        }
        case "Doors - Garage": {
          return angular.extend(new constants.RoomObject.Door(), entity);
        }
        default: {
        }
      }
    } */

    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    /**
     * функція завантажує модель яка пройшла через конфігуратор бінсів
     */
    async function loadOBJ(modelId, production, serializeSelect) {
      let entity = roomStuff.configurator.find((model) => model.id === modelId);
      const waitTime = 5000;
      const startTime = Date.now();
      while (!entity) {
        if (Date.now() - startTime >= waitTime) {
          // Превышено время ожидания, перезагрузка браузера
          location.reload();
          toastr.error("Exceeded waiting time, restart your browser");
          return;
        }
        await delay(1000);
        entity = roomStuff.configurator.find((model) => model.id === modelId);

        if (entity) {
          toastr.success("model is loading");
        } else {
          toastr.info("Please wait, the configuration model is loading...");
        }
      }
      toastr.success("model is loading");
      let y = Math.floor(parseInt(production.rotation._y));
      y = y === 0 ? (Object.is(y, -0) ? -100 : 100) : y;
     // const widthCor = Math.round((entity?.width_configurator * 4) / 10);

      console.log("rotation", production, "y", y);
      let positionX = production.position.x;
      let positionZ = production.position.z;

      if (entity?.width_configurator) {
        let WallCor = entity.width / 20;
        const c = (entity.width - entity?.width_configurator) / 10;

        const constantPosition = entity?.width_configurator
          ? (entity?.width_configurator + entity.width / 20) / 2 - c
          : 0;
          
        entity.width += WallCor;

        positionZ = production.position.z + constantPosition;
        positionX = production.position.x + constantPosition;

        positionX =
          y === -1  ? production.position.x - constantPosition : positionX;

        positionZ =
          y === 3 || y === -2 || y === -3 
            ? production.position.z - constantPosition
            : positionZ;

        positionX =
          y === 100 || y === 2 || y === 6 
            ? production.position.x + constantPosition / 2
            : positionX;

        positionX =
          y === -100 || y === -2 
            ? production.position.x - constantPosition / 2
            : positionX;
      }
     // serializeSelect[0].id = modelId;
      //  objects2DSerializer.deserialize(serializeSelect)
      scene2DSyncSrvc.createObject(entity, {
        position: new THREE.Vector3(
          positionX,
          production.position.y,
          positionZ
        ),
        rotation: new THREE.Euler(
          production.rotation._x,
          production.rotation._y,
          production.rotation._z
        ),
      });
    }

    /**
     * core function which create scene
     * also uses the load scene method after using the beans configurator
     * @param {*} e
     * @param {*} record
     */

    function handleLoadRoomEvent(e, record) {
      // console.log("Angular", AngularService.getData());

      if (record?.data) {
        var data = record.data;
      } else {
        $scope.isLoadingConfig = false;
        var data = {};
      }

      Scene2d.clean();
      if ($rootScope.configurator) {
        data = localStorageService.get("room");
        let production;

        if (Object.keys(data).length === 0) {
          toastr.error(
            "There was a system error on the server, the room is not loaded. Maybe you do not have rights. Contact your system administrator. Error - 1203"
          );
        } else {
          data.screenshot = data?.screenshot;
          production = data?.product;
        }

        $scope.isLoadingConfig = true;
        // var path = `assets/configurator/scene/${userNameScene}scene.json`;
        var modelId = localStorageService.get("modelId");
        //console.log("scene", scene3D.getChildren());
        if (modelId) {
          loadOBJ(modelId, production, data?.serializeSelect);
        } else {
          if (data.serializeSelect.length > 0) {
            objects2DSerializer.deserialize(data.serializeSelect);
          } else {
            toastr.error("not modelId");
          }
        }
        $timeout(function () {
          $scope.isLoadingConfig = false;
        }, 10);

        if (AngularService.getData()) {
          $rootScope.isTemplate = AngularService.getDataConfiguration();
        }
      }
      scene3D.dispose();
      roomStateManager.clearCurrentRoomState();

      engine2DSrvc.get().findComponentByType(DrawCustomRoom).disable();

      roomStateManager.setPoints(data.points);

      var objects = room2DBuilder.build(data.points);
      Scene2d.addMany(objects);

      $rootScope.allowMoveTouchPoint = true;
      customRoomFlag = false;

      if (data.objects.length > 0) {
        objects2DSerializer.deserialize(data.objects);
      }

      roomStateManager.setWallMaterial(data.wallColorScheme);
      roomStateManager.setFloorMaterial(data.floorColorScheme);

      $scope.menuActiveStep = 1;
      $rootScope.$broadcast("changeStep", { index: 1, step: "Size" });
      restoreProjectSettings();
      if (record?.screenshot) {
        screenShotStoreSrvc.loadScreenshot(record.screenshot);
        $rootScope.$broadcast("initScreenshotArray");
      }

      $rootScope.$broadcast("changeFloorArea");

      $timeout(function () {
        Scene2d.resize();
        Scene2d.render();
        scene3D.resize();
        $scope.isLoading = false;
        $scope.isLoadingBg = false;
      }, 500);

      if (record?.step) {
        $rootScope.$broadcast("changeStep", record.step);
      }
    }

    $rootScope.logout = function () {
      localStorageService.clearAll();
      $scope.userAccount = {};
      $rootScope.templateMaster = false;
      $rootScope.userLogged = false;
      $location.path("/login");
    };

    var goBackToProjectUnsubscribe = $rootScope.$on(
      "GoBackToProject",
      function () {
        if (roomStateManager.hasPoints()) {
          $rootScope.$broadcast("changeStep", {
            index: 1,
            step: "Size",
          });
          $rootScope.$broadcast("initScreenshotArray");
          engine2DSrvc.get().findComponentByType(DrawCustomRoom).disable();
          $scope.isLoading = false;
          $scope.isLoadingBg = false;
        }
      }
    );

    var sceneReadyUnsubscribe = $rootScope.$on(
      "Scene ready",
      function (e, data) {
        $scope.sceneReady = true;
        var key = AuthenticationService.userId() || "unauthorized";
        // console.debug("key", key);
        var info = localStorageService.get(key);
        if (info) {
          if (info.data) {
            $rootScope.isTemplate = false;
            $rootScope.editedRoomData = info;
            $rootScope.notStep = false;
            handleLoadRoomEvent(null, info);
            SaveStateService.clearSavedState();
          } else {
            $scope.sceneReady = false;
            $location.path(info);
          }
        }
      }
    );

    $scope.$on("shopping list ready", function (e, data) {
      if ($scope.sceneReady && $rootScope.editedRoomData) {
        $scope.sceneReady = false;
        restoreProjectSettings();
        $rootScope.$broadcast(
          "calculateSPL",
          SaveStateService.prepareSLData($rootScope.editedRoomData.data.objects)
        );
      }
    });

    $scope.$on("$destroy", function () {
      loadRoom();
      wallBeyondCamera();
      stepActive();
      updateCanvas2d();
      updateTableCM();
      goBackToProjectUnsubscribe();
      sceneReadyUnsubscribe();
    });
  },
]);
