(function () {
  var service = function (
    roomStuffFactory,
    Scene2d,
    scene3D,
    objectControls,
    dimensionalRadialsManager,
    objectMaterial,
    modelLoader,
    roomStateManager,
    engine2DSrvc
  ) {
   var del2dObj = function del2dObj(model) {
      if (model.parent) {
        model.parent.remove(model);
      } else {
        Scene2d.remove(model);
      }
      roomStateManager.removeObjectColorScheme(model);
      var selection2D = engine2DSrvc.get().findComponentByType(Selection2D);
      selection2D.setSelected(null);
      Scene2d.render();
    };

    return {
      replaceModel3D: function (old3DModel, meshData, colorScheme, uuid) {
        objectControls.setButtonVisibility('rotateCounterClockwise', false);
        objectControls.setButtonVisibility('delete', false);
        objectControls.setButtonVisibility('info', false);
        objectControls.setButtonVisibility("configurator", false);
        objectControls.setButtonVisibility('clone', false);
        objectControls.setButtonVisibility('rotateClockwise', false);
        dimensionalRadialsManager.deleteDimensionalRadials();

        return modelLoader.load(meshData, true).then(function (newModel3D) {
          newModel3D.uuid = uuid;

          if (meshData.hasOwnProperty('selectedAccessoryId')) {
            newModel3D.userData.selectedAccessoryId = _.clone(
              meshData.selectedAccessoryId
            );
          }

          newModel3D.userData.entity = meshData.entity;

          newModel3D.position.copy(old3DModel.position);
          if (!newModel3D.userData.entity.wallInteraction) {
            newModel3D.position.y = newModel3D.userData.entity.height / 2;
          }
          newModel3D.rotation.copy(old3DModel.rotation);

          if (old3DModel.userData.wall) {
            var wall = scene3D.getObject(old3DModel.userData.wall);
            scene3D.remove(old3DModel);
            if (old3DModel.userData.wall)
              newModel3D.userData.wall = old3DModel.userData.wall;
            scene3D.add(newModel3D);
            if (wall) wall = updateWall(wall);
          } else {
            scene3D.add(newModel3D);
            scene3D.remove(old3DModel);
          }

          objectMaterial.setMaterial(newModel3D, colorScheme);
          return newModel3D;
        });
      },
      replaceModel2D: function (oldModel2D, newEntity) {
        var newModel2D = roomStuffFactory.buildRoomItem(newEntity);
        if (oldModel2D.parent) {
          oldModel2D.parent.add(newModel2D);
        }
        newModel2D.position.copy(oldModel2D.position);
        if (!newModel2D.userData.entity.wallInteraction) {
          newModel2D.position.z = newModel2D.userData.entity.height / 2;
        }
        newModel2D.rotation.copy(oldModel2D.rotation);
        del2dObj(oldModel2D);
        if (newModel2D.parent) {
          newModel2D.userData.wall = newModel2D.parent.name;
        } else {
          Scene2d.addModel(newModel2D);
        }
        return newModel2D;
      },
    };
  };

  angular
    .module('valleyCraftApp')
    .service('replaceModelSrvc', [
      'roomStuffFactory',
      'Scene2d',
      'scene3D',
      'objectControls',
      'dimensionalRadialsManager',
      'objectMaterial',
      'modelLoader',
      'roomStateManager',
      'engine2DSrvc',
      service,
    ]);
})();
