import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ObjectTypeComponent } from './object-type/object-type.component';
import { BinComponent } from './bin/bin.component';
import { BinColorComponent } from './bin-color/bin-color.component';
import { TableComponent } from './table/table.component';
import { ResourceService } from 'src/app/services/resource.service';
import { ObjModelEntityInterface } from '../../interfaces/objModel.interface';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pb-products-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    ObjectTypeComponent,
    BinComponent,
    BinColorComponent,
    TableComponent,
  ],
  templateUrl: './products-menu.component.html',
  styleUrls: ['./products-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsMenuComponent implements OnDestroy {
  colorShemeBins;

  activeObjectType: unknown = null;
  rootModelBins: ObjModelEntityInterface[];
  modelBins: ObjModelEntityInterface[];
  subscriptionModel$: Subscription;
  subscriptionColorShemes$: Subscription;
  mountable = true;
  storageColorShemesBins;
  dataSubjectColorVarians$: Observable<any>;
  constructor(
    public resourceServise: ResourceService
  ) {
    const dropDownMenuDropId = ['8-0', '8-1', '8-2'];
    //console.log('rot', this.resourceServise.storageObjModelData)
    this.subscriptionModel$ = this.resourceServise
      .getData()
      .subscribe((products) => {
        this.rootModelBins = products.filter((product) => {
          if (
            dropDownMenuDropId.includes(product.dropId)
          ) {
            product.id = product._id;
            product.img =
              'assets/images/ProductImage/' +
              product.base_model_name +
              product.color_scheme[0] +
              '.png';
            product.model =
              'assets/models/akro-mils/' +
              product.base_model_name +
              product.color_scheme[0] +
              '.js';
            return product;
          }
        });
        this.resourceServise.storageObjModelData = this.rootModelBins;
        this.modelBins = this.rootModelBins;
      });
    this.subscriptionColorShemes$ = this.resourceServise
      .getAllColorSchemes()
      .subscribe((colorShemes) => {
        this.resourceServise.storageColorShemesData = colorShemes;
      });
    this.dataSubjectColorVarians$ = this.resourceServise.getDataColorVariants();
  }

  changeCheckMountable($event) {
    this.mountable = this.mountable ? false : true;
    const checkMountable = this.mountable ? 'mountable' : 'null';
    this.modelBins = this.rootModelBins.filter(
      (input) => input.wallInteraction === checkMountable
    );
  }

  setActiveObjectType(item: unknown) {
    this.activeObjectType = item;
  }

  ngOnDestroy(): void {
    if (this.subscriptionModel$) {
      this.subscriptionModel$.unsubscribe();
    }
    if (this.subscriptionColorShemes$) {
      this.subscriptionColorShemes$.unsubscribe();
    }
  }
}
