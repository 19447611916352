import { DOCUMENT } from '@angular/common';
// tslint:disable no-string-literal
import { Inject, Injectable } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { PreloaderService } from './preloader.service';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { ColorShemesInterface } from '../interfaces/colorShemes.interface';
import { productForShopingList, ResourceService } from './resource.service';
import { componentPartsOfTheModel } from '../shared/libs/componentPartsOfTheModel';
import { setCenterPosition } from 'src/app/shared/utils/setCenterPosition';
import { setZObjectPosition } from 'src/app/shared/utils/zPosition';
import { getMainObjectParams } from 'src/app/shared/utils/getMainObjectParams';
import { setStartBinPosition } from 'src/app/shared/utils/setStartBinPosition';
import { constructorChecks } from "@angular/cdk/schematics";


declare const v3d: any;
// declare const scene: any;
export type objectBin = { mtlUrl: string, objUrl: string, id: string, variants: any, modelDataVariants: string, length: number, height: number };
@Injectable({
  providedIn: 'root',
})
export class scene3DService {
  private _camera = new THREE.PerspectiveCamera();
  objects: any = [];
  canvas: any;
  draggableObjects: DragControls;
  scene: THREE.Scene = new THREE.Scene();
  raycaster: THREE.Raycaster = new THREE.Raycaster();
  renderer: THREE.WebGLRenderer;
  controls: OrbitControls;
  colorShemes: ColorShemesInterface[];
  main_object_coords: any;
  startMinXObjectPositionArray: number[];
  mainObjectName: any;
  mainObjectBaseName: any;
  mainObjectDropId: any;
  mainObject: any;
  progress: number;
  //rootModelName: string;
  _uuidRootObjectModel: string;
  domElement: HTMLElement;
  perspective: string;
  category: string;
  valueComponentPartsOfTheModel: string[] = [];
  variantsBins: [{ 'nameBins': string, 'variants': [], 'variantsAll': [] }] = [] as any;
  constructor(
    private preloaderService: PreloaderService,
    @Inject(DOCUMENT) private document: Document,
    private resourceService: ResourceService
  ) {
    componentPartsOfTheModel.forEach(part => {
      this.valueComponentPartsOfTheModel.push(...part.parts);
    })
  }
  private _reqAnim;
  get camera() {
    return this._camera;
  }
  set uuidRootObjectModel(name) {
    this._uuidRootObjectModel = name;
  }

  get uuidRootObjectModel() {
    return this._uuidRootObjectModel;
  }

  initScene(objModelData): HTMLElement {
    this.category = objModelData.model_category;
    this.createCanvas();
    this.createOrthographicCamera();
    this.createScene(objModelData);
    this.createControls();
    // this.helpers();
    this.domElement = this.renderer.domElement;
    return this.domElement;
  }
  createCanvas() {
    this.canvas = this.document.querySelector('#pb-configurator');
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
    });
  }

  createPerspectiveCameraCamera() {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;
    const fov = 45;
    const aspect = width / height; //холст по умолчанию
    const near = 1;
    const far = 1000;
    this._camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this._camera.position.set(0, 0, 200);
    this._camera.lookAt(400, 400, 0);
    // this._camera.position.set(0, 100, 10);

    // this._camera.up.set( 0, 77, 0);
    // this._camera.updateProjectionMatrix();
  }


  createOrthographicCamera() {
    const left = -150;
    const right = 150;
    const top = 76;
    const bottom = -76;
    const near = 1;
    const far = 1000;
    this._camera = new THREE.OrthographicCamera(
      left,
      right,
      top,
      bottom,
      near,
      far
    );
    this._camera.position.set(0, 0, 200);
    this._camera.clearViewOffset();
    this._camera.updateProjectionMatrix();
    this._camera.updateMatrix();
  }

  switchCamera() {
    if (this._camera instanceof THREE.PerspectiveCamera) {
      this.createOrthographicCamera();
      this.perspective = 'Orthographic';
    } else {
      this.createPerspectiveCameraCamera();
      this.perspective = 'Perspective';
    }
    this.createControls();
  }

  private resizeRendererToDisplaySize(renderer) {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;
    const needResize =
      this.canvas.width !== width || this.canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  createControls() {
    this.controls = new OrbitControls(this._camera, this.renderer.domElement);
    this.controls.listenToKeyEvents(window); // optional
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = 10;
    this.controls.maxDistance = 1000;
    this.controls.mouseButtons = {
      LEFT: null, //THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE, // THREE.MOUSE.PAN
    };

    this.controls.touches = {
      ONE: THREE.TOUCH.ROTATE,
      TWO: THREE.TOUCH.DOLLY_PAN,
    };

    this.controls.update();
  }

  clearObjectsArray() {
    if (this.objects.length) {
      this.objects = []
    }
  }

  createScene(objModelData) {
    // console.log('objModelData', objModelData);
    this.clearObjectsArray();
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#e8e6e6a1');
    const progressCb = (progress: number) => {
      this.progress = progress;
    };
    const errorCb = (err) => {
      console.error(err);
    };
    // добавим направленный источник света
    this.addLite();
    const mtlLoader = new MTLLoader();
    mtlLoader.load(
      objModelData.mtlUrl,
      (material) => {
        material.preload();
        const objLoader = new OBJLoader();
        objLoader.setMaterials(material);
        objLoader.load(objModelData.objUrl, (root: any) => {
          root.children.forEach((child) => {
            child.userData.name = 'root';
          })
          this.mainObjectName = objModelData.name;
          this.mainObjectBaseName = objModelData.baseName;
          this.mainObjectDropId = objModelData.dropId;
          this.addColor(root, objModelData.variants);
          // TODO this.addColorScheme(root, "TEXWHT")
          this.mainObject = root;
          this.scene.add(root);
          setCenterPosition(root);
          this.main_object_coords = getMainObjectParams(root, objModelData.baseName);
          window['scene'] = this.scene;
          window['camera'] = this.camera;
          window['objects'] = this.objects;
          this.copyAndPushToObjectArray(root, objModelData.name);
        });
      },
      progressCb,
      errorCb
    );
    this.render();
  }

  helpers() {
    this.scene.add(
      new THREE.ArrowHelper(
        this.raycaster.ray.direction,
        this.raycaster.ray.origin,
        300,
        0xff0000
      )
    );

    this.scene.add(new THREE.AxesHelper(150));

    this.scene.add(
      new THREE.ArrowHelper(
        new THREE.Vector3(1, 2, 0),
        new THREE.Vector3(0, 0, 0),
        1,
        0xff4800
      )
    );
  }

  sceneAdd(object) {
    this.scene.add(object);
    setZObjectPosition(object, this.mainObjectBaseName, this.main_object_coords, this.scene);
  }

  addLite() {
    // добавим источники освещения
    var hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
    hemiLight.position.set(0, 50, 0);
    // добавим полусферическии источник света
    this.scene.add(hemiLight);
    const dirLight = new THREE.DirectionalLight(0xffffff, 0.54);
    dirLight.position.set(-8, 12, 8);
    dirLight.castShadow = true;
    dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
    // добавим направленный источник света
    this.scene.add(dirLight);
  }
  addColorScheme(root, colorSchemename) { 
    const colorSheme =  this.getColorShemeObjModel(colorSchemename);
    root.traverse((node) => {      
        node.material?.color.setHex(colorSheme.materials[0].color);
    })
console.log(colorSheme)
  }
  addColor(root, variants) {

    if (this.category !== 'Configurator') {
      const colorSheme = variants.colorScheme?.name
        ? this.getColorShemeObjModel(variants.colorScheme.name)
        : root.children[0].color;
      const materials = colorSheme.materials ? colorSheme.materials : 'grey';

      root.traverse((node) => {
        const material = node.material?.name !== undefined ? materials.filter(
          (material) => material.materialName === node.material?.name
        )[0] : undefined;

        if (material) {
          node.material.color.setHex(material.color);
        }
      });
    } else {
      variants.forEach((variant) => {
        const colorSheme = this.getColorShemeObjModel(variant.colorScheme.name);
        const materials = colorSheme.materials;

        root.traverse((node) => {
          const material = node.material?.name !== undefined ? materials.filter(
            (material) => material.materialName === node.material?.name
          )[0] : undefined;

          if (material) {
            node.material.color.setHex(material.color);
          }
        })

      });
    }
  }

  filterPartsModelRoot(child) {
    if (!this.valueComponentPartsOfTheModel.includes(child.name)) {
      return child;
    }
  }

  addColorBins(root, color) {
    const colorSheme = color
      ? this.getColorShemeObjModel(color)
      : root.children[0].color;

    const materials = colorSheme.materials ? colorSheme.materials : 'grey';

    if (root.userData?.entity?.variantsAll) {
      root.userData.entity.variants = root.userData.entity.variantsAll.filter(
        (variant) => variant.colorScheme.name === color
      )[0];
    }
    root.traverse((node) => {
      let material;
      if (root.userData?.entity?.colorSheme) {
        material = node.material?.name !== undefined ? materials.filter(
          (material) => material.materialName === root.userData?.entity?.colorSheme
        )[0] : undefined;
      } else {
        material = node.material?.name !== undefined ? materials.filter(
          (material) => material.materialName === node.material?.name
        )[0] : undefined;
      }

      if (material) {
        this.resourceService.productForShopingList.forEach((product: productForShopingList) => {
          if (root.uuid === product.uuid) {
            product.colorSheme = color;
            product.variants = root.userData.entity.variants;
          }
        })
        node.material.name = (root.userData?.entity?.colorSheme ? root.userData?.entity?.colorSheme : node.material.name) + '_' + color;
        node.material.color.setHex(material.color);
      }
    });
  }

  public render() {
    if (this.resizeRendererToDisplaySize(this.renderer)) {
      const canvas = this.renderer.domElement;
      this._camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this._camera.updateProjectionMatrix();
    }
    this._reqAnim = requestAnimationFrame(() => {
      this.controls.update();
      this.render();
    });
    this.renderer.render(this.scene, this._camera);
  }

  stopAnimationRender() {
    window.cancelAnimationFrame(this._reqAnim);
  }

  private getColorShemeObjModel(colorSchemeName: string) {
    return this.resourceService.storageColorShemesData.filter(
      (sheme) => sheme.colorSchemeName === colorSchemeName
    )[0];
  }

  copyAndPushToObjectArray(root, rootType) {
    const newGroup = new THREE.Group();
    root.children.forEach((child) => {
      const mesh = new THREE.Mesh(child.geometry, child.material);
      mesh.userData.name = 'root';
      mesh.userData.rootType = rootType;
      newGroup.add(mesh);
    }); //
    this.uuidRootObjectModel = newGroup.uuid;
    this.objects.push(newGroup);
  }

  addModelScene(objectBin: objectBin) {
    const progressCb = (progress: number) => {
      this.progress = progress;
    };
    const errorCb = (err) => {
      console.error(err);
    };

    const mtlLoader = new MTLLoader();
    mtlLoader.load(
      objectBin.mtlUrl,
      (material) => {
        material.preload();
        const objLoader = new OBJLoader();
        objLoader.setMaterials(material);
        objLoader.load(objectBin.objUrl, (root) => {
          root.traverse(function (children) {
            if (children.isMesh) {
              children.userData.entity = { id: objectBin.id, variants: objectBin.variants, variantsAll: objectBin.modelDataVariants, colorSheme: children.material.name };
              this.resourceService.productForShopingList.push({ id: objectBin.id, variants: objectBin.variants, uuid: children.uuid, length: objectBin.length, height: objectBin.height });
            }
          }.bind(this));
          root.userData.entity = { variants: objectBin.variants, variantsAll: objectBin.modelDataVariants };

          this.addColorBins(root, objectBin.variants.colorScheme?.name);
          // this.objects.push(root);
          this.scene.add(root);
          setStartBinPosition(
            root, this.mainObjectName, this.mainObjectDropId, this.mainObjectBaseName, this.main_object_coords, this.objects, this.scene, this.camera, this.resourceService.productForShopingList
          );
        });
      },
      progressCb,
      errorCb
    );
  }
}
