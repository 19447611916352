import Drag2D from './Drag2D';
var DragCabinet2D = function (dependencyContainer) {
    Drag2D.prototype.constructor.call(this, dependencyContainer);

    this._constants = dependencyContainer.getService('constants');
    this._roomStuffFactory = dependencyContainer.getService('roomStuffFactory');
    this._roomStuff = dependencyContainer.getService('roomStuff');
    this._Scene2d = dependencyContainer.getService('Scene2d');
    this._step3Helper = dependencyContainer.getService('step3Helper');
    this._step3DataSrv = dependencyContainer.getService('step3DataSrv');
    this._roomStateManager = dependencyContainer.getService('roomStateManager');
    this._collisionSrv = dependencyContainer.getService('collisionSrvc');
    this._obbBuilder = dependencyContainer.getService('obbBuilder');
    this._objectGapHelperSrvc = dependencyContainer.getService('objectGapHelperSrvc');
    this._suiteUtilsSrvc = dependencyContainer.getService('suiteUtilsSrvc');
};

DragCabinet2D.prototype = Object.create(Drag2D.prototype);

DragCabinet2D.prototype.constructor = DragCabinet2D;

DragCabinet2D.prototype.dragging = function (newPosition, draggedObject) {
    this._step3Helper.highlightWallIfDefined(
        this._selectedWall,
        this._constants.HighlightState.DEFAULT
    );

    this._selectedWall = this.getIntersectedWall(draggedObject);

    if (this._selectedWall) {
        if (!draggedObject.userData.entity.disableAutoOrientation)
            draggedObject.rotation.z = this._selectedWall.rotation.y;
        this._step3Helper.highlightWallIfDefined(
            this._selectedWall,
            this._constants.HighlightState.SELECTED
        );
    }

    draggedObject.position.copy(newPosition);
};

DragCabinet2D.prototype.dragEnd = function (draggedObject) {
    if (this._selectedWall) {
        var snapPosition = this._step3Helper.snapToWallWithGap(draggedObject, this._selectedWall);

        var collided = this._collisionSrv.isCollide(draggedObject, this._staticObjs, {
            draggedObjectPosition: snapPosition,
            upAxis: 'z'
        });

        var allWalls = this._Scene2d.getWalls();
        var firstWallIndex = allWalls.indexOf(this._selectedWall);
        var Walls = this._Scene2d.getWalls();
        var secondWall = Walls[(firstWallIndex + 1) % Walls.length];
        if (draggedObject.userData.entity.isCornerSuite) {
            snapPosition = this._step3Helper.snapToWallWithGap(draggedObject, secondWall);
        }
        allWalls.splice(firstWallIndex, 1);
        var collidedWithWall = this._collisionSrv.isCollideWithWall(draggedObject, allWalls, {
            draggedObjectPosition: snapPosition,
            upAxis: 'z'
        });

        if (!collided && !collidedWithWall) {
            draggedObject.position.copy(snapPosition);
            if (draggedObject.userData.entity.isSuite) {
                this.addSuiteObjects(draggedObject);
            }
        } else {
            if (collided) {
                this.edgeSnap(draggedObject, collided);
                correctPosition.call(this, draggedObject);
            } else {
                this.edgeSnapWall(draggedObject, collidedWithWall);
                if (draggedObject.userData.entity.isSuite) {
                    this.addSuiteObjects(draggedObject);
                }
            }
        }
    } else {
        correctPosition.call(this, draggedObject);
    }
};

function correctPosition(obj) {
    if (
        obj.userData.entity.objectType === this._constants.ObjectType.WALL_PANEL ||
        obj.userData.entity.type === this._constants.CabinetType.WALL ||
        obj.userData.entity.isSuite
    ) {
        this._addObj = false;
        this._Scene2d.remove(obj);
    }
}

DragCabinet2D.prototype.addSuiteObjects = function (obj) {
    var objects = [],
        position = new THREE.Vector3();
    (groupId = Date.now()),
        (suiteUniqueId = Date.now()),
        (suiteInfo = {
            suiteUniqueId: suiteUniqueId,
            objects: []
        });
    for (var i = 0; i < obj.userData.entity.objects.length; i++) {
        var entity = _.cloneDeep(this._roomStuff.getById(obj.userData.entity.objects[i].id));
        if (obj.userData.entity.objects[i].accessoryId)
            entity.defaultAccessory = obj.userData.entity.objects[i].accessoryId;
        objects[i] = this._roomStuffFactory.buildRoomItem(entity);
        position = this._Scene2d.getSuitesNewPosition(obj.userData.entity, obj, i);
        objects[i].position.set(position.x, position.y, position.z);
        objects[i].rotation.set(0, 0, obj.rotation._z + obj.userData.entity.objects[i].rotation._z);
        objects[i].userData.groupId = groupId;
        objects[i].userData.suiteId = obj.userData.entity._id;
        objects[i].userData.suiteUniqueId = suiteUniqueId;
        objects[i].userData.isCornerSuite = obj.userData.entity.isCornerSuite;
        suiteInfo.objects.push(objects[i].uuid);
        this._Scene2d.addModel(objects[i]);
        this._roomStateManager.saveObjectColorScheme({
            objectId: objects[i].uuid,
            entityId: objects[i].userData.entity.id,
            scheme: this._suiteUtilsSrvc.getDefaultSuiteObject3dColorScheme(objects[i]),
            updateIfExist: false
        });
    }
    this._roomStateManager.saveSuiteObjects(suiteInfo);
    this._Scene2d.remove(obj);
};

DragCabinet2D.prototype.edgeSnap = function (draggedObject, collided) {
    var edgeSnapLength = 15,
        collidedEntity = collided.userData.entity,
        draggedPosition = this._step3Helper.snapToWall(
            draggedObject.position.clone(),
            this._selectedWall
        ),
        collidedPosition = this._step3Helper.snapToWall(
            collided.position.clone(),
            this._selectedWall
        ),
        len = draggedPosition.distanceTo(collidedPosition),
        shouldEdgeSnap = len > collidedEntity.length - edgeSnapLength;
    if (shouldEdgeSnap) {
        var direction = draggedPosition.clone().sub(collidedPosition).normalize();
        var draggedEntity = draggedObject.userData.entity;
        var wallDir = this._selectedWall.getWorldDirection();
        var draggedObjectBorder = draggedEntity.borders;

        var position = collidedPosition
            .clone()
            .add(direction.clone().multiplyScalar(collidedEntity.length / 2))
            .add(direction.clone().multiplyScalar(draggedEntity.length / 2))
            .add(wallDir.clone().multiplyScalar(collidedEntity.width / 2));

        if (draggedObjectBorder && draggedObjectBorder.wall)
            position.add(wallDir.multiplyScalar(parseFloat(draggedObjectBorder.wall)));

        var gap = this._objectGapHelperSrvc.getGapBetweenTwoObjects(draggedEntity, collidedEntity);

        if (gap !== 0) position.add(direction.multiplyScalar(gap));
        //remove this after add gap for wall panels on server
        else position.add(direction.multiplyScalar(0.15875));

        position.z = draggedObject.position.z;

        if (
            !this._collisionSrv.isCollide(draggedObject, this.getStaticObjects(draggedObject), {
                draggedObjectPosition: position,
                upAxis: 'z'
            })
        ) {
            draggedObject.position.copy(position);
        }
    }
};

DragCabinet2D.prototype.edgeSnapWall = function (draggedObject, collided) {
    collidedPosition = this._step3Helper.snapToWallWithGap(draggedObject, this._selectedWall);
    draggedObject.position.copy(collidedPosition);

    var collidedPosition = this._step3Helper.snapToWallWithGap(draggedObject, collided, true);
    draggedObject.position.copy(collidedPosition);
};

DragCabinet2D.prototype.isKnownObject = function (object) {
    var userData = object.userData;
    var entity = userData.entity;
    return (
        (   entity instanceof this._constants.RoomObject.Configurator ||
            entity instanceof this._constants.RoomObject.AkroMils ||
            entity instanceof this._constants.RoomObject.Cabinet ||
            entity instanceof this._constants.RoomObject.WallPanel ||
            entity instanceof this._constants.RoomObject.Table ||
            entity instanceof this._constants.RoomObject.Suite) &&
        !userData.suiteId
    );
};

DragCabinet2D.prototype.isEnabled = function () {
    return Drag2D.prototype.isEnabled.call(this) && this.isKnownObject(this.getSelectedObject());
};
export default DragCabinet2D 