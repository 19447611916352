function setItemAsync(key, data) {
  return new Promise((resolve, reject) => {
    try {
      const value = localStorage.setItem(key, data);
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
}
function getItemAsync(key) {
  return new Promise((resolve, reject) => {
    try {
      const value = localStorage.getItem(key);
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
}
export { setItemAsync, getItemAsync };

/**
 * // Використання асинхронної функції
      async function myFunction() {
        try {
           const value = await setItemAsync("room", JSON.stringify(room));
           console.log(value);
        } catch (error) {
           console.error(error);
           }
        }
*/
