import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { scene3DService } from './scene3D.service';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
import { AddEventListenerService } from './addEventListener.service';
import { SpriteService } from './sprite.service';
import { ResourceService } from './resource.service';
import constants from '../core_/services/constants';
import { getIntendBinFromPanel } from 'src/app/shared/utils/zPosition';
import { getMinMaxParams } from 'src/app/shared/utils/getMinMaxParams';
import { ColorsModalComponent } from "../components/modals/colors-modal/colors-modal.component";
declare const v3d: any;
@Injectable({
  providedIn: 'root',
})
export class StateService {
  cdnUrl = environment.cdnUrl;
  appLoaded: Subject<boolean> = new Subject();
  appReseted: Subject<boolean> = new Subject();
  progress: number;
  showProgress = false;
  sprite = false;
  private _camera;
  private _controls;
  private _objects: any = this.scene3DService.objects;
  private _objectDrag;
  private _main_object_coords: any = this.scene3DService.main_object_coords;
  private _mainObject: any;
  private _mainObjectName: any;
  private _mainObjectDropId: any;
  draggableObjects: DragControls;
  scene: THREE.Scene;
  raycaster: THREE.Raycaster = new THREE.Raycaster();
  //renderer: THREE.WebGLRenderer;
  group = new THREE.Group();
  domElement: HTMLElement;
  selector = 'mouseDrag';
  rootModelName: string;
  transform: TransformControls;
  objectDragEventAction;
  objectDragStartAction;
  objModelData;
  collisionPositionModel = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private scene3DService: scene3DService,
    private addEventListenerService: AddEventListenerService,
    private spriteService: SpriteService,
    private resourceService: ResourceService,
    private matDialog: MatDialog
  ) { }

  getProgressValue() {
    return this.progress;
  }

  isShowProgress() {
    return this.showProgress;
  }

  setSelectorMouse(id) {
    this.selector = id;
  }

  init(objModelData) {
    this.objModelData = objModelData;
    this.domElement = this.scene3DService.initScene(objModelData);
    this._camera = this.scene3DService.camera;
    this.scene = this.scene3DService.scene;
    this._mainObject = this.scene3DService.mainObject;
    this._mainObjectName = this.scene3DService.mainObjectName;
    this._mainObjectDropId = this.scene3DService.mainObjectDropId;
    this.createDragControl();
    // this.createTransformControl();

    this.addEventListenerService.addListenerMouseMove(
      this.domElement,
      (event) => {
        const spriteRaycaster = this.spriteService.raycasterIntersectObjectsSprite(this._camera, this.domElement, event);
        if (spriteRaycaster) {
          this.sprite = true;
        } else {
          this.sprite = false;
        }
      }
    );
    this.addEventListenerService.addListenerMouseUp(
      this.domElement,
      (event) => {
        this.setSelector(event);
      }
    );
    this.scene.add(this.createSprite());
  }

  selectSomeSceneObject(object) {
    if (object.userData.name === 'root') {
      return;
    }
    if (!this.sprite) {
      this.objectDragStartAction = object;
      this.setPanelBinColor();
    }
    if (object.uuid !== this.scene3DService.uuidRootObjectModel
      && (object instanceof THREE.Mesh) && !this.sprite
    ) {
      this.spriteService.setVisibleObjectControls(true, object)
      this.spriteService.objectOnWhichIsCalled = { object: object };
      return;
    }

    if (
      this.selector === 'mouseDelete' &&
      object.uuid !== this.scene3DService.uuidRootObjectModel
    ) {
      this.deleteObject(object)
    } else if (
      object.uuid === this.scene3DService.uuidRootObjectModel
    ) {
      //this.scene.background = new THREE.Color('#d9b9a7');
    } else {
      if (this.selector === 'mouseDragHelper') {
        // this.removeBoxHelper(object);
        // this.swichControlTransform(object, 'on');
      } else if (this.selector === 'mouseDrag') {
        // this.swichControlTransform(object, 'off');
        // this.addBoxHelper(object);
      } else {
      }
      /*  event.object.material.transparent = true;
      event.object.material.opacity = 0.5; */
    }
  }

  openDialog(component) {
    let dialogRef = this.matDialog.open(component, {
      // width: '800px',
      // enterAnimationDuration,
      // exitAnimationDuration,
    });
    dialogRef.updatePosition({
      bottom: "10px"
    })
  }

  setSelector(event) {
    const objectSprite = this.spriteService.raycasterIntersectObjects(this.scene, this._camera, this.domElement, event);
    if (objectSprite) {
      // console.log('objectSprite', objectSprite);
      // console.log('event', event);
      // this.selectSomeSceneObject(objectSprite);
      if (objectSprite?.userData?.name === 'root') {
        if (!this.sprite) {
          this.spriteService.setVisibleObjectControls(false)
        }
        return;
      }
      if (objectSprite instanceof THREE.Sprite) {
        if (objectSprite.name === 'mouseDelete') {
          // console.log('this.spriteService.objectOnWhichIsCalled');
          this.deleteObject(this.spriteService.objectOnWhichIsCalled);
          this.selector = 'mouseDrag';
        } else if (objectSprite.name === 'mouseAdd') {
          this.cloneObject(event, this.spriteService.objectOnWhichIsCalled.object);
        } else if (objectSprite.name === 'mouseColorChange') {
          this.openDialog(ColorsModalComponent)
        } else {
          // this.selector = objectSprite.name;
        }

      }
    } else {
      this.spriteService.setVisibleObjectControls(false)
    }
  }

  createTransformControl() {
    this.transform = new TransformControls(this._camera, this.domElement);
  }

  createSprite() {
    return this.spriteService.objectControls;
  }

  createDragControl() {
    this._controls = new DragControls(
      this.scene3DService.objects,
      this._camera,
      this.domElement
    );
    this.draggableObjects = this._controls.getObjects();

    this._controls.addEventListener(
      'hoveron',
      function (event) {
        // console.log("hoveron", event.object)
        // event.object.material.wireframe = true;
      }.bind(this)
    );
    this._controls.addEventListener(
      'hoveroff',
      function (event) {
        // event.object.material.wireframe = false;
      }.bind(this)
    );
    this._controls.addEventListener(
      'dragstart',
      function (event) {
        if (event.object.userData.name === 'root') {
          return;
        }
        if (event.object.uuid !== this.scene3DService.uuidRootObjectModel
          && (event.object instanceof THREE.Mesh) && !this.sprite
        ) {
          this.objectDragStartAction = event.object;
          this.setPanelBinColor();
          this.spriteService.setVisibleObjectControls(true, event.object)
          this.spriteService.objectOnWhichIsCalled = event;
          return;
        }

        if (
          this.selector === 'mouseDelete' &&
          event.object.uuid !== this.scene3DService.uuidRootObjectModel
        ) {
          // console.log('event');
          this.deleteObject(event)
        } else if (
          event.object.uuid === this.scene3DService.uuidRootObjectModel
        ) {
          //this.scene.background = new THREE.Color('#d9b9a7');
        } else {
          if (this.selector === 'mouseDragHelper') {
            // this.removeBoxHelper(event.object);
            // this.swichControlTransform(event.object, 'on');
          } else if (this.selector === 'mouseDrag') {
            // this.swichControlTransform(event.object, 'off');
            // this.addBoxHelper(event.object);
          } else {
          }
          /*  event.object.material.transparent = true;
          event.object.material.opacity = 0.5; */
        }
      }.bind(this)
    );

    this._controls.addEventListener(
      'drag',
      function (event) {
        // console.log('event', event.object);
        this.dragSpriteOnOff(event);
        this.collisionLogic(event.object, this.scene3DService.objects);
        this.setCorrectPositionForDragingObject(event);
      }.bind(this)
    );

    this._controls.addEventListener(
      'dragend',
      function (event) {
        this.removeBoxHelper(event.object);
        // this.swichControlTransform(event.object, 'off');
        if (
          this.selector === 'mouseAdd' &&
          event.object.uuid !== this.scene3DService.uuidRootObjectModel
        ) {
          // const objCopy = this.copyModel(event, event.object);
          // this.scene3DService.objects.push(objCopy);
          // this.scene3DService.sceneAdd(objCopy);
          this.cloneObject(event, event.object);
        }
        if (
          this.selector === 'mouseDrag' &&
          event.object.uuid !== this.scene3DService.uuidRootObjectModel
        ) {
          this.backObjectToStartPositionAfterIntersection(event.object, this.scene3DService.objects);
        }
        // this.scene.background = new THREE.Color('white');
        // event.object.position.z = 0;
        //  this.scene3DService.render();
      }.bind(this)
    );
  }

  backObjectToStartPositionAfterIntersection(object, objectsArray) {
    let isIntersect = false;
    // console.log('this._mainObjectName', this._mainObjectName);
    let isWithRootIntersect = this.scene3DService.mainObjectName.toLowerCase().includes('panel')
      || this.scene3DService.mainObjectName.toLowerCase().includes('rack')
      || this.scene3DService.mainObjectName.toLowerCase().includes('rail')
      || this.scene3DService.mainObjectName.toLowerCase().includes('strip')
      || false;
    // console.log('isWithRootIntersect', isWithRootIntersect);
    if (object.userData.name !== 'root') {
      let updatedObjectsArray = this.updateObjectsArray(objectsArray)
      const targetObject = new THREE.Box3().setFromObject(object);
      updatedObjectsArray.forEach(function (element) {
        if (element.uuid !== object.uuid) {
          if (isWithRootIntersect) {
            if (element.userData.name !== 'root') {
              const neighbourObject = new THREE.Box3().setFromObject(element);
              if (targetObject.intersectsBox(neighbourObject)) {
                object.position.y = object.startObjectPosition.y
                isIntersect = true;
              }
            }
          } else {
            const neighbourObject = new THREE.Box3().setFromObject(element);
            if (targetObject.intersectsBox(neighbourObject)) {
              object.position.y = object.startObjectPosition.y
              isIntersect = true;
            }
          }
        }
      })
      if (!isIntersect) {
        object.startObjectPosition.y = object.position.y
      }
    }
  }

  updateObjectsArray(objectsArray) {
    let newObjectsArray: any[] = [];
    objectsArray.forEach((element) => {
      newObjectsArray = [...newObjectsArray, ...element.children];
    });
    return newObjectsArray
  }

  dragSpriteOnOff(event, swith = 'off') {
    if (swith === 'off') {
      this.spriteService.setVisibleObjectControls(false);
      return
    }
    if (event.object.uuid !== this.scene3DService.uuidRootObjectModel) {
      this.spriteService.setPositionObjectControls(event.object.position);
    } else {
      this.spriteService.setVisibleObjectControls(false);
    }

  }
  
  cloneObject(event, object) {
    // console.log('cloneObject');
    this._objectDrag = object;
    const objsArrayCopy = this.copyManyModels(event, object);
    objsArrayCopy.forEach((el) => {
      el.userData = object.userData;
      this.resourceService.productForShopingList.push({ id: el.userData.entity.id, variants: el.userData.entity.variants, uuid: el.uuid });
      this.scene3DService.objects.push(el);
      this.scene3DService.sceneAdd(el);
    });
    this.objectDragEventAction = event;
  }

  setPanelBinColor() {
    this.resourceService.doSetColorVariantsBins(this.objectDragStartAction.userData.entity.variantsAll)
  }

  setColorBins(color) {
    const selectedObject = this.scene.getObjectByProperty('uuid', this.objectDragStartAction.uuid);
    this.scene3DService.addColorBins(selectedObject, color.colorSchemeName);
    this.resourceService.productForShopingList.indexOf(this.objectDragStartAction.uui)
  }

  deleteObject(event) {
    this.spriteService.setVisibleObjectControls(false)
    // this.removeBoxHelper(event.object);
    // this.swichControlTransform(event.object, 'off');
    const selectedObject = this.scene.getObjectByProperty('uuid', event.object.uuid);
    const selectedObjectParent = selectedObject.parent
    selectedObjectParent.remove(selectedObject);
    this.scene.remove(selectedObjectParent);
    this.resourceService.productForShopingList.splice(this.resourceService.productForShopingList.indexOf(event.object.uuid));
    // console.log(this.resourceService.productForShopingList)
    // this.scene3DService.objects = [...this.scene3DService.objects.filter(el => event.object.uuid !== el.children[0].uuid)]
  }

  switchCamera() {
    this.scene3DService.switchCamera();
    this._camera = this.scene3DService.camera;
    this.createDragControl();
    // this.createTransformControl();
  }

  removeBoxHelper(object) {
    object.remove(object.getObjectByName('BoxHelper'));
    object.remove(object.getObjectByName('VertexNormalsHelper'));
  }

  swichControlTransform(object, swichKey) {
    const nameTransform = 'Transform';
    if (swichKey === 'on') {
      this.transform.attach(object);
      this.transform.name = nameTransform;
      this.scene.add(this.transform);
    } else if (swichKey === 'off') {
      this.transform.detach();
      const selectedObject = this.scene.getObjectByName(nameTransform);
      this.scene.remove(selectedObject);
    }
  }

  addBoxHelper(object) {
    const helper = new THREE.BoxHelper(object, 0xff0000);
    helper.geometry.center(object);
    helper.name = 'BoxHelper';
    object.add(helper);
  }

  addVertexNormalsHelper(object) {
    const helper = new VertexNormalsHelper(object, 2, 0x00ff00, 1);
    helper.geometry.center(object);
    helper.name = 'VertexNormalsHelper';
    object.add(helper);
  }
  getCenterPoint(parentObj, childrenObj) {
    childrenObj.geometry.center(parentObj);
  }

  controlDrags(event) {
    // this._controls.transformGroup = false;
    this.draggableObjects.push(...this.scene3DService.objects);
    // this.scene3DService.render();
  }


  collisionLogic(object, objectsArray) {
    let params = this.scene3DService.main_object_coords;
    let updatedObjectsArray = this.updateObjectsArray(objectsArray)
    const root = this.scene3DService.uuidRootObjectModel;
    updatedObjectsArray.forEach(function (element) {
      if (element.uuid !== object.uuid) {
        const targetObject = new THREE.Box3().setFromObject(object);
        const neighbourObject = new THREE.Box3().setFromObject(element);
        const neighbourPosition = new THREE.Vector3(
          (neighbourObject.max.x + neighbourObject.min.x) / 2,
          (neighbourObject.max.y + neighbourObject.min.y) / 2,
          (neighbourObject.max.z + neighbourObject.min.z) / 2
        );
        const itemWidth = targetObject.max.x - targetObject.min.x;
        const itemHeight = targetObject.max.y - targetObject.min.y;
        const itemLength = targetObject.max.z - targetObject.min.z;
        const neighbourWidth = neighbourObject.max.x - neighbourObject.min.x;
        const neighbourHeight = neighbourObject.max.y - neighbourObject.min.y;
        const neighbourLength = neighbourObject.max.z - neighbourObject.min.z;
        if (targetObject.intersectsBox(neighbourObject)) {
          if (
            // object.position.y > neighbourObject.max.y
            // || (object.position.y <= neighbourObject.max.y && object.position.y > neighbourObject.min.y)
            object.position.y > neighbourObject.max.y
            && Math.abs(object.position.y - neighbourObject.max.y) < itemHeight
            // && Math.abs(object.position.x - neighbourPosition.x) < itemWidth / 2 + neighbourWidth / 2
          ) {
            object.position.y = neighbourObject.max.y + itemHeight / 2;
          }
          if (
            // object.position.y < neighbourObject.min.y
            // || (object.position.y >= neighbourObject.min.y && object.position.y < neighbourObject.max.y)
            object.position.y < neighbourObject.min.y
            && Math.abs(object.position.y - neighbourObject.min.y) < itemHeight
            // && Math.abs(object.position.x - neighbourPosition.x) < itemWidth / 2 + neighbourWidth / 2
          ) {
            object.position.y = neighbourObject.min.y - itemHeight / 2;
          }
          // if (
          //   object.position.x < neighbourObject.min.x &&
          //   Math.abs(object.position.x - neighbourObject.min.x) < itemWidth &&
          //   Math.abs(object.position.y - neighbourPosition.y) <
          //   itemHeight / 2 + neighbourHeight / 2
          // ) {
          //   object.position.x = neighbourObject.min.x - itemWidth / 2;
          // }
          // if (
          //   object.position.x > neighbourObject.max.x &&
          //   Math.abs(object.position.x - neighbourObject.max.x) < itemWidth &&
          //   Math.abs(object.position.y - neighbourPosition.y) <
          //   itemHeight / 2 + neighbourHeight / 2
          // ) {
          //   object.position.x = neighbourObject.max.x + itemWidth / 2;
          // }
        }
        let collisionXMin, collisionXMax, collisionYMin, collisionYMax = false
        if (object.position.y < params.min.y + itemHeight / 2) {
          collisionXMin = true;
          object.position.y = params.min.y + itemHeight / 2;
        }
        if (object.position.y > params.max.y - itemHeight / 2) {
          collisionXMax = true;
          object.position.y = params.max.y - itemHeight / 2;
        }
        if (object.position.x < params.min.x + 1 + itemWidth / 2) {
          collisionYMin = true;
          object.position.x = params.min.x + 1 + itemWidth / 2;
        }
        if (object.position.x > params.max.x - 1 - itemWidth / 2) {
          collisionYMax = true;
          object.position.x = params.max.x - 1 - itemWidth / 2;
        }
        if (collisionXMin || collisionXMax || collisionYMin || collisionYMax) {
          this.collisionPositionModel = true;
        } else {
          this.collisionPositionModel = false;
        }
      }
    }.bind(this));
  }

  setMainParams(object) {
    let newBox = new THREE.Box3().setFromObject(object);
    if (newBox.max.x > this._main_object_coords.max.x)
      this._main_object_coords.max.x = newBox.max.x;
    if (newBox.max.y > this._main_object_coords.max.y)
      this._main_object_coords.max.y = newBox.max.y;
    if (newBox.max.z > this._main_object_coords.max.z)
      this._main_object_coords.max.z = newBox.max.z;
    if (newBox.min.x < this._main_object_coords.min.x)
      this._main_object_coords.min.x = newBox.min.x;
    if (newBox.min.y < this._main_object_coords.min.y)
      this._main_object_coords.min.y = newBox.min.y;
    if (newBox.min.z < this._main_object_coords.min.z)
      this._main_object_coords.min.z = newBox.min.z;
  }

  cloneModel(event, object) {
    const rect = this.domElement.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
    const y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
    var obj = object.clone();
    obj.position.x = this._objectDrag.position.x + 30;
    obj.position.y = this._objectDrag.position.y;
    obj.name = new Date().getTime();
    const group = new THREE.Group();
    return group.add(obj);
  }

  copyModel(event, object) {
    const rect = this.domElement.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
    const y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
    const obj = new THREE.Mesh(object.geometry, object.material.clone());
    const objParams = new THREE.Box3().setFromObject(obj);
    const objectWidth = Math.abs(objParams.max.x - objParams.min.x);
    obj.position.x = this._objectDrag.position.x + objectWidth;
    obj.position.y = this._objectDrag.position.y;
    obj.name = new Date().getTime();
    const group = new THREE.Group();
    return group.add(obj);
  }


  copyManyModels(event, object) {
    let _main_object_coords = this.scene3DService.main_object_coords;
    const objParams = new THREE.Box3().setFromObject(object);
    const objectWidth = Math.abs(objParams.max.x - objParams.min.x);
    const distanceToRightBorder = Math.abs(_main_object_coords.max.x - objParams.max.x);
    const distanceToLeftBorder = Math.abs(_main_object_coords.min.x - objParams.min.x);
    const distance = distanceToRightBorder >= distanceToLeftBorder ? distanceToRightBorder : distanceToLeftBorder;
    const needsObjectsQuantityInRow = Math.floor(distance / objectWidth);
    const rest = distance - (objectWidth * needsObjectsQuantityInRow);
    const stepForItemDrawing = objectWidth + rest / (needsObjectsQuantityInRow);
    let newObjectsArray: any[] = [];
    for (
      let itemCount = objParams.max.x - objectWidth / 2 + stepForItemDrawing;
      itemCount < _main_object_coords.max.x;
      itemCount = itemCount + stepForItemDrawing
    ) {
      const obj = new THREE.Mesh(object.geometry, object.material.clone());
      const group = new THREE.Group();
      obj.position.x = itemCount;
      obj.position.y = object.position.y;
      obj.position.z = object.position.z;
      obj.name = String(new Date().getTime());
      obj.side = object.side;
      obj.startObjectPosition = {
        x: itemCount,
        y: object.position.y,
        z: object.position.z
      };
      obj.rotation.y = object.rotation.y;
      obj.userData.entity = {
        id: object.userData.entity.id,
        colorSheme: object.userData.entity.colorSheme,
        variants: object.userData.entity.variants,
        variantsAll: object.userData.entity.variantsAll
      };
      // this.addColorBins(obj, object.userData.entity.variants.colorScheme?.name);
      if (!this.checkIntersection(obj)) {
        group.add(obj);
        newObjectsArray.push(group);
      }
    }
    // left row fulling
    for (
      let itemCount = objParams.min.x + objectWidth / 2 - stepForItemDrawing;
      itemCount > _main_object_coords.min.x;
      itemCount = itemCount - stepForItemDrawing
    ) {
      const obj = new THREE.Mesh(object.geometry, object.material.clone());
      const group = new THREE.Group();
      obj.position.x = itemCount;
      obj.position.y = object.position.y;
      obj.position.z = object.position.z;
      obj.name = String(new Date().getTime());
      obj.side = object.side;
      obj.startObjectPosition = {
        x: itemCount,
        y: object.position.y,
        z: object.position.z
      };
      obj.rotation.y = object.rotation.y;
      obj.userData.entity = {
        id: object.userData.entity.id,
        colorSheme: object.userData.entity.colorSheme,
        variants: object.userData.entity.variants,
        variantsAll: object.userData.entity.variantsAll
      };
      // console.log('this.checkIntersection(obj)', this.checkIntersection(obj));
      if (!this.checkIntersection(obj)) {
        group.add(obj);
        newObjectsArray.push(group);
      }
    }
    return newObjectsArray;
  }

  checkIntersection(object) {
    const objectParams = new THREE.Box3().setFromObject(object);
    const objectsArr = this.scene3DService.objects;
    let newObjectsArray: any[] = [];
    let result = false;
    objectsArr.forEach((element) => {
      newObjectsArray = [...newObjectsArray, ...element.children];
    });
    // delete root object
    newObjectsArray = [...newObjectsArray.filter(el => el.userData.name !== 'root')]
    // delete our object
    newObjectsArray = newObjectsArray.filter(el => el.uuid !== object.uuid)
    for (let index = 0; index < newObjectsArray.length; index++) {
      let neighbourObjectParams = new THREE.Box3().setFromObject(
        newObjectsArray[index]
      );
      if (objectParams.intersectsBox(neighbourObjectParams)) {
        result = true;
      }
    }
    return result;
  }


  getMainObjectParams(root) {
    const rootParams = getMinMaxParams(root)
    rootParams.min.x = rootParams.min.x + constants.BORDER_INDENT
    rootParams.max.x = rootParams.max.x - constants.BORDER_INDENT
    rootParams.min.y = rootParams.min.y + constants.BORDER_INDENT
    rootParams.max.y = rootParams.max.y - constants.BORDER_INDENT
    return rootParams;
  }

  getMaxMinCoordsBySceneObjects(object) {
    const mainParams = this._main_object_coords;
    const objectsArr = this.scene3DService.objects;
    let newObjectsArray: any[] = [];
    objectsArr.forEach((element) => {
      newObjectsArray = [...newObjectsArray, ...element.children];
    });
    const params = {
      min: { x: mainParams.min.x, y: mainParams.min.y, z: mainParams.min.z },
      max: { x: mainParams.max.x, y: mainParams.max.y, z: mainParams.max.z },
    };
    objectsArr.forEach((el) => {
      const newBox = new THREE.Box3().setFromObject(el);
      if (newBox.max.x < params.max.x) params.max.x = newBox.max.x;
      if (newBox.max.y < params.max.y) params.max.y = newBox.max.y;
      if (newBox.max.z < params.max.z) params.max.z = newBox.max.z;
      if (newBox.min.x > params.min.x) params.min.x = newBox.min.x;
      if (newBox.min.y > params.min.y) params.min.y = newBox.min.y;
      if (newBox.min.z > params.min.z) params.min.z = newBox.min.z;
    });
    return params;
  }

  getMainObjectWidth() {
    let main_object_coords = this.getMainObjectParams(window['scene'])
    return Math.abs(
      main_object_coords.max.x - main_object_coords.min.x
    );
  }

  setCorrectPositionForDragingObject(event) {
    let intendBinFromPanel = getIntendBinFromPanel(this.scene3DService.mainObjectBaseName) || 0
    this._main_object_coords = this.scene3DService.main_object_coords;
    // this._startMinXObjectPosition = this.scene3DService.startMinXObjectPosition;
    let lastEvent = event;
    let targetObject = event.object;
    if (lastEvent.type && lastEvent.type === 'drag' && targetObject.userData.name !== 'root') {
      const newBox = new THREE.Box3().setFromObject(targetObject);
      const itemWidth = newBox.max.x - newBox.min.x;
      const itemHeight = newBox.max.y - newBox.min.y;
      const itemLength = newBox.max.z - newBox.min.z;
      if (newBox.min.z < this._main_object_coords.min.z || newBox.min.z > this._main_object_coords.min.z) {
        this.scene.traverse((child) => {
          if (child.uuid === targetObject.uuid) {
            child.position.z = targetObject.startObjectPosition.z;
            // child.position.z = this._main_object_coords.min.z + intendBinFromPanel + itemLength / 2;
          }
        });
      }
      if (newBox.min.x < targetObject.startObjectPosition.x || newBox.min.x > targetObject.startObjectPosition.x) {
        this.scene.traverse((child) => {
          if (child.uuid === targetObject.uuid) {
            child.position.x = targetObject.startObjectPosition.x;
          }
        });
      }
    }
  }

  calculateAndCreateVariants(group: any) {
    let groupArray = [];
    const newGroup = new THREE.Group();
    const box = new THREE.Box3();
    let newBox = box
      .copy(group.children[0].geometry.boundingBox)
      .applyMatrix4(group.children[0].matrixWorld);
    const itemWidth = newBox.max.x - newBox.min.x;
    const itemHeight = newBox.max.y - newBox.min.y;
    const itemLength = newBox.max.z - newBox.min.z;
    const mainMinX = this._main_object_coords.min.x;
    const mainMaxX = this._main_object_coords.max.x;
    const mainMinY = this._main_object_coords.min.y;
    const mainMaxY = this._main_object_coords.max.y;
    const mainMinZ = this._main_object_coords.min.z;
    const mainMaxZ = this._main_object_coords.max.z;
    const mainLength = mainMaxZ - mainMinZ;
    const mainWidth = mainMaxX - mainMinX;
    const mainHeight = mainMaxY - mainMinY;
    const rowQuality = Math.floor(mainWidth / (itemWidth + 1));
    const colQuality = Math.floor(mainHeight / (itemHeight + 1));
    const position = { x: 0, y: 0, z: 0 };
    if (mainLength > itemLength) {
      position.z = mainMinZ + 1;
    } else {
      position.z = mainMaxZ + itemLength / 2;
    }
    for (
      let y = mainMinY + itemHeight / 2 + 1;
      y <= mainMaxY - 1;
      y = y + itemHeight + 1
    ) {
      position.y = y;
      for (
        let x = mainMinX + itemWidth / 2 + 1;
        x <= mainMaxX - 1;
        x = x + itemWidth + 1
      ) {
        let newGroup = new THREE.Group();
        const geometry = new THREE.BoxGeometry(
          itemWidth,
          itemHeight,
          itemLength
        );
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

        const cubeA = new THREE.Mesh(geometry, material);
        position.x = x;
        cubeA.position.set(position.x, position.y, position.z);
        newGroup.add(cubeA);
        //@ts-ignore
        this.scene.add(newGroup);
        //@ts-ignore
        groupArray.push(newGroup);
      }
    }
  }

  private switchObjectsInGroups(groups: string[], visible: boolean): void {
    groups.forEach((name) => {
      const group = this.scene.getObjectByName(name);

      if (!group) {
        return;
      }

      group.traverse((child) => {
        if (
          child &&
          !child.name.includes('v3d_Parent_Inverse_Proxy') &&
          child.visible === !visible
        ) {
          child.visible = visible;
        }
      });
    });
  }
}
