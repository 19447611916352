import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  Inject,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';

import { StateService } from 'src/app/services/state.service';
import { FormsModule } from '@angular/forms';
import { ExporterObjMtlService } from 'src/app/services/exporterObjMtl.service';
import { ResourceService } from 'src/app/services/resource.service';
import { of, Subscription } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { modelSaveProduct } from 'src/app/interfaces/saveProductModel.interface';
import { LocalStorageExporterObjMtlService } from 'src/app/services/localStorageExporterObjMtl.service';
import { scene3DService } from 'src/app/services/scene3D.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { getSceneHeight } from 'src/app/shared/utils/getSceneHeight';
import { getSceneWidth } from 'src/app/shared/utils/getSceneWidth';

@Component({
  selector: 'pb-configurator',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './configurator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('canvasContainer') canvasContainer: ElementRef;
  upload = true;
  modelOld: any;
  _obj: any; //ObjModelInterface;
  _patch: string;
  templateUrl: string;
  model_name: string;
  model_origin_name: string;
  model_base_name: string;
  model_dropId: string;
  model_path: string;
  model_img: string;
  model_category: string;
  enableSelection: boolean = true;
  subscription$: Subscription;
  subscription1$: Subscription;
  radioButton = [
    { name: 'Drag', id: 'mouseDrag', ischeck: true },
    { name: 'DragHelper', id: 'mouseDragHelper', ischeck: false },
    { name: 'Delete', id: 'mouseDelete', ischeck: false },
    { name: 'Add', id: 'mouseAdd', ischeck: false },
  ];
  timeId;
  constructor(
    @Inject('$location')
    private $location: any,
    @Inject(DOCUMENT) private document: Document,
    private stateService: StateService,
    private elRef: ElementRef,
    private exporterObjMtlService: ExporterObjMtlService,
    private localStorageExporterObjMtlService: LocalStorageExporterObjMtlService,
    private scene3DService: scene3DService,
    private resourceService: ResourceService,
    private loader: LoaderService
  ) {
    this._obj = JSON.parse(this.$location.search().obj);
    this._patch = JSON.parse(this.$location.search().patch);
    //console.log('this._obj.entity', this._patch);
    this.templateUrl = this._obj.locationHash[0];
    this.model_img = this._obj.entity.img;
    this.model_category = this._obj.entity.category;
    this.model_origin_name = this._obj.entity.name;
    this.model_base_name = this._obj.entity.baseName;
    this.model_dropId = this._obj.entity.dropId;
    this.model_name = this._obj.entity.model
      .split('/')
      .slice(-1)[0]
      .split('.')[0];
    this.model_path =
      this._obj.entity.model
        .split('/')
        .slice(0, this._obj.entity.model.split('/').length - 1)
        .join('/') + '/';
    if (this.model_category !== 'Configurator') {
      this.resourceService
        .getDataById(this._obj.entity.id)
        .subscribe((data) => {
          this.modelOld = data[0];
          console.log('data[0]', data[0]);
        });
    } else {
      this.resourceService
        .getConfiguratorDataById(this._obj.entity.id)
        .subscribe((data) => {
          this.modelOld = data[0];
        });
    }
  }
  ischeck = true;
  fileUrl;
  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    const id = (event.target as HTMLElement).id;
    if (id === 'pb-configurator') {
      // this.stateService.controlDrags(event);
    } else if (id === 'download') {
      // this.downloadModel();
    } else if (id === 'goWithoutSaving') {
      this.goWithoutSaving(this.templateUrl);
    } else if (id === 'template') {
      this.goBackUploadTemplate(this.templateUrl);
    } else {
      this.stateService.setSelectorMouse(id);
      setTimeout(() => {
        this.setChecked(this.getElementSelector('#', id).id);
      }, 1);
    }
  }

  goWithoutSaving(templateUrl) {
    const currentUrl = window.location.protocol;
    window.localStorage.removeItem('modelId');
    window.location.assign(
      `${currentUrl}/#/templates/${templateUrl}/configurator/notId/${this._patch}`
    );
  }

  widthBinConfigurator() {
    return getSceneWidth();
  }


  heightBinConfigurator() {
    return getSceneHeight();
  }
  productForShopingList() {
    let shop: any = [];

    this.scene3DService.scene.children.forEach(item => {
      if (item.type === 'Group' && item.userData.entity) {
        const children = item.children[0]
        shop.push({ id: children.userData.entity.id, variants: children.userData.entity.variants, uuid: children.uuid });
      }
    })
    return shop;
  }

  goBackUploadTemplate(templateUrl): void {
    console.log('templateUrl', templateUrl);
    this.loader.showLoadingService();
    this.loader.updatePreloaderProgress(1);
    const nameModel = 'configurator' + Date.now();
    const data = {
      mtl: this.exporterObjMtlService.exporterMtl(),
      obj: this.exporterObjMtlService.exporterModel(nameModel),
    };

    const upload = [
      {
        file: data.mtl,
        imageFolder: 'model',
        newName: `${nameModel}.mtl`,
        id: null,
      },
      {
        file: data.obj,
        imageFolder: 'model',
        newName: `${nameModel}.obj`,
        id: null,
      },
    ];
    this.modelOld.modelOldId = this.modelOld._id;
    this.modelOld.first_model_name = this.modelOld.category !== 'Configurator' ? this.modelOld.name : this.modelOld.first_model_name;
    this.modelOld.name = `Generator${nameModel}`;
    this.modelOld.base_model_name = `${nameModel}`;
    this.modelOld.category = 'Configurator';
    this.modelOld.left_menu_alias = 'Configurator';
    // this.modelOld.productForShopingList = this.resourceService.productForShopingList;
    this.modelOld.productForShopingList = this.productForShopingList();
    console.log(this.modelOld.productForShopingList, this.productForShopingList())
    if (this.scene3DService.variantsBins.length > 0) {
      this.scene3DService.variantsBins.forEach((variantsBinsPart) => {
        this.modelOld.variants.push(variantsBinsPart.variants);
      });
      this.modelOld.variantsBinsPart = this.scene3DService.variantsBins;
    }

    const category = ['Louvered Panel', 'Rail/Rail Rack'];
    //const nameRail = ['30148', '30632'];
    if (category.includes(this.modelOld.dropName)) {
      this.modelOld.width_configurator = this.widthBinConfigurator();
      this.modelOld.height_configurator = this.heightBinConfigurator();
    } else {
      this.modelOld.width_configurator_shelfing = this.widthBinConfigurator();
      //}
    }

    delete this.modelOld._id;

    this.subscription$ = this.resourceService
      .create(this.modelOld)
      .pipe(
        map((dataMap: any) => {
          if (!dataMap) {
            return [];
          }
          console.log(dataMap);
          return dataMap;
        }),
        mergeMap((dataMapvalue): any => {
          if (dataMapvalue._id && upload.length) {
            window.localStorage.setItem('modelId', dataMapvalue._id);
            upload.map((fileSet) => {
              if (fileSet.file) {
                console.log('map', fileSet.imageFolder);
                this.resourceService
                  .upload({
                    value: fileSet.file,
                    imageFolder: fileSet.imageFolder,
                    newName: fileSet.newName,
                    id: fileSet.id || dataMapvalue._id,
                  })
                  .subscribe((data: any) => {
                    console.log('upload', data.code);
                    window.localStorage.setItem('reload', 'true');
                    const currentUrl = window.location.protocol;
                    if (+data.code === 0) {
                      let i = 5;
                      this.timeId = setInterval(function () {
                        this.loader.updatePreloaderProgress(++i);
                        console.log(i)
                      }.bind(this), 1000);
                      window.location.assign(
                        `${currentUrl}/#/templates/${templateUrl}/configurator/${dataMapvalue._id}/${this._patch}`
                      );
                    } else {
                      console.error(
                        'error convert .obj',
                        data.code,
                        '---',
                        data
                      );
                      this.loader.hideLoadingService();
                    }
                  });
              }
            });
          }
          return [dataMapvalue];
        }),
        catchError((error) => of(error))
      )
      .subscribe((data) => { });
  }

  /**
   * Set first status radioButton
   * @param selector
   */
  setChecked(selector): void {
    this.radioButton.map((item) => {
      if (item.id !== selector) {
        (this.document.getElementById(item.id) as HTMLInputElement).checked =
          false;
      } else {
        (this.document.getElementById(selector) as HTMLInputElement).checked =
          true;
      }
    });
  }

  getElementSelector(type, selector): HTMLElement {
    let element = this.elRef.nativeElement.querySelector(type + selector);
    return element;
  }

  ngOnInit(): void {
    window.addEventListener('keydown', this.onKeyDown.bind(this));
    window.addEventListener('keyup', this.onKeyUp.bind(this));
    let objModelData = {
      mtlUrl: this.model_path + this.model_name + '.mtl',
      objUrl: this.model_path + this.model_name + '.obj',
      imgUrl: this.model_img,
      model_category: this.model_category,
      variants: (() => {
        if (this.model_category !== 'Configurator') {
          const variants = this._obj.entity.variants
            ? this._obj.entity.variants.filter(
              (entity) => entity.hikId === this.model_name
            )[0]
            : undefined;
          // this.modelOld.variants.push(...variants)
          return variants !== undefined ? variants : null;
        } else {
          return this._obj.entity.variants || null;
        }
      })(),
      name: this.model_origin_name,
      baseName: this.model_base_name,
      dropId: this.model_dropId,
      wallInteraction: this._obj.entity.wallInteraction,
    };
    this.stateService.init(objModelData);
  }
  ngAfterViewInit() { }
  onKeyDown(event) {
    this.enableSelection = parseInt(event.keyCode) === 16 ? true : false;
    if (this.enableSelection) {
      this.stateService.switchCamera();
    }
  }

  onKeyUp() {
    this.enableSelection = false;
  }
  /**
   * download model on computer user
   */
  downloadModel(): void {
    //this.scene3DService.stopAnimationRender();
    this.localStorageExporterObjMtlService.saveFile(
      this.exporterObjMtlService.exporterModel('myModel'),
      'myModel'
    );
    this.localStorageExporterObjMtlService.saveTextFile(
      this.exporterObjMtlService.exporterMtl(),
      'myModel',
      'mtl'
    );
  }

  getProgressValue() {
    return Math.floor(this.stateService.progress);
  }

  getShowProgress() {
    return this.stateService.showProgress;
  }

  ngOnDestroy(): void {
    this.scene3DService.stopAnimationRender();
    this.scene3DService.scene.remove.apply(
      this.scene3DService.scene,
      this.scene3DService.scene.children
    );
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
    if (this.subscription1$) {
      this.subscription1$.unsubscribe();
    }
    if (this.timeId) {
      clearInterval(this.timeId);
    }
    this.resourceService.clear();
    const localStorageSize = this.getLocalStorageSize();
    console.log('Розмір localStorage:', localStorageSize);
    console.log('DESTROY');
  }

  getLocalStorageSize() {
    var totalSize = 0;

    for (var key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        totalSize += ((localStorage[key].length + key.length) * 2);
      }
    }

    // Перетворення розміру в кілобайти або мегабайти
    if (totalSize < 1024) {
      return totalSize + ' байт';
    } else if (totalSize < 1048576) {
      return (totalSize / 1024).toFixed(2) + ' КБ';
    } else {
      return (totalSize / 1048576).toFixed(2) + ' МБ';
    }
  }
}
