import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { PreloaderService } from 'src/app/services/preloader.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  animations: [
    trigger('fadeOut', [
      state(
        'notFaded',
        style({
          opacity: 1,
        })
      ),
      state(
        'faded',
        style({
          opacity: 0,
        })
      ),
      transition('notFaded => faded', [animate('1s')]),
    ]),
  ],
})
export class PreloaderComponent implements OnInit {
  @ViewChild('preloader') preloader: ElementRef;

  isPreloaderVisibleOnce$ = this.preloaderService.isPreloaderVisible$.pipe(take(1));
  isPreloaderVisible$ = this.preloaderService.isPreloaderVisible$;
  preloaderProgress$ = this.preloaderService.preloaderProgress$;
  preloaderProgressSubscription: Subscription;

  constructor(private preloaderService: PreloaderService, private renderer: Renderer2) {}

  ngOnInit(): void {}

  onFadeOutDone(): void {
    // after setting opacity to 0,
    // set display to none
    if (
      this.preloader.nativeElement.style.opacity === '0' ||
      !this.preloader.nativeElement.style.opacity
    ) {
      this.renderer.setStyle(this.preloader.nativeElement, 'display', 'none');
    }
  }
}
