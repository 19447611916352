; (function () {
    'use strict';

    var dependencies = ['obbBuilder', 'scene3D'];

    var service = function (obbBuilder, scene3D) {

        return {
            intersectObjectsObb: function (ray, objects) {

                var obj,
                    obb,
                    point,
                    i = 0,
                    intersection = [];

                for (; i < objects.length; i++) {
                    obj = objects[i];
                    obb = obbBuilder.create().build(obj);
                    point = obb.intersectRay(ray);
                    if (point) {
                        intersection.push({
                            object: obj,
                            point : point,
                            distance: point.distanceTo(ray.origin)
                        });
                    }
                }

                intersection.sort(function (a, b) {
                    return a.distance - b.distance;
                });

                if (intersection.length > 1 && intersection[1].object.userData.wall) {
                    var wall = scene3D.getObject(intersection[1].object.userData.wall);
                    if (intersection[0].object === wall) {
                        intersection.shift();
                    }
                }

                return intersection;
            }
        }
    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('rayHelper', service);

})();
