import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AbstractBaseService } from '../abstract/abstract-base-service.abstarct';
import { ColorShemesInterface } from '../interfaces/colorShemes.interface';
import {
  ObjModelEntityInterface,
  ObjModelInterface,
} from '../interfaces/objModel.interface';
import { SaveProductModelInterface } from '../interfaces/saveProductModel.interface';
import { RootService } from './root.service';

export type objmtl = { mtl: string; obj: string };
export type dataReObjMtl = { isConnected: boolean };
export type productForShopingList = { id: string, variants: Object, uuid: string, colorSheme?: string, length?: number, height?: number };
@Injectable({
  providedIn: 'root',
})
export class ResourceService
  extends RootService
  implements AbstractBaseService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  protected configuratorUrl = `${this.apiUrl}/configurator`;
  protected productUrl = `${this.apiUrl}/products`;
  protected colorShemesUrl = `${this.apiUrl}/colorSchemes/colorSchemes`;
  private _storageObjModelData;
  private _storageColorShemesData;
  private _storageColorShemesBins;
  //private _width_bins_for_configurator = 0;
  colorShemeBins;
  private _dataSubjectColorVarians = new BehaviorSubject<any>(null);
  //private dataSubjectColorVarians: Subject<any> = new Subject<any>();
  public dataSubjectColorVarians$: Observable<any> = this._dataSubjectColorVarians.asObservable();
  productForShopingList: productForShopingList[] = [];

  constructor(private http: HttpClient) {
    super(http);
  }

  setDataColorVariants(data: any) {
    this._dataSubjectColorVarians.next(data);
  }

  getDataColorVariants() {
    return this._dataSubjectColorVarians.asObservable();
  }
  set storageColorShemesBins(storageData) {
    this._storageColorShemesBins = storageData;
  }

  get storageColorShemesBins() {
    return this._storageColorShemesBins;
  }

  set storageObjModelData(storageData) {
    this._storageObjModelData = storageData;
  }

  get storageObjModelData() {
    return this._storageObjModelData;
  }

  set storageColorShemesData(storageData) {
    this._storageColorShemesData = storageData;
  }

  get storageColorShemesData() {
    return this._storageColorShemesData;
  }


  doSetColorVariantsBins(data: any): void {
    this.colorShemeBins = [];
    this.storageColorShemesData.forEach((item) => {
      data.filter((dataBins) => {
        if (item.colorSchemeName === dataBins.colorScheme.name) {
          this.colorShemeBins.push(item);
          return item;
        }
      });
    })
    this.storageColorShemesBins = this.colorShemeBins;
    this.setDataColorVariants(this.colorShemeBins);
  }
  createOBJMTL(data: objmtl): Observable<dataReObjMtl> {
    return this.http.post<dataReObjMtl>(
      `${this.apiUrl}/configurator/configurator-objmtl`,
      { data: JSON.stringify(data) }
      // this.httpOptions
    );
  }

  createMtl(data: string): Observable<ObjModelInterface> {
    return this.http.post<ObjModelInterface>(
      `${this.apiUrl}/configurator/configurator-mtl`,
      { data: JSON.stringify(data) }
    );
  }
  createObj(data: string): Observable<ObjModelInterface> {
    return this.http.post<ObjModelInterface>(
      `${this.apiUrl}/configurator/configurator-obj`,
      { data: JSON.stringify(data) }
    );
  }
  getData(): Observable<ObjModelEntityInterface[]> {
    return this.http.get<ObjModelEntityInterface[]>(`${this.productUrl}/products`);
  }

  getDataById(id: string): Observable<ObjModelInterface> {
    return this.http.get<ObjModelInterface>(`${this.productUrl}/product/${id}`);
  }
  getConfiguratorDataById(id: string): Observable<ObjModelInterface> {
    return this.http.get<ObjModelInterface>(`${this.configuratorUrl}/product/${id}`);
  }
  update(data: ObjModelInterface): Observable<ObjModelInterface> {
    return this.http.patch<ObjModelInterface>(
      `${this.configuratorUrl}/product/${data.entity._id}`,
      data
    );
  }

  create(data: SaveProductModelInterface): Observable<SaveProductModelInterface> {
    console.log('this.configuratorUrl', this.configuratorUrl);
    return this.http.post<any>(
      `${this.configuratorUrl}/product`,
      data
    );
  }

  upload(data: {
    value: string | Blob, imageFolder: string,
    newName: string, id: string
  }): Observable<SaveProductModelInterface> {
    const url = `${this.configuratorUrl}/upload`;
    const formData = new FormData();
    formData.append('file', new Blob([data.value], { type: "application/octet-stream" }));
    formData.append("imageFolder", data.imageFolder);
    formData.append("newName", data.newName);
    formData.append("id", data.id);
    return this.http.post<any>(url, formData);
  }

  destroy(id: string): Observable<Response> {
    return this.http.delete<any>(`${this.productUrl}/${id}`);
  }

  getAllColorSchemes(): Observable<ColorShemesInterface[]> {
    return this.http.get<ColorShemesInterface[]>(this.colorShemesUrl);
  }

  clear() {
    this.productForShopingList = [];
  }

}
