import * as THREE from "../libs/three";
var constants = (function () {
  var render = new THREE.WebGLRenderer({ antialias: true });

  var constants = {
    API_URL: "https://dev-demo-plastic-bins.myers3d.com",
    //API_URL: "http://localhost:7000",
    BORDER_INDENT: 3,
    RAIL_TOP_BORDER_INDENT_1: -2,
    RAIL_TOP_BORDER_INDENT_2: 0.2,
    RAIL_TOP_BORDER_INDENT_3: 3.5,
    RAIL_TOP_BORDER_INDENT_4: -3.5,
    RAIL_SIDES_BORDER_INDENT: 0,
    RAIL_STEP_INDENT: 15,
    RAIL_STEP_INDENT_2: 15.2,
    RAIL_BOTTOM_INDENT: 13,
    RAIL_BOTH_SIDES_INDENT: 2,
    RAIL_INDENT_1: 0.1,
    RAIL_INDENT_2: 0.5,
    RAIL_RACK_BOTH_SIDES_INDENT: 2,
    RAIL_RACK_LEFT_INDENT: 3,
    RAIL_RACK_RIGHT_INDENT: 7,
    RAIL_RACK_STEP_INDENT: 5.1,
    RAIL_RACK_BOTTOM_INDENT: 25,
    RAIL_RACK_TOP_INDENT: 2.5,
    RAIL_RACK_INDENT: 0,
    RAIL_RACK_INDENT_2: 4.9,
    PANEL_INDENT: 0.1,
    PANEL_STEP_INDENT: 3.4,
    PANEL_RACK_INDENT_FULL: 10,
    PANEL_RACK_INDENT: 5,
    PANEL_RACK_BOTH_SIDES_INDENT: 5,
    PANEL_RACK_SLIM_BOTH_SIDES_INDENT: 3,
    PANEL_RACK_BOTTOM_INDENT: 15,
    PANEL_RACK_BOTTOM_INDENT_FULL: 3,
    UNDO_STACK_LIMIT: 5,
    wallPanelLength: 58.42,
    plugAutoplaceAt: 243.84,
    lightSwitchPanelMinLength: 30.48,
    wallPanelItemHeight: 10.16,
    wallPanelItemLength: 7.62,
    windowFloorOffset: 150,
    akroMilsFloorOffset: 150,
    windowOffsetFromRoof: 5.08,
    wallHeight: 304,
    wallWidth: 15,
    wallCabinetDefaultHeight: 213.36,
    PanelType: {
      BLANK: 0,
      PLUG: 1,
      SWITCH: 2,
      GHOST: 3,
    },
    StairsType: {
      DEFAULT: 0,
      WALL_CUT: 1,
      WALL_SNAP: 2,
      CORNER_SNAP: 3,
    },
    CabinetType: {
      TOOL_STORAGE: 4,
      WALL: 5,
      HIGH_CAPACITY: 6,
    },
    TableType: {
      ADJUSTABLE: 7,
      CORNER: 8,
    },
    wallUnitLength: {
      FT: true,
      M: false,
    },
    RoomObject: {
      Window: function () {},
      Door: function () {},
      Stairs: function () {},
      Cabinet: function () {},
      AkroMils: function () {},
      Table: function () {},
      Vehicle: function () {},
      Healthcare: function () {},
      MRO: function () {},
      Misc: function () {},
      WallPanel: function () {},
      Suite: function () {},
      Configurator: function () {},
    },
    ItemOption: {
      MIRROW: 0,
      ROTATE: 1,
      DOOR_FLIP_X: 2,
      DOOR_FLIP_Y: 3,
    },
    ObjectType: {
      CABINET: 2,
      TABLE: 3,
      WALL_PANEL: 4,
      CONFIGURATOR: 5,
    },
    HighlightState: {
      DEFAULT: 0x000000,
      SELECTED: 0xffff00,
      ERROR: 0xff0000,
    },
    maxAnisotropy: render.getMaxAnisotropy(),
    SizeUnit: {
      CM: 0,
      FT: 1,
      INCH: 2,
      FT_INCH: 3,
    },
    textView: {
      companiNemeTwo: "Akro-Mils/Jamco Products",
      companiNeme: "Plastic bins",
      buttonAddNewModel: "Add new Vendor model",
      buttonAddNewModelAkro: "Add new Akro-Mils/Jamco Products model",
    },
    signUp: {
      mailSubject: "Here is your Akro-Mils Jamco Layout",
      mailtextMessage: "Here is a link your Structures and Bins layout.",
    },
    roles: {
      basicUser: "basicUser",
      adminSale: "adminSale",
    },
  };

  constants.lightSwitchFloorOffset = 124.46 + constants.wallPanelItemHeight / 2;
  constants.plugFloorOffset = 43.18 + constants.wallPanelItemHeight / 2;

  angular.module("valleyCraftApp").constant("constants", constants);
  return constants;
})();

export default constants;
