(function () {
  var directive = function () {
    return {
      templateUrl: './app/views/action-stepToolTip.html',
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        key: '@',
      },
      controller: [
        '$scope',
        '$timeout',
        function ($scope) {
          if (localStorage.getItem($scope.key)) {
            $scope.action = true;
          } else {
            $scope.action = false;
          }
          console.log($scope.action);
          $scope.changeActionShowToolTip = function () {
            $scope.action = !$scope.action;
            if ($scope.action) {
              localStorage.setItem(this.key, 1);
            } else {
              localStorage.removeItem(this.key);
            }
          };
        },
      ],
    };
  };

  angular.module('valleyCraftApp').directive('actionTooltip', directive);
})();
