import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'pb-bin-color',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bin-color.component.html',
})
export class BinColorComponent {
  @Input() item: { productDetailsImage: string; colorSchemeName: string } | undefined;
  constructor(private stateService: StateService) {
  }

  setColorBin(item) {
    this.stateService.setColorBins(item)
  }
}
