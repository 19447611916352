'use strict';
angular.module('valleyCraftApp').controller('selectCombinationMA',selectCombinationMA);

selectCombinationMA.$inject = ['$scope'];

function selectCombinationMA($scope){
    
    console.log('Dialog Controller - ', $scope.ngDialogData.combination);
    $scope.selected_combination = $scope.ngDialogData.selected_accessories || [];
    $scope.product_combination = $scope.ngDialogData.all_accessories;
    
    $scope._selectProduct = function(pr){
        if(_.indexOf($scope.selected_combination, pr._id) !== -1){
             $scope.selected_combination.splice(_.indexOf($scope.selected_combination, pr._id), 1);
        }
        else {
            $scope.selected_combination.push(pr._id);
        }
    };
    
    $scope._add = function(){
        $scope.closeThisDialog($scope.selected_combination);
    };
    
    $scope._clear = function(){
        $scope.selected_combination = [];
    };
    
    $scope._close = function(){
        $scope.closeThisDialog();
    };
    
    $scope._checked = function(item){
        if(_.indexOf($scope.selected_combination, item._id) !== -1){
            return true;
        }
        else {
            return false;
        }
    };
    
}