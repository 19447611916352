<div
  style="display: flex; align-items: center"
  class="group hover:cursor-pointer flex flex-col items-center gap-3"
  *ngIf="item"
>
  <div
    class="flex mr-1.5 cursor-pointer hover:bg-lime-400 transition duration-300 border-2 border-transparent group-hover:border-lime-400 rounded overflow-hidden"
    style="display: flex"
  >
    <img
      [id]="item._id || ''"
      [src]="item.img"
      class="w-24 h-24 object-contain"
      style="width: 32px; margin-right: 5px"
      [alt]="item.name || ''"
      (mousedown)="onStartDrag($event)"
    />
  </div>
  <div>{{ item.name }}</div>
</div>
