import { Injectable } from '@angular/core';


@Injectable()
export class AngularJsService {
  constructor() { }

  private _projectSettingsJS = {};
  private _projectConfigurationJS: Object | boolean = false;

  getData(): Object | boolean {
    return this._projectSettingsJS;
  }

  setData(projectSettingsJS) {
    this._projectSettingsJS = { ...this._projectSettingsJS, ...projectSettingsJS };
  }

  getDataConfiguration(): Object | boolean {
    return this._projectConfigurationJS;
  }

  setDataConfiguration(projectConfigurationJS) {
    this._projectConfigurationJS = projectConfigurationJS;
  }

  clear() {
    this._projectSettingsJS = {};
    this._projectConfigurationJS = false;
  }

}