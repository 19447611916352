import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceService } from 'src/app/services/resource.service';
import { scene3DService } from 'src/app/services/scene3D.service';

@Component({
  selector: 'pb-bin',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bin.component.html',
})
export class BinComponent {
  @Input() item: { _id: string; img: string; name: string } | undefined;

  constructor(
    private resourceService: ResourceService,
    private scene3DService: scene3DService
  ) { }

  onStartDrag($event) {
    const data = this.resourceService.storageObjModelData;
    const modelData = data.filter((data) => $event.target.id == data._id)[0];
    if (modelData) {
      const model_name = modelData.model.split('/').slice(-1)[0].split('.')[0];
      const model_path =
        modelData.model
          .split('/')
          .slice(0, modelData.model.split('/').length - 1)
          .join('/') + '/';
      this.scene3DService.addModelScene(
        {
          mtlUrl: model_path + model_name + '.mtl',
          objUrl: model_path + model_name + '.obj',
          id: modelData._id,
          variants: modelData.variants.filter((entity) => entity.hikId === model_name)[0],
          modelDataVariants: modelData.variants,
          length: modelData.length,
          height: modelData.height,
        }
      );
      this.resourceService.doSetColorVariantsBins([]);
    }
  }
}
