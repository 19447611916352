<div mat-dialog-title class="terms-modal__title">
  <img src="assets/configurator_images/logo.jpg" alt="Logo" />
  <h3 class="terms-modal__title__text">3D Visualizer - Terms of Use</h3>
</div>
<mat-dialog-content>
  <ol>
    <li>
      Akro-Mils, a division of Myers Industries, Inc. and Jamco Products Inc., a
      subsidiary of Myers Industries (collectively, "We", or "Us" or "Our")
      manufacture and supply organizational storage systems("Products").
    </li>
    <li>
      We have licenced a 3-D visualization software tool("3-D Visualizer"). You
      have requested access to the 3-D Visualizer and We are granting You access
      so that You can help Your customers develop (and visualize) Our Products
      in their spaces.
    </li>
    <li>
      However, You cannot use the 3-D Visualizer unless You agree to these Terms
      of Use. If You use the 3-D Visualizer, You are consenting to these Terms
      of Use.
    </li>
    <li>
      We have trained You (virtually, in-person or online) and You know how to
      use the 3-D Visualizer.
    </li>
    <li>
      The 3-D Visualizer contains virtual renderings of Our product offerings.
      It also contains a Product catalogue. You can create a space for Your
      customer within the software and drag and drop the virtual renderings into
      the space to see how they fit in Your customers space prior to
      purchasing such Products. These renderings are not actual photos, however,
      so You must examine the Product catalogue before You or Your customer
      purchase any Products from Us.
    </li>
    <li>
      In order to use the 3-D Visualizer, You have to input data into the
      system. Such data includes, but is not limited to: Your name, company
      name, email address, phone number; and Your customer's company name,
      market, location, and anticipated purchase date.
    </li>
    <li>
      We will capture information about the Products and the systems You create
      in the 3-D VIsualizer (collectively, "Projects"), and We will store that
      information in Our customer relationship management system. We will
      contact You to follow up on Projects, and You agree that we can do so. We
      will not contact Your customer directly.
    </li>
    <li>
      You are responsible for the accuracy of the Information You put into the
      3-D Visualizer. You are liable for the Projects You create, not Us. If
      Your customer is unhappy or dissatisfied with the Project, it is Your
      responsibility to fix it. We will not accept Product returns or issue
      refunds to You or any of Your customers who are unhappy or dissatisfied
      with the Project You made for them.
    </li>
    <li>
      We have the right to terminate Your access to the 3-D
      Visualizer at any time.
    </li>
  </ol>
</mat-dialog-content>
<mat-dialog-actions [align]="'center'">
  <button mat-flat-button color="warn" (click)="dialogRef.close()">
    Return to login
  </button>
</mat-dialog-actions>
