'use strict';
angular.module('valleyCraftApp').controller('suitesDialogController', suitesDialogController);

suitesDialogController.$inject = [
    '$scope',
    '$rootScope',
    'roomStuff',
    'ResourceService',
    'toastr',
    '$routeParams',
    'Upload',
    '$q',
    'scene3D',
    'sanitizeSrvc',
    'constants',
];

function suitesDialogController(
    $scope,
    $rootScope,
    roomStuff,
    ResourceService,
    toastr,
    $routeParams,
    Upload,
    $q,
    scene3D,
    sanitizeSrvc,
    constants
) {
    console.log('dialogSuiteSave open');
    $scope.project_data = $scope.ngDialogData.project_data;
    $scope.suiteId = $scope.ngDialogData.suiteId;
    $scope.removedSuiteImage = [];
    $scope.colorSchemes = [];
    //  var apiUrl = 'http://138.197.129.42:9909';
    var apiUrl = constants.API_URL; // 'http://dev-demo-plastic-bins.myers3d.com';
    // var apiUrl = 'http://localhost:7000';

    var size = scene3D.getSuitesSize();

    var newColorScheme = {
        product_img_url: null,
        hikashop_id: null,
        spl_img_url: null,
        name: null,
        short_key: null,
        active: false,
        isNew: true,
        color_scheme: {}
    };

    if ($scope.suiteId) {
        ResourceService.getOneSuite($scope.suiteId)
            .then(function (suite) {
                $scope.currentSuite = suite;
                $scope.currentSuite.length = size.x;
                $scope.currentSuite.width = size.z;
                $scope.currentSuite.height = size.y;
                ($scope.currentSuite.objects = $scope.project_data.data.objects),
                    setTimeout(function () {
                        $('#color-scheme-tabs a:first').tab('show');
                    }, 0);
                $scope.currentSuite.variants = $scope.currentSuite.variants || [
                    {
                        hikId: '',
                        colorScheme: {}
                    }
                ];
            })
            .catch(function (err) {
                toastr.error(err);
            });
    } else {
        $scope.currentSuite = {
            name: null,
            description: null,
            msrp: null,
            hikashop_id: null,
            visible: false,
            color_schemes: [],
            leftMenuImg: null,
            length: size.x,
            width: size.z,
            height: size.y,
            objects: $scope.project_data.data.objects,
            roomId: $routeParams.room_id,
            isCornerSuite: false,
            variants: [
                {
                    hikId: '',
                    colorScheme: {}
                }
            ]
        };
    }

    $scope.onAddVariant = function () {
        $scope.currentSuite.variants.push({
            hikId: '',
            colorScheme: {},
            accessory: {}
        });
    };

    $scope._addColorScheme = function () {
        $scope.currentSuite.color_schemes.push(_.cloneDeep(newColorScheme));
        setTimeout(function () {
            $('#color-scheme-tabs a:last').tab('show');
        }, 0);
        console.log($scope.currentSuite.color_schemes);
    };

    $scope._removeColorScheme = function (index) {
        var removed = $scope.currentSuite.color_schemes[index];
        if (removed.spl_img_url !== null) {
            $scope.removedSuiteImage.push(removed.spl_img_url);
        }
        if (removed.product_img_url !== null) {
            $scope.removedSuiteImage.push(removed.product_img_url);
        }
        $scope.currentSuite.color_schemes.splice(index, 1);
        setTimeout(function () {
            $('#color-scheme-tabs a:first').tab('show');
        }, 0);
    };

    $scope._setColor = function (item, pr_type, color) {
        item.color_scheme[pr_type] = color;
        return item;
    };

    $scope.addFilesForUpload = function (file, folder_name, color_key, suite_color) {
        if (file) {
            if (folder_name === 'LeftMenuThumbnails') {
                $scope.currentSuite.files = [];
                var data = {
                    file: file,
                    folder_name: folder_name,
                    file_name: null,
                    old_file_name: null
                };
                $scope.currentSuite.files.push(data);
            } else {
                if (!suite_color.hasOwnProperty('files')) {
                    suite_color.files = [];
                }
                var data = {
                    file: file,
                    folder_name: folder_name,
                    color_key: color_key,
                    file_name: null,
                    old_file_path: null
                };
                var index = _.findIndex(suite_color.files, {
                    folder_name: folder_name
                });
                if (index !== -1) {
                    suite_color.files.splice(index, 1);
                    suite_color.files.push(data);
                } else {
                    suite_color.files.push(data);
                }
                console.log('files - ', suite_color);
            }
        }
    };

    $scope.save = function () {
        if (checkUniqueColorSchemeKey($scope.currentSuite.color_schemes)) {
            $scope.filesUpload = [];
            handleUploadImage($scope.currentSuite);
            _.forEach($scope.currentSuite.color_schemes, function (item) {
                handleUploadImage(item);
            });

            if ($scope.suiteId) {
                ResourceService.updateSuite($scope.currentSuite._id, $scope.currentSuite)
                    .then(function (result) {
                        uploadImage($scope.currentSuite);
                        deleteSuiteImage();
                        toastr.success('Suite updated succcesfully');
                        $scope.closeThisDialog();
                    })
                    .catch(function (err) {
                        toastr.error(err);
                    });
            } else {
                ResourceService.saveSuite($scope.currentSuite)
                    .then(function (result) {
                        uploadImage(result);
                        deleteSuiteImage();
                        console.log(result);
                        toastr.success('Suite saved succesfully');
                        $scope.closeThisDialog($scope.project_data);
                    })
                    .catch(function (err) {
                        toastr.error(err);
                    });
            }
        }

        console.log('Suite data - ', $scope.currentSuite);
        console.log('filesUpload - ', $scope.filesUpload);
    };

    function uploadImage(suite) {
        if ($scope.filesUpload.length) {
            _.forEach($scope.filesUpload, function (instance) {
                Upload.upload({
                    url: apiUrl + '/suites/upload',
                    data: {
                        file: instance.file,
                        folder_name: instance.folder_name,
                        file_name: instance.file_name,
                        old_file_path: instance.old_file_path,
                        suiteId: suite._id
                    }
                });
                // .then(function (evt) {
                //     // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                //     // console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                // }, function (resp) {
                //     // console.log('Error status: ' + resp.status);
                //     // toastr.success(resp.statusText);
                // }, function (evt) {
                //     var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                //     console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                // });
            });
        }
    }

    function deleteSuiteImage() {
        if ($scope.removedSuiteImage.length) {
            ResourceService.deleteSuiteImage($scope.removedSuiteImage)
                .then(function (result) {
                    console.log(result);
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
    }

    function handleUploadImage(item) {
        var product_img_url = 'assets/images/suites/ProductImage/';
        var spl_img_url = 'assets/images/suites/ShoppingList/';
        var left_menu_img_url = 'assets/images/suites/LeftMenuThumbnails/';
        _.forEach(item.files, function (file) {
            var name = $scope.currentSuite.name;

            if (item.hasOwnProperty('short_key')) {
                file.color_key = item.short_key;
                name = sanitizeSrvc.sanitizeFilename(name) + '_' + item.short_key;
            }
            name = name.replace(/\s/g, '_').replace(/\//g, '').replace(/"/g, '').replace(/'/g, '');
            file.file_name = name + '.jpg';
            if (file.folder_name === 'ProductImage') {
                file.old_file_path = item.product_img_url;
                item.product_img_url = product_img_url + file.file_name;
            }
            if (file.folder_name === 'ShoppingList') {
                file.old_file_path = item.spl_img_url;
                item.spl_img_url = spl_img_url + file.file_name;
            }
            if (file.folder_name === 'LeftMenuThumbnails') {
                file.old_file_path = item.leftMenuImg;
                item.leftMenuImg = left_menu_img_url + file.file_name;
            }
            $scope.filesUpload.push(file);
        });
        delete item['files'];
    }

    function getAvailableColorSchemeByProduct() {
        var colorSchemes = {};
        _.forEach($scope.project_data.data.objects, function (item) {
            var pr = roomStuff.getById(item.id);

            if (pr) {
                if (pr.left_menu_alias === 'Work Tables') {
                    var products = roomStuff.getProductsByProperty('category', pr.category);
                    var colors = _.map(products, 'color_scheme');
                    colors = _.uniq(_.flattenDeep(colors));
                    var key_color;
                    if (pr.type === 8) {
                        key_color = pr.left_menu_alias + ': Corner';
                    } else {
                        key_color = pr.left_menu_alias + ': Adjustable';
                    }
                    if (!colorSchemes.hasOwnProperty(key_color)) {
                        colorSchemes[key_color] = colors;
                    }
                } else {
                    var products = roomStuff.getProductsByProperty(
                        'left_menu_alias',
                        pr.left_menu_alias
                    );
                    var colors = _.map(products, 'color_scheme');
                    colors = _.uniq(_.flattenDeep(colors));
                    if (!colorSchemes.hasOwnProperty(pr.left_menu_alias)) {
                        colorSchemes[pr.left_menu_alias] = colors;
                    }
                }
            }
        });

        $scope.productColorSchemes = colorSchemes;
        console.log('Available product color sceme - ', colorSchemes);
    }
    function checkUniqueColorSchemeKey(colors) {
        var c_key = _.map(colors, 'short_key');
        if (_.some(c_key, _.isNull) || _.some(c_key, _.isUndefined)) {
            toastr.warning('The values of the short color keys should not be empty!');
            return false;
        }

        c_key = _.map(c_key, function (i) {
            return i.toLowerCase();
        });
        console.log(c_key);
        if (
            _.some(_.countBy(c_key), function (el) {
                return el > 1;
            })
        ) {
            toastr.warning('The values of the short color keys must be unique!');
            return false;
        }
        _.forEach(colors, function (item) {
            delete item['isNew'];
        });
        return true;
    }

    getAvailableColorSchemeByProduct();
}

angular.module('valleyCraftApp').directive('uppercaseOnly', [
    // Dependencies

    // Directive
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('keypress', function (e) {
                    var char = e.char || String.fromCharCode(e.charCode);
                    if (!/^[A-Z0-9]$/i.test(char)) {
                        e.preventDefault();
                        return false;
                    }
                });

                function parser(value) {
                    if (ctrl.$isEmpty(value)) {
                        return value;
                    }
                    var formatedValue = value.toUpperCase();
                    if (ctrl.$viewValue !== formatedValue) {
                        ctrl.$setViewValue(formatedValue);
                        ctrl.$render();
                    }
                    return formatedValue;
                }

                function formatter(value) {
                    if (ctrl.$isEmpty(value)) {
                        return value;
                    }
                    return value.toUpperCase();
                }

                ctrl.$formatters.push(formatter);
                ctrl.$parsers.push(parser);
            }
        };
    }
]);
