import angular from 'angular';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProductsMenuComponent } from '../products-menu/products-menu.component';
import { ConfiguratorMenuComponent } from '../configurator-menu/configurator-menu.component';
import { ConfiguratorComponent } from '../configurator/configurator.component';
import { ObjModelInterface } from 'src/app/interfaces/objModel.interface';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'pb-home',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    ProductsMenuComponent,
    ConfiguratorMenuComponent,
    ConfiguratorComponent,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  private _obj: ObjModelInterface; 
  isLoadingIntersector: Subject<boolean> = this.loader.isLoading;
  isLoadingService: Subject<boolean> = this.loader.isLoadingService;
  preloaderProgressSubject = this.loader.preloaderProgressSubject;
  constructor(
    @Inject('$location') private $location: any,
    private loader: LoaderService 
  ) {
    this._obj = JSON.parse(this.$location.search().obj);
  }
  
}

angular.module('valleyCraftApp').directive(
  'pbHome',
  downgradeComponent({
    component: HomeComponent,
  }) as angular.IDirectiveFactory
);
