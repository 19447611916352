import * as THREE from "../libs/three";
(function () {
  var dependencies = [];

  var service = function () {
    //store material of each object
    var objectMaterial = {
      //meshUuid : material
    };

    //store object color scheme
    var colorScheme = [
      // {entityId:..,scheme:..,objectId:...}
    ];

    var suites = [
      // { suiteUniqueId:..., objects: ['uuid'] }
    ];

    // REFACTOR
    // make an array with string for each wall
    var savedWallMaterial;
    var savedFloorMaterial;

    //store room points as array of {x:0,y:0,depth:0}
    var roomPoints;

    //store room points as vector array
    var points = [];

    var getColorSchemeByEntityId = function (entityId) {
      let result;
      colorScheme.forEach(function (cs) {
        if (cs.entityId === entityId) {
          result = cs.scheme;
        }
      });
      return result;
    };

    var getColorSchemeByObjectId = function (objectId) {
      // console.log("objectId", objectId)
      var i = 0,
        item;
      for (; i < colorScheme.length; i++) {
        item = colorScheme[i];
        // console.log("objectIditem", item)
        if (item.objectId === objectId) return item;
      }
      return null;
    };

    var convertPoints = function (roomPoints) {
      var result = [],
        i = 0,
        point;

      for (; i < roomPoints.length; i++) {
        point = roomPoints[i];
        result.push(new THREE.Vector3(point.x, point.y, 0));
      }

      return result;
    };

    return {
      getSavedWallMaterial: function () {
        return savedWallMaterial;
      },

      setWallMaterial: function (val) {
        savedWallMaterial = val;
      },

      getSavedFloorMaterial: function () {
        return savedFloorMaterial;
      },

      setFloorMaterial: function (val) {
        savedFloorMaterial = val;
      },

      clearCurrentRoomState: function () {
        objectMaterial = {};
        colorScheme.length = 0;
        roomPoints = [];
        points = [];
      },
      saveObjectColorScheme: function (data) {
        var item = this.getColorSchemeByObjectId(data.objectId);
        if (item) {
          if (data.updateIfExist !== false) item.scheme = data.scheme;
        } else {
          colorScheme.push({
            entityId: data.entityId,
            objectId: data.objectId,
            scheme: data.scheme,
          });
        }
      },
      saveObjectMaterial: function (mesh) {
        // console.log("mesh", mesh);
        if (mesh.name === "floor") {
          mesh.material.side = 2;
        }
        objectMaterial[mesh.uuid] = mesh.material.clone();
      },
      getObjectMaterial: function (meshUuid) {
        return objectMaterial[meshUuid];
      },
      getObjectsColorSchemes: function () {
        return colorScheme;
      },
      getColorSchemeByEntityId: getColorSchemeByEntityId,
      removeObjectColorScheme: function (objectId) {
        var item = this.getColorSchemeByObjectId(objectId);
        if (item) {
          var index = colorScheme.indexOf(item);
          colorScheme.splice(index, 1);
        }
      },
      getColorSchemeByObjectId: getColorSchemeByObjectId,
      setPoints: function (pts) {
        //  var clone = points.slice(0);
        roomPoints = pts;
        points = convertPoints(pts);
      },
      getNextPointIndex: function (index) {
        return index === roomPoints.length - 1 ? 0 : index + 1;
      },
      getPrevPointIndex: function (index) {
        return index === 0 ? roomPoints.length - 1 : index - 1;
      },
      getPoint: function (index) {
        return points[index];
      },
      getPoints: function () {
        return points;
      },
      updatePoint: function (index, point, depth) {
        var p = roomPoints[index];
        p.x = point.x;
        p.y = point.y;
        p.depth = depth || p.depth;
        points[index].set(point.x, point.y, 0);
      },
      getRoomPoints: function () {
        return roomPoints;
      },
      insertPoint: function (addIndex, point) {
        roomPoints.splice(addIndex, 0, point);
        points.splice(addIndex, 0, new THREE.Vector3(point.x, point.y, 0));
      },
      hasPoints: function () {
        return roomPoints && roomPoints.length !== 0;
      },
      removePoint: function (index) {
        roomPoints.splice(index, 1);
        points.splice(index, 1);
      },
      saveSuiteObjects: function (suite) {
        suites.push(suite);
      },
      getSuiteObjectsBySuiteUniqueId: function (id) {
        var result;
        suites.forEach(function (suite) {
          if (suite.suiteUniqueId.valueOf() === id.valueOf()) {
            result = suite.objects;
          }
        });
        return result;
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("roomStateManager", service);
})();
