import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { Observable } from "rxjs";
import { ResourceService } from "../../../services/resource.service";
import { BinColorComponent } from "../../products-menu/bin-color/bin-color.component";
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-terms-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, BinColorComponent],
  templateUrl: './colors-modal.component.html',
  styleUrls: ['./colors-modal.component.scss'],
})
export class ColorsModalComponent {
  dataSubjectColorVarians$: Observable<any>;
  constructor(
    private stateService: StateService,
    public dialogRef: MatDialogRef<ColorsModalComponent>,
    public resourceService: ResourceService
  ) {
    this.dataSubjectColorVarians$ = this.resourceService.getDataColorVariants();
  }

  setColorBin(item) {
    this.stateService.setColorBins(item)
  }
}

angular.module('valleyCraftApp').directive(
  'changeColorsModal',
  downgradeComponent({
    component: ColorsModalComponent,
  }) as angular.IDirectiveFactory
);
