import Drag2D from "./Drag2D";
var DragVehicle2D = function (dependencyContainer) {
  Drag2D.prototype.constructor.call(this, dependencyContainer);
  this._step3Helper = dependencyContainer.getService("step3Helper");
  this._constants = dependencyContainer.getService("constants");
  this._step3DataSrv = dependencyContainer.getService("step3DataSrv");
};

DragVehicle2D.prototype = Object.create(Drag2D.prototype);

DragVehicle2D.prototype.constructor = DragVehicle2D;

DragVehicle2D.prototype.dragging = function (newPosition, draggedObject) {
  draggedObject.position.copy(newPosition);

  var objectVertexInsideRoomCount = this._step3Helper.vertexInsideRoomCount(
    draggedObject,
    this._step3DataSrv.roomPolygonPoints
  );

  this._selectedWall = this._step3Helper.getIntersectedWall(
    draggedObject,
    this._step3DataSrv.wallsData
  );

  this._canBePlaced = objectVertexInsideRoomCount === 4;

  if (
    this._selectedWall &&
    !draggedObject.userData.entity.disableAutoOrientation
  )
    draggedObject.rotation.z = this._selectedWall.rotation.y;

  this._step3Helper.highlightIfDefined(
    draggedObject,
    this._canBePlaced
      ? this._constants.HighlightState.SELECTED
      : this._constants.HighlightState.ERROR
  );
};

DragVehicle2D.prototype.dragEnd = function (draggedObject) {};

DragVehicle2D.prototype.isEnabled = function () {
  var selObj = this.getSelectedObject();
  if (selObj) {
    return (
      (Drag2D.prototype.isEnabled.call(this) &&
        selObj.userData.entity instanceof this._constants.RoomObject.Vehicle) ||
      selObj.userData.entity instanceof this._constants.RoomObject.Misc ||
      selObj.userData.entity instanceof this._constants.RoomObject.Healthcare ||
      selObj.userData.entity instanceof this._constants.RoomObject.MRO
    );
  }
};

export default DragVehicle2D;
