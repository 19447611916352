import WallView from "../component/3D/WallView";

(function () {

    var directive = function (scene3D, engine3DSrvc,orbitControl) {
        return {
            restrict: 'E',
            templateUrl: './app/views/step4-bottom-toolbar.html',
            replace: true,
            scope: {},
            controller: ['$scope', function ($scope) {

                var viewWallComponent = engine3DSrvc.getEngine().findComponentByType(WallView);

                $scope.isInWallMode = false;
                $scope.zoom = function (direction) {
                    if (viewWallComponent.isInWallMode())
                        viewWallComponent.zoom(direction);
                    else {
                        orbitControl.dolly(direction);
                    }
                };
                $scope.center = function(){
                    scene3D.centralizeCamera();
                };

                var enterUnreg = viewWallComponent.on('enter', function () {
                    $scope.isInWallMode = true;
                });

                var leaveUnreg = viewWallComponent.on('leave', function () {
                    $scope.isInWallMode = false;
                });

                $scope.$on('$destroy', function () {
                    leaveUnreg();
                    enterUnreg();
                });
            }]
        }
    };

    angular.module('valleyCraftApp').directive('step4BottomToolbar', ['scene3D', 'engine3DSrvc','orbitControl', directive]);
})();