import ObjectControlsHandlerBase from './ObjectControlsHandlerBase'

var DeleteHandler = function (dependencyContainer, select3D) {
    ObjectControlsHandlerBase.prototype.constructor.call(this, 'delete');

    this._sceneSyncSrvc = dependencyContainer.getService('sceneSyncSrvc');
    this._scene3D = dependencyContainer.getService('scene3D');
    this._dimensionalRadialsManager = dependencyContainer.getService('dimensionalRadialsManager');

    this._select3D = select3D;
};

DeleteHandler.prototype = Object.create(ObjectControlsHandlerBase.prototype);

DeleteHandler.prototype.constructor = DeleteHandler;

DeleteHandler.prototype.invokeAction = function (actionName, arg) {

    if (actionName === 'hold') {
        var obj = this._select3D.getSelected(),
            number,
            isSuite = 0;
        for (var i = 0; i < obj.length; i++) {
            if (obj[i].userData.suiteId) {
                number = i;
                isSuite = 1;
            }
        }
        if (isSuite) {
            var chldn = this._scene3D.filterChildren(function (item) {
                return item.userData.suiteUniqueId === obj[number].userData.suiteUniqueId;
            });
            this._sceneSyncSrvc.deleteObjects(chldn);        
        } 
        this._sceneSyncSrvc.deleteObjects(this._select3D.getSelected());
        this._select3D.clearSelection();
        this._dimensionalRadialsManager.deleteDimensionalRadials();
    }

    return false;
};

DeleteHandler.prototype.canExecute = function (objects) {
    return ObjectControlsHandlerBase.prototype.canExecute.call(this, objects) && this._canDelete(objects);
};

DeleteHandler.prototype._canDelete = function (objects) {
    var i = 0,
        entity;

    for (; i < objects.length; i++) {
        entity = objects[i].userData.entity;
        if (entity.isWall || entity.isFloor)
            return false
    }

    return true;
};

export default DeleteHandler;