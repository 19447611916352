import constants from '../../core_/services/constants';

export const getStepForBinRowPosition = (name) => {
  // console.log('name', name);
  let step = 0;
  if(name === '1-0') {
    // console.log('PANEL_INDENT');
    step = constants.PANEL_STEP_INDENT;
  }
  if(name === '30016' || name === '30148' || name === '30632') {
    step = constants.RAIL_STEP_INDENT;
  }
  if(name === '30008') {
    step = constants.RAIL_STEP_INDENT_2;
  }
  if(name === '30658' || name === '30676') {
    // console.log('PANEL_RACK_BOTH_SIDES_INDENT');
    step = constants.PANEL_STEP_INDENT;
  }
  if(name === '30651' || name === '30653') {
    // console.log('PANEL_RACK_INDENT_FULL');
    step = constants.PANEL_STEP_INDENT;
  }
  if(name === '30661') {
    // console.log('PANEL_RACK_INDENT');
    step = constants.PANEL_STEP_INDENT;
  }
  if(name === '30812') {
    // console.log('PANEL_RACK_INDENT');
    step = constants.RAIL_RACK_STEP_INDENT;
  }
  return step;
}

