import * as THREE from '../libs/three';
; (function () {
    'use strict';

    var dependencies = [];

    var service = function () {

        var plane = new THREE.Plane();
        var ray = new THREE.Ray();

        var viewType = {
            VIEW_2D: 0,
            VIEW_3D: 1
        };

        var connectAllWalls = function (walls, vType) {
            var count = walls.length,
                 i = 0,
                 currentWall,
                 nextWall;

            for (; i < count; i++) {
                currentWall = walls[i];
                nextWall = walls[(i + 1) % count];
                connectTwoWalls(currentWall, nextWall, vType);
            }
        };

        var moveWallAVertices = function (wall, position) {
            /*
              vertex[1] - back top right
              vertex[3] - back bottom right
             */
            wall.geometry.vertices[3].x = position.x;
            wall.geometry.vertices[3].z = position.z;
            wall.geometry.vertices[1].x = position.x;
            wall.geometry.vertices[1].z = position.z;
            wall.geometry.verticesNeedUpdate = true;
        };

        var moveWallBVertices = function (wall, position) {
            /*
             vertex[4] - back left top 
              vertex[6] - back left bottom 
             */

            wall.geometry.vertices[6].x = position.x;
            wall.geometry.vertices[6].z = position.z;
            wall.geometry.vertices[4].x = position.x;
            wall.geometry.vertices[4].z = position.z;
            wall.geometry.verticesNeedUpdate = true;
        };

        var connectTwoWalls = function (wallA, wallB, vType) {

            wallA.updateMatrixWorld();
            wallB.updateMatrixWorld();

            var currentPoint = wallA.localToWorld(new THREE.Vector3(wallA.geometry.vertices[2].x, 0, 0)),
                prevPoint = wallA.localToWorld(new THREE.Vector3(0, 0, 0)),
                currentWallDir = wallA.getWorldDirection(),
                nextWallDirection = wallB.getWorldDirection(),
                wallAWidth = wallA.userData.width || wallA.userData.entity.width,
                wallBWidth = wallB.userData.width || wallB.userData.entity.width,
                wallAPoint,
                wallBPoint,
                planePosition,
                upVector,
                rayDir,
                rayOrigin,
                angle,
                sign = 1,
                intersectionPoint,
                dotProduct = currentWallDir.dot(nextWallDirection);

            if (dotProduct >= 0.995 && dotProduct <= 1.01) {
                var direction = currentWallDir.clone().negate();
                wallAPoint = wallA.worldToLocal(direction.clone().multiplyScalar(wallAWidth).add(currentPoint));
                wallBPoint = wallB.worldToLocal(direction.multiplyScalar(wallBWidth).add(currentPoint));
                moveWallAVertices(wallA, wallAPoint);
                moveWallBVertices(wallB, wallBPoint);
            }
            else {

                if (vType === viewType.VIEW_3D) {
                    currentWallDir.negate();
                    nextWallDirection.negate();
                    planePosition = nextWallDirection.clone().multiplyScalar(wallBWidth).add(currentPoint);
                    rayOrigin = currentWallDir.clone().multiplyScalar(wallAWidth).add(prevPoint);
                    upVector = new THREE.Vector3(0, 1, 0);
                    angle = ((Math.atan2(nextWallDirection.x, nextWallDirection.z) -
                        Math.atan2(currentWallDir.x, currentWallDir.z) + Math.PI * 2) % (Math.PI * 2)) - Math.PI;
                    sign = angle > 0 ? -1 : 1; //inner / outer angle
                }
                else {
                    planePosition = nextWallDirection.clone().negate().multiplyScalar(wallBWidth).add(currentPoint);
                    rayOrigin = currentWallDir.clone().negate().multiplyScalar(wallAWidth).add(prevPoint);
                    upVector = new THREE.Vector3(0, 0, 1);
                }

                rayDir = currentWallDir.clone().applyAxisAngle(upVector, Math.PI / 2 * sign);
                plane.setFromNormalAndCoplanarPoint(nextWallDirection, planePosition);
                ray.set(rayOrigin, rayDir);

                intersectionPoint = ray.intersectPlane(plane);

                if (intersectionPoint) {
                    wallAPoint = wallA.worldToLocal(intersectionPoint.clone());
                    wallBPoint = wallB.worldToLocal(intersectionPoint);
                    moveWallAVertices(wallA, wallAPoint);
                    moveWallBVertices(wallB, wallBPoint);
                }
            }
        }

        return {
            connectAllWalls2D: function (walls) {
                connectAllWalls(walls, viewType.VIEW_2D);
            },
            connectAllWalls3D: function (walls) {
                connectAllWalls(walls, viewType.VIEW_3D);
            },
            connectTwoWalls2D: function (wallA, wallB) {
                connectTwoWalls(wallA, wallB, viewType.VIEW_2D);
            },
            connectTwoWalls3D: function () {
                connectTwoWalls(wallA, wallB, viewType.VIEW_3D);
            }
        }
    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('wallConnectionSrvc', service);

})();
