import * as THREE from '../libs/three';
; (function () {
    'use strict';

    var dependencies = ['wall3DDataManager'];

    var service = function (wall3DDataManager) {

        var wallNumberSize = 40;
        var controls;
        var scaleVector = new THREE.Vector3();
        var scaleFactor = 25;
        var isVisible = true;

        var createWallNumberImg = function (number) {

            var canvas = document.createElement('canvas'),
                cnx = canvas.getContext('2d'),
                size = 64,
                halfOfSize = size / 2,
                textSize;

            canvas.width = canvas.height = size;

            cnx.font = "40px Arial";
            cnx.textAlign = "center";

            textSize = cnx.measureText(number);

            cnx.fillText(number, halfOfSize, halfOfSize + textSize.width / 2);

            cnx.globalCompositeOperation = "destination-over";
            cnx.beginPath();
            cnx.arc(halfOfSize, halfOfSize, halfOfSize, 0, Math.PI * 2, true);
            cnx.closePath();
            cnx.fillStyle = 'white';
            cnx.fill();

            return canvas;
        };

        var buildWallNumbers = function (walls) {

            var i = 0,
                wall,
                sprite,
                wallNumber,
                texture,
                result = [],
                container = new THREE.Object3D();

            container.name = 'wallNumbers';
            container.userData.keepOnScene = false;
            container.visible = isVisible;

            for (; i < walls.length; i++) {
                wall = walls[i];
                wallNumber = wall.name.split(' ').pop();

                texture = new THREE.Texture(createWallNumberImg(wallNumber));
                texture.needsUpdate = true;

                sprite = new THREE.Sprite(new THREE.SpriteMaterial({
                    map: texture,
                    depthTest: false,
                    depthWrite: false,
                    needsUpdate: true
                }));

                sprite.scale.set(wallNumberSize, wallNumberSize, 1);
                sprite.name = 'wallNumber ' + wallNumber;
                sprite.position.copy(wall3DDataManager.getPoints(wall.name).topCenter);

                container.add(sprite);
            }

            return container;
        };

        return {
            get: function (walls) {
                controls = controls || buildWallNumbers(walls);
                return controls;
            },
            updateScale: function (cameraPosition) {
                if (controls) {
                    var i = 0,
                        sprites = controls.children,
                        sprite,
                        scale;
                    for (; i < sprites.length; i++) {
                        sprite = sprites[i];
                        scale = scaleVector.subVectors(sprite.position, cameraPosition).length() / scaleFactor;
                        sprite.scale.set(scale, scale, 1);
                    }
                }
            },
            setVisibility: function (bool) {
                if (controls) {
                    controls.visible = bool && isVisible;
                }
            },
            clear: function () {
                controls = null;
            },
            setIsVisible: function (bool) {
                isVisible = bool;
            },
            isVisible: function () {
                return controls && controls.visible;
            }
        }

    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('wallNumberSrvc', service);

})();
