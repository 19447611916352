import * as THREE from '../libs/three';
; (function () {

    var service = function () {

        return {
            addSelectionBoxToMesh: function (mesh) {

                if (mesh.name === 'floor') {
                    var geometry = new THREE.EdgesGeometry(mesh.geometry.clone());

                    var material = new THREE.LineBasicMaterial({
                        color: 0x00ff00,
                        depthTest: false,
                        // depthWrite: false,
                        transparent: true
                    });
                    
                    var selectionBox = new THREE.LineSegments(geometry, material);
                    selectionBox.visible = false;
                    selectionBox.name = 'selection';

                    mesh.geometry.computeBoundingBox();

                    mesh.add(selectionBox);
                }
                else {
                    var geometry = new THREE.EdgesGeometry(new THREE.BoxGeometry(1, 1, 1));

                    var material = new THREE.LineBasicMaterial({
                        color: 0x00ff00,
                        depthTest: false,
                        // depthWrite: false,
                        transparent: true
                    });

                    var selectionBox = new THREE.LineSegments(geometry, material);
                    selectionBox.visible = false;
                    selectionBox.name = 'selection';

                    mesh.geometry.computeBoundingBox();
                    var size = mesh.geometry.boundingBox.size();
                    var diff = mesh.geometry.boundingBox.center();
                    selectionBox.scale.set(size.x + 2, size.y, size.z + 2);

                  //  var m = new THREE.Matrix4();
                   // m.extractRotation(mesh.matrixWorld);

                    //diff.applyMatrix4(m);
                    selectionBox.position.add(diff);

                    mesh.add(selectionBox);
                }


            }
        }
    };

    angular.module('valleyCraftApp').service('object3DSelectionBox', service);
})();