import Engine from "../component/Engine";
import DependencyContainer from "../component/DependencyContainer";
import Select3D from "../component/3D/Select3D";
import Scene3DNavigationJoystick from "../component/3D/Scene3DNavigationJoystick";
import AxisDragHandler from "../component/3D/Drag/Handler/AxisDragHandler";
import CounterClockwiseRotateHandler from "../component/3D/ObjectControls/CounterClockwiseRotateHandler";
import DeleteHandler from "../component/3D/ObjectControls/DeleteHandler";
import CloneHandler from "../component/3D/ObjectControls/CloneHandler";
import InfoHandler from "../component/3D/ObjectControls/InfoHandler";
import ConfiguratorHandler from "../component/3D/ObjectControls/ConfiguratorHandler";
import ObjectAdd from "../component/3D/ObjectAdd/ObjectAdd";
import FloorObjectHandler from "../component/3D/ObjectAdd/FloorObjectHandler";
import WallEmbeddableObjectHandler from "../component/3D/ObjectAdd/WallEmbeddableObjectHandler";
import WallMountObjectHandler from "../component/3D/ObjectAdd/WallMountObjectHandler";
import GroupObjectHandler from "../component/3D/ObjectAdd/GroupObjectHandler";
import CornerGroupObjectHandler from "../component/3D/ObjectAdd/CornerGroupObjectHandler";
import HideLookBlockWalls from "../component/3D/HideLookBlockWalls";
import WallNumbersUpdate from "../component/3D/WallNumbersUpdate";
import Drag3D from "../component/3D/Drag/Drag3D";
import WallMountObjectDrag from "../component/3D/Drag/Behavior/WallMountObjectDrag";
import SnapHandler from "../component/3D/Drag/Handler/SnapHandler";
import FloorObjectDrag from "../component/3D/Drag/Behavior/FloorObjectDrag";
import FloorObjectSmartDrag from "../component/3D/Drag/Handler/FloorObjectSmartDrag";
import WallObjectDrag from "../component/3D/Drag/Behavior/WallObjectDrag";
import WallObjectSmartDrag from "../component/3D/Drag/Handler/WallObjectSmartDrag";
import GroupObjectDrag from "../component/3D/Drag/Behavior/GroupObjectDrag";
import SuiteAxisDragHandler from "../component/3D/Drag/Handler/SuiteAxisDragHandler";
import ObjectControls from "../component/3D/ObjectControls/ObjectControls";
import ClockwiseRotateHandler from "../component/3D/ObjectControls/ClockwiseRotateHandler";
import WallView from "../component/3D/WallView";

(function () {
  "use strict";

  var dependencies = [
    "scene3D",
    "constants",
    "keyState",
    "configuratorService",
    "screenShotStoreSrvc",
    "objects2DSerializer",
    "orbitControl",
    "Scene2d",
    "collisionSrvc",
    "objectGapHelperSrvc",
    "scene3dOverlay",
    "sceneHelper",
    "wallVisibilityManager",
    "wallCutHelper",
    "scene2DSyncSrvc",
    "obbBuilder",
    "wall3DDataManager",
    "objectControls",
    "geometryHelper",
    "sceneSyncSrvc",
    "objectCheckerSrvc",
    "object3DCloneSrvc",
    "roomStateManager",
    "dimensionalRadialsManager",
    "wallNumberSrvc",
    "object3DPositionHistory",
    "rayHelper",
    "productDetailDialogSrvc",
    "snappingDisableSrvc",
    "roomStuff",
    "$q",
    "toastr",
    "$location",
    "AngularService",
  ];

  var service = function (
    scene3D,
    constants,
    keyState,
    configuratorService,
    orbitControl,
    Scene2d,
    collisionSrvc,
    objectGapHelperSrvc,
    scene3dOverlay,
    sceneHelper,
    wallVisibilityManager,
    wallCutHelper,
    scene2DSyncSrvc,
    obbBuilder,
    wall3DDataManager,
    objectControls,
    geometryHelper,
    sceneSyncSrvc,
    objectCheckerSrvc,
    object3DCloneSrvc,
    roomStateManager,
    dimensionalRadialsManager,
    wallNumberSrvc,
    object3DPositionHistory,
    rayHelper,
    productDetailDialogSrvc,
    snappingDisableSrvc,
    roomStuff,
    $q,
    AngularService,
    $location
  ) {
    var engine3D;
    var servicesObj = arguments;
    var dependencyContainer;

    return {
      getEngine: function () {
        if (!engine3D) {
          scene3D.init(1, 1); //need canvas , init scene first

          engine3D = new Engine();

          dependencyContainer = new DependencyContainer(
            servicesObj,
            dependencies
          );

          var select3DComponent = new Select3D(dependencyContainer);
          var configuratorHandler = new ConfiguratorHandler(
            dependencyContainer,
            select3DComponent
          );
          configuratorHandler.set($location);
          engine3D.addComponents([
            new Scene3DNavigationJoystick(dependencyContainer),
            new ObjectControls(dependencyContainer, [
              new ClockwiseRotateHandler(
                dependencyContainer,
                select3DComponent
              ),
              new CounterClockwiseRotateHandler(
                dependencyContainer,
                select3DComponent
              ),
              new DeleteHandler(dependencyContainer, select3DComponent),
              new CloneHandler(dependencyContainer, select3DComponent),
              new InfoHandler(dependencyContainer, select3DComponent),
              configuratorHandler,
            ]),
            new ObjectAdd(dependencyContainer, [
              new FloorObjectHandler(dependencyContainer),
              new WallEmbeddableObjectHandler(dependencyContainer),
              new WallMountObjectHandler(dependencyContainer),
              new GroupObjectHandler(dependencyContainer),
              new CornerGroupObjectHandler(dependencyContainer),
            ]),
            select3DComponent,
            new HideLookBlockWalls(dependencyContainer),
            new WallNumbersUpdate(dependencyContainer),
            new Drag3D(dependencyContainer, [
              new WallMountObjectDrag(dependencyContainer, [
                new AxisDragHandler(dependencyContainer),
                new WallObjectSmartDrag(dependencyContainer),
                new SnapHandler(dependencyContainer),
              ]),
              new FloorObjectDrag(dependencyContainer, [
                new FloorObjectSmartDrag(dependencyContainer),
                new SnapHandler(dependencyContainer),
              ]),
              new WallObjectDrag(dependencyContainer, [
                new AxisDragHandler(dependencyContainer),
                new WallObjectSmartDrag(dependencyContainer),
                new SnapHandler(dependencyContainer),
              ]),
              new GroupObjectDrag(dependencyContainer, [
                new SuiteAxisDragHandler(dependencyContainer),
                new SnapHandler(dependencyContainer),
              ]),
            ]),
            new WallView(dependencyContainer),
          ]);

          engine3D.init(scene3D.getHtmlElement());
        }

        return engine3D;
      },

      activateStepRelatedComponents: function (step) {
        if (!engine3D) return;

        switch (step) {
          case "step3":
          case "step1":
          case "step2":
            {
              engine3D.findComponentByType(ObjectControls).disable();
              engine3D.findComponentByType(Select3D).disable();
              engine3D.findComponentByType(ObjectAdd).disable();
            }
            break;
          case "step4":
            {
              engine3D.findComponentByType(Select3D).enable();
              engine3D.findComponentByType(Drag3D).enable();
              engine3D.findComponentByType(WallView).enable();
              engine3D.findComponentByType(ObjectControls).enable();
              engine3D.findComponentByType(ObjectAdd).enable();
            }
            break;
          case "step5":
            {
              engine3D.findComponentByType(ObjectAdd).disable();
              engine3D.findComponentByType(Select3D).disable();
              engine3D.findComponentByType(Drag3D).disable();
              engine3D.findComponentByType(WallView).disable();
              engine3D.findComponentByType(ObjectControls).disable();
            }
            break;
        }
      },

      dispose: function () {
        if (engine3D) {
          engine3D.dispose();
          dependencyContainer.dispose();
          engine3D = null;
        }
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("engine3DSrvc", service);
})();
