import * as THREE from "../libs/three";

(function () {
  var service = function (
    materialBuilder,
    resourceLoader,
    $q,
    materialConfigManager,
    constants,
    wall3DDataManager
  ) {
    var cachedMaterials = {
      //hash:material
    };

    var materialMaps = [
      "map",
      "normalMap",
      "displacementMap",
      "envMap",
      "specularMap",
      "bumpMap",
      "alphaMap",
    ];

    var getMapsUrls = function (config) {
      var result = [],
        i = 0,
        mapName,
        url;
      for (; i < materialMaps.length; i++) {
        mapName = materialMaps[i];
        url = config[mapName];

        result.push(url || null);
      }
      return result;
    };

    var setMapsToConfig = function (maps, config) {
      for (var i = 0; i < materialMaps.length; i++) {
        var mapName = materialMaps[i];
        config[mapName] = maps[i];
      }
    };

    var imagesLoaded = function (
      materialConfig,
      counter,
      deferred,
      mesh,
      materialIndex,
      loadedImages
    ) {
      var configCopy = $.extend(true, {}, materialConfig);
      setMapsToConfig(loadedImages, materialConfig);
      var newMaterial = materialBuilder.create(materialConfig);

      if (mesh.material instanceof THREE.MeshFaceMaterial) {
        mesh.material.materials[materialIndex] = newMaterial;
      } else {
        mesh.material = newMaterial;
      }

      var hash = JSON.stringify(configCopy);
      cachedMaterials[hash] = newMaterial.clone();

      counter.value--;

      if (counter.value <= 0) {
        deferred.resolve();
      }
    };

    var getMaterials = function (mesh) {
      var result = [];

      if (mesh.material instanceof THREE.MeshFaceMaterial) {
        var objectMaterials = mesh.material.materials,
          materialIndex = 0,
          materialName,
          definedMaterialNames = materialConfigManager.definedMaterialNames;

        for (; materialIndex < objectMaterials.length; materialIndex++) {
          materialName = objectMaterials[materialIndex].name;
          result.push({
            name: materialName,
            index: materialIndex,
          });
        }
      } else {
        result.push({
          name: mesh.material.name,
          index: materialIndex,
        });
      }

      return result;
    };

    return {
      setMaterial: function (mesh, colorScheme) {
        var objectMaterials = mesh.material.materials,
          i = 0,
          materialIndex,
          material,
          deferred = $q.defer(),
          materialConfig,
          materials = getMaterials(mesh),
          counter = { value: materials.length },
          hash;

        for (; i < materials.length; i++) {
          material = materials[i];
          if (mesh.userData.entity.isFloor) {
            materialConfig = materialConfigManager.getConfigForFloor(
              material.name,
              colorScheme,
              mesh
            );
          } else if (mesh.userData.entity.isWall) {
            var wallLength = wall3DDataManager.getSize(mesh.name).length;
            materialConfig = materialConfigManager.getConfigForWall(
              material.name,
              colorScheme,
              wallLength
            );
          } else {
            materialConfig = materialConfigManager.getConfig(
              material.name,
              colorScheme,
              mesh.userData.entity
            );
          }
          hash = JSON.stringify(materialConfig);

          if (cachedMaterials[hash]) {
            if (mesh.material instanceof THREE.MeshFaceMaterial)
              objectMaterials[material.index] = cachedMaterials[hash].clone();
            else mesh.material = cachedMaterials[hash].clone();

            counter.value--;
            // if (counter.value <= 0)
            //     deferred.resolve();
          } else if (materialConfig) {
            var maps = getMapsUrls(materialConfig);
            resourceLoader
              .load(maps)
              .then(
                imagesLoaded.bind(
                  null,
                  materialConfig,
                  counter,
                  deferred,
                  mesh,
                  material.index
                )
              )
              .catch(function () {
                console.error("resource load fail");
                deferred.reject();
              });
          } else {
            counter.value--;
          }
          if (counter.value <= 0) deferred.resolve();
        }

        return deferred.promise;
      },
    };
  };

  angular
    .module("valleyCraftApp")
    .service("objectMaterial", [
      "materialBuilder",
      "resourceLoader",
      "$q",
      "materialConfigManager",
      "constants",
      "wall3DDataManager",
      service,
    ]);
})();
