<div *ngIf="isLoadingService | async" class="loading-bg">
  <div class="loading-overlay"></div>
  <div class="loading-content">
    <div class="cssload-loader">Loading {{preloaderProgressSubject | async}}</div>
  </div> 
</div>

<div *ngIf="isLoadingIntersector | async" class="loading-bg">
  <div class="loading-overlay"></div>
  <div class="loading-content">
    <img src="assets/images/loading.gif" width="72" height="72" />
  </div>
</div>


<mat-sidenav-container
  class="h-[calc(100vh_-_var(--pb-toolbar-height,8rem))] bg-white"
  autosize
>
  <mat-sidenav
    #sideNav
    mode="side"
    position="start"
    opened
    class="w-[28rem] overflow-y-auto px-4"
  >
    <pb-products-menu></pb-products-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-full px-4 pb-4 gap-5">
      <div class="flex-none">
      <!--   <pb-configurator-menu></pb-configurator-menu> -->
      </div>
      <div class="flex-1 bg-gray-100 rounded">
        <pb-configurator></pb-configurator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>