import * as THREE from 'three';

export const checkIntersection = (object, objects) => {
  const objectParams = new THREE.Box3().setFromObject(object);
  const objectsArr = objects;
  let newObjectsArray: any[] = [];
  let result = false;
  objectsArr.forEach((element) => {
    newObjectsArray = [...newObjectsArray, ...element.children];
  });
  for (let index = 1; index < newObjectsArray.length; index++) {
    let neighbourObjectParams = new THREE.Box3().setFromObject(
      newObjectsArray[index]
    );
    if (objectParams.intersectsBox(neighbourObjectParams)) {
      result = true;
      break;
    }
  }
  return result;
}

export const getIntersectionObjectHeight = (object, objects) => {
  // console.log('object', object);
  const objectParams = new THREE.Box3().setFromObject(object);
  const objectsArr = objects;
  let newObjectsArray: any[] = [];
  let result = 0;
  objectsArr.forEach((element) => {
    newObjectsArray = [...newObjectsArray, ...element.children];
  });
  newObjectsArray = [...newObjectsArray.filter(el => el.userData.name !== 'root')]
  // console.log('newObjectsArray', newObjectsArray);
  // console.log('getIntersectionObject objectsArr', objectsArr);
  for (let index = 0; index < newObjectsArray.length; index++) {
    let neighbourObjectParams = new THREE.Box3().setFromObject(
      newObjectsArray[index]
    );
    if (objectParams.intersectsBox(neighbourObjectParams)) {
      // console.log('objectParams', objectParams);
      // console.log('neighbourObjectParams', neighbourObjectParams);
      // console.log('neighbourObjectParams Array[index]', newObjectsArray[index]);
      result =
        Math.abs(neighbourObjectParams.max.y - neighbourObjectParams.min.y) /
        2;
      break;
    }
  }
  return result;
}

export const getIntersectionObjectLength = (object, objects) => {
  // console.log('object', object);
  const objectParams = new THREE.Box3().setFromObject(object);
  const objectsArr = objects;
  let newObjectsArray: any[] = [];
  let result = 0;
  objectsArr.forEach((element) => {
    newObjectsArray = [...newObjectsArray, ...element.children];
  });
  newObjectsArray.filter(obj => obj.uuid !== object.uuid)
  // console.log('newObjectsArray', newObjectsArray);
  // console.log('getIntersectionObject objectsArr', objectsArr);
  // console.log('getIntersectionObject object', object);
  for (let index = 1; index < newObjectsArray.length; index++) {
    let neighbourObjectParams = new THREE.Box3().setFromObject(
      newObjectsArray[index]
    );
    if (objectParams.intersectsBox(neighbourObjectParams)) {
      // console.log('newObjectsArray[index]', newObjectsArray[index]);
      result =
        Math.abs(neighbourObjectParams.max.z - neighbourObjectParams.min.z) /
        2;
      break;
    }
  }
  return result;
}

export const getIntersectionObject = (object, objects) => {
  // console.log('object', object);
  const objectParams = new THREE.Box3().setFromObject(object);
  const objectsArr = objects;
  let newObjectsArray: any[] = [];
  let result;
  objectsArr.forEach((element) => {
    newObjectsArray = [...newObjectsArray, ...element.children];
  });
  // console.log('newObjectsArray', newObjectsArray);
  // console.log('getIntersectionObject objectsArr', objectsArr);
  // console.log('getIntersectionObject object', object);
  for (let index = 1; index < newObjectsArray.length; index++) {
    let neighbourObjectParams = new THREE.Box3().setFromObject(
      newObjectsArray[index]
    );
    if (objectParams.intersectsBox(neighbourObjectParams)) {
      // console.log('newObjectsArray[index]', newObjectsArray[index]);
      result = newObjectsArray[index];
      break;
    }
  }
  return result;
}

export const getIntersectionObjectParams = (object, objects) => {
  // console.log('object', object);
  const objectParams = new THREE.Box3().setFromObject(object);
  const objectsArr = objects;
  let newObjectsArray: any[] = [];
  let result;
  objectsArr.forEach((element) => {
    newObjectsArray = [...newObjectsArray, ...element.children];
  });
  // console.log('newObjectsArray', newObjectsArray);
  // console.log('getIntersectionObject objectsArr', objectsArr);
  // console.log('getIntersectionObject object', object);
  for (let index = 1; index < newObjectsArray.length; index++) {
    let neighbourObjectParams = new THREE.Box3().setFromObject(
      newObjectsArray[index]
    );
    if (objectParams.intersectsBox(neighbourObjectParams)) {
      // console.log('newObjectsArray[index]', newObjectsArray[index]);
      neighbourObjectParams.side = newObjectsArray[index].side
      result = neighbourObjectParams;
      break;
    }
  }
  return result;
}
