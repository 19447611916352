import * as THREE from "../../../libs/three";
import ObjectControlsHandlerBase from "./ObjectControlsHandlerBase";
import { setItemAsync } from "../../../libs/asyncLocalStorage";
var ConfiguratorHandler = function (dependencyContainer, select3D) {
  ObjectControlsHandlerBase.prototype.constructor.call(this, "configurator");
  this._scene3D = dependencyContainer.getService("scene3D");
  this._configuratorService = dependencyContainer.getService(
    "configuratorService"
  );
  this._sceneSyncSrvc = dependencyContainer.getService("sceneSyncSrvc");
  this._roomStateManager = dependencyContainer.getService("roomStateManager");
  this._scene2d = dependencyContainer.getService("Scene2d");
  this._dimensionalRadialsManager = dependencyContainer.getService(
    "dimensionalRadialsManager"
  );
  this._screenShotStoreSrvc = dependencyContainer.getService(
    "screenShotStoreSrvc"
  );
  this._objects2DSerializer = dependencyContainer.getService(
    "objects2DSerializer"
  );
  this._select3D = select3D;
  this._angularJsService = dependencyContainer.getService("AngularService");
  this._getWallmountableObj = () => {
    return ["windows", "doors", "garageDoors", "misc"];
  };
  /**before dependencyContainer add in engine3DSrvc */
};

ConfiguratorHandler.prototype = Object.create(
  ObjectControlsHandlerBase.prototype
);

ConfiguratorHandler.prototype.constructor = ConfiguratorHandler;

ConfiguratorHandler.prototype.invokeAction = function (actionName, arg) {
  if (actionName === "hold") {
    const objectsAll = this._scene2d.getEntityObjects();
    // const wallmountableObj = this._getWallmountableObj();
    /* const objects =  objectsAll.reduce((result, object) => {
      if (wallmountableObj.includes(object.userData.entity.category)) {
        result.push(object);
      }
      return result;
    }, []);  */

    const points = this._roomStateManager.getRoomPoints();
    const walls = this._scene3D.filterChildren(function (mesh) {
      return mesh.userData.entity && mesh.userData.entity.isWall;
    });
    const obj = {};
    const obj1 = this._select3D.getSelected();
    Object.assign(obj, obj1);
    this.deleteSelect(obj1);
    let select = [];
    const objects = objectsAll.reduce((result, object) => {
      if (obj[0].uuid !== object.uuid) {
        result.push(object);
      } else {
        select.push(object);
      }
      return result;
    }, []);

    const wallMountableInfo = walls.find((wall) => {
      if (
        obj[0].userData.entity.wallInteraction === "mountable" &&
        wall.rotation.equals(obj[0].rotation)
      ) {
        const boxWall = new THREE.Box3().setFromObject(wall);
        const boxObj = new THREE.Box3().setFromObject(obj[0]);
        return boxWall.intersectsBox(boxObj);
      }
      return false;
    });

    const wallMountable = wallMountableInfo
      ? {
          uuid: wallMountableInfo.uuid,
          position: wallMountableInfo.position,
          rotation: wallMountableInfo.rotation,
        }
      : null;
    const floor = this._scene3D.getFloor();

    const wallColorScheme = walls.reduce((result, wall) => {
      const colorScheme = this._roomStateManager.getColorSchemeByObjectId(
        wall.name
      )?.scheme;
      if (colorScheme) {
        if (Array.isArray(colorScheme)) {
          result.push({ name: wall.name, colorScheme: colorScheme[0] });
        } else {
          result.push({ name: wall.name, colorScheme: colorScheme });
        }
      }
      return result;
    }, []);

    const floorColorScheme = this._roomStateManager.getColorSchemeByObjectId(
      floor.name
    ).scheme;

    const screenshot = this._screenShotStoreSrvc.getScreenShotObject();
    const product = {
      position: obj[0].position,
      rotation: obj[0].rotation,
      uuid: obj[0].uuid,
      wallMountable: wallMountable,
    };
    const room = {
      points,
      wallColorScheme,
      floorColorScheme,
      screenshot,
      product,
      objects: this._objects2DSerializer.serialize(objects),
      serializeSelect: this._objects2DSerializer.serialize(select),
    };
    //this.saveScene();
    this.getLocationNewConfigurtator(obj);

    try {
      (async () => {
        const value = await setItemAsync("room", JSON.stringify(room));
        console.log(value);
      })();
    } catch (error) {
      console.error(error);
    }
  }

  return false;
};

ConfiguratorHandler.prototype.deleteSelect = function (obj) {
  var number,
    isSuite = 0;
  for (var i = 0; i < obj.length; i++) {
    if (obj[i].userData.suiteId) {
      number = i;
      isSuite = 1;
    }
  }
  if (isSuite) {
    var chldn = this._scene3D.filterChildren(function (item) {
      return item.userData.suiteUniqueId === obj[number].userData.suiteUniqueId;
    });
    this._sceneSyncSrvc.deleteObjects(chldn);
  }
  this._select3D.clearSelection();
  this._dimensionalRadialsManager.deleteDimensionalRadials();
};

/* ConfiguratorHandler.prototype.saveScene = function () {
  const children = this._scene3D.getChildren();
  const cloneScene = [];
  const serializeScene = [];
  const wallmountableObj = this._getWallmountableObj();
  children.forEach((child) => {
    if (child.type === "Mesh" && child.name === "") {
      if (!wallmountableObj.includes(child.userData.entity.category)) {
        cloneScene.push(child);
        cloneScene.push({
          entity: child.userData.entity,
          position: child.position,
          rotation: child.rotation,
          uuid: child.uuid,
        });
      }
    }
  });
  this._configuratorService.saveSceneJson(
    JSON.stringify({
      serializeScene: this._objects2DSerializer.serialize(cloneScene),
    })
  );
}; */

ConfiguratorHandler.prototype.getLocationNewConfigurtator = function (obj) {
  var modeldata = {};
  modeldata.entity = {
    id: obj[0].userData.entity.id,
    img: obj[0].userData.entity.img,
    category: obj[0].userData.entity.category,
    model: obj[0].userData.entity.model,
    name: obj[0].userData.entity.name,
    baseName: obj[0].userData.entity.base_model_name,
    dropId: obj[0].userData.entity.dropId,
    wallInteractionobj: obj[0].userData.entity.wallInteraction,
    variants: obj[0].userData.entity.variants,
  };
  modeldata.locationHash = window.location.hash.split("/").slice(2);
  var objConfig = JSON.stringify(modeldata);
  const currentUrl = window.location.protocol;
  const patchData =
    this._angularJsService.getData().patch === "/planning" ? "planning" : "not";
  window.location.assign(
    `${currentUrl}/#/configurator?obj=${objConfig}&patch=${JSON.stringify(
      patchData
    )}`
  );
};

ConfiguratorHandler.prototype.set = function ($location) {
  $location = $location;
};

ConfiguratorHandler.prototype.canExecute = function (objects) {
  return (
    ObjectControlsHandlerBase.prototype.canExecute.call(this, objects) &&
    this._canDelete(objects)
  );
};

ConfiguratorHandler.prototype._canDelete = function (objects) {
  let category = ["1"]; //only panel // ["0", "1"];
  var i = 0,
    entity;

  for (; i < objects.length; i++) {
    entity = objects[i].userData.entity;
    if (entity.category.toLowerCase() === "akro-mils") {
      if (entity?.configurable) {
        return true;
      }
      if (
        category.includes(entity.dropId[0]) &&
        entity?.configurable === false
      ) {
        return false;
      }
      return category.includes(entity.dropId[0]);
    } else {
      if (
        entity.isWall ||
        entity.isFloor ||
        entity.category.toLowerCase() === "configurator" ||
        entity.category.toLowerCase() !== "akro-mils"
      ) {
        return false;
      }
    }
  }

  return true;
};

export default ConfiguratorHandler;
