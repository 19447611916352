import * as THREE from '../../libs/three';

import WallLengthChangeBase from './WallLengthChangeBase';
var MoveCorner = function (dependencyContainer) {
    WallLengthChangeBase.prototype.constructor.call(this, dependencyContainer);

    this._helperLines = dependencyContainer.getService('helperLines');
    this._wallConnectionSrvc = dependencyContainer.getService('wallConnectionSrvc');

    this._raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, -1));
    this._connectionPoint = null;
    this._pointsExceptDragged = null;
    this._minAngle = THREE.Math.degToRad(10);
};

MoveCorner.prototype = Object.create(WallLengthChangeBase.prototype);

MoveCorner.prototype.constructor = MoveCorner;

MoveCorner.prototype.mouseDown = function (e) {

    var rayOrigin = this._Scene2d.screenToWorld(e.offsetX, e.offsetY);
    rayOrigin.z = 500;

    this._raycaster.ray.origin.copy(rayOrigin);

    var connectionPoints = this._Scene2d.getConnectionPoints();

    var intersects = this._raycaster.intersectObjects(connectionPoints)[0];

    if (intersects) {
        this._connectionPoint = intersects.object;
        this._Scene2d.addMany(this._helperLines.getLines());
        var connectionPointIndex = this._connectionPoint.userData.index;
        this._pointsExceptDragged = this._roomStateManager.getPoints().filter(function (item, index) {
            return index !== connectionPointIndex;
        })
        return false;
    }
};

MoveCorner.prototype.mouseMove = function (e) {
    if (this._connectionPoint) {

        var newPosition = this._Scene2d.screenToWorld(e.offsetX, e.offsetY),
            points = this._roomStateManager.getPoints(),
            currentIndex = this._connectionPoint.userData.index,
            prevIndex = this._roomStateManager.getPrevPointIndex(currentIndex),
            nextIndex = this._roomStateManager.getNextPointIndex(currentIndex),
            prevPrevIndex = this._roomStateManager.getPrevPointIndex(prevIndex),
            nextNextIndex = this._roomStateManager.getNextPointIndex(nextIndex),
            currentPoint = points[currentIndex],
            prevPoint = points[prevIndex],
            nextPoint = points[nextIndex],
            prevPrevPoint = points[prevPrevIndex],
            nextNextPoint = points[nextNextIndex],
            currentWall = this._Scene2d.getWallByIndex(currentIndex + 1),
            prevWall = this._Scene2d.getWallByIndex(prevIndex + 1),
            nextWall = this._Scene2d.getWallByIndex(nextIndex + 1),
            prevPrevWall = this._Scene2d.getWallByIndex(prevPrevIndex + 1),
            intersectionPoint;

        newPosition.z = 0;
        intersectionPoint = this._helperLines.test(newPosition, this._pointsExceptDragged);
        newPosition = intersectionPoint || newPosition;

        if (
            !this._isWallLengthValid(prevPoint, newPosition)
            || !this._isWallLengthValid(newPosition, nextPoint)
            || !this._angleIsValid(prevPoint, newPosition, nextPoint)
            || !this._angleIsValid(prevPrevPoint, prevPoint, newPosition)
            || !this._angleIsValid(newPosition, nextPoint, nextNextPoint)
            || this._containsPoint(newPosition, this._pointsExceptDragged)
            || this._isIntersects({ point: prevPoint, index: prevIndex }, { point: newPosition, index: currentIndex }, points)
            || this._isIntersects({ point: newPosition, index: currentIndex }, { point: nextPoint, index: nextIndex }, points)
            || !this._wallObjectsNotOverlap(currentWall, newPosition.distanceTo(nextPoint))
            || !this._wallObjectsNotOverlap(prevWall, prevPoint.distanceTo(newPosition))
        ) {
            return false;
        }

        this._room2DBuilder.updateWallLength(currentWall, newPosition, nextPoint);
        this._room2DBuilder.updateWallLength(prevWall, prevPoint, newPosition);

        this._roomStateManager.updatePoint(currentIndex, newPosition);

        this._wallConnectionSrvc.connectTwoWalls2D(currentWall, nextWall, nextPoint);
        this._wallConnectionSrvc.connectTwoWalls2D(prevWall, currentWall, currentPoint);
        this._wallConnectionSrvc.connectTwoWalls2D(prevPrevWall, prevWall, prevPoint);

        this._roomSizeManager.updateWallSizes(currentWall);
        this._roomSizeManager.updateWallSizes(prevWall);
        this._roomSizeManager.updateWallSizes(nextWall);

        this._connectionPoint.position.set(newPosition.x, newPosition.y, 1);

        this._updateFloor();

        this._Scene2d.render();

        return false;
    }
};

MoveCorner.prototype._angleIsValid = function (a, b, c) {
    var v1 = a.clone().sub(b).normalize(),
        v2 = c.clone().sub(b).normalize(),
        angle = v1.angleTo(v2);
    return angle > this._minAngle;
};

MoveCorner.prototype.mouseUp = function () {
    if (this._connectionPoint) {
        this._connectionPoint = null;
        this._helperLines.hideAll();
        this._Scene2d.render();
        return false;
    }
};

export default MoveCorner;
