import * as THREE from "../libs/three";
import dotaReplaceAssets from '../libs/replace';
(function () {
  var service = function (constants, materialStuffService, ResourceService) {
    var common = {
      M2: {
        color: 0x121212,
        specular: 0xffffff,
      },
    };

    var colorScheme = [];

    ResourceService.getAllColorSchemes().then(function (data) {
      colorScheme = data;
    });

    var main = {
      M1: {
        shininess: 30,
        reflectivity: 0.1,
      },
      M2: {
        shininess: 60,
        reflectivity: 0.68,
        normalMap: "/assets/textures/cabinets/nm.jpg",
        normalScale: new THREE.Vector2(0.5, 0.5),
        normalMapSettings: {
          wrapS: THREE.RepeatWrapping,
          wrapT: THREE.RepeatWrapping,
          repeat: new THREE.Vector2(10, 10),
        },
      },
      chrome: {
        color: 0xffffff,
        specular: 0xffffff,
        shininess: 90,
        reflectivity: 1,
        envMap: "/assets/textures/cabinets/envMap.png",
      },
      wheel: {
        color: 0xffffff,
        specular: 0xffffff,
        shininess: 90,
        reflectivity: 1,
        map: "/assets/textures/cabinets/wheel.jpg",
      },
      Standard_1: {},
    };

    var merge = function (materialName, baseConfig, colorSchemeName) {
      for (var i = 0; i < colorScheme.length; i++) {
        if (colorScheme[i].colorSchemeName === colorSchemeName) {
          var schemeId = i;
          break;
        }
      }
      if (typeof schemeId !== "undefined") {
        for (var j = 0; j < colorScheme[schemeId].materials.length; j++) {
          if (
            colorScheme[schemeId].materials[j].materialName === materialName
          ) {
            var colorSchemeConfig = colorScheme[schemeId].materials[j];
            break;
          }
        }
      }
      if (colorSchemeConfig) {
        var result = $.extend(true, {}, baseConfig);
        return $.extend(true, result, colorSchemeConfig);
      }
      return null;
    };

    var mergeWallsFloor = function (
      materialName,
      baseConfig,
      colorSchemeConfig
    ) {
      var result = $.extend(true, {}, baseConfig);
      if (colorSchemeConfig) {
        result = $.extend(true, result, colorSchemeConfig);
      }
      return result;
    };

    return {
      colorSchemes: function () {
        return colorScheme;
      },
      getConfigForFloor: function (materialName, material, mesh) {
        var materialObject =
          materialStuffService.getFloorMaterialByName(material);
        var repeatX =
            (Math.abs(
              mesh.geometry.boundingBox.max.x - mesh.geometry.boundingBox.min.x
            ) /
              (materialObject.width * materialObject.scale)) *
            0.01,
          repeatY =
            (Math.abs(
              mesh.geometry.boundingBox.max.y - mesh.geometry.boundingBox.min.y
            ) /
              (materialObject.height * materialObject.scale)) *
            0.01;
        var base = {
          mapSettings: {
            wrapS: THREE.RepeatWrapping,
            wrapT: THREE.RepeatWrapping,
            repeat: new THREE.Vector2(repeatX, repeatY),
          },
        };
        var colorSchemeConfig = { map: materialObject.path };
        var merged = mergeWallsFloor(materialName, base, colorSchemeConfig);
        merged.name = materialName;
        return merged;
      },

      getConfigForWall: function (materialName, material, wallLength) {
        var materialObject =
          materialStuffService.getWallMaterialByName(material);
        var repeatX =
            (wallLength * 10) / (materialObject.width * materialObject.scale),
          repeatY =
            (constants.wallHeight * 10) /
            (materialObject.height * materialObject.scale);
        var base = {
          mapSettings: {
            wrapS: THREE.RepeatWrapping,
            wrapT: THREE.RepeatWrapping,
            repeat: new THREE.Vector2(repeatX, repeatY),
          },
        };
        var colorSchemeConfig = { map: materialObject.path };
        var merged = mergeWallsFloor(materialName, base, colorSchemeConfig);
        merged.name = materialName;
        return merged;
      },

      getConfig: function (materialName, colorScheme, entity) {
        var result;
        var base = main[materialName];

        result = merge(materialName, base, colorScheme);

      /*   if (result?.map) {
          result.map = dotaReplaceAssets(result.map);
        } */

        result && (result.name = materialName);

        return result;
      },
      definedMaterialNames: Object.getOwnPropertyNames(main),
    };
  };

  angular
    .module("valleyCraftApp")
    .service("materialConfigManager", [
      "constants",
      "materialStuffService",
      "ResourceService",
      service,
    ]);
})();
