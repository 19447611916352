import ComponentBase from '../ComponentBase';
import * as THREE from '../../libs/three';
import Selection2D from './Selection2D';
var Drag2D = function (dependencyContainer) {
    ComponentBase.prototype.constructor.call(this);
    this._dragging = false;
    this._canBePlaced = false;

    this._step3Helper = dependencyContainer.getService('step3Helper');
    this._constants = dependencyContainer.getService('constants');
    this._keyState = dependencyContainer.getService('keyState');
    this._Scene2d = dependencyContainer.getService('Scene2d');
    this._collisionSrv = dependencyContainer.getService('collisionSrvc');
    this._obbBuilder = dependencyContainer.getService('obbBuilder');

    this._select2DComponent = null;
    this._lastValidPosition = new THREE.Vector3();
    this._initialDrag = true;
    this._staticObjs = null;
    this._addObj = true;
};

Drag2D.prototype = Object.create(ComponentBase.prototype);

Drag2D.prototype.constructor = Drag2D;

Drag2D.prototype.init = function (engine) {
    ComponentBase.prototype.init.call(this, engine);
    this._select2DComponent = this._engine.findComponentByType(Selection2D);
};

Drag2D.prototype._invokeDragging = function (e, draggedObject) {
    this._dragging = true;
    var position = this.mouseToScreen(e);
    position.z = draggedObject.position.z;

    if (this._initialDrag) {
        this._initialDrag = false;
        this._lastValidPosition.copy(draggedObject.position);
    }

    this.dragging(position, draggedObject);

    draggedObject.visible = true;

    this._staticObjs = this._staticObjs || this.getStaticObjects(draggedObject);

    var collide = this.isCollide(draggedObject, this._staticObjs);

    if (collide) {
        draggedObject.position.copy(this._lastValidPosition);
    } else {
        this._lastValidPosition.copy(draggedObject.position);
        draggedObject.position.copy(draggedObject.position);
    }

    var groupId = draggedObject.userData.groupId;
    if (groupId) {
        var objects = this._Scene2d.getChildren();
        for (var i = 0; i < objects.length; i++) {
            if (objects[i].userData.groupId && objects[i].userData.groupId === groupId)
                objects[i].userData.groupId = undefined;
        }
    }

    this._Scene2d.render();
};

Drag2D.prototype.mouseDown = function (e) {
    var draggedObject = this.getSelectedObject();
    var position = this.mouseToScreen(e);
    position.z = draggedObject.position.z;
    if ((position, draggedObject)) {
        this.dragStart(position, draggedObject);
    }
};

Drag2D.prototype.mouseMove = function (e) {
    var draggedObject = this.getSelectedObject();
    if (draggedObject && this._keyState.isMouseHold) {
        var isSingleClick =
            this._select2DComponent.mouseDownTime &&
            new Date().getTime() - this._select2DComponent.mouseDownTime < 150;
        if (!isSingleClick) {
            this._invokeDragging(e, draggedObject);
        }
    }
};

Drag2D.prototype.touchMove = function (e) {
    e.preventDefault();
    var draggedObject = this.getSelectedObject();
    if (draggedObject) {
        this._invokeDragging(e, draggedObject);
    }
};

Drag2D.prototype._invokeDragEnd = function (e) {
    var draggedObject = this.getSelectedObject();

    if (this._dragging && draggedObject) {
        this.dragEnd(draggedObject);
    }

    this.release();

    this._initialDrag = true;
    this._canBePlaced = this._dragging = false;
    this._step3Helper.highlightWallIfDefined(
        this._selectedWall,
        this._constants.HighlightState.DEFAULT
    );
    this._selectedWall = this._staticObjs = null;

    this._Scene2d.render();
};

Drag2D.prototype._invokeDragCanceled = function (e) {
    var draggedObject = this.getSelectedObject();

    if (draggedObject) {
        this.dragCanceled(draggedObject);
    }
    this.release();
};

Drag2D.prototype.mouseUp = function (e) {
    this._invokeDragEnd();
    this._invokeDragCanceled();
};

Drag2D.prototype.touchEnd = function (e) {
    e.preventDefault();
    this._invokeDragEnd();
    this._invokeDragCanceled();
    this._Scene2d.render();
};

Drag2D.prototype.mouseToScreen = function (e) {
    var boundingRect = this._engine._element.getBoundingClientRect(),
        mouseCoords = e.touches ? e.touches[0] : e,
        position = this._Scene2d.screenToWorld(
            mouseCoords.clientX - boundingRect.left,
            mouseCoords.clientY - boundingRect.top
        );
    return position;
};

Drag2D.prototype.dragStart = function (draggedObject) {};

//called on mouseMove/touchMove if object is selected
Drag2D.prototype.dragging = function (position, draggedObject) {};

//called on touchEnd/mouseUp if dragging has occur
Drag2D.prototype.dragEnd = function (draggedObject) {};

Drag2D.prototype.dragCanceled = function (draggedObject) {};

//called on touchEnd/mouseUp
Drag2D.prototype.release = function () {};

//get static object for collision check
Drag2D.prototype.getStaticObjects = function (draggedObject) {
    return this._Scene2d.getChildren().filter(function (item) {
        return item.userData.entity && item !== draggedObject;
    });
};

Drag2D.prototype.isCollide = function (draggedObject, staticObj) {
    return this._collisionSrv.isCollide(draggedObject, staticObj, { upAxis: 'z' });
};

Drag2D.prototype.isEnabled = function () {
    return this._enable && this.getSelectedObject();
};

Drag2D.prototype.getSelectedObject = function () {
    return this._select2DComponent.selectedObject;
};

Drag2D.prototype.getIntersectedWall = function (draggedObject) {
    var walls = this._Scene2d.getWalls();

    var wall = this._step3Helper.getWallInHotZone(draggedObject, walls);

    if (wall) return wall;

    var obb = this._obbBuilder
        .create()
        .setBoundingBox(draggedObject.userData.boundingBox)
        .build(draggedObject);

    return this._collisionSrv.objectOBBIntersectWalls(obb, walls);
};
export default Drag2D;