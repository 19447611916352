(function () {
  var dependencies = ["constants", "roomStuff", "roomStateManager"];
  var productData = [];
  var subfloorActive = true;
  var includSubfloorInSPL = true;

  var service = function (constants, roomStuff, roomStateManager) {
    function findProduct(id, color) {
      return _.find(productData, { id: id, color: color });
    }

    function setDimensions(product) {
      var previousWidth = product.width;
      var previousLength = product.length;
      var previousHeight = product.height;
      if (product.objectType === constants.ObjectType.CABINET) {
        product.width = Math.round(previousLength / 2.54);
        product.length = Math.round(previousWidth / 2.54);
      } else if (product.objectType === constants.ObjectType.CONFIGURATOR) {
        product.width = Math.round(product.Oldwidth / 2.54);
        product.length = Math.round(previousWidth / 2.54);
      } else {
        product.width = Math.round(previousWidth / 2.54);
        product.length = Math.round(previousLength / 2.54);
      }
      product.height = Math.round(previousHeight / 2.54);
    }

    function recalculateWallPanelsQuantity() {
      for (var i = 0; i < productData.length; i++) {
        if (productData[i].objectType === constants.ObjectType.WALL_PANEL) {
          productData[i].quantity = Math.ceil(productData[i].quantity / 2);
        }
      }
    }

    function isProduct(product) {
      return (
        (product.userData.entity.objectType === constants.ObjectType.CABINET ||
          product.userData.entity.objectType === constants.ObjectType.TABLE ||
          product.userData.entity.objectType ===
            constants.ObjectType.WALL_PANEL) &&
        !product.userData.suiteId
      );
    }

    function isPartOfSuite(product) {
      return (
        product.userData.suiteId &&
        product.userData.entity.objectType === constants.ObjectType.CABINET
      );
    }


    function isPartOfConfigurator(product) {
      return (
        !product.userData.suiteId &&
        product.userData.entity.objectType === constants.ObjectType.CONFIGURATOR
      );
    }

    function calculateAccessoryProductData(product) {
      if (product.userData.hasOwnProperty("selectedAccessoryId")) {
        var color = getProductColorScheme(product);
        var hikId = getProductHikId(
          product.userData.entity,
          color,
          product.userData.selectedAccessoryId
        );
        var variant = _.find(product.userData.entity.variants, function (pv) {
          return pv.hikId === hikId;
        });
        // product with accessory has
        // one sku for product
        // and accessory
        if (
          variant &&
          variant.accessory &&
          variant.accessory.useProductHikId &&
          variant.accessory.id === product.userData.selectedAccessoryId
        )
          return;

        var accessory = _.find(roomStuff.accessoriesProduct(), {
          _id: product.userData.selectedAccessoryId,
        });

        // the same accessory already
        // added - increase quantity
        var foundProduct = _.find(productData, { _id: accessory._id });
        if (foundProduct) {
          foundProduct.quantity++;
        } else {
          // add new accessory as
          // a productData item
          var productDataItem = _.cloneDeep(accessory);
          productDataItem.quantity = 1;
          productDataItem.color = null;
          productDataItem.img = productDataItem.preview_img;
          productDataItem.hikId = getProductHikId(
            productDataItem,
            productDataItem.color
          );
          productDataItem.price = getPrice(productDataItem);
          productData.push(productDataItem);
        }
      }
    }

    function getProductColorScheme(product) {
      var color = roomStateManager.getColorSchemeByObjectId(product.uuid);
      return color ? color.scheme : product.userData.choosenColor;
    }

    function calculateProductProductData(product) {
    var color = getProductColorScheme(product);      
      var foundProduct = null;
      if (color) {
        foundProduct = findProduct(product.userData.entity.id, color);
      }
      // the same product already
      // added - increase quantity
      if (foundProduct) {
        foundProduct.quantity++;
      } else {
        // add new product as
        // a productData item
        var productDataItem = _.cloneDeep(product.userData.entity);
        productDataItem.quantity = 1;
        productDataItem.color = color ? color : "";
        productDataItem.sku =
          productDataItem.base_model_name + productDataItem.color;
        productDataItem.img =
          "assets/images/ProductImage/" + productDataItem.sku + ".png";
        var accessoryId = product.userData.selectedAccessoryId
          ? product.userData.selectedAccessoryId
          : "";
        productDataItem.hikId = getProductHikId(
          productDataItem,
          productDataItem.color,
          accessoryId
        );
        productDataItem.price = getPrice(productDataItem);
        setDimensions(productDataItem);
        productData.push(productDataItem);
      }
    }

    function getSuiteColorSchemeIndex(param, product) {
      var products = [];

      for (var i = 0; i < param.objects.length; i++) {
        var prod = param.objects[i].userData
          ? param.objects[i]
          : param.objects[i].object;
        if (prod.userData.suiteUniqueId === product.userData.suiteUniqueId) {
          products.push(prod);
        }
      }

      var colorOjb = {};
      // get products (THREE objects)
      for (var i = 0; i < products.length; i++) {
        var clr = roomStateManager.getColorSchemeByObjectId(products[i].uuid),
          objType;
        clr = clr ? clr.scheme : products[i].userData.choosenColor;
        if (products[i].userData.entity.type === 8) {
          objType = products[i].userData.entity.left_menu_alias + ": Corner";
        } else if (
          products[i].userData.entity.left_menu_alias === "Work Tables"
        ) {
          objType =
            products[i].userData.entity.left_menu_alias + ": Adjustable";
        } else {
          objType = products[i].userData.entity.left_menu_alias;
        }
        colorOjb[objType] = clr;
      }
      // find the most frequent
      // occurrence of color sceme
      // among suite children
      var occurrence = 0;
      var index = 0;
      var itemColorSchemeIndex = 0;
      var productDataItem = roomStuff.getById(product.userData.suiteId);
      _.forEach(productDataItem.color_schemes, function (cs) {
        var colorSchemeOccurence = 0;
        _.forEach(cs.color_scheme, function (csKey, csValue) {
          if (colorOjb[csValue].valueOf() === csKey.valueOf()) {
            colorSchemeOccurence++;
          }
        });
        if (colorSchemeOccurence > occurrence) {
          occurrence = colorSchemeOccurence;
          itemColorSchemeIndex = index;
        }
        index++;
      });

      return itemColorSchemeIndex;
    }

    function calculateSuiteProductData(product, param, suitesId) {
      var productDataItem = roomStuff.getById(product.userData.suiteId);
      var itemColorSchemeIndex = getSuiteColorSchemeIndex(param, product);
      var color = productDataItem.color_schemes[itemColorSchemeIndex].short_key;

      // if product is not part
      // of already processed suite,
      // otherwise skip (no need to
      // process each product)
      if (
        !_.find(suitesId, function (id) {
          return id === product.userData.suiteUniqueId;
        })
      ) {
        suitesId.push(product.userData.suiteUniqueId);

        var foundProduct;

        if (color) {
          foundProduct = findProduct(product.userData.suiteId, color);
        }

        // if the same suite is already
        // added, increase quantity
        if (foundProduct) {
          foundProduct.quantity++;
        } else {
          productDataItem = _.cloneDeep(productDataItem);
          productDataItem.quantity = 1;
          productDataItem.color = color ? color : "";
          productDataItem.img =
            productDataItem.color_schemes[itemColorSchemeIndex].spl_img_url;
          productDataItem.hikId = getProductHikId(
            productDataItem,
            productDataItem.color
          );
          productDataItem.price = getPrice(productDataItem);
          setDimensions(productDataItem);
          productData.push(productDataItem);
        }
      }
    }

    /**
     *
     * @param {*} product
     */

    function calculateConfiguratorProductData(product) {
      if (product.userData.entity?.productForShopingList) {
        console.log('shop',product.userData.entity);
        product.userData.entity.productForShopingList.forEach((item) => {
          let foundProductBins = null;
          const color = item?.colorSheme
            ? item?.colorSheme
            : item.variants.colorScheme.name;

          const productBIns = JSON.parse(
            angular.toJson(roomStuff.getByIdakroMils(item.id))
          );
          if (color) {
            foundProductBins = findProduct(item.id, color);
          }
          if (foundProductBins) {
            foundProductBins.quantity++;
          } else {
            productBIns.quantity = 1;
            productBIns.color = color ? color : "";
            productBIns.sku = productBIns.base_model_name + productBIns.color;
            productBIns.img =
              "assets/images/ProductImage/" + productBIns.sku + ".png";
            productBIns.hikId = getProductHikId(productBIns, color, () =>
              productBIns.userData.selectedAccessoryId
                ? productBIns.userData.selectedAccessoryId
                : ""
            );
            productBIns.price = getPrice(productBIns);
            setDimensions(productBIns);
            productData.push(productBIns);
          }
        });
      }
      var color = getProductColorScheme(product);
      var foundProduct = null;

      if (color) {
        foundProduct = findProduct(product.userData.entity.modelOldId, color);
      }
      // the same product already
      // added - increase quantity
      if (foundProduct) {
        foundProduct.quantity++;
      } else {
        // add new product as
        // a productData item
        var productDataItem = _.cloneDeep(product.userData.entity);
        productDataItem.id = product.userData.entity.modelOldId;
        productDataItem.quantity = 1;
        productDataItem.color = color ? color : "";
        productDataItem.name = productDataItem.first_model_name;
        productDataItem.sku =
          productDataItem.description + productDataItem.color;

        productDataItem.img =
          "assets/images/ProductImage/" + productDataItem.sku + ".png";

        var accessoryId = product.userData.selectedAccessoryId
          ? product.userData.selectedAccessoryId
          : "";
        productDataItem.hikId = getProductHikId(
          productDataItem,
          productDataItem.color,
          accessoryId
        );
        productDataItem.price = getPrice(productDataItem);
        setDimensions(productDataItem);
        productData.push(productDataItem);
      }
    }

    function addHashKeysToProductDataItems() {
      for (var i = 0; i < productData.length; i++) {
        productData[i].$$hashKey = "object:" + i;
      }
    }

    function getProductHikId(productData, color, accessory) {
      var variantByColor;
      var variantByColorAndAccessory;
      // three types of products
      // suite, product (cabient, table, etc),
      // accessory
      if (productData.isSuite) {
        variantByColor = _.find(productData.variants, function (v) {
          return v.colorScheme.name === color;
        });
        if (variantByColor) {
          return variantByColor.hikId;
        } else {
          console.warn("Sku for suite not found: ", productData.name);
          return null;
        }
      } else if (productData.category === "Accessories") {
        return productData.hikashop_id;
      } else {
        var variantByColor = _.find(productData.variants, function (v) {
          return v.colorScheme.name === color;
        });
        if (accessory) {
          variantByColorAndAccessory = _.find(
            productData.variants,
            function (v) {
              return (
                v.colorScheme.name === color &&
                v.accessory &&
                v.accessory.id === accessory
              );
            }
          );
        }
        if (variantByColorAndAccessory) {
          return variantByColorAndAccessory.hikId;
        } else if (variantByColor) {
          return variantByColor.hikId;
        } else {
          console.warn("Sku for product not found: ", productData.name);
          return null;
        }
      }
    }

    function getPrice(productData) {
      if (productData.category === "Accessories") {
        return productData.msrp;
      } else {
        var variant = _.find(productData.variants, function (v) {
          return v.hikId === productData.hikId;
        });
        if (variant) {
          return variant.price;
        } else {
          return 0;
        }
      }
    }

    function calculateProductData(param) {
      if (!param) return;
      productData = [];
      var suitesId = [];

      for (var i = 0; i < param.objects.length; i++) {
        var product = param.objects[i].userData
          ? param.objects[i]
          : param.objects[i].object;
        if (isProduct(product)) {
          calculateProductProductData(product);
          calculateAccessoryProductData(product);
        } else if (isPartOfSuite(product)) {
          calculateSuiteProductData(product, param, suitesId);
        } else if (isPartOfConfigurator(product)) {
          calculateConfiguratorProductData(product, param, suitesId);
        }
      }
      addHashKeysToProductDataItems();
      recalculateWallPanelsQuantity();
      return productData;
    }

    return {
      setProductData: function (val) {
        if (val) productData = val;
      },
      getProductData: function () {
        return productData;
      },
      getParamsReport: function () {
        return { isSBF: subfloorActive, isDRSBF: includSubfloorInSPL };
      },
      getProductHikId: getProductHikId,
      calculateProductData: calculateProductData,
      getTotal: function (productData) {
        var sum = 0;
        for (var i = 0; i < productData.length; i++) {
          sum += (productData[i].price || 0) * productData[i].quantity;
        }
        return sum;
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("shoppingListSrvc", service);
})();
