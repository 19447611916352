"use strict";
const moment = require("moment");
angular.module("valleyCraftApp").controller("AuthCtrl", [
  "$http",
  "$scope",
  "$rootScope",
  "$location",
  "ResourceService",
  "CryptoJSService",
  "localStorageService",
  "toastr",
  "$timeout",
  "AuthenticationService",
  "ngDialog",
  "$compile",
  "SendingLog",
  "googleAnalitycs",
  "roomStuff",
  "SaveStateService",
  "roomStateManager",
  "constants",
  "CsvService",
  "CsvExcelService",
  "PaginationService",
  function (
    $http,
    $scope,
    $rootScope,
    $location,
    ResourceService,
    CryptoJSService,
    localStorageService,
    toastr,
    $timeout,
    AuthenticationService,
    ngDialog,
    $compile,
    SendingLog,
    googleAnalitycs,
    roomStuff,
    SaveStateService,
    roomStateManager,
    constants,
    CsvService,
    CsvExcelService,
    PaginationService
  ) {
    // pager object
    $scope.pager = {
      pageSize: "25",
    };
    // paged items
    $scope.pagedItems = [];
    $scope.loginData = {
      email: "",
      password: "",
    };
    // sort status
    $scope.sortAllProjectsStatus = {
      userName: true,
      companyName: true,
      date: true,
      projectName: true,
      type: true,
    };
    $scope.bsTableOptions = {
      showColumns: false,
      search: true,
      pagination: true,
      escape: false,
      selectItemName: "toolbar",
      pageSize: 10,
      pageList: [5, 10, 20],
    };
    $scope.slTotal = 0;

    $scope.sortType = "dt";

    $scope.usersArray = [];
    $scope.usersArrayByCompany = [];
    $scope.companiesArray = [
      {
        companyName: "",
      },
    ];

    $scope.newUserData = {};
    $scope.findUserData = {
      companyName: "",
      userDataSelected: {},
      userDataSelected_id: "",
      showUserProjects: false,
      selectedUserRooms: [],
    };
    $scope.companyForDelete = {
      companyName: "",
      _id: "",
    };

    $scope.allUserRoomsArray = [];
    $scope.selectedRoomsArrayForExport = [];
    $scope.selectedUserRoomsArrayForExport = [];
    $scope.exportAllProjectsCheckbox = false;
    $scope.exportAllUserProjectsCheckbox = false;
    $scope.isBasicUser = false;
    $scope.locationPath = $location.$$path;

    // if($location.$$path === '/registration'){
    //   $scope.isLoginPage = false;
    // }

    var userData,
      user_ID = AuthenticationService.userId();
    var mail = AuthenticationService.mail();
    var publisher = AuthenticationService.publisher();
    var role = AuthenticationService.role(); // doesn't work
    // let role = localStorageService.get("role") || false;

    if (roomStateManager.hasPoints()) {
      $scope.activeLinkToBack = true;
    } else {
      $scope.activeLinkToBack = false;
    }

    console.log(
      "AuthenticationService.userId()",
      AuthenticationService.userId()
    );

    if (AuthenticationService.userId()) {
      $scope.isLoading = true;
      if (publisher) {
        ResourceService.getAllTemplates().then(
          function (data) {
            $scope.isLoading = false;
            toastr.success("Loading projects.");
          /*   $scope.userRooms = data.map((item) => {
              console.log("getById", item);
            }); */
            $scope.userRooms = data.map((item) => {
              const formattedDate = formatDate(item.lastEdited);
              return { ...item, lastEdited: formattedDate };
            });
          },
          function (data) {
            toastr.error(data.message);
          }
        );
      } else {
        ResourceService.getAllroom().then(
          function (data) {
            $scope.isLoading = false;
            toastr.success("Loading projects.");

            $scope.userRooms = data.map((item) => {
              const formattedDate = formatDate(item.lastEdited);
              return { ...item, lastEdited: formattedDate };
            });
          },
          function (data) {
            toastr.error(data.message);
          }
        );
      }

      if (role && role === constants.roles.basicUser) {
        $scope.isBasicUser = true;
      }
    }

    function formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    }
    $scope.userName = AuthenticationService.userLogged();
    //console.log($scope.userName);
    if ($scope.userName) {
      $rootScope.userLogged = true;
      $rootScope.labelUserLogged =
        $scope.userName.firstName + " " + $scope.userName.lastName;
      //get user data accounts
      getUserData();
    }
    function getOrderItems() {
      var orderData = [];
      for (var key in $scope.chosenPasses) {
        var item = $scope.chosenPasses[key];
        if (item.quantity > 0) {
          orderData.push({
            passId: item.passId,
            quantity: item.quantity,
            unitPrice: item.price,
          });
        }
      }
      return orderData;
    }

    /* console.log(
      "AuthenticationService.userLogged()",
      AuthenticationService.userLogged()
    ); */

    function getRoomIndex(rooms, roomId) {
      for (var i = 0; i < rooms.length; i++) {
        if (rooms[i]["_id"] === roomId) {
          // console.log('ROOM INDEX', i);
          return i;
        }
      }
    }

    $scope.termsAccepted = false;
    $scope.termsAcceptedHandler = function (acceptedValue) {
      $scope.termsAccepted = acceptedValue;
    };

    $scope.initBsTableAfterRowRepeat = function () {
      console.log("initBsTableAfterRowRepeat");
      $timeout(function () {
        var table = angular.element("#no-more-tables");
        table.bootstrapTable($scope.bsTableOptions);
        table.bootstrapTable("hideColumn", "mid");
        table.bind("body-changed.bs.table", function () {
          //console.log('get here one more time');
          $compile(table)($scope);
        });
      }, 0);
    };

    $scope.initBsTableAfterRowRepeatForAllProjects = function () {
      console.log("initBsTableAfterRowRepeatForAllProjects");
      $timeout(function () {
        var table = angular.element("#no-more-tables-all-projects");
        table.bootstrapTable($scope.bsTableOptions);
        table.bootstrapTable("hideColumn", "mid");
        table.bind("body-changed.bs.table", function () {
          //console.log('get here one more time');
          $compile(table)($scope);
        });
      }, 0);
    };
    window.openReport = {
      "click .report": function (e, value, row, index) {
        for (var i = 0; i < $scope.userRooms.length; i++) {
          if ($scope.userRooms[i]._id === row.mid) {
            $scope.openProjectReport($scope.userRooms[i]);
            return;
          }
        }
        $scope.openProjectReport({});
      },
    };
    window.clickRowToOptions = {
      "click .edit_project": function (e, value, row, index) {
        // var i = (row.id - 1);
        // var i = $scope.userRooms.length - row.id;
        var roomId = JSON.parse(e.target.attributes["data-id"].nodeValue)._id;
        SaveStateService.clearSavedState();
        // var rowForIndex = $scope.userRooms[i];
        $scope.editProject(roomId);
      },
      "click .share_project": function (e, value, row, index) {
        // var i = (row.id - 1);
        // var i = $scope.userRooms.length - row.id;
        // var rowForIndex = $scope.userRooms[i];
        var room = JSON.parse(e.target.attributes["data-id"].nodeValue);
        openWindowSendEmail(room._id, room.imgPreview);
      },
      "click .remove_project": function (e, value, row, index) {
        var room = JSON.parse(e.target.attributes["data-id"].nodeValue);
        var bId = JSON.parse(e.target.attributes["data-bid"].nodeValue);
        confirmDelete(room, bId);
      },
      "click .up_project": function (e, value, row, index) {
        // var i = (row.id - 1);
        var roomId = JSON.parse(e.target.attributes["data-id"].nodeValue)._id;
        var roomIndex = getRoomIndex($scope.userRooms, roomId);
        // var i = $scope.userRooms.length - row.id;
        var roomUpUp = $scope.userRooms[roomIndex],
          roomUpDown = $scope.userRooms[roomIndex + 1],
          tempDt;
        if (!roomUpDown) {
          roomUpDown = $scope.userRooms[roomIndex - 1];
        }

        tempDt = roomUpUp.dt;
        roomUpUp.dt = roomUpDown.dt;
        roomUpDown.dt = tempDt;
        MoveUpDown(
          roomUpUp._id,
          roomUpUp,
          roomIndex,
          roomUpDown._id,
          roomUpDown,
          roomIndex + 1
        );
      },
      "click .down_project": function (e, value, row, index) {
        // var i = (row.id - 1);
        // var i = $scope.userRooms.length - row.id;
        var roomId = JSON.parse(e.target.attributes["data-id"].nodeValue)._id;
        var roomIndex = getRoomIndex($scope.userRooms, roomId);

        var roomDownDown = $scope.userRooms[roomIndex],
          roomDownUp = $scope.userRooms[roomIndex - 1],
          tempDt;
        if (!roomDownUp) {
          roomDownUp = $scope.userRooms[roomIndex + 1];
        }
        tempDt = roomDownDown.dt;
        roomDownDown.dt = roomDownUp.dt;
        roomDownUp.dt = tempDt;
        MoveUpDown(
          roomDownUp._id,
          roomDownUp,
          roomIndex,
          roomDownDown._id,
          roomDownDown,
          roomIndex - 1
        );
      },
      "click .show_update": function (e, value, row, index) {
        // var i = (row.id - 1);
        var roomId = JSON.parse(e.target.attributes["data-id"].nodeValue)._id;
        var roomIndex = getRoomIndex($scope.userRooms, roomId);
        var rowForIndex = $scope.userRooms[roomIndex];
        rowForIndex.showAsTemplate = !rowForIndex.showAsTemplate;
        ResourceService.updateTemplate(rowForIndex._id, rowForIndex);
      },
    };

    $rootScope.logout = function () {
      localStorageService.clearAll();
      //console.log("LogOut user clear localStorageService = ", localStorageService);
      //console.log("logout $scope.userAccount = ", $scope.userAccount);
      $scope.userAccount = {};
      $rootScope.templateMaster = false;
      $rootScope.userLogged = false;
      $location.path("/login");
    };

    $scope.handleLogin = function () {
      if (!$scope.loginData.email || !$scope.loginData.password) {
        toastr.error("Username and password are mandatory!");
        return;
      }
      $scope.userAccount = ResourceService.login($scope.loginData).then(
        function (data) {
          localStorageService.set("mail", data.email);
          localStorageService.set("auth_token", data._id);
          if (data.role) {
            localStorageService.set("role", data.role);
          }
          if (data.publisher) {
            localStorageService.set("publisher", data.publisher);
            $rootScope.templateMaster = true;
          }
          if (
            (data.role && data.role === constants.roles.basicUser) ||
            (!data.role && !data.publisher)
          ) {
            $scope.isBasicUser = true;
            if (!data.role && !data.publisher) {
              // data.role = constants.roles.basicUser;
              ResourceService.updateUserProfileData(data._id, {
                role: constants.roles.basicUser,
                companyName: data.companyName || "",
                publish: false,
                isActive: true,
              }).then(
                function (message) {
                  localStorageService.set("role", constants.roles.basicUser);
                  toastr.success(message);
                },
                function (data) {
                  toastr.error(data.error);
                }
              );
            }
          }
          //console.log("get response from server data = ", data);
          localStorageService.set("user", {
            firstName: data.firstName,
            lastName: data.lastName,
          });

          //send log create user
          SendingLog.send("userlogin", null, null, data.email);

          googleAnalitycs.setUser(
            data._id,
            data.firstName + " " + data.lastName,
            data.email
          );

          // if user save project without login
          if ($rootScope.userSave_RoomData) {
            $rootScope.userSave_RoomData.user_id = data._id;
            if (AuthenticationService.publisher()) {
              ResourceService.saveTemplate($rootScope.userSave_RoomData).then(
                function (result) {
                  $rootScope.userSave_RoomData = null;
                  toastr.success("Your project is saved successfully!");
                  // Send log create project
                  SendingLog.send(
                    "projectcreate",
                    $rootScope.userSave_RoomData.titleProject,
                    $rootScope.userSave_RoomData.projectDetails.project
                      .totalValue
                  );
                },
                function (result) {
                  toastr.error(result);
                }
              );
            } else {
              ResourceService.saveRoom($rootScope.userSave_RoomData).then(
                function (result) {
                  $rootScope.userSave_RoomData = null;
                  toastr.success("Your project is saved successfully!");
                  // Send log create project
                  SendingLog.send(
                    "projectcreate",
                    $rootScope.userSave_RoomData.titleProject,
                    $rootScope.userSave_RoomData.projectDetails.project
                      .totalValue
                  );
                },
                function (result) {
                  toastr.error(result);
                }
              );
            }
          }

          $timeout(function () {
            $rootScope.userLogged = true;
            $rootScope.labelUserLogged = data.firstName + " " + data.lastName;
            $location.path("/account");
          }, 1000);
        },
        function (data, status) {
          if (status === 401) {
            toastr.error("Wrong username and/or password!");
          } else {
            toastr.error(data);
          }
        }
      );
    };

    $scope.downloadCSVForAllCheckedProjects = function (selectedProjectsArray) {
      $scope.downloadCSV(selectedProjectsArray);
    };

    /**
     *
     * @param {*} productDataArray
     */
    $scope.downloadCSV = function (productDataArray) {
      const csvData = [];
      function convertConstToStr(str) {
        if (!str) {
          console.warn("No string provided");
          return;
        }
        return str
          .toLowerCase()
          .replace(/_/g, " ")
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      }
      productDataArray.forEach((productData) => {
        const user = productData.user;
        const projectDetailsData = productData.projectDetails || {};
        const projectUrl = constants.API_URL + "/#/browsing/" + productData._id;

        const projectDetailsReport = {
          userName: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
          userEmail: user.email || "",
          name: projectDetailsData?.title || "",
          industry:
            convertConstToStr(projectDetailsData?.project?.industry) || null,
          timeline:
            convertConstToStr(projectDetailsData?.project?.timelines) || null,
          projectUrl: projectUrl,
          created: productData.date,
          updated: productData.lastEdited,
          floorArea: projectDetailsData?.project.FloorArea,
        };

        const projectDetails = {
          customerName: projectDetailsData?.address?.clientName || "",
          streetNumber: projectDetailsData?.address?.street || "",
          city: projectDetailsData?.address?.city || "",
          state: projectDetailsData?.address?.state || "",
          zip: projectDetailsData?.address?.zip || "",
          customerEmail: projectDetailsData?.contact?.email || "",
          tel: projectDetailsData?.contact?.tel || "",
          mobile: projectDetailsData?.contact?.mobile || "",
          value: projectDetailsData?.project?.totalAmount || "",
        };

        csvData.push({
          /*   products: [
            {
              sku: productData._id,
              quantity: productData.quantity,
            },
          ], */
          products: productData.products,
          projectDetailsReport: projectDetailsReport,
          projectDetails: projectDetails,
        });
      });
      CsvExcelService.save(csvData);
    };

    $scope.getUserProjects = function () {
      $scope.isLoading = true;
      // let selectedUserId = '58124b38e2784538f43e2cbe';
      let selectedUserId = $scope.findUserData.userDataSelected._id;
      ResourceService.getAllRoomsBySelectedUser(selectedUserId).then(
        function (data) {
          // toastr.success("Loading projects.");
          // console.log('getAllRoomsBySelectedUser data', data);
          if (data.length) {
            data.forEach((el, index) => {
              el.user = $scope.usersArray.length
                ? $scope.usersArray.find((user) => user._id === el.user_id)
                : null;
              el.serialNumber = index + 1;
              el.checked = false;
            });
            $scope.findUserData.selectedUserRooms = data;
            // console.log('$scope.selectedUserRooms', $scope.findUserData.selectedUserRooms);
            $scope.findUserData.showUserProjects = true;
            setTimeout(
              () => document.getElementById("show_projects_box").click(),
              10
            );
          } else {
            toastr.error("User doesn't have projects");
          }
          $scope.isLoading = false;
        },
        function (data) {
          toastr.error(data.message);
        }
      );
    };

    $scope.addUserAccountByDefault = function (data) {
      // console.log('selectUserAccount data', data);
      if (typeof data === "string") {
        $scope.findUserData.userDataSelected = JSON.parse(data);
      } else {
        $scope.findUserData.userDataSelected = data ? data : {};
        $scope.findUserData.userDataSelected_id = data ? data._id : "";
      }
      // console.log('$scope.findUserData', $scope.findUserData);
    };

    $scope.selectUserAccount = function () {
      $scope.findUserData.userDataSelected = $scope.usersArrayByCompany.find(
        (el) =>
          el._id === $scope.findUserData.userDataSelected_id && el.isActive
      );
      // console.log('findUserData.userDataSelected_id', $scope.findUserData.userDataSelected_id);
    };

    $scope.selectAllProjectsCheckbox = function (project) {
      // checked project by id
      if (project) {
        if (project.checked) {
          $scope.selectedRoomsArrayForExport.push(project);
        } else {
          $scope.selectedRoomsArrayForExport =
            $scope.selectedRoomsArrayForExport.filter(
              (el) => el._id !== project._id
            );
        }
      } else {
        // checked selected projects
        let currentCheck = !$scope.exportAllProjectsCheckbox;
        $scope.exportAllProjectsCheckbox = currentCheck;
        // console.log(checkbox);
        // addUserForm.querySelector('[name=firstName]').value = '';
        $scope.allUserRoomsArray.forEach((el, index) => {
          if ($scope.pagedItems.find((el2) => el2._id === el._id)) {
            el.checked = $scope.exportAllProjectsCheckbox;
            if (el.checked) {
              $scope.selectedRoomsArrayForExport.push(el);
            } else {
              $scope.selectedRoomsArrayForExport =
                $scope.selectedRoomsArrayForExport.filter(
                  (el2) => el2._id !== el._id
                );
            }
          }
        });
      }
    };

    $scope.selectAllUserProjectsCheckbox = function (project) {
      // checked project by id
      if (project) {
        if (project.checked) {
          $scope.selectedUserRoomsArrayForExport.push(project);
        } else {
          $scope.selectedUserRoomsArrayForExport =
            $scope.selectedUserRoomsArrayForExport.filter(
              (el) => el._id !== project._id
            );
        }
      } else {
        // checked all projects
        $scope.exportAllUserProjectsCheckbox =
          !$scope.exportAllUserProjectsCheckbox;
        $scope.findUserData.selectedUserRooms.forEach((el) => {
          el.checked = $scope.exportAllUserProjectsCheckbox;
          if (el.checked) {
            $scope.selectedUserRoomsArrayForExport.push(el);
          } else {
            $scope.selectedUserRoomsArrayForExport =
              $scope.selectedUserRoomsArrayForExport.filter(
                (el2) => el2._id !== el._id
              );
          }
        });
      }
    };

    $scope.setPage = function (page, pageSize) {
      // console.log('page', page);

      // get pager object from service
      $scope.pager = PaginationService.getPager(
        $scope.allUserRoomsArray.length,
        page,
        pageSize
      );

      // cancel all projects if selected on preview page
      if ($scope.exportAllProjectsCheckbox) {
        $scope.selectAllProjectsCheckbox();
      }

      // get current page of items
      $scope.pagedItems = $scope.allUserRoomsArray.slice(
        $scope.pager.startIndex,
        $scope.pager.endIndex + 1
      );
    };

    $scope.sortAllProjects = function (sortName) {
      switch (sortName) {
        case "userName": {
          if ($scope.sortAllProjectsStatus.userName) {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort((a, b) =>
              a.user.firstName > b.user.firstName ? 1 : -1
            );
            $scope.sortAllProjectsStatus.userName = false;
          } else {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort((a, b) =>
              a.user.firstName < b.user.firstName ? 1 : -1
            );
            $scope.sortAllProjectsStatus.userName = true;
          }
          $scope.allUserRoomsArray.forEach(
            (el, index) => (el.serialNumber = index + 1)
          );
          break;
        }
        case "companyName": {
          if ($scope.sortAllProjectsStatus.companyName) {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort((a, b) =>
              a.user.companyName > b.user.companyName ? 1 : -1
            );
            $scope.sortAllProjectsStatus.companyName = false;
          } else {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort((a, b) =>
              a.user.companyName < b.user.companyName ? 1 : -1
            );
            $scope.sortAllProjectsStatus.companyName = true;
          }
          $scope.allUserRoomsArray.forEach(
            (el, index) => (el.serialNumber = index + 1)
          );
          break;
        }
        case "date": {
          if ($scope.sortAllProjectsStatus.date) {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort(
              (a, b) => +new Date(b.date) - +new Date(a.date)
            );
            $scope.sortAllProjectsStatus.date = false;
          } else {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort(
              (a, b) => +new Date(a.date) - +new Date(b.date)
            );
            $scope.sortAllProjectsStatus.date = true;
          }
          $scope.allUserRoomsArray.forEach(
            (el, index) => (el.serialNumber = index + 1)
          );
          break;
        }
        case "projectName": {
          if ($scope.sortAllProjectsStatus.projectName) {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort((a, b) =>
              a.projectDetails.title > b.projectDetails.title ? 1 : -1
            );
            $scope.sortAllProjectsStatus.projectName = false;
          } else {
            $scope.allUserRoomsArray = $scope.allUserRoomsArray.sort((a, b) =>
              a.projectDetails.title < b.projectDetails.title ? 1 : -1
            );
            $scope.sortAllProjectsStatus.projectName = true;
          }
          $scope.allUserRoomsArray.forEach(
            (el, index) => (el.serialNumber = index + 1)
          );
          break;
        }
        case "type": {
          break;
        }
      }
      $scope.pagedItems = $scope.allUserRoomsArray.slice(
        $scope.pager.startIndex,
        $scope.pager.endIndex + 1
      );
    };

    $scope.getAllUsers = function () {
      ResourceService.getAllusers().then(
        function (data) {
          if ($scope.findUserData.companyName) {
            $scope.usersArrayByCompany = data.filter(
              (user) =>
                user.companyName === $scope.findUserData.companyName &&
                user.isActive
            );
          } else {
            $scope.usersArrayByCompany = data.filter((user) => user.isActive);
          }
          $scope.usersArray = data;
          $scope.addUserAccountByDefault($scope.usersArrayByCompany[0]);
        },
        function (data) {
          toastr.error(data.message);
        }
      );
    };

    $scope.getAllCompanies = function () {
      ResourceService.getAllCompanies().then(
        function (data) {
          $scope.companiesArray = data;
          $scope.findUserData.companyName = data[0].companyName;
          $scope.companyForDelete = {
            companyName: data[0].companyName,
            // _id: data[0]._id
          };
        },
        function (data) {
          toastr.error(data.message);
        }
      );
    };

    $scope.getAllUsersRooms = function () {
      $scope.isLoading = true;
      // let date = new Date(2022, 7, 1, 0, 0, 0, 0);
      let page = 0;
      let pageSize = 0;
      ResourceService.getAllUsersRooms(page, pageSize).then(
        function (data) {
          if (data) {
            data.forEach((el, index) => {
              el.user = $scope.usersArray.length
                ? $scope.usersArray.find((user) => user._id === el.user_id)
                : null;
              el.serialNumber = index + 1;
              el.checked = false;
              el.products = addDataObjectsInRoomsSKU(el.data?.objects);

            });
            $scope.allUserRoomsArray = data;
            $scope.setPage(1, 25);
            $scope.isLoading = false;
            // $scope.initBsTableAfterRowRepeatForAllProjects(); // other pagination case
          }
        },
        function (data) {
          toastr.error(data.message);
        }
      );
    };
    const addDataObjectsInRoomsSKU = function (objects) {
      const SKU = [];
      objects.forEach((item) => {
        SKU.push(
          roomStuff.getById(item.id)?.base_model_name + item?.colorScheme
        );
      });
      return SKU;
    };

    $scope.cancelAddUserData = function () {
      $scope.newUserData = {};
      let addUserForm = angular.element("#addUserForm")[0];
      addUserForm.querySelector("[name=firstName]").value = "";
      addUserForm.querySelector("[name=lastName]").value = "";
      addUserForm.querySelector("[name=contact]").value = "";
      addUserForm.querySelector("[name=RegEmail]").value = "";
      addUserForm.querySelector("[name=RegPassword]").value = "";
      addUserForm.querySelector("[name=check_password]").value = "";
    };

    $scope.addUser = function () {
      let addUserForm = angular.element("#addUserForm")[0];
      $scope.newUserData.firstName =
        addUserForm.querySelector("[name=firstName]").value;
      $scope.newUserData.lastName =
        addUserForm.querySelector("[name=lastName]").value;
      $scope.newUserData.contact =
        addUserForm.querySelector("[name=contact]").value;
      $scope.newUserData.email =
        addUserForm.querySelector("[name=RegEmail]").value;
      $scope.newUserData.password =
        addUserForm.querySelector("[name=RegPassword]").value;
      $scope.newUserData.check_password = addUserForm.querySelector(
        "[name=check_password]"
      ).value;
      let register = $scope.newUserData;
      let salt = register.email;
      // var enc_password = CryptoJS.PBKDF2(register.password, salt, {keySize: 256 / 32});
      // var enc_check_password = CryptoJS.PBKDF2(register.check_password, salt, {keySize: 256 / 32});

      let registerUser = {
        companyName: register.companyName,
        firstName: register.firstName,
        lastName: register.lastName,
        contact: register.contact,
        role: register.role,
        email: register.email,
        password: register.password,
        check_password: register.check_password,
        publisher: false,
        isActive: true,
      };

      if (!registerUser.companyName) {
        toastr.warning("Company name must be added!");
      } else if (!registerUser.firstName) {
        toastr.warning("First name must be added!");
      } else if (!registerUser.lastName) {
        toastr.warning("Last name must be added!");
      } else if (!registerUser.email) {
        toastr.warning("Email must be added");
      } else if (!registerUser.email.includes("@")) {
        toastr.warning("Email must be correct! Like this: test@test.com");
      } else if (register.password.length < 6) {
        toastr.warning("Password must have more than 6 symbols!");
      } else if (register.password !== register.check_password) {
        toastr.warning("Password and check_password must be the same!");
      } else if (!register.role) {
        toastr.warning("Role must be added!");
      } else {
        toastr.success("New User is created successfully!");
        ResourceService.signup(registerUser).then(
          function (data) {
            //send log create user
            SendingLog.send("usercreate", null, null, registerUser.email);
            googleAnalitycs.accountCreated();
            // console.log("signup user data = ", $scope.register);
            toastr.success("New User is created successfully!");
            $scope.usersArrayByCompany = [...$scope.usersArrayByCompany, data];
            $scope.newUserData = {};
            addUserForm.querySelector("[name=firstName]").value = "";
            addUserForm.querySelector("[name=lastName]").value = "";
            addUserForm.querySelector("[name=contact]").value = "";
            addUserForm.querySelector("[name=RegEmail]").value = "";
            addUserForm.querySelector("[name=RegPassword]").value = "";
            addUserForm.querySelector("[name=check_password]").value = "";
          },
          function (data) {
            if (data.status && data.status === 409) {
              toastr.error(data.message);
            } else {
              toastr.error(data);
            }
          }
        );
      }

      //   toastr.warning("Username and password are mandatory!");
      //   // noty({
      //   //   text: "Username and password are mandatory!",
      //   //   timeout: 2000,
      //   //   type: "warning",
      //   // });
    };

    $scope.deleteUser = function () {
      //console.log("register params = ", register);
      let userDataSelected = $scope.findUserData.userDataSelected;
      ResourceService.deleteUserAccount(userDataSelected._id, {
        isActive: false,
      }).then(
        function (data) {
          //send log create user
          SendingLog.send("userdelete", null, null, userDataSelected.email);
          // googleAnalitycs.accountDeleted();
          // console.log("signup user data = ", $scope.register);
          toastr.success("User account is deleted successfully!");
          $scope.usersArrayByCompany = $scope.usersArrayByCompany.filter(
            (user) => user._id !== userDataSelected._id
          );
          $scope.newUserData = {};
          $scope.findUserData.userDataSelected_id =
            $scope.usersArrayByCompany[0]._id;
          $scope.findUserData.userDataSelected = $scope.usersArrayByCompany[0];
        },
        function (data) {
          if (data.status && data.status === 409) {
            toastr.error(data.message);
          } else {
            toastr.error(data);
          }
        }
      );
    };

    $scope.addCompany = function () {
      let addCompanyForm = angular.element("#addCompanyForm")[0];
      // let addCompanyId = angular.element('#addCompanyId')[0]
      const register = addCompanyForm.querySelector("[name=company]").value;
      if (
        $scope.companiesArray.find(
          (company) => company.companyName === register
        )
      ) {
        return toastr.error("The same company name is already created");
      }
      if (register) {
        let registerCompany = {
          companyName: register,
        };
        ResourceService.createCompany(registerCompany).then(
          function (data) {
            //send log create user
            SendingLog.send("companycreate", null, null, null);
            // googleAnalitycs.accountCreated();
            // console.log("signup user data = ", $scope.register);
            $scope.companiesArray = [...$scope.companiesArray, data];
            toastr.success("New Company is created successfully!");
            $scope.newUserData = {};
          },
          function (data) {
            if (data.status && data.status === 409) {
              toastr.error(data.message);
            } else {
              toastr.error(data);
            }
          }
        );
      } else {
        toastr.error("Please enter the company name");
      }
    };

    $scope.deleteCompany = function () {
      let companyForDelete = $scope.companiesArray.find(
        (company) => company.companyName === $scope.companyForDelete.companyName
      );
      ResourceService.deleteCompany(companyForDelete._id).then(
        function (data) {
          //send log create user
          SendingLog.send("companydelete", null, null, companyForDelete._id);
          // googleAnalitycs.accountDeleted();
          // console.log("signup user data = ", $scope.register);
          toastr.success("Company is deleted successfully!");
          $scope.companiesArray = $scope.companiesArray.filter(
            (company) => company._id !== companyForDelete._id
          );
          $scope.companyForDelete = $scope.companiesArray[0];
        },
        function (data) {
          if (data.status && data.status === 409) {
            toastr.error(data.message);
          } else {
            toastr.error(data);
          }
        }
      );
    };

    $scope.signUp = function (register) {
      //console.log("register params = ", register);
      var salt = register.email;

      // var enc_password = CryptoJS.PBKDF2(register.password, salt, {keySize: 256 / 32});
      // var enc_check_password = CryptoJS.PBKDF2(register.check_password, salt, {keySize: 256 / 32});
      var registerUser = {
        firstName: register.firstName,
        lastName: register.lastName,
        email: register.email,
        password: register.password,
        companyName: "",
        contact: "",
        role: "",
        publisher: false,
        isActive: true,
      };

      if (
        register.email !== undefined ||
        register.password !== undefined ||
        register.check_password !== undefined
      ) {
        if (register.password !== register.check_password) {
          toastr.warning("password and check_password must be the same!");
        } else {
          ResourceService.signup(registerUser).then(
            function (data) {
              localStorageService.set("auth_token", data._id);
              localStorageService.set("role", data.role);
              localStorageService.set("user", {
                firstName: data.firstName,
                lastName: data.lastName,
              });

              //send log create user
              SendingLog.send("usercreate", null, null, register.email);
              googleAnalitycs.accountCreated();
              // if user save project without login
              if ($rootScope.userSave_RoomData) {
                $rootScope.userSave_RoomData.user_id = data._id;
                ResourceService.saveRoom($rootScope.userSave_RoomData).then(
                  function (result) {
                    $rootScope.userSave_RoomData = null;
                    toastr.success("Your project is saved successfully!");
                    // Send log create project
                    SendingLog.send(
                      "projectcreate",
                      $rootScope.userSave_RoomData.titleProject,
                      $rootScope.userSave_RoomData.projectDetails.project
                        .totalValue
                    );
                  },
                  function (result) {
                    toastr.error(result);
                  }
                );
              }

              //console.log("signup user data = ", $scope.register);
              $scope.register = {};

              $timeout(function () {
                $location.path("/account");
              }, 1000);
            },
            function (data) {
              if (data.status && data.status === 409) {
                toastr.error(data.message);
              } else {
                toastr.error(data);
              }
            }
          );
        }
      } else {
        noty({
          text: "Username and password are mandatory!",
          timeout: 2000,
          type: "warning",
        });
      }
    };

    function MoveUpDown(room1_id, room1, index1, room2_id, room2, index2) {
      var table = angular.element("#no-more-tables");
      ResourceService.updateTemplate(room1_id, room1);
      ResourceService.updateTemplate(room2_id, room2).then(
        function (data) {
          toastr.success("Update order");
          //send log delete project
          //SendingLog.send('projectdelete', null, null);
          ResourceService.getAllTemplates().then(
            function (data) {
              $scope.initBsTableAfterRowRepeat();
            },
            function (data) {
              toastr.error(data.message);
            }
          );
        },

        function (data) {
          toastr.error(data.message);
        }
      );
    }

    var confirmDelete = function (room, bid) {
      var suiteWarning = room.suiteId
        ? "Warning: deleting this template will delete the assocciated suite."
        : "";
      ngDialog
        .openConfirm({
          disableAnimation: true,
          template: "./app/views/help/confirmDelete.html",
          data: {
            suiteWarning: suiteWarning,
          },
        })
        .then(
          function (confirm) {
            deleteRoomProject(room._id, bid);
          },
          function (reject) {}
        );
    };

    function deleteRoomProject(room_Id, bid) {
      var table = angular.element("#no-more-tables");
      var removeID;

      if (AuthenticationService.userId()) {
        if (AuthenticationService.publisher()) {
          ResourceService.deleteTemplate(room_Id).then(
            function (data) {
              toastr.success("Delete room");
              //send log delete project
              SendingLog.send("projectdelete", null, null);

              ResourceService.getAllTemplates().then(
                function (data) {
                  $scope.userRooms = data.map((item) => {
                    const formattedDate = formatDate(item.lastEdited);
                    return { ...item, lastEdited: formattedDate };
                  });
                  //removeID = String(removeID);
                  table.bootstrapTable("remove", {
                    field: "id",
                    values: [bid.toString()],
                  });
                },
                function (data) {
                  toastr.error(data.message);
                }
              );
            },

            function (data) {
              toastr.error(data.message);
            }
          );
        } else {
          ResourceService.deleteRoom(room_Id).then(
            function (data) {
              toastr.success("Delete room");
              //console.log(data);
              //send log delete project
              SendingLog.send("projectdelete", null, null);
              ResourceService.getAllroom().then(
                function (data) {
                  $scope.userRooms = data.map((item) => {
                    const formattedDate = formatDate(item.lastEdited);
                    return { ...item, lastEdited: formattedDate };
                  });
                  table.bootstrapTable("remove", {
                    field: "id",
                    values: [bid.toString()],
                  });
                },
                function (data) {
                  toastr.error(data.message);
                }
              );
            },
            function (data) {
              toastr.error(data.message);
            }
          );
        }
      }
    }

    var openWindowSendEmail = function (id_room, img) {
      var room_share;
      var url_project = constants.API_URL + "/#/browsing/" + id_room;

      if (publisher) {
        ResourceService.getOneTemplate(id_room).then(
          function (data) {
            room_share = data;
          },
          function (data) {
            toastr.error(data.message);
          }
        );
      } else {
        ResourceService.getOne(id_room).then(
          function (data) {
            room_share = data;
          },
          function (data) {
            toastr.error(data.message);
          }
        );
      }
      var textMessage = constants.signUp.mailtextMessage;
      $scope.mailOptions = {
        from: userData.email,
        to: "",
        subject: constants.signUp.mailSubject,
        content: textMessage,
        url_project: url_project,
        img: img,
      };
      ngDialog.open({
        disableAnimation: true,
        template: "./app/views/share/sendEmail.html",
        plain: false,
        scope: $scope,
        controller: [
          "$scope",
          "SocialShare",
          "ngDialog",
          function ($scope, SocialShare, ngDialog) {
            $scope.sendEmail = function () {
              SocialShare.sendEmail($scope.mailOptions);
              //send log share project
              SendingLog.send(
                "projectshare",
                room_share.titleProject,
                room_share.projectDetails.project.totalAmount
              );
              ngDialog.close();
            };
          },
        ],
      });
    };

    $scope.editProject = function (room_id, room = false) {
      if (publisher) {
        ResourceService.getOneTemplate(room_id).then(
          function (data) {
            $rootScope.editedRoomData = data;
            $timeout(function () {
              if (AuthenticationService.publisher() && !room) {
                $location.path("/templates/" + room_id);
              } else {
                $location.path("/planning/" + room_id);
              }
            }, 0);

            $timeout(function () {
              $rootScope.$broadcast("loadRoom", data);
            }, 300);
          },
          function (data) {
            toastr.error(data);
          }
        );
      } else {
        ResourceService.getOne(room_id).then(
          function (data) {
            $rootScope.editedRoomData = data;
            $timeout(function () {
              if (AuthenticationService.publisher() && !room) {
                $location.path("/templates/" + room_id);
              } else {
                $location.path("/planning/" + room_id);
              }
            }, 0);

            $timeout(function () {
              $rootScope.$broadcast("loadRoom", data);
            }, 300);
          },
          function (data) {
            toastr.error(data);
          }
        );
      }
    };

    $scope.updateUserProfileData = function () {
      if (
        $scope.userProfileData.firstName != "" &&
        $scope.userProfileData.lastName != ""
      ) {
        //console.log('success update');
        ResourceService.updateUserProfileData(
          user_ID,
          $scope.userProfileData
        ).then(
          function (message) {
            $rootScope.labelUserLogged =
              $scope.userProfileData.firstName +
              " " +
              $scope.userProfileData.lastName;
            localStorageService.set("user", {
              firstName: $scope.userProfileData.firstName,
              lastName: $scope.userProfileData.lastName,
            });
            userData.firstName = $scope.userProfileData.firstName;
            userData.lastName = $scope.userProfileData.lastName;
            toastr.success(message);
          },
          function (data) {
            toastr.error(data.error);
          }
        );
      } else {
        toastr.error("All fields must be completed!");
      }
    };

    $scope.updateUserAuthData = function () {
      if (
        $scope.userAuthData.email != "" &&
        $scope.userAuthData.newPassword != "" &&
        $scope.userAuthData.oldPassword != "" &&
        $scope.userAuthData.confirmPassword != ""
      ) {
        if (
          $scope.userAuthData.newPassword ===
          $scope.userAuthData.confirmPassword
        ) {
          ResourceService.updateUserAuthData(user_ID, $scope.userAuthData).then(
            function (message) {
              //console.log('success update');
              toastr.success(message);
              userData.email = $scope.userAuthData.email;
              $scope.userAuthData.newPassword =
                $scope.userAuthData.confirmPassword =
                $scope.userAuthData.oldPassword =
                  "";
            },
            function (data) {
              toastr.error(data.error);
            }
          );
        } else
          toastr.error(
            "Field New Password and Confirm Password must be equal!"
          );
      } else {
        toastr.error("All fields must be completed!");
      }
    };

    $scope.resetUserDataIfNotSave = function () {
      //getUserData();
      $scope.userProfileData.firstName = userData.firstName;
      $scope.userProfileData.lastName = userData.lastName;
      $scope.userAuthData.email = userData.email;
      $scope.userAuthData.newPassword =
        $scope.userAuthData.confirmPassword =
        $scope.userAuthData.oldPassword =
          "";
      $scope.form.userAuthForm.$setPristine();
      $scope.form.userAuthForm.$setUntouched();
      $scope.form.userProfileForm.$setPristine();
      $scope.form.userProfileForm.$setUntouched();
    };

    $scope.userManagementInit = function () {
      $scope.getAllCompanies();
      $scope.getAllUsers();
    };
    // function prepareSLData(info) {
    //     var data = {};
    //     data.objects = [];
    //     for (var i = 0; i < info.length; i++) {
    //         var obj = {};
    //         obj.userData = {};
    //         obj.userData.entity = roomStuff.getById(info[i].id);
    //         obj.userData.choosenColor = info[i].colorScheme;
    //         data.objects.push(obj);
    //     }
    //     return data;
    // }
    $scope.openProjectReport = function (room) {
      $scope.dataProject = room.projectDetails.project;
      $rootScope.editedRoomData = room;
      $rootScope.$broadcast(
        "calculateSPL",
        SaveStateService.prepareSLData(room.data.objects)
      );
      $timeout(function () {
        angular.element("#report-project").modal("show");
      }, 100);
    };

    $scope.goBackToProject = function () {
      if ($scope.activeLinkToBack) {
        $location.path("/planning");
        $timeout(function () {
          $rootScope.$broadcast("GoBackToProject");
        }, 350);
      }
    };

    $scope.test = function () {
      console.log("show hide check");
    };

    // $scope.updateShowTemplate = function (id, data) {
    //     data.showAsTemplate = !data.showAsTemplate;
    //     console.log('template show now is set to: ' + data.ShowAsTemplate);
    //     ResourceService.updateTemplate(id, data);
    // }
    $scope.notEmpty = 0;

    function getUserData() {
      ResourceService.getUserDataAccount(user_ID).then(
        function (data) {
          // console.debug('userData = ', data);
          userData = data;
          $scope.userProfileData = {
            firstName: userData.firstName,
            lastName: userData.lastName,
          };
          $scope.userAuthData = {
            email: userData.email,
            newPassword: "",
            oldPassword: "",
            confirmPassword: "",
          };
        },
        function (data) {
          toastr.error(data);
        }
      );
    }
  },
]);
