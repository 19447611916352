;(function () {

    var service = function () {
        return {
            wallsData: null,
            roomPolygonPoints : null
        }
    };

    angular.module('valleyCraftApp').service('step3DataSrv', service);
})();
