'use strict';

/**
 * @ngdoc directive
 * @name valleyCraftApp.directive:step1
 * @description
 * # step1
 */
angular.module('valleyCraftApp').directive('step1', ['SaveStateService', '$rootScope',
  function(SaveStateService, $rootScope) {
  return {
    templateUrl: './app/views/steps/step1.html',
    restrict: 'E',
    controller: function($scope, $rootScope) {
      $scope.InvisibleStep1 = function(room) {
        $rootScope.$broadcast('SelectRoom', room);
      };
      $scope.clearSavedState = function(){
        SaveStateService.clearSavedState();
        $rootScope.editedRoomData = null;
      }
    },
    link: function postLink(scope, element, attrs) {
    }
  };
}]);
