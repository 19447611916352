import angular from 'angular';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

angular.module('valleyCraftApp')
  .directive(
    'appTest',
    downgradeComponent({component: TestComponent}) as angular.IDirectiveFactory
  );