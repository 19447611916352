import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestComponent } from './components/test/test.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthInterceptor } from './shared/auth/auth.interseptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TermsModalComponent } from './components/modals/terms-modal/terms-modal.component';
import { LoginTermsCheckboxComponent } from './components/login/login-terms-checkbox/login-terms-checkbox.component';
import { LoaderInterceptor } from './shared/loader/loader.intersector';
import { AngularJsService } from './services/angularJsService.service';

function getAngularJSService(angularJSServiceName: string) {
  const $injector = (window as any).angular?.element(document.body).injector();
  return $injector.has(angularJSServiceName)
    ? $injector.get(angularJSServiceName)
    : null;
}

declare var angular: any;
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    TestComponent,
    /* DefaultLayoutComponent, */
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UpgradeModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatRippleModule,
    MatIconModule,
    HttpClientModule,
    TermsModalComponent,
    LoginTermsCheckboxComponent,
  ],
  providers: [
    AngularJsService,
    {
      provide: '$window',
      useFactory: () => getAngularJSService('$window'),
    },
    {
      provide: '$state',
      useFactory: () => getAngularJSService('$state'),
    },
    {
      provide: '$stateParams',
      useFactory: () => getAngularJSService('$stateParams'),
    },
    {
      provide: '$location',
      useFactory: () => getAngularJSService('$location'),
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: LoaderInterceptor
    }
  ],
  bootstrap: [],
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) { }
  ngDoBootstrap() {
    const angularService = this.upgrade.injector.get(AngularJsService);
    angular.module('valleyCraftApp').factory('AngularService', () => angularService);

    this.upgrade.bootstrap(document.documentElement, ['valleyCraftApp']);

  }
}
