import Engine from "../component/Engine";
import DependencyContainer from "../component/DependencyContainer";
import DrawCustomRoom from "../component/2D/DrawCustomRoom";
import ZoomCamera from "../component/2D/ZoomCamera";
import ConnectionPointHover from "../component/2D/ConnectionPointHover";
import SplitWall from "../component/2D/SplitWall";
import RemoveNode from "../component/2D/RemoveNode";
import MoveCorner from "../component/2D/MoveCorner";
import WallHighlight from "../component/2D/WallHighlight";
import MoveWall from "../component/2D/MoveWall";
import MoveCamera from "../component/2D/MoveCamera";
import ShowSizesOnFloorClick from "../component/2D/ShowSizesOnFloorClick";
import Selection2D from "../component/2D/Selection2D";
import DragWindow2D from "../component/2D/DragWindow2d";
import DragStairs2D from "../component/2D/DragStairs2D";
import DragCabinet2D from "../component/2D/DragCabinet2D";
import DragVehicle2D from "../component/2D/DragVehicle2D";
import DragSuite2D from "../component/2D/DragSuite2D";

(function () {
  var dependencies = [
    "Scene2d",
    "step3Helper",
    "constants",
    "keyState",
    "configuratorService",
    "step3DataSrv",
    "roomStateManager",
    "roomStuffFactory",
    "collisionSrvc",
    "obbBuilder",
    "objectGapHelperSrvc",
    "geometryHelper",
    "roomSizeManager",
    "room2DBuilder",
    "helperLines",
    "wallConnectionSrvc",
    "cursorBuilder",
    "roomStuff",
    "toastr",
    "suiteUtils2DSrvc",
    "sceneHelper",
    "suiteUtilsSrvc",
  ];

  var service = function (
    Scene2d,
    step3Helper,
    constants,
    keyState,
    configuratorService,
    step3DataSrv,
    roomStateManager,
    roomStuffFactory,
    collisionSrvc,
    obbBuilder,
    objectGapHelperSrvc,
    geometryHelper,
    roomSizeManager,
    room2DBuilder,
    helperLines,
    wallConnectionSrvc,
    cursorBuilder,
    roomStuff,
    toastr,
    suiteUtils2DSrvc,
    sceneHelper,
    suiteUtilsSrvc
  ) {
    var engine2d;
    var servicesObj = arguments;
    var dependencyContainer;

    return {
      get: function () {
        if (!engine2d) {
          var canvas = Scene2d.init(1, 1);

          engine2d = new Engine();

          dependencyContainer = new DependencyContainer(
            servicesObj,
            dependencies
          );

          engine2d.addComponents([
            new DrawCustomRoom(dependencyContainer),
            new ZoomCamera(dependencyContainer),
            new ConnectionPointHover(dependencyContainer),
            new SplitWall(dependencyContainer),
            new RemoveNode(dependencyContainer),
            new MoveCorner(dependencyContainer),
            new WallHighlight(dependencyContainer),
            new MoveWall(dependencyContainer),
            new MoveCamera(dependencyContainer),
            new ShowSizesOnFloorClick(dependencyContainer),

            new Selection2D(dependencyContainer),
            new DragWindow2D(dependencyContainer),
            new DragStairs2D(dependencyContainer),
            new DragCabinet2D(dependencyContainer),
            new DragVehicle2D(dependencyContainer),
            new DragSuite2D(dependencyContainer),
          ]);

          engine2d.init(canvas);
        }

        return engine2d;
      },

      activateStepRelatedComponents: function (step) {
        if (!engine2d) return;

        switch (step) {
          case "step1":
            {
              engine2d.findComponentByType(DrawCustomRoom).enable();
              engine2d.findComponentByType(SplitWall).disable();
              engine2d.findComponentByType(RemoveNode).disable();
            }
            break;
          case "step2":
            {
              engine2d.findComponentByType(Selection2D).disable();
              engine2d.findComponentByType(DragWindow2D).disable();
              engine2d.findComponentByType(DragStairs2D).disable();
              engine2d.findComponentByType(DragCabinet2D).disable();
              engine2d.findComponentByType(DragVehicle2D).disable();
              engine2d.findComponentByType(DragSuite2D).disable();

              engine2d.findComponentByType(WallHighlight).enable();
              engine2d.findComponentByType(ConnectionPointHover).enable();
              engine2d.findComponentByType(MoveCorner).enable();
              engine2d.findComponentByType(MoveWall).enable();
              engine2d.findComponentByType(ShowSizesOnFloorClick).enable();
            }
            break;
          case "step3":
            {
              engine2d.findComponentByType(Selection2D).enable();
              engine2d.findComponentByType(DragWindow2D).enable();
              engine2d.findComponentByType(DragStairs2D).enable();
              engine2d.findComponentByType(DragCabinet2D).enable();
              engine2d.findComponentByType(DragVehicle2D).enable();
              engine2d.findComponentByType(DragSuite2D).enable();

              engine2d.findComponentByType(RemoveNode).disable();
              engine2d.findComponentByType(SplitWall).disable();
              engine2d.findComponentByType(WallHighlight).disable();
              engine2d.findComponentByType(ConnectionPointHover).disable();
              engine2d.findComponentByType(MoveCorner).disable();
              engine2d.findComponentByType(MoveWall).disable();
              engine2d.findComponentByType(ShowSizesOnFloorClick).disable();
            }
            break;
        }
      },

      dispose: function () {
        if (engine2d) {
          engine2d.dispose();
          dependencyContainer.dispose();
          engine2d = null;
        }
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("engine2DSrvc", service);
})();
