'use strict';

angular.module('valleyCraftApp').service('SaveStateService', ['AuthenticationService', 'roomStateManager', 'localStorageService',
    '$rootScope', 'roomStuff',
    function(AuthenticationService, roomStateManager, localStorageService, $rootScope, roomStuff){
        var clearSavedState = function(){
            var key = AuthenticationService.userId() || 'unauthorized';
            localStorageService.remove(key);
            $rootScope.sceneReady = false;
        }
        var prepareSLData = function(info){
            var data = {};
            data.objects = [];
            for (var i = 0; i < info.length; i++) {
                var obj = {};
                obj.userData = {};
                obj.userData.entity = roomStuff.getById(info[i].id);
                obj.userData.choosenColor = info[i].colorScheme;
                if (info[i].accessoryId) {
                    obj.userData.selectedAccessoryId = info[i].accessoryId;
                }
                if (info[i].suiteId) {
                    obj.userData.suiteId = info[i].suiteId;
                }
                if (info[i].groupId) {
                    obj.userData.groupId = info[i].groupId;
                }
                if (info[i].suiteUniqueId) {
                    obj.userData.suiteUniqueId = info[i].suiteUniqueId;
                }
                data.objects.push(obj);
            }
            return data;
        }
        var saveRoute = function(route){
           var key = AuthenticationService.userId();
           if(!key) return;
           localStorageService.set(key, route);
        }
        var service = {
            clearSavedState: clearSavedState,
            prepareSLData: prepareSLData,
            saveRoute: saveRoute
        };
        return service;
}]);
