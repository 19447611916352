import * as THREE from "../../../libs/three";
import RotateHandlerBase from "./RotateHandlerBase";
import ObjectInsideRoom from "../Drag/Class/ObjectInsideRoom";

var ClockwiseRotateHandler = function (dependencyContainer, select3D) {
  RotateHandlerBase.prototype.constructor.call(
    this,
    dependencyContainer,
    select3D,
    "rotateClockwise"
  );

  this._scene3D = dependencyContainer.getService("scene3D");
  this._sceneHelper = dependencyContainer.getService("sceneHelper");
  this._objectControls = dependencyContainer.getService("objectControls");
  this._orbitControl = dependencyContainer.getService("orbitControl");
  this._geometryHelper = dependencyContainer.getService("geometryHelper");
  this._scene2DSyncSrvc = dependencyContainer.getService("scene2DSyncSrvc");
  this._collisionSrvc = dependencyContainer.getService("collisionSrvc");

  this._newPositionPoint = new THREE.Vector3();
  this._x = 0;
  this._z = 0;
  this._insideRoomChecker = new ObjectInsideRoom(dependencyContainer);

  this._raycaster = new THREE.Raycaster();
  this._handled = false;
  this._groundPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

  this._container = new THREE.Object3D();
  this._container2 = new THREE.Object3D();
  this._container.add(this._container2);

  this._rotationPoint = null;

  this._lastValidAngle = 0;
  this._colliderCache = {};
  this._objectPosition = new THREE.Vector3();
  this._objectRotation = new THREE.Euler();
  this._staticObjects = null;

  this._select3D = select3D;
};

ClockwiseRotateHandler.prototype = Object.create(RotateHandlerBase.prototype);

ClockwiseRotateHandler.prototype.constructor = ClockwiseRotateHandler;

ClockwiseRotateHandler.prototype._canBeRotated = function (objects) {
  var i = 0,
    obj,
    entity;

  for (; i < objects.length; i++) {
    obj = objects[i];
    entity = obj.userData.entity;
    if (
      entity.category.toLowerCase() === "akro-mils" &&
      entity.wallInteraction === "null"
    ) {
        entity.wallInteraction = null;
    }

    if (entity.isWall || entity.isFloor || entity.wallInteraction) {
      return false;
    }
  }

  return true;
};

ClockwiseRotateHandler.prototype._wrap = function (objects) {
  this._container2.add.apply(this._container2, objects);
  this._scene3D.remove(objects);
  this._scene3D.add(this._container);
  this._lastValidAngle = this._container.rotation.y;
};

ClockwiseRotateHandler.prototype._unWrap = function () {
  var i = 0,
    obj,
    objects = this._container2.children,
    containerRot = this._container.rotation.y;

  for (; i < objects.length; i++) {
    obj = objects[i];

    this._container2.localToWorld(obj.position);
    obj.rotation.set(0, obj.rotation.y + containerRot, 0);
    this.RotateAroundOutsideAxis(obj, containerRot);

    this._scene2DSyncSrvc.rotate(obj);
    this._scene2DSyncSrvc.moveObject(obj);
  }

  this._scene3D.add(objects);
  this._container2.children.length = 0;
  this._container.rotation.y = 0;
  this._scene3D.remove(this._container);
};

ClockwiseRotateHandler.prototype.RotateAroundOutsideAxis = function (
  rotatedObj,
  angle
) {
  var xk = rotatedObj.position.x,
    zk = rotatedObj.position.z,
    sn = Math.sin(angle),
    cs = Math.cos(angle);

  rotatedObj.position.x = cs * (xk - this._x) + sn * (zk - this._z) + this._x;
  rotatedObj.position.z = -sn * (xk - this._x) + cs * (zk - this._z) + this._z;
};

ClockwiseRotateHandler.prototype.invokeAction = function (actionName, arg) {
  // switch (actionName) {
  //     case 'hold':
  //         {
  //             this._hold(this._select3D.getSelected());
  //         }
  //         break;
  //     case 'move':
  //         {
  //             this._move(this._select3D.getSelected(), arg);
  //         }
  //         break;
  //     case 'mouseLeave':
  //     case 'release':
  //         {
  //             this._cleanState();
  //         }
  //         break;
  // }

  if (actionName === "hold") {
    this._singleMove(this._select3D.getSelected());
  }

  return false;
};

ClockwiseRotateHandler.prototype._hold = function (objects) {
  this._handled = true;

  this._rotationPoint = this._geometryHelper.getObjectsCenter(objects);

  this._container.position.copy(this._rotationPoint);
  this._container2.position.copy(this._rotationPoint.clone().negate());

  this._wrap(objects);

  this._orbitControl.disable();

  this._staticObjects = this._scene3D.filterChildren(function (obj) {
    return (
      obj instanceof THREE.Mesh &&
      obj.userData.entity &&
      !obj.userData.entity.isFloor &&
      objects.indexOf(obj) === -1
    );
  });
};

ClockwiseRotateHandler.prototype._isCollide = function (
  draggedObjs,
  staticObjs,
  angle
) {
  var i = 0,
    j = 0,
    isCollide,
    obj;

  this._x = 0;
  this._z = 0;
  for (; j < draggedObjs.length; j++) {
    obj = draggedObjs[j];
    this._x += obj.position.x;
    this._z += obj.position.z;
  }
  this._x /= draggedObjs.length;
  this._z /= draggedObjs.length;

  for (; i < draggedObjs.length; i++) {
    obj = draggedObjs[i];

    this._objectPosition.copy(obj.position);

    var xk = this._objectPosition.x,
      zk = this._objectPosition.z,
      sn = Math.sin(angle),
      cs = Math.cos(angle);

    this._objectPosition.x =
      cs * (xk - this._x) + sn * (zk - this._z) + this._x;
    this._objectPosition.z =
      -sn * (xk - this._x) + cs * (zk - this._z) + this._z;

    if (!this._insideRoomChecker.check(this._objectPosition)) return true;

    this._container2.localToWorld(this._objectPosition);

    this._objectRotation.set(0, obj.rotation.y + angle, 0);

    isCollide = this._collisionSrvc.isCollide(obj, staticObjs, {
      upAxis: "y",
      draggedObjectPosition: this._objectPosition,
      newRotation: this._objectRotation,
      staticColliderCache: this._colliderCache,
    });

    if (isCollide) return true;
  }

  return false;
};

// ClockwiseRotateHandler.prototype._move = function (objects, e) {
//     if (this._handled) {

//         var coord = this._sceneHelper.getCanvasClientXY(e, this._scene3D.getHtmlElement());
//         this._scene3D.getPickingRay(coord.x, coord.y, this._raycaster);

//         var point = this._raycaster.ray.intersectPlane(this._groundPlane);

//         if (point) {
//             var angle = -Math.atan2(point.z - this._rotationPoint.z, point.x - this._rotationPoint.x);
//             this._container.rotation.y = THREE.Math.degToRad(Math.round(THREE.Math.radToDeg(angle)));

//             var isCollide = this._isCollide(objects,
//                 this._staticObjects,
//                 this._container.rotation.y);

//             if (!isCollide)
//                 this._lastValidAngle = this._container.rotation.y;
//             else
//                 this._container.rotation.y = this._lastValidAngle;
//         }

//         return false;
//     }
// };

ClockwiseRotateHandler.prototype._singleMove = function (objects) {
  var rotationDegree = objects[0].userData.entity.defaultRotationDegree;

  this._rotationPoint = this._geometryHelper.getObjectsCenter(objects);

  this._container.position.copy(this._rotationPoint);
  this._container2.position.copy(this._rotationPoint.clone().negate());

  this._wrap(objects);

  this._orbitControl.disable();

  this._staticObjects = this._scene3D.filterChildren(function (obj) {
    return (
      obj instanceof THREE.Mesh &&
      obj.userData.entity &&
      !obj.userData.entity.isFloor &&
      objects.indexOf(obj) === -1
    );
  });

  this._container.rotation.y -= THREE.Math.degToRad(rotationDegree);

  var isCollide = this._isCollide(
    objects,
    this._staticObjects,
    this._container.rotation.y
  );

  if (!isCollide) this._lastValidAngle = this._container.rotation.y;
  else this._container.rotation.y = this._lastValidAngle;

  this._unWrap();
  this._orbitControl.enable();
  this._colliderCache = {};
};

ClockwiseRotateHandler.prototype._cleanState = function () {
  if (this._handled) {
    this._unWrap();
    this._orbitControl.enable();
    this._colliderCache = {};
  }

  this._handled = false;
};

ClockwiseRotateHandler.prototype.canExecute = function (objects) {
  return (
    RotateHandlerBase.prototype.canExecute.call(this, objects) &&
    this._canBeRotated(objects)
  );
};

export default ClockwiseRotateHandler;
