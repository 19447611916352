import Selection2D from "../component/2D/Selection2D";

("use strict");

// refactor
angular.module("valleyCraftApp").controller("ProductDetailsController", [
  "roomStuff",
  "roomStateManager",
  "constants",
  "Scene2d",
  "roomStuffFactory",
  "$scope",
  "ngDialog",
  "scene3D",
  "sceneSyncSrvc",
  "wallCutHelper",
  "engine2DSrvc",
  "modelLoader",
  "objectMaterial",
  "scene2DSyncSrvc",
  "ScaleService",
  "collisionSrvc",
  "ResourceService",
  "dimensionalRadialsManager",
  "objectControls",
  "replaceModelSrvc",
  "$rootScope",
  "shoppingListSrvc",
  function (
    roomStuff,
    roomStateManager,
    constants,
    Scene2d,
    roomStuffFactory,
    $scope,
    ngDialog,
    scene3D,
    sceneSyncSrvc,
    wallCutHelper,
    engine2DSrvc,
    modelLoader,
    objectMaterial,
    scene2DSyncSrvc,
    ScaleService,
    collisionSrvc,
    ResourceService,
    dimensionalRadialsManager,
    objectControls,
    replaceModelSrvc,
    $rootScope,
    shoppingListSrvc
  ) {
    var constantsService = constants;
    $scope.isPartOfSuite = false;
    if (
      $scope.ngDialogData.sender.valueOf() === "2d" ||
      $scope.ngDialogData.sender.valueOf() === "3d"
    ) {
      $scope.showButtons = true;
    } else {
      $scope.showButtons = false;
    }
    if (!$scope.ngDialogData.item.isGeneric) {
      $scope.isCEG = true;
      if (
        $scope.ngDialogData.mesh &&
        $scope.ngDialogData.mesh.userData.suiteId
      ) {
        // VCF-416
        $scope.isPartOfSuite = true;
      }
    } else {
      $scope.isCEG = false;
      $scope.isSelected = "Dimensions";
    }

    $scope.swapGroupProducts = [];
    $scope.product = _.cloneDeep($scope.ngDialogData.item);
    $scope.mesh = $scope.ngDialogData.mesh;

    $scope.selected = {};
    $scope.selected.accessory = "none";
    $scope.isSelectedAccessory = false;
    $scope.productAccessories = [];
    $scope.productImage = null;

    $scope.subCategories = getSubCategories();

    function getSelectedAccessoryId() {
      return $scope.selected.accessory && $scope.isSelectedAccessory
        ? $scope.selected.accessory.accessory_id
        : "";
    }

    // set active Accessory if exists property 'selectedAccessoryId' in mesh
    function setActiveAccessory() {
      if ($scope.mesh.userData.hasOwnProperty("selectedAccessoryId")) {
        $scope.isSelectedAccessory = true;
        $scope.selected.accessory = _.find($scope.product.accessories, {
          accessory_id: $scope.mesh.userData.selectedAccessoryId,
        });
        $scope.product.hikId = shoppingListSrvc.getProductHikId(
          $scope.product,
          $scope.isColorSelected,
          getSelectedAccessoryId()
        );
      }
    }

    function setSwapGroupProducts() {
      angular.forEach($scope.products, function (prod) {
        if (!prod.swap_group_name || !$scope.product.swap_group_name) return;
        var bigger, smaller;
        if (
          prod.swap_group_name.length > $scope.product.swap_group_name.length
        ) {
          bigger = prod.swap_group_name;
          smaller = $scope.product.swap_group_name;
        } else {
          bigger = $scope.product.swap_group_name;
          smaller = prod.swap_group_name;
        }
        var regexp = new RegExp(smaller);
        if (regexp.test(bigger)) {
          $scope.swapGroupProducts.push(prod);
        }
      });
    }

    setActiveAccessory();

    getProductAccessories();

    function getProductAccessories() {
      if (
        $scope.product.hasOwnProperty("has_accessories") &&
        $scope.product.has_accessories
      ) {
        $scope.productAccessories = _.clone($scope.product.accessories);

        _.forEach($scope.productAccessories, function (pr) {
          var accessory_data = _.find(roomStuff.accessoriesProduct(), {
            _id: pr.accessory_id,
          });
          pr.data = accessory_data;
        });
      } else {
        $scope.productAccessories = [];
      }
    }

    $scope.getPrice = function (product) {
      if (product === "none") return;
      if (product.data && product.data.category === "Accessories") {
        var variant = _.find($scope.product.variants, function (pv) {
          return pv.hikId === $scope.product.hikId;
        });
        if (
          variant &&
          variant.accessory &&
          variant.accessory.useProductHikId &&
          variant.accessory.id === product.accessory_id
        ) {
          return 0;
        } else {
          return product.data.msrp;
        }
      } else {
        var variant = _.find(product.variants, function (pv) {
          return pv.hikId === product.hikId;
        });
        if (variant) {
          return variant.price;
        } else {
          return 0;
        }
      }
    };

    $scope.convertToInch = function (value) {
      return Math.round(value / 2.54);
    };

    $scope.changeColor = function (color) {
      // console.log('color', color);
      if ($scope.product.isSuite) {
        var chldn = scene3D.filterChildren(function (item) {
            return (
              item.userData.suiteUniqueId ===
              $scope.ngDialogData.mesh.userData.suiteUniqueId
            );
          }),
          colors = [],
          currentCS = true,
          typeObj,
          currentIndex;
        for (var i = 0; i < chldn.length; i++) {
          colors[i] = roomStateManager.getColorSchemeByObjectId(chldn[i].uuid);
          colors[i] = colors[i].scheme;
        }
        for (i = 0; i < $scope.product.color_schemes.length; i++) {
          for (var j = 0; j < chldn.length; j++) {
            if (chldn[j].userData.entity.type === 8) {
              typeObj = chldn[j].userData.entity.left_menu_alias + ": Corner";
            } else if (
              chldn[j].userData.entity.left_menu_alias === "Work Tables"
            ) {
              typeObj =
                chldn[j].userData.entity.left_menu_alias + ": Adjustable";
            } else {
              typeObj = chldn[j].userData.entity.left_menu_alias;
            }
            if (
              $scope.product.color_schemes[i].color_scheme[typeObj] !==
              colors[j]
            ) {
              currentCS = false;
            }
          }
          if (currentCS) {
            color = $scope.product.color_schemes[i].short_key;
            currentIndex = i;
          }
          currentCS = true;
        }
        $scope.product.img =
          $scope.product.color_schemes[currentIndex].product_img_url;
        $scope.oldColor = $scope.isColorSelected;
        $scope.isColorSelected = color;
      } else {
        $scope.oldColor = $scope.isColorSelected;
        $scope.isColorSelected = color;

        if ($scope.isCEG) {
          if ($scope.isSelectedAccessory) {
            $scope.productImage = $scope.selected.accessory.products_img[color];
          }

          $scope.product.img =
            "assets/images/ProductImage/" +
            $scope.product.base_model_name +
            color +
            ".png";

          $scope.product.hikId = shoppingListSrvc.getProductHikId(
            $scope.product,
            $scope.isColorSelected,
            getSelectedAccessoryId()
          );
          // if ($scope.product.left_menu_alias === 'Wall Panels') {
          //     $scope.product.productCode = $scope.product.base_model_name;
          // } else {
          //     $scope.product.productCode = $scope.product.base_model_name + color;
          // }
        } else {
          $scope.product.productImage =
            "assets/images/generic/ProductImage/" +
            $scope.product.category +
            "/" +
            $scope.product.base_model_name +
            color +
            ".png";
        }
      }
    };

    var selection2D;

    selection2D = engine2DSrvc.get().findComponentByType(Selection2D);
    $scope.products;
    $scope.swapGroupProducts = [];
    if ($scope.isCEG) {
      var entityId = $scope.mesh
        ? $scope.mesh.userData.entity.id
        : $scope.product.id;
      var cs = $scope.mesh
        ? roomStateManager.getColorSchemeByObjectId($scope.mesh.uuid)
        : null;
      $scope.oldColor = $scope.isColorSelected = cs
        ? cs.scheme
        : roomStateManager.getColorSchemeByEntityId(entityId);
      $scope.changeColor($scope.isColorSelected);
      if (!$scope.isColorSelected) {
        $scope.isColorSelected = $scope.product.color_scheme[0];
      }
      // if ($scope.product.left_menu_alias === 'Wall Panels') {
      //     $scope.product.productCode = $scope.product.base_model_name;
      // } else {
      //   $scope.product.productCode =
      //       $scope.product.base_model_name + $scope.isColorSelected;
      // }

      $scope.product.hikId = shoppingListSrvc.getProductHikId(
        $scope.product,
        $scope.isColorSelected,
        getSelectedAccessoryId()
      );

      $scope.isSelected = "Description";
    } else {
      $scope.dimensions = {};
      $scope.dimensions.width = $scope.convertToInch(
        $scope.mesh
          ? $scope.mesh.userData.width || $scope.product.width
          : $scope.product.width
      );
      $scope.dimensions.height = $scope.convertToInch(
        $scope.mesh
          ? $scope.mesh.userData.height || $scope.product.height
          : $scope.product.height
      );
      $scope.dimensions.length = $scope.convertToInch(
        $scope.mesh
          ? $scope.mesh.userData.length || $scope.product.length
          : $scope.product.length
      );
    }
    $scope.valleyCraftItems = [
      { text: "Suites", items: roomStuff.suites },
      /*  { text: "Cabinets", items: roomStuff.highCapacityCabinets },
      { text: "Workbenches", items: roomStuff.toolStorageCabinets }, 
      { text: "Wall Cabinets", items: roomStuff.wallCabinets },
      { text: "Work Tables", items: roomStuff.tables },
      { text: "Wall Panels", items: roomStuff.wallPanels }, */
      { text: "Accessories", items: roomStuff.accessories },
      { text: "Windows", items: roomStuff.windows },
      { text: "Stairs", items: roomStuff.stairs },
      { text: "Healthcare", items: roomStuff.healthcare },
      { text: "MRO", items: roomStuff.mro },
      { text: "Doors - Entry", items: roomStuff.doors },
      { text: "Vehicles", items: roomStuff.vehicles },
      { text: "Misc", items: roomStuff.misc },
      { text: "Doors - Garage", items: roomStuff.garageDoors },
    ];

    function menuTextToLeftMenuAlias(menuText) {
      switch (menuText) {
        case "Cabinets": {
          return "High Capacity";
        }
        case "Workbenches": {
          return "Tool Storage";
        }
        default: {
          return menuText;
        }
      }
    }

    angular.forEach($scope.valleyCraftItems, function (menu) {
      if ($scope.products === undefined) {
        if (
          menuTextToLeftMenuAlias(menu.text) === $scope.product.left_menu_alias
        ) {
          $scope.products = menu.items;
        }
      }
    });

    setSwapGroupProducts();

    function getWallPanels() {
      var louveredWallPanels = _.filter(roomStuff.wallPanels, function (item) {
        return item.name
          .replace(/\s+/g, "")
          .match(new RegExp(/louveredwallpanel/gi));
      });
      var pegboardWallPanels = _.filter(roomStuff.wallPanels, function (item) {
        return item.name
          .replace(/\s+/g, "")
          .match(new RegExp(/pegboardwallpanel/gi));
      });
      var orphants = _.difference(
        roomStuff.wallPanels,
        [].concat(louveredWallPanels).concat(pegboardWallPanels)
      );

      if (orphants.length) {
        louveredWallPanels = louveredWallPanels.concat(orphants);
        console.warn("Failed to sort wall cabinets", orphants);
      }

      return {
        louveredWallPanels: louveredWallPanels,
        pegboardWallPanels: pegboardWallPanels,
      };
    }

    function getWallCabinets() {
      var wide15 = _.filter(roomStuff.wallCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/15x/gi));
      });
      var wide24 = _.filter(roomStuff.wallCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/24x/gi));
      });
      var corner = _.filter(roomStuff.wallCabinets, function (item) {
        return item.name.match(new RegExp(/corner/gi));
      });
      var orphants = _.difference(
        roomStuff.wallCabinets,
        [].concat(wide15).concat(wide24).concat(corner)
      );

      if (orphants.length) {
        wide15 = wide15.concat(orphants);
        console.warn("Failed to sort wall cabinets", orphants);
      }

      return {
        wide15: wide15,
        wide24: wide24,
        corner: corner,
      };
    }

    function getWorkTables() {
      var cornerWorkTables = _.filter(roomStuff.tables, function (item) {
        return item.name.match(new RegExp(/corner/gi));
      });
      var workTablesWithDrawers = _.filter(roomStuff.tables, function (item) {
        return item.name.match(new RegExp(/drawers/gi));
      });
      var workTablesWithCasters = _.filter(roomStuff.tables, function (item) {
        return item.name.match(new RegExp(/casters/gi));
      });
      var workTables = _.difference(
        roomStuff.tables,
        []
          .concat(cornerWorkTables)
          .concat(workTablesWithDrawers)
          .concat(workTablesWithCasters)
      );

      return {
        cornerWorkTables: cornerWorkTables,
        workTablesWithDrawers: workTablesWithDrawers,
        workTablesWithCasters: workTablesWithCasters,
        workTables: workTables,
      };
    }

    function getWorkbenches() {
      var wide24 = _.filter(roomStuff.toolStorageCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/24x/gi));
      });
      var wide48 = _.filter(roomStuff.toolStorageCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/48x/gi));
      });
      var corner = _.filter(roomStuff.toolStorageCabinets, function (item) {
        return item.name.match(new RegExp(/corner/gi));
      });
      var orphants = _.difference(
        roomStuff.toolStorageCabinets,
        [].concat(wide24).concat(wide48).concat(corner)
      );

      if (orphants.length) {
        wide24 = wide24.concat(orphants);
        console.warn("Failed to sort workbench", orphants);
      }
      return { wide24: wide24, wide48: wide48, corner: corner };
    }

    function getCabinets() {
      var deepDoorCabinets = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name.replace(/\s+/g, "").match(/deepdoorcabinet/gi);
        }
      );
      var electronicLockingCabinets = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name
            .replace(/\s+/g, "")
            .match(/electroniclockingcabinet/gi);
        }
      );
      var toolCabinets = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name.replace(/\s+/g, "").match(/toolcabinet/gi);
        }
      );
      var toolCabinetsWithAccessories = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name.replace(/\s+/g, "").match(/hookkit/gi);
        }
      );
      toolCabinets = _.difference(toolCabinets, toolCabinetsWithAccessories);
      var cabinets = _.difference(
        roomStuff.highCapacityCabinets,
        []
          .concat(deepDoorCabinets)
          .concat(electronicLockingCabinets)
          .concat(toolCabinets)
          .concat(toolCabinetsWithAccessories)
      );

      return {
        deepDoorCabinets: deepDoorCabinets,
        electronicLockingCabinets: electronicLockingCabinets,
        cabinets: cabinets,
        // toolCabinets: {
        //     toolCabinets: toolCabinets,
        //     toolCabinetsWithAccessories: toolCabinetsWithAccessories
        // }
        toolCabinets: toolCabinets.concat(toolCabinetsWithAccessories),
      };
    }

    function getSubCategories() {
      console.log("ALIAS", $scope.product.left_menu_alias);

      switch ($scope.product.left_menu_alias) {
        case "Work Tables": {
          const workTables = getWorkTables();
          if (!workTables) {
            return;
          }
          return [
            {
              text: "Work Tables",
              items: workTables.workTables,
            },
            {
              text: "Work Tables with Casters",
              items: workTables.workTablesWithCasters,
            },
            {
              text: "Work Tables with Drawers",
              items: workTables.workTablesWithDrawers,
            },
            {
              text: "Corner Work Tables",
              items: workTables.cornerWorkTables,
            },
          ];
        }
        case "Tool Storage": {
          const workbenches = getWorkbenches();

          return [
            {
              text: '24" Wide',
              items: workbenches.wide24,
            },
            {
              text: '48" Wide',
              items: workbenches.wide48,
            },
            {
              text: 'Corner (36")',
              items: workbenches.corner,
            },
          ];
        }

        case "Wall Cabinets": {
          const wallCabinets = getWallCabinets();

          return [
            {
              text: '15" Wide',
              items: wallCabinets.wide15,
            },
            {
              text: '24" Wide',
              items: wallCabinets.wide24,
            },
            {
              text: 'Corner (21")',
              items: wallCabinets.corner,
            },
          ];
        }

        case "Wall Panels": {
          const wallPanels = getWallPanels();

          return [
            {
              text: "Louvered Wall Panels",
              items: wallPanels.louveredWallPanels,
            },
            {
              text: "Pegboard Wall Panels",
              items: wallPanels.pegboardWallPanels,
            },
          ];
        }

        case "High Capacity": {
          const cabinets = getCabinets();

          return [
            {
              text: "Standard Cabinets",
              items: cabinets.cabinets,
            },
            {
              text: "Deep Door Cabinets",
              items: cabinets.deepDoorCabinets,
            },
            {
              text: "Electronic Locking Cabinets",
              items: cabinets.electronicLockingCabinets,
            },
            // {
            //     text: 'Tool Cabinets',
            //     items: cabinets.toolCabinets.toolCabinets
            // },
            // {
            //     text: 'Tool Cabinets with Accessories',
            //     items: cabinets.toolCabinets.toolCabinetsWithAccessories
            // },
            {
              text: "Tool Cabinets",
              items: cabinets.toolCabinets,
            },
          ];
        }

        default:
          break;
      }
    }

    $scope.isActiveSubCategory = function (subCategory) {
      if (!subCategory) {
        return;
      }

      return subCategory.items.find(function (sc) {
        return sc.name === $scope.product.name;
      });
    };

    $scope.changeSubCategory = function (subCategory) {
      const firstItem = subCategory.items[0];
      if (firstItem) {
        $scope.product = firstItem;
        $scope.changeColor(firstItem.color_scheme[0]);
        $scope.changeSwapProduct(firstItem);
        $scope.swapGroupProducts.length = 0;

        setSwapGroupProducts();
      }
    };

    //end if($scope.isCEG)
    $scope.selectTab = function (tabName) {
      $scope.isSelected = tabName;
    };

    $scope.isActiveColor = function (color) {
      if (!$scope.isColorSelected) {
        if ($scope.mesh) {
          var csObj = roomStateManager.getColorSchemeByObjectId(
            $scope.mesh.uuid
          );
          $scope.isColorSelected = csObj ? csObj.scheme : null;
        } else {
          $scope.isColorSelected = roomStateManager.getColorSchemeByEntityId(
            $scope.product.id
          );
        }

        $scope.oldColor = $scope.isColorSelected;
      }
      if (!$scope.isColorSelected) {
        $scope.isColorSelected = $scope.product.color_scheme[0];
        $scope.oldColor = $scope.isColorSelected;
      }

      return $scope.isColorSelected === color;
    };

    $scope.isActiveSwapProduct = function (prod) {
      if ($scope.product && prod && $scope.product._id === prod._id) {
        return true;
      }
      return false;
    };

    $scope.isActiveTab = function (tabName) {
      return $scope.isSelected.valueOf() === tabName.valueOf();
    };

    $scope.selectAccessoryModel = function (val) {
      $scope.selected.accessory = val;
      if (_.isString(val)) {
        $scope.isSelectedAccessory = false;
        $scope.productImage = null;
      } else {
        $scope.isSelectedAccessory = true;
        $scope.productImage = val.products_img[$scope.isColorSelected];
      }
      $scope.product.hikId = shoppingListSrvc.getProductHikId(
        $scope.product,
        $scope.isColorSelected,
        getSelectedAccessoryId()
      );
      //            console.log(val);
    };

    $scope.changeSwapProduct = function (swapProduct) {
      $scope.product = swapProduct;
      getProductAccessories();
      // if ($scope.product.left_menu_alias === 'Wall Panels') {
      //     $scope.product.productCode = $scope.product.base_model_name;
      // } else

      if ($scope.isCEG) {
        if (!_.isString($scope.selected.accessory)) {
          $scope.selected.accessory = _.find($scope.product.accessories, {
            accessory_id: getSelectedAccessoryId(),
          });
          $scope.productImage =
            $scope.selected.accessory.products_img[$scope.isColorSelected];
        }
        const checkColorSchemesForSelectedColor =
          $scope.product.color_scheme.includes($scope.isColorSelected);

        if (!checkColorSchemesForSelectedColor) {
          // console.log('need to change color');
          $scope.changeColor($scope.product.color_scheme[0]);
        }

        $scope.product.hikId = shoppingListSrvc.getProductHikId(
          $scope.product,
          $scope.isColorSelected,
          getSelectedAccessoryId()
        );
        // $scope.product.productCode =
        //     $scope.product.base_model_name + $scope.isColorSelected;
        $scope.product.img =
          "assets/images/ProductImage/" +
          $scope.product.base_model_name +
          $scope.isColorSelected +
          ".png";
        // }
      } else {
        $scope.selected.accessory = "none";
        $scope.isSelectedAccessory = false;
        $scope.productImage = null;
        $scope.dimensions.width = $scope.convertToInch($scope.product.width);
        $scope.dimensions.height = $scope.convertToInch($scope.product.height);
        $scope.dimensions.length = $scope.convertToInch($scope.product.length);
      }
    };

    $scope.updateCanvas = function () {
      var isMode3D = $scope.ngDialogData.sender === "3d",
        oldModel2D = isMode3D
          ? $scope.ngDialogData.item2d
          : $scope.ngDialogData.mesh,
        colorScheme = $scope.isColorSelected,
        entity = $scope.product,
        newModel2D,
        uuid;
      if (!$scope.isCEG && !checkDimensions()) {
        ngDialog.open({
          plain: true,
          template: "Incorrect dimensions for the item. Can't scale item!",
        });
      } else {
        console.log("entity.left_menu_alias",entity.left_menu_alias)
        if (
          entity.left_menu_alias === "Tool Storage" ||
          entity.left_menu_alias === "Work Tables" ||
          entity.left_menu_alias === "High Capacity"
        ) {
          newModel2D = replaceModelSrvc.replaceModel2D(oldModel2D, entity);
          if (!$scope.isCEG && checkDimensionsChange()) {
            scale2DObject(newModel2D);
          }
          uuid = newModel2D.uuid;

          if ($scope.isSelectedAccessory) {
            newModel2D.userData.selectedAccessoryId =
              $scope.selected.accessory.accessory_id;
          } else {
            if (newModel2D.userData.hasOwnProperty("selectedAccessoryId")) {
              delete newModel2D.userData["selectedAccessoryId"];
            }
          }

          if (isMode3D) {
            var meshData = {
              entity: entity,
            };
            var old3DModel = $scope.mesh;
            if ($scope.isSelectedAccessory) {
              meshData.selectedAccessoryId =
                $scope.selected.accessory.accessory_id;
            } else {
              if (old3DModel.userData.hasOwnProperty("selectedAccessoryId")) {
                delete old3DModel.userData["selectedAccessoryId"];
              }
            }
            replaceModel3D(old3DModel, meshData, colorScheme, uuid).then(
              function (model) {
                if (!$scope.isCEG && checkDimensionsChange()) {
                  scale3DObject(model);
                }
              }
            );
          }

          roomStateManager.saveObjectColorScheme({
            objectId: uuid,
            entityId: entity.id,
            scheme: colorScheme,
            updateIfExist: false,
          });
        } else if (
          oldModel2D.userData.entity !== entity ||
          colorScheme !== $scope.oldColor
        ) {
          console.log('oldModel')
          newModel2D = replaceModel2D(oldModel2D, entity);
          uuid = newModel2D.uuid;

          if (isMode3D) {
            var meshData = {
              entity: entity,
            };
            var old3DModel = $scope.mesh;
            replaceModel3D(old3DModel, meshData, colorScheme, uuid);
          }

          roomStateManager.saveObjectColorScheme({
            objectId: uuid,
            entityId: entity.id,
            scheme: colorScheme,
            updateIfExist: false,
          });
        } else {
          console.log('else')
          if (!$scope.isCEG && checkDimensionsChange()) {
            if (!isMode3D) {
              scale2DObject(oldModel2D);
            } else {
              scale3DObject($scope.mesh);
            }
          }
        }
        $scope.dimensions.uuid = uuid;
        if (colorScheme)
          $rootScope.$broadcast("cegObjectColorSchemeChanged", colorScheme);
        ngDialog.close();
      }
    };

    // REFACTOR
    $scope.deleteModel = function () {
      if ($scope.ngDialogData.sender.valueOf() === "2d") {
        ngDialog
          .openConfirm({
            disableAnimation: true,
            template: "./app/views/help/productConfirmDelete.html",
          })
          .then(
            function (confirm) {
              if ($scope.product.isSuite) {
                var chldn = scene3D.filterChildren(function (item) {
                  return (
                    item.userData.suiteUniqueId ===
                    $scope.ngDialogData.mesh.userData.suiteUniqueId
                  );
                });
                for (var i = 0; i < chldn.length; i++) {
                  $scope.del2dObj(chldn[i]);
                }
              } else {
                $scope.del2dObj($scope.mesh);
              }
              ngDialog.close();
            },
            function (reject) {}
          );
      } else if ($scope.ngDialogData.sender.valueOf() === "3d") {
        ngDialog
          .openConfirm({
            disableAnimation: true,
            template: "./app/views/help/productConfirmDelete.html",
          })
          .then(
            function (confirm) {
              del3dObj();
              ngDialog.close();
            },
            function (reject) {}
          );
      }
    };

    $scope.del2dObj = function (model) {
      if (model.parent) {
        model.parent.remove(model);
      } else {
        Scene2d.remove(model);
      }
      roomStateManager.removeObjectColorScheme(model);
      selection2D.setSelected(null);
      Scene2d.render();
    };

    function updateWall(wall) {
      var objs = scene3D.getChildren();
      var stairs = [];
      for (var i = 0; i < objs.length; i++) {
        if (objs[i].userData.type === 0) {
          stairs.push(objs[i]);
        }
      }
      objs = scene3D.getWallChildren(wall.name);
      var originalWall = scene3D.getNonCuttedWallByName(wall.name);
      var cutted = wallCutHelper.cutHolesInWall(
        originalWall,
        scene3D.getWallChildren(wall.name),
        stairs
      );

      cutted.material = wall.material.clone();
      cutted.add.apply(cutted, wall.children);
      wall.remove.apply(wall, wall.children);

      scene3D.remove(wall);
      scene3D.add(cutted);

      return cutted;
    }

    var del3dObj = function () {
      objectControls.setButtonVisibility("rotateCounterClockwise", false);
      objectControls.setButtonVisibility("delete", false);
      objectControls.setButtonVisibility("info", false);
      objectControls.setButtonVisibility("configurator", false);
      objectControls.setButtonVisibility("clone", false);
      objectControls.setButtonVisibility("rotateClockwise", false);
      if ($scope.product.isSuite) {
        var chldn = scene3D.filterChildren(function (item) {
          return (
            item.userData.suiteUniqueId ===
            $scope.ngDialogData.mesh.userData.suiteUniqueId
          );
        });
        for (var i = 0; i < chldn.length; i++) {
          sceneSyncSrvc.deleteObject(chldn[i]);
        }
      } else {
        sceneSyncSrvc.deleteObject($scope.mesh);
      }
      sceneSyncSrvc.deleteObject($scope.mesh);
      dimensionalRadialsManager.deleteDimensionalRadials();
    };

    function scale2DObject(model) {
      ScaleService.setDimensions(
        model.userData,
        $scope.dimensions,
        false,
        model.uuid
      );
      ScaleService.scale(model, false);
      Scene2d.render();
    }

    function scale3DObject(model) {
       if (model.uuid === $scope.dimensions.uuid) {
      ScaleService.setDimensions(
        model.userData,
        $scope.dimensions,
        false,
        model.uuid
      );
      ScaleService.scale(model, true);
        }
      if (model.userData.wall) {
        var floor = scene3D.getFloor(),
          floorPlane = new THREE.Plane(),
          roofPlane = new THREE.Plane(
            floor.getWorldDirection(),
            -1 * constants.wallHeight
          ),
          bBox = new THREE.Box3();

        floorPlane.setFromNormalAndCoplanarPoint(
          floor.getWorldDirection(),
          floor.position
        );

        bBox.setFromObject(model);

        var thisWall = scene3D.getObject(model.userData.wall);
        var staticObjects = scene3D.getChildren().filter(function (obj) {
          return (
            obj !== model &&
            obj.visible &&
            obj instanceof THREE.Mesh &&
            obj !== thisWall
          );
        });
        if (
          collisionSrvc.isCollide(model, staticObjects) ||
          bBox.intersectsPlane(floorPlane) ||
          bBox.intersectsPlane(roofPlane)
        ) {
          ngDialog
            .openConfirm({
              disableAnimation: true,
              template: "./app/views/help/cantBeScaledInfo.html",
            })
            .then(
              function (confirm) {
                ngDialog.close();
              },
              function (reject) {}
            );
          var oldDimensions = {
            width: model.userData.entity.width,
            height: model.userData.entity.height,
            length: model.userData.entity.length,
          };
          ScaleService.setDimensions(model.userData, oldDimensions, true);
          ScaleService.scale(model, true);
          model.scale.set(1, 1, 1);
        } else {
          var model2D = Scene2d.getObjectByUUID(model.uuid);
          scale2DObject(model2D);
        }
        updateWall(scene3D.getObject(model.userData.wall));
      } else {
        var staticObjects = scene3D.getChildren().filter(function (obj) {
          return obj.visible && obj instanceof THREE.Mesh && obj !== model;
        });
        if (collisionSrvc.isCollide(model, staticObjects)) {
          ngDialog
            .openConfirm({
              disableAnimation: true,
              template: "./app/views/help/cantBeScaledInfo.html",
            })
            .then(
              function (confirm) {
                ngDialog.close();
              },
              function (reject) {}
            );
          var oldDimensions = {
            width: model.userData.entity.width,
            height: model.userData.entity.height,
            length: model.userData.entity.length,
          };
          ScaleService.setDimensions(model.userData, oldDimensions, true);
          ScaleService.scale(model, true);
        } else {
          var model2D = Scene2d.getObjectByUUID(model.uuid);
          scale2DObject(model2D);
        }
      }
    }

    function checkDimensionsChange() {
       console.log("chekD")
      return (
        $scope.dimensions.width !==
          $scope.convertToInch($scope.product.width) ||
        $scope.dimensions.height !==
          $scope.convertToInch($scope.product.height) ||
        $scope.dimensions.length !== $scope.convertToInch($scope.product.length)
      );
    }

    function checkDimensions() {
      return (
        $scope.dimensions.length &&
        $scope.dimensions.height &&
        $scope.dimensions.width
      );
    }

    $scope.cancelDialog = function () {
      ngDialog.close();
    };

    var replaceModel2D = function (oldModel2D, newEntity) {
      var newModel2D = roomStuffFactory.buildRoomItem(newEntity);
      if (oldModel2D.parent) {
        oldModel2D.parent.add(newModel2D);
      }
      newModel2D.position.copy(oldModel2D.position);
      if (!newModel2D.userData.entity.wallInteraction) {
        //TODO
          newModel2D.position.z = newModel2D.userData.entity.height / 2;
      }
      newModel2D.rotation.copy(oldModel2D.rotation);
      $scope.del2dObj(oldModel2D);
      if (newModel2D.parent) {
        newModel2D.userData.wall = newModel2D.parent.name;
      } else {
        Scene2d.addModel(newModel2D);
      }
      if (!$scope.isCEG && checkDimensionsChange()) {
        scale2DObject(newModel2D);
      }
      return newModel2D;
    };

    var replaceModel3D = function (old3DModel, meshData, colorScheme, uuid) {
      objectControls.setButtonVisibility("rotateCounterClockwise", false);
      objectControls.setButtonVisibility("delete", false);
      objectControls.setButtonVisibility("info", false);
      objectControls.setButtonVisibility("configurator", false);
      objectControls.setButtonVisibility("clone", false);
      objectControls.setButtonVisibility("rotateClockwise", false);
      dimensionalRadialsManager.deleteDimensionalRadials();

      modelLoader.load(meshData, true).then(function (newModel3D) {
        newModel3D.uuid = uuid;
        //TODO
      newModel3D.userData.entity = meshData.entity; //усі нові фігури будуть

        if (meshData.hasOwnProperty("selectedAccessoryId")) {
          newModel3D.userData.selectedAccessoryId = _.clone(
            meshData.selectedAccessoryId
          );
        }

        newModel3D.userData.entity = $scope.product;

        newModel3D.position.copy(old3DModel.position);
        if (!newModel3D.userData.entity.wallInteraction) {
          //TODO
            newModel3D.position.y = newModel3D.userData.entity.height / 2;
        }
        newModel3D.rotation.copy(old3DModel.rotation);

        if (old3DModel.userData.wall) {
          var wall = scene3D.getObject(old3DModel.userData.wall);
          scene3D.remove(old3DModel);
          if (old3DModel.userData.wall)
            newModel3D.userData.wall = old3DModel.userData.wall;
          scene3D.add(newModel3D);
          if (wall) wall = updateWall(wall);
        } else {
          scene3D.add(newModel3D);
          scene3D.remove(old3DModel);
        }

        objectMaterial.setMaterial(newModel3D, colorScheme);
        if (!$scope.isCEG && checkDimensionsChange()) {
          scale3DObject(newModel3D);
        }
      });
    };
  },
]);
