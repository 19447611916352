import * as THREE from '../../../libs/three';
import TouchAndMouseMap from '../../TouchAndMouseMap';
import AddObjectHandlerBase from './AddObjectHandlerBase';

var ObjectAdd = function (dependencyContainer, handlers) {
    TouchAndMouseMap.prototype.constructor.call(this);

    this._roomStuff = dependencyContainer.getService('roomStuff');
    this._scene3D = dependencyContainer.getService('scene3D');
    this._sceneHelper = dependencyContainer.getService('sceneHelper');
    this._objectCheckerSrvc = dependencyContainer.getService('objectCheckerSrvc');
    this._scene2DSyncSrvc = dependencyContainer.getService('scene2DSyncSrvc');
    this._$q = dependencyContainer.getService('$q');
    this._roomStateManager = dependencyContainer.getService('roomStateManager');
    this._toastr = dependencyContainer.getService('toastr');

    this._handlers = handlers;
    this._activeEntity = null;
    this._raycaster = new THREE.Raycaster();

    this._defaultHandler = new AddObjectHandlerBase();
    this._currentHandler = this._defaultHandler;

    this._ghostObject = this._buildGhostObject();
    this._scene3D.add(this._ghostObject);
};

ObjectAdd.prototype = Object.create(TouchAndMouseMap.prototype);

ObjectAdd.prototype.constructor = ObjectAdd;

ObjectAdd.prototype.setEntity = function (entity) {
    this._activeEntity = entity;
};

ObjectAdd.prototype._buildGhostObject = function () {
    var edges = new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(1, 1, 1));
    var material = new THREE.LineBasicMaterial({
        color: 0x00ff00,
        depthTest: false,
        depthWrite: false
    });
    var ghostObject = new THREE.LineSegments(edges, material);
    ghostObject.renderOrder = 2;
    ghostObject.visible = false;
    ghostObject.name = 'ghostObject';
    return ghostObject;
};

ObjectAdd.prototype._selectHandler = function (entity) {
    var i = 0,
        handlers = this._handlers,
        handler;
    for (; i < handlers.length; i++) {
        handler = handlers[i];
        if (handler.canExecute(entity)) return handler;
    }
    return this._defaultHandler;
};

ObjectAdd.prototype.hold = function () {
    var result = true,
        groupId = Date.now();
    this._currentHandler.dragEnd();
    if (this._currentHandler.hold) {
        if (this._ghostObject.visible) {
            if (this._activeEntity.isCornerSuite && Math.abs(Math.round(this._ghostObject.rotation._y * 100) / 100) === 0.75) {
              this._toastr.info('Cannot place suite here');
              return;
            }
            var suiteUniqueId = Date.now();
            var suiteInfo = {
                suiteUniqueId: suiteUniqueId,
                objects: []
            };
            var objRotY,
                objectRot = new THREE.Object3D();
            var promises = [];
            for (var i = 0; i < this._activeEntity.objects.length; i++) {
                var objRotY,
                    objectRot = new THREE.Object3D();
                if (this._activeEntity.isCornerSuite) {
                    objRotY = this._activeEntity.objects[i].rotation._z;
                } else {
                    objRotY = 0;
                }
                objectRot.rotation.copy(this._ghostObject.rotation);
                objectRot.rotation._y += objRotY;
                var entity = _.cloneDeep(this._roomStuff.getById(this._activeEntity.objects[i].id));
                if (this._activeEntity.objects[i].accessoryId)
                    entity.defaultAccessory = this._activeEntity.objects[i].accessoryId;
                promises.push(
                    this._scene2DSyncSrvc.createObject(entity, {
                        position: this._scene3D.getSuitesNewPosition(
                            this._activeEntity,
                            this._ghostObject,
                            i,
                            this._activeEntity.isCornerSuite
                        ),
                        rotation: objectRot.rotation,
                        wall: this._ghostObject.userData.wall,
                        suiteId: this._activeEntity._id,
                        suiteUniqueId: suiteUniqueId,
                        groupId: groupId,
                        isCornerSuite: this._activeEntity.isCornerSuite,
                        objRot: objRotY
                    })
                );
            }
            var self = this;
            this._$q.all(promises).then(function (obj3DArray) {
                for (var i = 0; i < obj3DArray.length; i++) {
                    suiteInfo.objects.push(obj3DArray[i].uuid);
                }
                self._roomStateManager.saveSuiteObjects(suiteInfo);
            });

            this._ghostObject.visible = false;
            result = false;
        }
    } else {
        if (this._ghostObject.visible) {
            this._ghostObject.visible = false;
            this._scene2DSyncSrvc.createObject(this._activeEntity, {
                position: this._ghostObject.position,
                rotation: this._ghostObject.rotation,
                wall: this._ghostObject.userData.wall
            });
            result = false;
        }
    }
    this._currentHandler = this._defaultHandler;
    this._staticObjects = this._raycastObjects = this._activeEntity = null;
    return result;
};

ObjectAdd.prototype.move = function (e) {
    if (this._activeEntity && this._currentHandler !== this._defaultHandler) {
        var coord = this._sceneHelper.getCanvasClientXY(e, this._scene3D.getHtmlElement());
        this._scene3D.getPickingRay(coord.x, coord.y, this._raycaster);

        this._staticObjects =
            this._staticObjects ||
            this._scene3D.filterChildren(this._currentHandler.getStaticObjectPredicate());

        var position = this._currentHandler.dragBegin(
            this._raycaster.intersectObjects(this._raycastObjects),
            this._staticObjects
        );

        if (position) {
            this._ghostObject.position.copy(position);
            this._ghostObject.visible = true;
            return false;
        }
    }
};

ObjectAdd.prototype.mouseEnter = function () {
    var entity = this._activeEntity;
    if (entity) {
        this._currentHandler = this._selectHandler(entity);

        this._raycastObjects =
            this._raycastObjects ||
            this._scene3D.filterChildren(this._currentHandler.getRaycastObjectPredicate());

        this._ghostObject.scale.set(entity.length, entity.height, entity.width);
        this._ghostObject.userData.entity = entity;
        this._currentHandler.setGhostObject(this._ghostObject);
    }
};

ObjectAdd.prototype.disable = function () {
    TouchAndMouseMap.prototype.disable.call(this);
    this._ghostObject.visible = false;
    this._activeEntity = this._raycastObjects = this._staticObjects = null;
};

ObjectAdd.prototype.dispose = function () {
    TouchAndMouseMap.prototype.dispose.call(this);
    this._scene3D.remove(this._ghostObject);
};

export default ObjectAdd;