import dotaReplaceAssets from "../libs/replace";

(function () {
  var service = function (constants, ResourceService, $http, $rootScope, $q) {
    var abilities = constants.ItemOption;
    var deferred = $q.defer();
    var configurator = [];
    var akroMils = [],
      toolStorageCabinets = [],
      wallCabinets = [],
      highCapacityCabinets = [],
      tables = [],
      wallPanels = [],
      suites = [],
      accessories = [];

    var gardenTools = [],
      windows = [],
      doors = [],
      misc = [],
      vehicles = [],
      mro = [],
      healthcare = [],
      stairs = [],
      garageDoors = [];

    var all = [],
      allGeneric = [];
    var allColorSchemes = [];

    var accessoriesProduct = [];
    ResourceService.getAccessories().then(function (result) {
      accessoriesProduct = result;
    });

    ResourceService.getAllColorSchemes().then(function (result) {
      result.forEach((result) => {
        if (
          result.colorSchemeImage &&
          !result.colorSchemeImage.includes("assets")
        ) {
          result.colorSchemeImage = "assets" + result.colorSchemeImage;
        }
        if (
          result.productDetailsImage &&
          !result.productDetailsImage.includes("assets")
        ) {
          result.productDetailsImage = "assets" + result.productDetailsImage;
        }
      });
      allColorSchemes = result;
    });

    ResourceService.getAllSuites().then(function (snapshot) {
      var suitesActive = _.filter(snapshot, { visible: true });
      // var suitesActive = snapshot;
      _.forEach(suitesActive, function (suite) {
        suite.id = suite._id;
        suite.color_schemes = suite.color_schemes;
        suite.img = dotaReplaceAssets(suite.productImage);
        suite.leftMenuImg = dotaReplaceAssets(suite.leftMenuImg);
        suite.img = suite.color_schemes.length
          ? suite.color_schemes[0].product_img_url
          : null;
        suite.isSuite = true;
        suites.push(angular.extend(new constants.RoomObject.Suite(), suite));
      });
      all = all.concat(suites);
    });

    function widthConfiguratorFix(entity) {
      return entity?.width_configurator + entity.width / 20;
    }

    function getProductConfigurator() {
      ResourceService.getProductConfigurator().then(function (response) {
        angular.forEach(response, function (product) {
          if (product.visible) {
            product.id = product._id;
            product.img =
              "assets/images/ProductImage/" +
              product.description +
              (product.description.indexOf(product.color_scheme[0]) !== -1
                ? ""
                : product.color_scheme[0]) +
              ".png";
            product.leftMenuImg ==
              "assets/images/LeftMenuThumbnails/" +
                product.description +
                (product.description.indexOf(product.color_scheme[0]) !== -1
                  ? ""
                  : product.color_scheme[0]) +
                ".png";
            product.swapImg =
              "assets/images/SwapPictogram/" +
              product.description +
              (product.description.indexOf(product.color_scheme[0]) !== -1
                ? ""
                : product.color_scheme[0]) +
              ".png";
            product.model =
              "assets/configurator/" + product.base_model_name + ".js";
            product.sprite =
              "assets/images/PlanView/" +
              product.description +
              (product.description.indexOf(product.color_scheme[0]) !== -1
                ? ""
                : product.color_scheme[0]) +
              ".png";
            var temp = product.length; // MOVE TO SERVER
            const temp_shelfing =
              product.width_configurator_shelfing > temp
                ? product.width_configurator_shelfing
                : temp;
            product.length = product.width;
            product.length *= 2.54;
            product.height = product.height_configurator
              ? product.height_configurator
              : product.height * 2.54;
            product.Oldwidth = temp * 2.54;
            product.widthOld = product.width;
            product.width = product.width_configurator
              ? widthConfiguratorFix(product)
              : temp_shelfing;
            /*  
            product.type = constants.CabinetType.TOOL_STORAGE;*/
            product.objectType = constants.ObjectType.CONFIGURATOR;
            product.abilities = [abilities.ROTATE];
            configurator.push(
              angular.extend(new constants.RoomObject.Configurator(), product)
            );
          }
        });
        all = all.concat(configurator);

        var resolve = _.once(function () {
          deferred.resolve();
        });
        resolve();
        $rootScope.$broadcast("Items loaded", null);
      });
    }

    getProductConfigurator();
    ResourceService.getProducts().then(function (response) {
      angular.forEach(response, function (product) {
        if (product.visible) {
          if (Array.isArray(product.color_scheme)) {
            product.color_scheme[0] = product.color_scheme[0]
              ? product.color_scheme[0]
              : "";
          }
          switch (product.left_menu_alias) {
            case "Akro-Mils":
              {
                product.id = product._id;
                product.img =
                  "assets/images/ProductImage/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.leftMenuImg =
                  "assets/images/LeftMenuThumbnails/" +
                  product.base_model_name +
                  ".png";
                product.swapImg =
                  "assets/images/SwapPictogram/" +
                  product.base_model_name +
                  ".png";
                product.model =
                  "assets/models/akro-mils/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".js";
                product.sprite =
                  "assets/images/PlanView/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                var temp = product.length; // MOVE TO SERVER
                product.length = product.width;
                product.width = temp;
                product.length *= 2.54;
                product.width *= 2.54;
                product.height *= 2.54;
                /*  */ product.type = constants.CabinetType.TOOL_STORAGE;
                product.objectType = constants.ObjectType.CABINET;
                product.abilities = [abilities.ROTATE];
                akroMils.push(
                  angular.extend(new constants.RoomObject.AkroMils(), product)
                );
              }
              break;
            case "Tool Storage":
              {
                product.id = product._id;
                product.img =
                  "assets/images/ProductImage/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.leftMenuImg =
                  "assets/images/LeftMenuThumbnails/" +
                  product.base_model_name +
                  ".png";
                product.swapImg =
                  "assets/images/SwapPictogram/" +
                  product.base_model_name +
                  ".png";
                product.model =
                  "assets/models/cabinets/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".js";
                product.sprite =
                  "assets/images/PlanView/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                var temp = product.length; // MOVE TO SERVER
                product.length = product.width;
                product.width = temp;
                product.length *= 2.54;
                product.width *= 2.54;
                product.height *= 2.54;
                product.type = constants.CabinetType.TOOL_STORAGE;
                product.objectType = constants.ObjectType.CABINET;
                product.abilities = [abilities.ROTATE];
                toolStorageCabinets.push(
                  angular.extend(new constants.RoomObject.Cabinet(), product)
                );
              }
              break;
            case "Wall Cabinets":
              {
                product.id = product._id;
                product.img =
                  "assets/images/ProductImage/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.leftMenuImg =
                  "assets/images/LeftMenuThumbnails/" +
                  product.base_model_name +
                  ".png";
                product.swapImg =
                  "assets/images/SwapPictogram/" +
                  product.base_model_name +
                  ".png";
                product.model =
                  "assets/models/cabinets/" + product.base_model_name + "RB.js";
                product.sprite =
                  "assets/images/PlanView/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                var temp = product.length; // MOVE TO SERVER
                product.length = product.width;
                product.width = temp;
                product.length *= 2.54;
                product.width *= 2.54;
                product.height *= 2.54;
                product.type = constants.CabinetType.WALL;
                product.objectType = constants.ObjectType.CABINET;
                product.abilities = [abilities.ROTATE];
                wallCabinets.push(
                  angular.extend(new constants.RoomObject.Cabinet(), product)
                );
              }
              break;
            case "High Capacity":
              {
                product.id = product._id;
                product.img =
                  "assets/images/ProductImage/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.leftMenuImg =
                  "assets/images/LeftMenuThumbnails/" +
                  product.base_model_name +
                  ".png";
                product.swapImg =
                  "assets/images/SwapPictogram/" +
                  product.base_model_name +
                  ".png";
                product.model =
                  "assets/models/cabinets/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".js";
                product.sprite =
                  "assets/images/PlanView/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                var temp = product.length; // MOVE TO SERVER
                product.length = product.width;
                product.width = temp;
                product.length *= 2.54;
                product.width *= 2.54;
                product.height *= 2.54;
                product.type = constants.CabinetType.HIGH_CAPACITY;
                product.objectType = constants.ObjectType.CABINET;
                product.abilities = [abilities.ROTATE];
                highCapacityCabinets.push(
                  angular.extend(new constants.RoomObject.Cabinet(), product)
                );
              }
              break;
            case "Work Tables":
              {
                product.id = product._id;
                product.img =
                  "assets/images/ProductImage/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.leftMenuImg =
                  "assets/images/LeftMenuThumbnails/" +
                  product.base_model_name +
                  ".png";
                product.swapImg =
                  "assets/images/SwapPictogram/" +
                  product.base_model_name +
                  ".png";
                product.model =
                  "assets/models/cabinets/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".js";
                product.sprite =
                  "assets/images/PlanView/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.length *= 2.54;
                product.width *= 2.54;
                product.height *= 2.54;
                product.type =
                  product.category !== "Corner Work Table"
                    ? constants.TableType.ADJUSTABLE
                    : constants.TableType.CORNER;
                product.objectType = constants.ObjectType.TABLE;
                product.abilities = [abilities.ROTATE];
                tables.push(
                  angular.extend(new constants.RoomObject.Table(), product)
                );
              }
              break;
            case "Wall Panels":
              {
                product.id = product._id;
                product.img =
                  "assets/images/ProductImage/" +
                  product.base_model_name +
                  ".png";
                product.leftMenuImg =
                  "assets/images/LeftMenuThumbnails/" +
                  product.base_model_name +
                  ".png";
                product.swapImg =
                  "assets/images/SwapPictogram/" +
                  product.base_model_name +
                  ".png";
                product.model =
                  "assets/models/cabinets/" + product.base_model_name + ".js";
                product.sprite =
                  "assets/images/PlanView/" +
                  product.base_model_name +
                  product.color_scheme[0] +
                  ".png";
                product.objectType = constants.ObjectType.WALL_PANEL;
                var temp = product.length; // MOVE TO SERVER
                product.length = product.width;
                product.width = temp;
                product.length *= 2.54;
                product.width *= 2.54;
                product.prht *= 2.54;
                product.abilities = [abilities.ROTATE];
                wallPanels.push(
                  angular.extend(new constants.RoomObject.WallPanel(), product)
                );
              }
              break;
            case "Windows":
              {
                product.id = product._id;
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);
                windows.push(
                  angular.extend(new constants.RoomObject.Window(), product)
                );
              }
              break;
            case "Doors - Entry":
              var doorAbilities;
              if (product.isOpening === null || product.isOpening === false) {
                if (
                  product.isDouble === false ||
                  product.isDouble === undefined
                ) {
                  doorAbilities = [2, 3];
                } else {
                  doorAbilities = [3];
                }
              }
              {
                product.id = product._id;
                product.type = "door";
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.productImage = dotaReplaceAssets(product.productImage);
                product.swapImage = dotaReplaceAssets(product.swapImage);
                product.sprite = dotaReplaceAssets(product.sprite);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);
                product.abilities = product.abilities || doorAbilities;
                doors.push(
                  angular.extend(new constants.RoomObject.Door(), product)
                );
              }
              break;
            case "Stairs":
              {
                product.id = product._id;
                product.type = constants.StairsType.DEFAULT;
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.sprite = dotaReplaceAssets(product.sprite);
                product.swapImg = dotaReplaceAssets(product.swapImg);
                product.model = dotaReplaceAssets(product.model);
                if (Array.isArray(product.model)) {
                  product.getModel = function (meshData) {
                    return meshData.flip ? this.model[1] : this.model[0];
                  };
                }

                if (product.id === "5887545cdd525c0ace965cb7") {
                  product.type = constants.StairsType.WALL_CUT;
                }

                stairs.push(
                  angular.extend(new constants.RoomObject.Stairs(), product)
                );
              }
              break;

            case "Healthcare":
              {
                product.id = product._id;
                product.abilities = [abilities.ROTATE];
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.sprite = dotaReplaceAssets(product.sprite);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);
                healthcare.push(
                  angular.extend(new constants.RoomObject.Healthcare(), product)
                );
              }
              break;
            case "MRO":
              {
                product.id = product._id;
                product.abilities = [abilities.ROTATE];
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.sprite = dotaReplaceAssets(product.sprite);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);
                mro.push(
                  angular.extend(new constants.RoomObject.MRO(), product)
                );
              }
              break;
            case "Vehicles":
              {
                product.id = product._id;
                product.abilities = [abilities.ROTATE];
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.sprite = dotaReplaceAssets(product.sprite);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);
                vehicles.push(
                  angular.extend(new constants.RoomObject.Vehicle(), product)
                );
              }

              break;
            case "Misc":
              {
                product.id = product._id;
                product.abilities = [abilities.ROTATE];
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);

                product.sprite = dotaReplaceAssets(product.sprite);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);

                misc.push(
                  angular.extend(new constants.RoomObject.Misc(), product)
                );
              }
              break;
            case "Doors - Garage":
              {
                product.id = product._id;
                product.type = "door";
                product.img = dotaReplaceAssets(product.productImage);
                product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
                product.swapImage = dotaReplaceAssets(product.swapImage);
                product.swapImg = dotaReplaceAssets(product.swapImage);
                product.model = dotaReplaceAssets(product.model);

                garageDoors.push(
                  angular.extend(new constants.RoomObject.Door(), product)
                );
              }
              break;

            default: {
            }
          }
        }
      });

      all = all
        .concat(configurator)
        .concat(akroMils)
        .concat(highCapacityCabinets)
        .concat(toolStorageCabinets)
        .concat(wallCabinets)
        .concat(tables)
        //                .concat(suites)
        .concat(accessories)
        .concat(gardenTools)
        .concat(windows)
        .concat(doors)
        .concat(misc)
        .concat(mro)
        .concat(healthcare)
        .concat(vehicles)
        .concat(stairs)
        .concat(garageDoors)
        .concat(wallPanels);
      var resolve = _.once(function () {
        deferred.resolve();
      });
      resolve();
      $rootScope.$broadcast("Items loaded", null);
    });

    return {
      getProductConfigurator: function () {
        getProductConfigurator();
      },
      configurator: configurator,
      akroMils: akroMils,
      windows: windows,
      doors: doors,
      stairs: stairs,
      mro: mro,
      healthcare: healthcare,
      highCapacityCabinets: highCapacityCabinets,
      toolStorageCabinets: toolStorageCabinets,
      wallCabinets: wallCabinets,
      tables: tables,
      wallPanels: wallPanels,
      gardenTools: gardenTools,
      suites: function () {
        return suites;
      },
      misc: misc,
      vehicles: vehicles,
      garageDoors: garageDoors,
      accessoriesProduct: function () {
        return accessoriesProduct;
      },
      getById: function (id) {
        var i = 0,
          item;
        for (; i < all.length; i++) {
          item = all[i];
          if (item.id === id) return item;
        }
        return null;
      },
      getByIdakroMils: function (id) {
        return akroMils.filter((product) => product.id === id)[0];
      },
      getProductsByProperty: function (property, value) {
        return _.filter(all, function (p) {
          return p[property] === value;
        });
      },
      waitLoad: function () {
        return deferred.promise;
      },
      getAllColorSchemes: function () {
        return allColorSchemes;
      },
    };
  };

  angular
    .module("valleyCraftApp")
    .service("roomStuff", [
      "constants",
      "ResourceService",
      "$http",
      "$rootScope",
      "$q",
      service,
    ]);
})();
