import ComponentBase from '../ComponentBase';
var HideLookBlockWalls = function (dependencyContainer) {
    ComponentBase.prototype.constructor.call(this);

    this._scene3D = dependencyContainer.getService('scene3D');
    this._wallVisibilityManager = dependencyContainer.getService('wallVisibilityManager');
    this._keyState = dependencyContainer.getService('keyState');
};

HideLookBlockWalls.prototype = Object.create(ComponentBase.prototype);

HideLookBlockWalls.prototype.constructor = HideLookBlockWalls;

HideLookBlockWalls.prototype.mouseDown = function () {
    this._walls = this._scene3D.getWalls();
    this._wallsChildren = this._scene3D.getWallsObjects();
};

HideLookBlockWalls.prototype.mouseMove = function () {
    if (this._keyState.isMouseHold) {
        var camera = this._scene3D.getCamera();
        this._wallVisibilityManager.hideLookBlockWalls(this._walls, camera.getWorldDirection(), this._wallsChildren);
    }
};

HideLookBlockWalls.prototype.dispose = function () {
    ComponentBase.prototype.dispose.call(this);
    this._scene3D = this._wallVisibilityManager = this._keyState =
        this._walls = this._wallsChildren = null;
};

export default HideLookBlockWalls;