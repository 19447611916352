import DragBehaviorBase from "./DragBehaviorBase";
import VerticalOffsetValidator from "../Class/VerticalOffsetValidator";
import CollideChecker from "../Class/CollideChecker";
import ObjectInsideRoom from "../Class/ObjectInsideRoom";

var GroupObjectDrag = function (dependencyContainer, handlers) {
    
        DragBehaviorBase.prototype.constructor.call(this, handlers);
    
        this._objectCheckerSrvc = dependencyContainer.getService('objectCheckerSrvc');
    
        this._verticalOffsetValidator = new VerticalOffsetValidator(dependencyContainer);
        this._colliderCheck = new CollideChecker(dependencyContainer);
        this._objectInsideRoom = new ObjectInsideRoom(dependencyContainer);
    };
    
    GroupObjectDrag.prototype = Object.create(DragBehaviorBase.prototype);
    
    GroupObjectDrag.prototype.constructor = GroupObjectDrag;
    
    GroupObjectDrag.prototype.canExecute = function (selected) {
    
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].userData.suiteId &&
                selected[i].userData.groupId &&
                !selected[i].userData.isCornerSuite)
                continue;
            else
                return false;
        }
    
        return true;
    };
    
    GroupObjectDrag.prototype.getDragPlane = function (selected, position) {
    
        var first = selected[0];
    
        this._dragPlane.setFromNormalAndCoplanarPoint(first.getWorldDirection(), position);
    
        return this._dragPlane;
    };
    
    GroupObjectDrag.prototype.drag = function (staticObjects, allDraggedObjects,
        curentlyDraggedObject, oldPos, newPos, dragState) {
    
        var resultingPosition = DragBehaviorBase.prototype.drag.apply(this, arguments) || newPos.sub(dragState.offset);
    
        if (!this._verticalOffsetValidator.check(resultingPosition, curentlyDraggedObject) ||
            !this._colliderCheck.check(resultingPosition, staticObjects, curentlyDraggedObject) ||
            !this._objectInsideRoom.check(resultingPosition)
        ) {
            return null;
        }
        else {
            resultingPosition.y = curentlyDraggedObject.position.y;
            return resultingPosition;
        }
    };
    
    GroupObjectDrag.prototype.dragEnd = function () {
        this._colliderCheck.clearState();
        this._objectInsideRoom.clearState();

        DragBehaviorBase.prototype.dragEnd.call(this);
    };
    
    GroupObjectDrag.prototype.getStaticObjectsFilter = function (draggedObjs) {
        var base = DragBehaviorBase.prototype.getStaticObjectsFilter.call(this, draggedObjs);
        return function (obj) {
            return base(obj) &&
                !this._objectCheckerSrvc.isFloor(obj);
        }.bind(this);
    };

    export default GroupObjectDrag;