import * as THREE from '../libs/three';
;(function () {

    var camera,
        scene,
        navigation,
        canvasWidth,
        canvasHeight;

    var downArrowSprite = THREE.ImageUtils.loadTexture('assets/images/Arrow-DowninCircle-icon.png');
    var homeSprite = THREE.ImageUtils.loadTexture('assets/images/Home-2-2-icon.png');

    var buildNavigationControls = function () {
        var offset = 5,
            width = 40,
            control = new THREE.Object3D(),
            center = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, width),
                new THREE.MeshBasicMaterial({ transparent: true, side: THREE.BackSide, map: homeSprite })),
            topArrow = new THREE.Mesh(new THREE.CircleGeometry(width / 2, 32),
                new THREE.MeshBasicMaterial({ transparent: true, side: THREE.BackSide, map: downArrowSprite })),
            leftArrow = topArrow.clone(),
            rightArrow = topArrow.clone(),
            bottomArrow = topArrow.clone();

        center.name = 'center';

        topArrow.name = 'up';
        topArrow.position.set(0, width + offset, 0);
        topArrow.rotation.z = THREE.Math.degToRad(180);

        leftArrow.name = 'left';
        leftArrow.position.set(width + offset, 0, 0);
        leftArrow.rotation.z = THREE.Math.degToRad(90);

        rightArrow.name = 'right';
        rightArrow.position.set(-width - offset, 0, 0);
        rightArrow.rotation.z = THREE.Math.degToRad(-90);

        bottomArrow.name = 'down';
        bottomArrow.position.set(0, -width - offset, 0);

        control.add(center, topArrow, leftArrow, rightArrow, bottomArrow);
        control.userData.screenOffsetY = width + width / 2 + offset;
        control.rotation.y = Math.PI;
        //control.rotation.x = THREE.Math.degToRad(45);

        return control;
    };

    var positioningControls = function () {
        var screenBottomCenter = new THREE.Vector3(0, -1, 0);
        screenBottomCenter.unproject(camera);
        navigation.position.set(screenBottomCenter.x, screenBottomCenter.y + navigation.userData.screenOffsetY, 0);
    };

    var service = function (sceneHelper, wall3DDataManager) {

        return {

            init: function (width, height) {
                scene = new THREE.Scene();
                camera = new THREE.OrthographicCamera(
                    width / -2, width / 2, height / 2,
                    height / -2, -1000, 1000);

                canvasWidth = width;
                canvasHeight = height;

                navigation = buildNavigationControls();
                scene.add(navigation);
                //navigation.visible = false;

                positioningControls();
            },
            handleNavigation: function (x, y) {

                if (!navigation.visible)
                    return null;

                var ray = sceneHelper.getPickingRay(x, y, canvasWidth, canvasHeight, camera);
                ray.ray.origin.z = 1;

                var intersection = ray.intersectObject(navigation, true),
                    object;
                if (intersection.length > 0) {
                    object = intersection[0].object;
                    return object.name;
                }
                return null;
            },
            handeResize: function (width, height) {
                canvasWidth = width;
                canvasHeight = height;
                camera.left = canvasWidth / -2;
                camera.right = canvasWidth / 2;
                camera.top = canvasHeight / 2;
                camera.bottom = canvasHeight / -2;
                camera.updateProjectionMatrix();

                positioningControls();
            },
            render: function (renderer) {
                renderer.render(scene, camera);
            },
            setControlsVisibility: function (bool) {
                navigation.visible = bool;
            },
            isVisible: function () {
                return navigation.visible;
            }
        }

    };

    angular.module('valleyCraftApp').service('scene3dOverlay', ['sceneHelper', service]);

})();
