import ObjectControlsHandlerBase from './ObjectControlsHandlerBase';

var RotateHandlerBase = function (dependencyContainer, select3D, name) {
    ObjectControlsHandlerBase.prototype.constructor.call(this, 'rotateHandlerBase');

    this.name = name;
};

RotateHandlerBase.prototype = Object.create(ObjectControlsHandlerBase.prototype);

RotateHandlerBase.prototype.constructor = RotateHandlerBase;

export default RotateHandlerBase;