
export const componentPartsOfTheModel = [
  { name: "shelving", parts: ['POST1', 'POST2', 'POST3', 'POST4', '12x24SHELF', '12x24SHELF01', '12x24SHELF02', '12x24SHELF03'] },
  { name: "louveredPanel", parts: [''] },
  { name: "louveredPanelRacks", parts: [''] },
  { name: "railRack", parts: [''] },
];
export function getPartsModel() {
  let value: string[] = [];
  componentPartsOfTheModel.forEach(part => {
    value.push(...part.parts);
  })
  return value;
}
