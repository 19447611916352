(function () {
  "use strict";

  var directive = function () {
    return {
      templateUrl: "./app/views/productsList.html",
      restrict: "E",
      scope: {
        itemClickCallback: "&itemClick",
        step: "@",
      },
      controller: controller,
    };
  };

  var dependencies = [
    "$scope",
    "roomStuff",
    "productDetailDialogSrvc",
    "constants",
    "$http",
  ];

  var controller = function (
    $scope,
    roomStuff,
    productDetailDialogSrvc,
    constants,
    $http
  ) {
    var itemClick = $scope.itemClickCallback();
    var workbenches = getWorkbenches();
    $scope.akroMils = getAkroMils();
    var workTables = getWorkTables();
    var cabinets = getCabinets();
    var wallCabinets = getWallCabinets();
    var wallPanels = getWallPanels();
    //

    $scope.containerId = "productList_" + new Date().getTime();
    $scope.parentContainerId = null;
    $scope.childContainerId = "productList_" + new Date().getTime();

    $scope.areMenuItems = function (items) {
      var areMenuItems =
        Array.isArray(items) &&
        items[0] &&
        items[0].constructor.name === "Object";
      return areMenuItems;
    };

    $scope.isMenuItem = function (item) {
      if (!$scope.parentContainerId) {
        $scope.parentContainerId = "productList_" + new Date().getTime();
      } else {
        $scope.parentContainerId = $scope.childContainerId;
      }
      $scope.childContainerId = "productList_" + new Date().getTime();
      return item && item.constructor.name === "Object";
    };

    function makeid() {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++) {
        result += characters.charAt(Math.floor(Math.random() * 20));
      }
      return result;
    }

    function getAkroMils() {
      var objReturn = {};
      console.log("AkroMils----------------------")
      angular.forEach(roomStuff.akroMils, function (value, key) {
        objReturn[value.dropId] = roomStuff.akroMils.filter((valueFilter) => {
          if (value.dropId === valueFilter.dropId) {
            return value;
          }
        });
      });
      return objReturn;
    }

    function getWorkbenches() {
      var wide24 = _.filter(roomStuff.toolStorageCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/24x/gi));
      });
      var wide48 = _.filter(roomStuff.toolStorageCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/48x/gi));
      });
      var corner = _.filter(roomStuff.toolStorageCabinets, function (item) {
        return item.name.match(new RegExp(/corner/gi));
      });
      var orphants = _.difference(
        roomStuff.toolStorageCabinets,
        [].concat(wide24).concat(wide48).concat(corner)
      );

      if (orphants.length) {
        wide24 = wide24.concat(orphants);
        console.warn("Failed to sort workbench", orphants);
      }
      return { wide24: wide24, wide48: wide48, corner: corner };
    }

    function getWorkTables() {
      var cornerWorkTables = _.filter(roomStuff.tables, function (item) {
        return item.name.match(new RegExp(/corner/gi));
      });
      var workTablesWithDrawers = _.filter(roomStuff.tables, function (item) {
        return item.name.match(new RegExp(/drawers/gi));
      });
      var workTablesWithCasters = _.filter(roomStuff.tables, function (item) {
        return item.name.match(new RegExp(/casters/gi));
      });
      var workTables = _.difference(
        roomStuff.tables,
        []
          .concat(cornerWorkTables)
          .concat(workTablesWithDrawers)
          .concat(workTablesWithCasters)
      );

      return {
        cornerWorkTables: cornerWorkTables,
        workTablesWithDrawers: workTablesWithDrawers,
        workTablesWithCasters: workTablesWithCasters,
        workTables: workTables,
      };
    }

    function getCabinets() {
      var deepDoorCabinets = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name.replace(/\s+/g, "").match(/deepdoorcabinet/gi);
        }
      );
      var electronicLockingCabinets = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name
            .replace(/\s+/g, "")
            .match(/electroniclockingcabinet/gi);
        }
      );
      var toolCabinets = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name.replace(/\s+/g, "").match(/toolcabinet/gi);
        }
      );
      var toolCabinetsWithAccessories = _.filter(
        roomStuff.highCapacityCabinets,
        function (item) {
          return item.name.replace(/\s+/g, "").match(/hookkit/gi);
        }
      );
      toolCabinets = _.difference(toolCabinets, toolCabinetsWithAccessories);
      var cabinets = _.difference(
        roomStuff.highCapacityCabinets,
        []
          .concat(deepDoorCabinets)
          .concat(electronicLockingCabinets)
          .concat(toolCabinets)
          .concat(toolCabinetsWithAccessories)
      );

      return {
        deepDoorCabinets: deepDoorCabinets,
        electronicLockingCabinets: electronicLockingCabinets,
        cabinets: cabinets,
        toolCabinets: {
          toolCabinets: toolCabinets,
          toolCabinetsWithAccessories: toolCabinetsWithAccessories,
        },
      };
    }

    function getWallCabinets() {
      var wide15 = _.filter(roomStuff.wallCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/15x/gi));
      });
      var wide24 = _.filter(roomStuff.wallCabinets, function (item) {
        return item.name.replace(/\s+/g, "").match(new RegExp(/24x/gi));
      });
      var corner = _.filter(roomStuff.wallCabinets, function (item) {
        return item.name.match(new RegExp(/corner/gi));
      });
      var orphants = _.difference(
        roomStuff.wallCabinets,
        [].concat(wide15).concat(wide24).concat(corner)
      );

      if (orphants.length) {
        wide15 = wide15.concat(orphants);
        console.warn("Failed to sort wall cabinets", orphants);
      }

      return {
        wide15: wide15,
        wide24: wide24,
        corner: corner,
      };
    }

    function getWallPanels() {
      var louveredWallPanels = _.filter(roomStuff.wallPanels, function (item) {
        return item.name
          .replace(/\s+/g, "")
          .match(new RegExp(/louveredwallpanel/gi));
      });
      var pegboardWallPanels = _.filter(roomStuff.wallPanels, function (item) {
        return item.name
          .replace(/\s+/g, "")
          .match(new RegExp(/pegboardwallpanel/gi));
      });
      var orphants = _.difference(
        roomStuff.wallPanels,
        [].concat(louveredWallPanels).concat(pegboardWallPanels)
      );

      if (orphants.length) {
        louveredWallPanels = louveredWallPanels.concat(orphants);
        console.warn("Failed to sort wall cabinets", orphants);
      }

      return {
        louveredWallPanels: louveredWallPanels,
        pegboardWallPanels: pegboardWallPanels,
      };
    }

    /**
     * готовим меню для отображения с json
     * @param {*} itemData
     * @returns
     */
    var getMenuItem = function (itemData) {
      itemData.id = makeid();
      if (itemData.itemsName) {
        var objName = $scope[itemData.objName];
        itemData.items = objName[itemData.itemsName];
        return itemData;
      }
      if (itemData.items) {
        angular.forEach(itemData.items, function (value, key) {
          getMenuItem(value);
        });
      }
      return itemData;
    };
    function init() {
      $http.get("assets/json/dropDownMenu.json").then(
        function successCallback(data) {
          angular.forEach(data.data.menu, function (value) {
            getMenuItem(value);
          });

          $scope.data = [
            {
              id: makeid(),
              text: "Akro-Mils/Jamco",
              image: "assets/images/buildingItems.png",
              collapsed: false,
              items: data.data.menu,
            },
            {
              id: makeid(),
              text: "Building Items",
              image: "assets/images/ToolStorage.png",
              collapsed: false,
              items: [
                {
                  id: makeid(),
                  text: "Windows",
                  image: "assets/images/Window.png",
                  items: roomStuff.windows,
                },
                {
                  id: makeid(),
                  text: "Doors - Entry",
                  image: "assets/images/DoorsEntry.png",
                  items: roomStuff.doors,
                },
                {
                  id: makeid(),
                  text: "Doors - Garage",
                  image: "assets/images/DoorsGarage.png",
                  items: roomStuff.garageDoors,
                },
                {
                  id: makeid(),
                  text: "Stairs",
                  image: "assets/images/Stairs.png",
                  items: roomStuff.stairs,
                },
                {
                  id: makeid(),
                  text: "Healthcare",
                  image: "assets/images/Healthcare.png",
                  items: roomStuff.healthcare,
                },
                {
                  id: makeid(),
                  text: "MRO",
                  image: "assets/images/MRO.png",
                  items: roomStuff.mro,
                },
                {
                  id: makeid(),
                  text: "Vehicles",
                  image: "assets/images/Vehicle.png",
                  items: roomStuff.vehicles,
                },
                {
                  id: makeid(),
                  text: "Garden Tools",
                  image: "assets/images/mover-icon.png",
                  items: roomStuff.gardenTools,
                },
                {
                  id: makeid(),
                  text: "Miscellaneous",
                  image: "assets/images/Misc.png",
                  items: roomStuff.misc,
                },
                {
                  id: makeid(),
                  text: "Suites",
                  image: "assets/images/Suite.png",
                  items: roomStuff.suites(),
                },
              ],
            },
          ];
        },
        function errorCallback(response) {
          // Запрос не выполняет код
        }
      );
    }
    /*   function init() {
      $http.get("assets/json/dropDownMenu.json").then(
        function successCallback(data) {
          angular.forEach(data.data.menu, function (value) {
            getMenuItem(value);
          });

          $scope.data = [
            {
              id: makeid(),
              text: "Akro-Mils / Jamco",
              image: "assets/images/buildingItems.png",
              collapsed: false,
              items: data.data.menu,
            },
            {
              id: makeid(),
              text: "Building Items",
              image: "assets/images/ToolStorage.png",
              collapsed: false,
              items: [
                {
                  id: makeid(),
                  text: "Windows",
                  image: "assets/images/Window.png",
                  items: roomStuff.windows,
                },
                {
                  id: makeid(),
                  text: "Doors - Entry",
                  image: "assets/images/DoorsEntry.png",
                  items: roomStuff.doors,
                },
                {
                  id: makeid(),
                  text: "Doors - Garage",
                  image: "assets/images/DoorsGarage.png",
                  items: roomStuff.garageDoors,
                },
                {
                  id: makeid(),
                  text: "Stairs",
                  image: "assets/images/Stairs.png",
                  items: roomStuff.stairs,
                },
                {
                  id: makeid(),
                  text: "Vehicles",
                  image: "assets/images/Vehicle.png",
                  items: roomStuff.vehicles,
                },
                {
                  id: makeid(),
                  text: "Garden Tools",
                  image: "assets/images/mover-icon.png",
                  items: roomStuff.gardenTools,
                },
                {
                  id: makeid(),
                  text: "Misc",
                  image: "assets/images/Misc.png",
                  items: roomStuff.misc,
                },
              ],
            },
            {
              id: makeid(),
              image: "assets/images/ceg-button-logo-2.jpg",
              text: "Products",
              collapsed: false,
              items: [
                {
                  id: makeid(),
                  text: "Workbenches",
                  image: "assets/images/ToolStorage.png",
                  items: [
                    {
                      id: makeid(),
                      text: '24" Wide',
                      items: workbenches.wide24,
                    },
                    {
                      id: makeid(),
                      text: '48" Wide',
                      items: workbenches.wide48,
                    },
                    {
                      id: makeid(),
                      text: 'Corner (36")',
                      items: workbenches.corner,
                    },
                  ],
                },
                {
                  id: makeid(),
                  text: "Work Tables",
                  image: "assets/images/WorkTable.png",
                  items: [
                    {
                      id: makeid(),
                      text: "Work Tables",
                      items: workTables.workTables,
                    },
                    {
                      id: makeid(),
                      text: "Work Tables with Casters",
                      items: workTables.workTablesWithCasters,
                    },
                    {
                      id: makeid(),
                      text: "Work Tables with Drawers",
                      items: workTables.workTablesWithDrawers,
                    },
                    {
                      id: makeid(),
                      text: "Corner Work Tables",
                      items: workTables.cornerWorkTables,
                    },
                  ],
                },
                {
                  id: makeid(),
                  text: "Cabinets",
                  image: "assets/images/highCapacity.png",
                  items: [
                    {
                      id: makeid(),
                      text: "Standard Cabinets",
                      items: cabinets.cabinets,
                    },
                    {
                      id: makeid(),
                      text: "Deep Door Cabinets",
                      items: cabinets.deepDoorCabinets,
                    },
                    {
                      id: makeid(),
                      text: "Electronic Locking Cabinets",
                      items: cabinets.electronicLockingCabinets,
                    },
                    // {
                    //     id: makeid(),
                    //     text: 'Tool Cabinets',
                    //     items: [
                    //         {
                    //             id: makeid(),
                    //             text: 'Tool Cabinets',
                    //             items: cabinets.toolCabinets.toolCabinets
                    //         },
                    //         {
                    //             id: makeid(),
                    //             text: 'Tool Cabinets with Accessories',
                    //             items: cabinets.toolCabinets.toolCabinetsWithAccessories
                    //         }
                    //     ]
                    // }
                    {
                      id: makeid(),
                      text: "Tool Cabinets",
                      items: cabinets.toolCabinets.toolCabinets.concat(
                        cabinets.toolCabinets.toolCabinetsWithAccessories
                      ),
                    },
                  ],
                },
                {
                  id: makeid(),
                  text: "Wall Cabinets",
                  image: "assets/images/WallCab.png",
                  items: [
                    {
                      id: makeid(),
                      text: '15" Wide',
                      items: wallCabinets.wide15,
                    },
                    {
                      id: makeid(),
                      text: '24" Wide',
                      items: wallCabinets.wide24,
                    },
                    {
                      id: makeid(),
                      text: 'Corner (21")',
                      items: wallCabinets.corner,
                    },
                  ],
                },
                {
                  id: makeid(),
                  text: "Wall Panels",
                  image: "assets/images/accessories.png",
                  items: [
                    {
                      id: makeid(),
                      text: "Louvered Wall Panels",
                      items: wallPanels.louveredWallPanels,
                    },
                    {
                      id: makeid(),
                      text: "Pegboard Wall Panels",
                      items: wallPanels.pegboardWallPanels,
                    },
                  ],
                },
                {
                  id: makeid(),
                  text: "Suites",
                  image: "assets/images/Suite.png",
                  items: roomStuff.suites(),
                },
              ],
            },
          ];
        },
        function errorCallback(response) {
          // Запрос не выполняет код
        }
      );
    } */

    // Инициализация
    init();

    $scope.itemClick = function (entity, e) {
      e.preventDefault();
      if (itemClick) {
        itemClick(entity);
      }
    };

    $scope.itemDoubleClick = function (entity) {
      productDetailDialogSrvc.show(entity);
    };
  };

  controller.$inject = dependencies;

  angular.module("valleyCraftApp").directive("productsList", directive);
})();
