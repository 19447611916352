import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { getIntersectionObjectParams } from '../shared/utils/checkIntersection';
import { scene3DService } from '../services/scene3D.service'
import {PreloaderService} from "./preloader.service";

export interface ModelSprite {
    name: string;
    constantPosition?: number;
    position?: THREE.Vector3;
    image: string;
    visible?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class SpriteService {

    raycaster: THREE.Raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2()
    nameObjectControl = 'objectControls';
    _objectControls = new THREE.Object3D();
    constCorrectSpritePosition = 10;
    objectOnWhichIsCalled;

    buttons = [
        {
            name: 'mouseDelete',
            image: new THREE.TextureLoader().load('assets/images/objectControls/delete.png')
        },
        {
            name: 'mouseColorChange',
            image: new THREE.TextureLoader().load('assets/images/objectControls/colors_change.png')
        },
        {
            name: 'mouseAdd',
            image: new THREE.TextureLoader().load('assets/images/objectControls/clone.png')
        },
    ];

    constructor(
      private scene3DService: scene3DService,
    ) {
        this.createObjectControls();
    }

    set objectControls(sprite) {
        this._objectControls.add(sprite);
    }

    get objectControls() {
        return this._objectControls;
    }

    setPositionObjectControls(position) {
        this._objectControls.position.set(position.x - this.constCorrectSpritePosition, position.y, position.z+10);
    }

    setVisibleObjectControls(visible: boolean, object = new THREE.Mesh()) {
      this._objectControls.visible = visible;
      const position = object.position;

      const testObject = new THREE.Mesh(
        object.geometry,
        object.materials
      );
      let testObjectParams = new THREE.Box3().setFromObject(object);
      let correctZPosition = object.side === 'FRONT' ? testObjectParams.max.z + 10 : testObjectParams.min.z - 10
      testObject.position.set(position.x, position.y + 5, correctZPosition)
      const intersectionObjectParams = getIntersectionObjectParams(testObject, this.scene3DService.objects)
      if(intersectionObjectParams) {
        correctZPosition = object.side === 'FRONT' ? intersectionObjectParams.max.z + 10 : intersectionObjectParams.min.z - 10
      }

      this._objectControls.position.set(position.x-this.constCorrectSpritePosition, position.y, correctZPosition);
    }

    createObjectControls() {
        this._objectControls.name = this.nameObjectControl;
        this.buttons.forEach((but, index) => {
            but['constantPosition'] = index !== 0 ? index * this.constCorrectSpritePosition : index;
            this.objectControls = this.createSprite(but);
        })
        this.setVisibleObjectControls(false)
    }


    createSprite(modelSprite) {
        const spritecorrectPosition = modelSprite.constantPosition || 0;
        const spriteMaterial = new THREE.SpriteMaterial({ map: modelSprite.image });
        const sprite = new THREE.Sprite(modelSprite.material || spriteMaterial);
        const position = modelSprite.position || new THREE.Vector3();
        sprite.name = modelSprite.name
        sprite.position.set(position.x + spritecorrectPosition, position.y + this.constCorrectSpritePosition, position.z);
        // sprite.position.set(position.x + spritecorrectPosition, position.y + this.constCorrectSpritePosition, position.z + this.constCorrectSpritePosition);
        sprite.visible = modelSprite.visible || true;
        sprite.scale.set(this.constCorrectSpritePosition, this.constCorrectSpritePosition, this.constCorrectSpritePosition);
        return sprite;
    }

    materialSpriteLoader(pathTexture) {
        const loader = new THREE.TextureLoader();
        const texture = loader.load(pathTexture);
        return texture;
    }

    raycasterIntersectObjectsSprite(camera, domElement, event) {
        this.mouse.x = (event.offsetX / domElement.clientWidth) * 2 - 1;
        this.mouse.y = -(event.offsetY / domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, camera);
        const sprite = this.raycaster.intersectObjects(this._objectControls.children, true)[0]
        if (sprite) {
            return sprite.object;
        }
    }

    raycasterIntersectObjects(scene, camera, domElement, event) {
        this.mouse.x = (event.offsetX / domElement.clientWidth) * 2 - 1;
        this.mouse.y = -(event.offsetY / domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, camera);
        const intersects = this.raycaster.intersectObjects(scene.children, true)[0];
        if (intersects) {
            return intersects.object;
        }
    }

    rayHelper(scene) {
        this.raycaster.ray.direction.set(1, 1, 1);
        const origin = new THREE.Vector3(); // начальная точка луча

        const direction = this.raycaster.ray.direction.clone();
        const length = 100; // длина линии, которая будет отображаться

        const hex = 0xffff00; // цвет линии, можно изменить
        const arrowHelper = new THREE.ArrowHelper(direction, origin, length, hex);
        scene.add(arrowHelper);
    }


}
