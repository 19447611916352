'use strict';
import Detector from '../libs/Detector'
/**
 * @ngdoc directive
 * @name valleyCraftApp.directive:webgl
 * @description
 * # webgl
 */
angular.module('valleyCraftApp').directive('webgl', ['Scene2d', '$window', 'ngDialog', '$rootScope',
 function (Scene2d, $window, ngDialog, $rootScope) {
	return {
		template: '',
		restrict: 'E',
		link: function (scope, element, attrs) {

			if (!Detector.webgl && !Detector.isChecked) {
				//Detector.addGetWebGLMessage();
				scope.dial = ngDialog.open({
					scope: scope,
					disableAnimation: true,
					className: 'ngdialog-theme-default screenshots-popup',
					template: '<div>Your graphics card does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">WebGL</a>.<br/>' +
							'Find out how to get it <a href="http://get.webgl.org/" style="color:#000">here</a>.</div>',
					plain: true
				});
				Detector.isChecked = true;
			}

			var canvas = Scene2d.init(element.width(), element.height(), attrs.step, element);

			Scene2d.render();
			element.append(canvas);

			var itemsLoadedUnsubscribe = $rootScope.$on('Items loaded', function(e, data){
				$rootScope.$broadcast('Scene ready', null);	
			});

			scope.$on('$destroy', function () {
			    itemsLoadedUnsubscribe();
			});
		}
	};
}]);
