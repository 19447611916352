import * as THREE from '../../../../libs/three';

var ObjectInsideRoom = function (dependencyContainer) {
    this._scene3D = dependencyContainer.getService('scene3D');
    this._rc = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -1, 0));
    this._floor = null;
};

ObjectInsideRoom.prototype.constructor = ObjectInsideRoom;

ObjectInsideRoom.prototype.check = function (newPosition) {
    this._floor = this._floor || this._scene3D.getObject('floor');
    this._rc.ray.origin.copy(newPosition);
    if (this._rc.intersectObject(this._floor).length === 0) 
        return false;
    return true;
};

ObjectInsideRoom.prototype.clearState = function () {
    this._floor = null;
};

export default ObjectInsideRoom;