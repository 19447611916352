import EventDispatch from './EventDispatch' 

var ComponentBase = function () {
    EventDispatch.prototype.constructor.call(this);
    this._callBacks = {};
    this._enable = true;
    this._engine = null;
};

ComponentBase.prototype = Object.create(EventDispatch.prototype);

ComponentBase.prototype.constructor = ComponentBase;
    
ComponentBase.prototype.disable = function () {
    this._enable = false;
};

ComponentBase.prototype.enable = function () {
    this._enable = true;
};

//called by engine
ComponentBase.prototype.isEnabled = function () {
    return this._enable;
};

//called by engine on init
ComponentBase.prototype.init = function (engine) {
    this._engine = engine;
};

ComponentBase.prototype.dispose = function () {
    EventDispatch.prototype.dispose.call(this);
    this._callBacks = this._engine  = null;
};
export default  ComponentBase;