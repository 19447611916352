; (function () {
    'use strict';

    var dependencies = ['scene3dOverlay', 'scene3D', 'wallNumberSrvc'];

    var service = function (scene3dOverlay, scene3D, wallNumberSrvc) {

        return {
            take: function () {

                var navigationVisibility = scene3dOverlay.isVisible();
                var wallNumbersVisibility = wallNumberSrvc.isVisible();

                scene3dOverlay.setControlsVisibility(false);
                wallNumberSrvc.setVisibility(false);

                scene3D.render();

                var image = scene3D.toImage();

                scene3dOverlay.setControlsVisibility(navigationVisibility);
                wallNumberSrvc.setVisibility(wallNumbersVisibility);

                return image;
            }
        }

    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('scene3DScreenshotSrvc', service);

})();
