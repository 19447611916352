//var driCoreServices = angular.module('valleyCraftApp.driCoreServices', []);
import dotaReplaceAssets from '../libs/replace';
angular.module("valleyCraftApp.driCoreServices").service("TokenInterceptor", [
  "$q",
  "$location",
  "localStorageService",
  "toastr",
  "$http",
  function ($q, $location, localStorageService, toastr) {
    return {
      request: function (config) {
        config.headers = config.headers || {};

        console.log("config.headers");
        console.log(config.headers);

        if (localStorageService.get("auth_token") !== null)
          config.headers.Authorization =
            "Bearer " + localStorageService.get("auth_token");

        return config;
      },

      response: function (response) {
        return response || $q.when(response);
      },
      responseError: function (response) {
        //console.log('response from TokenInterceptor, response = ', response);

        if (response.config.url !== "/login" && response.status === 401) {
          localStorageService.clearAll();
          $location.path("/login");
          toastr.error(
            "You have to perform signin to earn access to privileged resources!"
          );
        }

        return $q.reject(response);
      },
    };
  },
]);

angular
  .module("valleyCraftApp.driCoreServices")
  .service("AuthenticationService", [
    "localStorageService",
    function (localStorageService) {
      return {
        isLogged: function () {
          var authenticated = false;
          if (localStorageService.get("auth_token") !== null)
            authenticated = true;

          return authenticated;
        },
        userLogged: function () {
          return localStorageService.get("user");
        },
        userId: function () {
          // console.log(localStorageService.get('auth_token'));
          return localStorageService.get("auth_token");
        },
        mail: function () {
          return localStorageService.get("mail");
        },
        publisher: function () {
          return localStorageService.get("publisher") || false;
        },
        role: function () {
          return localStorageService.get("role") || false;
        },
      };
    },
  ]);

/**************************************************************************
 * Set environment values
 *************************************************************************/

angular.module("valleyCraftApp.driCoreServices").service("ResourceService", [
  "$q",
  "$http",
  "$location",
  "AuthenticationService",
  "constants",
  function ($q, $http, $location, AuthenticationService, constants) {
    var _promises = {};
    // var cartUrl = 'https://138.197.30.187';
    var cartUrl = "";
    var cartUrl2 = "https://cegarage.com/wp-json/wc/v3";

    var ck = "ck_8a428d6873321697a0101f3a53bfc3ae39dd7ffe";
    var cs = "cs_f2e0777285eaa912203ed34cadd4f72fc4f248d6";
    //  var apiUrl = 'http://138.197.129.42:9909';
    var apiUrl = constants.API_URL; //'http://dev-demo-plastic-bins.myers3d.com';
    // var apiUrl = 'http://localhost:7000';

    var _genericCallback = function (key, data) {
      console.log(
        "CALL BACK _genericCallback PARAM KEY = ",
        key,
        " DATA = ",
        data
      );
      _promises[key] = data;
    };

    var _promisesGetter = function (method, URL, data, key, refresh) {
      if (!refresh && _promises[key] !== undefined) {
        return $q.when(_promises[key]);
      } else {
        return _ajaxRequest(method, URL, data, key);
      }
    };

    var _ajaxRequest = function (method, URL, data, key) {
      URL = apiUrl + URL;
      var deferred = $q.defer();
      $http({ method: method, url: URL, data: data }).then(
        function (res) {
          // console.log('_ajaxRequest success');
          deferred.resolve(res.data);
          if (URL === "GET") {
            //console.log("_ajaxRequest URL === GET");
            _genericCallback(key, res.data);
          }
        },
        function (data) {
          console.log("_ajaxRequest error");
          deferred.reject(data);
        }
      );
      // .success()
      // .error();
      // console.log("deferred = ", deferred);
      return deferred.promise;
    };

    var _processProductsHikIds = function (prods) {
      if (!prods || !prods.length) {
        return;
      }
      var resultArr = [];

      for (var i = 0; i < prods.length; i++) {
        resultArr.push(prods[i].hikId);
      }

      return resultArr.join(",");
    };

    var _processProductsIds = function (wcProducts, sendProducts) {
      if (!wcProducts || !wcProducts.length) {
        return;
      }
      var resultArr = [];

      for (var i = 0; i < wcProducts.length; i++) {
        var pr = sendProducts.find(function (p) {
          return p.hikId === wcProducts[i].sku;
        });
        if (!pr) {
          continue;
        }

        pr["wcId"] = wcProducts[i].id;

        resultArr.push(wcProducts[i].id);
      }

      return {
        products: sendProducts,
        resIds: resultArr,
      };
    };

    var _postGrouppedProduct = function (idsArr) {
      var query =
        cartUrl2 + "/products?&consumer_key=" + ck + "&consumer_secret=" + cs;
      var body = {
        name: "Gr-" + Date.now(),
        type: "grouped",
        grouped_products: idsArr,
        catalog_visibility: "hidden",
      };

      return $http.post(query, body);
    };

    var _configureGrouppedProductQuantityParam = function (grProd, prdArr) {
      var resParam = [];
      var grProdsIds = grProd.grouped_products;

      if (!grProdsIds || !grProdsIds.length) {
        return;
      }

      for (var i = 0; i < grProdsIds.length; i++) {
        var tempProd = prdArr.find(function (prd) {
          return prd.wcId === grProdsIds[i];
        });
        var tempRes = "quantity[" + grProdsIds[i] + "]=" + tempProd.count;
        resParam.push(tempRes);
      }

      return resParam.join("&");
    };

    var _sendToCart = function (sid, sidName, productData) {
      // var products = [];
      // for (var i = 0; i < productData.length; i++) {
      //     products.push({
      //         hikId: productData[i].hikId,
      //         count: productData[i].quantity
      //     });
      // }
      // var query = cartUrl + '/add_to_cart_from_config.php';
      // var enableAccs = true;

      // return _getProductsByHikIds(products)
      //     .then(function (productsBySkuResponse) {
      //         var productsBySku = productsBySkuResponse.data;
      //         var sendProducts = _processProductsIds(productsBySku, products);

      //         products = sendProducts.products;

      //         return sendProducts.resIds;
      //     })
      //     .then(function (sendProducts) {
      //         return _postGrouppedProduct(sendProducts);
      //     })
      //     .then(function (grouppedProductResult) {
      //         var grouppedProduct = grouppedProductResult.data;
      //         var qParamStr = _configureGrouppedProductQuantityParam(
      //             grouppedProduct,
      //             products
      //         );
      //         var addToCartLink = '?add-to-cart=' + grouppedProduct.id + '&' + qParamStr;
      //         // console.log('addToCartLink', addToCartLink);
      //         var postMessageData = {
      //             messageType: 'ADD_TO_CARD',
      //             link: addToCartLink
      //         };
      //         window.parent.postMessage(postMessageData, '*');
      //     })
      //     .finally(function () {
      //         // console.log('finally');
      //         return Promise.resolve();
      //     });
      return Promise.resolve();
      // refactor, request is not needed
      // return _getAccessoriesPermission().then(function (data) {
      //   if (data.length > 0) {
      //     enableAccs = data[0].enable_accessories;
      //   }
      // })
      //   .finally(function () {
      //     return $http.post(query,
      //       {
      //         cookie: sidName + '=' + sid,
      //         items: products,
      //         enableAccessories: false
      //       },
      //       {
      //         headers: { 'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8" }
      //       })
      //   });
    };

    var _getProductsByHikIds = function (products) {
      var hikIds = _processProductsHikIds(products);
      var query2 =
        cartUrl2 +
        "/products?sku=" +
        hikIds +
        "&per_page=100" +
        "&consumer_key=" +
        ck +
        "&consumer_secret=" +
        cs;

      // console.log('products', products);
      // console.log('skus', skus);

      return $http.get(query2);
      // .then(function (productsBySkuResponse) {
      //   console.log('productsBySku', productsBySkuResponse.data);
      // })
    };

    var _getAccessoriesPermission = function () {
      var deferred = $q.defer();
      var requestUrl = apiUrl + "/admins/accessoriesPermission";
      $http.get(requestUrl).then(
        function (res) {
          deferred.resolve(res.data);
        },
        function (err) {
          deferred.reject(err);
        }
      );
      // .success()
      // .error();
      return deferred.promise;
    };

    var _toggleAccessories = function () {
      // var deferred = $q.defer();
      var requestUrl = apiUrl + "/admins/toggleAccessories";
      return $http.get(requestUrl);
      // .success(function(data){
      //   deferred.resolve(data);
      // })
      // .error(function(err){
      //   deferred.reject(err);
      // });
      // return deferred.promise;
    };

    return {
      signup: function (user) {
        // console.log("SIGNUP!!! client send user data user=", user);
        return _ajaxRequest("POST", "/users/register", user, null);
      },
      login: function (user) {
        //console.log("LOGIN!!! client send user data user=", user);
        return _ajaxRequest("POST", "/users/login", user, null);
      },
      forgotPass: function (data) {
        //console.log("FORGOT PASS!!! client send data =", data);
        return _ajaxRequest("POST", "/users/forgot", data, null);
      },
      resetPass: function (data, id) {
        //console.log("RESET PASS!!!");
        return _ajaxRequest("POST", "/users/reset-password/" + id, data, null);
      },
      getAllusers: function () {
        return _ajaxRequest("get", "/users/get-all-users-data/");
      },
      getAllroom: function () {
        return _ajaxRequest(
          "get",
          "/rooms/rooms/" + AuthenticationService.userId()
        );
      },
      getAllUsersRooms: function (page, pageSize) {
        return _ajaxRequest(
          "get",
          `/rooms/rooms/?page=${page}&pageSize=${pageSize}`
        );
      },
      getAllRoomsBySelectedUser: function (userId) {
        return _ajaxRequest("get", "/rooms/rooms/" + userId);
      },
      getAllTemplates: function () {
        return _ajaxRequest("get", "/templates/templates/");
      },
      getTemplateBySuiteId: function (id) {
        return _ajaxRequest(
          "get",
          "/templates/getTemplateBySuiteId/" + id,
          null,
          null
        );
      },
      getAllShownTemplates: function () {
        return _ajaxRequest("get", "/templates/showTemplates/");
      },
      deleteRoom: function (id) {
        return _ajaxRequest("delete", "/rooms/room/" + id, { _id: id }, null);
      },
      deleteTemplate: function (id) {
        return _ajaxRequest(
          "delete",
          "/templates/template/" + id,
          { _id: id },
          null
        );
      },
      saveSceneJson: function (data) {
        return _ajaxRequest("POST", "assets/configurator/scene", data, null);
      },
      saveRoom: function (data) {
        return _ajaxRequest("POST", "/rooms/room", data, null);
      },
      saveTemplate: function (data) {
        return _ajaxRequest("POST", "/templates/template", data, null);
      },
      saveSuite: function (data) {
        return _ajaxRequest("POST", "/suites/suite", data, null);
      },
      updateSuite: function (id, data) {
        return _ajaxRequest("PUT", "/suites/suite/" + id, data, null);
      },
      updateRoom: function (id, data) {
        return _ajaxRequest(
          "PUT",
          "/rooms/room/" + id,
          { id: id, room: data },
          null
        );
      },
      updateTemplate: function (id, data) {
        return _ajaxRequest(
          "PUT",
          "/templates/template/" + id,
          { id: id, room: data },
          null
        );
      },
      getOne: function (id) {
        return _ajaxRequest("get", "/rooms/room/" + id, null, null);
      },
      getOneTemplate: function (id) {
        return _ajaxRequest("get", "/templates/template/" + id, null, null);
      },
      //getAllUsers: function () {
      //  return _ajaxRequest('get', '/users/all-users', null, null);
      //},
      getUserDataAccount: function (id_user) {
        return _ajaxRequest(
          "get",
          "/users/getdata/" + id_user,
          { id_user: id_user },
          null
        );
      },
      updateUserProfileData: function (id_user, data) {
        return _ajaxRequest(
          "PUT",
          "/users/update/profile/" + id_user,
          { id_user: id_user, data: data },
          null
        );
      },
      deleteUserAccount: function (id_user, data) {
        return _ajaxRequest(
          "PUT",
          "/users/delete/account/" + id_user,
          { id_user: id_user, data: data },
          null
        );
      },
      updateUserAuthData: function (id_user, data) {
        return _ajaxRequest(
          "PUT",
          "/users/update/auth/" + id_user,
          { id_user: id_user, data: data },
          null
        );
      },
      screenShotSaveToUploads: function (img_base64) {
        return _ajaxRequest(
          "POST",
          "/rooms/save-screenshot",
          { image: img_base64 },
          null
        );
      },
      TemplateScreenShotSaveToUploads: function (img_base64) {
        return _ajaxRequest(
          "POST",
          "/templates/save-screenshot",
          { image: img_base64 },
          null
        );
      },
      screenshotDelete: function (id, step, room_id) {
        return _ajaxRequest(
          "delete",
          "/rooms/delete-screenshot/" +
            id +
            `/?step=${step}&room_id=${room_id}`,
          { id: id, step: step, room_id: room_id },
          null
        );
      },
      /*  TemplatesscreenshotDelete: function (id) {
        return _ajaxRequest(
          "delete",
          "/templates/template/" + id,
          { _id: id },
          null
        );
      }, */
      registerAdmin: function (data) {
        return _ajaxRequest("POST", "/admins/register", data, null);
      },
      loginAdmin: function (data) {
        return _ajaxRequest("POST", "/admins/login", data, null);
      },
      saveInLog: function (data) {
        return _ajaxRequest("POST", "/log/action", data, null);
      },
      getallaction: function () {
        return _ajaxRequest("get", "/log/getallaction");
      },
      getTotalAmountValue: function (data) {
        return _ajaxRequest("post", "/rooms/report-amount", data);
      },
      getRoomDataByUser: function (data) {
        return _ajaxRequest("post", "/rooms/get-rooms-by-user", data);
      },
      // not used
      getTotalTemplateAmountValue: function (data) {
        return _ajaxRequest("post", "/templates/report-amount", data);
      },
      getTemplateDataByUser: function (data) {
        return _ajaxRequest("post", "/templates/get-rooms-by-user", data);
      },
      getNewAccouts: function (data) {
        return _ajaxRequest("post", "/log/get-data-report-new-account", data);
      },
      getActivityAcounts: function (data) {
        return _ajaxRequest("post", "/log/get-data-report-activity", data);
      },
      getActivityUsersByPeriod: function (data) {
        return _ajaxRequest("post", "/log/get-activity-users", data);
      },
      getProducts: function () {
        return _ajaxRequest("get", "/products/products");
      },
      getProductConfigurator: function () {
        return _ajaxRequest("get", "/configurator/product/");
      },
      getProductConfiguratorById: function (id) {
        return _ajaxRequest("get", "/configurator/product/" + id);
      },
      getProductById: function (id) {
        return _ajaxRequest("get", "/products/product/" + id);
      },
      getCEGProducts: function () {
        return _ajaxRequest("get", "/products/productsCEG");
      },
      saveProduct: function (data) {
        return _ajaxRequest("post", "/products/product", data, null);
      },
      updateProduct: function (id, data) {
        return _ajaxRequest(
          "PUT",
          "/products/product/" + id,
          { id: id, data: data },
          null
        );
      },
      deleteProduct: function (id) {
        return _ajaxRequest(
          "delete",
          "/products/product/" + id,
          { _id: id },
          null
        );
      },
      getAllMaterials: function () {
        return _ajaxRequest("get", "/materials/materials/");
      },
      saveMaterial: function (data) {
        return _ajaxRequest("post", "/materials/material/", data, null);
      },
      updateMaterial: function (id, data) {
        return _ajaxRequest(
          "PUT",
          "/materials/material/" + id,
          { id: id, data: data },
          null
        );
      },
      deleteMaterial: function (id) {
        return _ajaxRequest(
          "delete",
          "/materials/material/" + id,
          { _id: id },
          null
        );
      },
      getAllSuites: function () {
        return _ajaxRequest("get", "/suites/suites");
      },
      getOneSuite: function (id) {
        return _ajaxRequest("get", "/suites/suite/" + id);
      },
      updateSuite: function (id, data) {
        return _ajaxRequest(
          "PUT",
          "/suites/suite/" + id,
          { id: id, data: data },
          null
        );
      },
      deleteSuite: function (id) {
        return _ajaxRequest("delete", "/suites/suite/" + id, { _id: id }, null);
      },
      deleteSuiteImage: function (images) {
        return _ajaxRequest(
          "delete",
          "/suites/deleteImages",
          { images: images },
          null
        );
      },
      getAllColorSchemes: function () {
        return _ajaxRequest("get", "/colorSchemes/colorSchemes").then(function (data) {
            angular.forEach(data, function (mat) {
                mat.colorSchemeImage = mat?.colorSchemeImage ? dotaReplaceAssets(mat?.colorSchemeImage) : mat?.colorSchemeImage;
                mat.productDetailsImage = mat?.productDetailsImage ? dotaReplaceAssets(mat?.productDetailsImage) : mat?.productDetailsImage;
            });
            return data; 
        }).catch(function (error) {
            console.error("Error fetching color schemes:", error);
            throw error; 
        });
    },
      saveColorScheme: function (data) {
        return _ajaxRequest("post", "/colorSchemes/colorScheme/", data, null);
      },
      updateColorScheme: function (id, data) {
        return _ajaxRequest(
          "PUT",
          "/colorSchemes/colorScheme/" + id,
          { id: id, data: data },
          null
        );
      },
      deleteColorScheme: function (id) {
        return _ajaxRequest(
          "delete",
          "/colorSchemes/colorScheme/" + id,
          { _id: id },
          null
        );
      },
      sendToCart: function (sid, sidName, data) {
        return _sendToCart(sid, sidName, data);
      },
      getAccessoriesPermission: function () {
        return _getAccessoriesPermission();
      },
      toggleAccessories: function () {
        return _toggleAccessories();
      },
      getAccessories: function () {
        return _ajaxRequest("get", "/accessories/accessories");
      },
      createAccessories: function (data) {
        return _ajaxRequest("post", "/accessories/create", data, null);
      },
      updateAccessories: function (id, data) {
        return _ajaxRequest("post", "/accessories/update/" + id, data, null);
      },
      deleteAccessories: function (id) {
        return _ajaxRequest("delete", "/accessories/delete/" + id, null, null);
      },
      createCompany: function (data) {
        return _ajaxRequest("post", "/companies/create", data, null);
      },
      getAllCompanies: function () {
        console.log("_ajaxRequest getAllCompanies");
        return _ajaxRequest("get", "/companies/get-all-companies/");
      },
      deleteCompany: function (id) {
        return _ajaxRequest("delete", "/companies/" + id, null, null);
      },
    };
  },
]);

angular
  .module("valleyCraftApp.driCoreServices")
  .service("CryptoJSService", function () {
    return window.CryptoJS;
  });

angular.module("valleyCraftApp.driCoreServices").service("Resolver", [
  "$q",
  function ($q) {
    return function (promises) {
      return $q.all(promises);
    };
  },
]);
