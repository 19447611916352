import ComponentBase from '../ComponentBase';
var Selection2D = function (dependencyContainer) {
    ComponentBase.prototype.constructor.call(this);
    this._constants = dependencyContainer.getService('constants');
    this._keyState = dependencyContainer.getService('keyState');
    this._Scene2d = dependencyContainer.getService('Scene2d');
    this._step3Helper = dependencyContainer.getService('step3Helper');
    this._roomStuffFactory = dependencyContainer.getService('roomStuffFactory');
    this._toastr = dependencyContainer.getService('toastr');

    this._activeEntity = null;
    this.selectedObject = null;
};

Selection2D.prototype = Object.create(ComponentBase.prototype);

Selection2D.prototype.constructor = Selection2D;

Selection2D.prototype.setSelected = function (obj) {
    //unhighlight old object
    if (this.selectedObject !== obj)
        this._step3Helper.highlightIfDefined(
            this.selectedObject,
            this._constants.HighlightState.DEFAULT
        );

    this._step3Helper.highlightIfDefined(obj, this._constants.HighlightState.SELECTED);

    this.selectedObject = obj;
    this.fireEvent('objectSelect', obj);

    this._Scene2d.render();
};

Selection2D.prototype.pickObject = function (e) {
    var raycaster = this._Scene2d.cameraRay(e.offsetX, e.offsetY);
    raycaster.ray.origin.z = 1000;

    var result = null;

    var objects = this._Scene2d.getEntityObjects();

    var intersected = raycaster.intersectObjects(objects);

    if (intersected.length !== 0) {
        result = intersected.sort(function (a, b) {
            return b.object.renderOrder - a.object.renderOrder;
        })[0];
    }

    return result;
};

Selection2D.prototype.mouseDown = function (e) {
    this.mouseDownTime = new Date().getTime();

    var intersected = this.pickObject(e);

    if (this.selectedObject || !intersected) this.setSelected(null);

    if (intersected) {
        this.setSelected(intersected.object);
    }
};

Selection2D.prototype.dblclick = function (e) {
    var intersected = this.pickObject(e);
    if (intersected) {
        this.fireEvent('objectDoubleClick', intersected.object);
    }
};

Selection2D.prototype.touchStart = function (e) {
    e.preventDefault();

    var boundingRect = this._engine._element.getBoundingClientRect(),
        touch = e.touches[0],
        raycaster = this._Scene2d.cameraRay(
            touch.clientX - boundingRect.left,
            touch.clientY - boundingRect.top
        );

    raycaster.ray.origin.z = 1000;

    var objects = this._Scene2d.getEntityObjects();

    var intersected = raycaster.intersectObjects(objects)[0];

    if (this.selectedObject || !intersected) {
        this.setSelected(null);

        if (this._activeEntity) {
            var obj = this._roomStuffFactory.buildRoomItem(this._activeEntity);
            this._activeEntity = null;
            obj.visible = false;
            this._Scene2d.addModel(obj);
            this.setSelected(obj);
        }
    }

    if (intersected) this.setSelected(intersected.object);
};

Selection2D.prototype.mouseEnter = function () {
    if (this._activeEntity && this._keyState.isMouseHold) {
        var obj = this._roomStuffFactory.buildRoomItem(this._activeEntity);
        obj.visible = false;
        this._Scene2d.addModel(obj);
        this.setSelected(obj);
    }
};

Selection2D.prototype.mouseLeave = function () {
    if (this._keyState.isMouseHold && this.selectedObject) {
        this._Scene2d.remove(this.selectedObject);
        //this._activeEntity = null;
        //this.setSelected(null);
        this._Scene2d.render();
    }
};

Selection2D.prototype.setActiveEntity = function (entity) {
    this._activeEntity = entity;
    this.setSelected(null);
};
export default Selection2D;