import Drag2D from './Drag2D';
import * as THREE from '../../libs/three';

var DragWindow2D = function (dependencyContainer) {
    Drag2D.prototype.constructor.call(this, dependencyContainer);

    this._constants = dependencyContainer.getService('constants');
    this._Scene2d = dependencyContainer.getService('Scene2d');
    this._step3Helper = dependencyContainer.getService('step3Helper');
    this._step3DataSrv = dependencyContainer.getService('step3DataSrv');
    this._obbBuilder = dependencyContainer.getService('obbBuilder');
    this._collisionSrv = dependencyContainer.getService('collisionSrvc');

    this._rayCaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, -1));
    this._lockDrag = false;
};

DragWindow2D.prototype = Object.create(Drag2D.prototype);

DragWindow2D.prototype.constructor = DragWindow2D;

DragWindow2D.prototype.dragging = function (newPosition, draggedObject) {

    var step3Helper = this._step3Helper,
       HighlightState = this._constants.HighlightState;

    //if object on wall , remove it from wall children
    if (draggedObject.parent instanceof THREE.Mesh) {
        this._selectedWall = draggedObject.parent;
        this._lockDrag = true;
        newPosition = draggedObject.localToWorld(new THREE.Vector3());
        this._selectedWall.remove(draggedObject);
        draggedObject.rotation.set(0, 0, this._selectedWall.rotation.y);
        this._Scene2d.addModel(draggedObject);
    }

    this._step3Helper.highlightWallIfDefined(this._selectedWall, HighlightState.DEFAULT);

    if (this._lockDrag) {

        this._rayCaster.ray.origin.copy(newPosition);
        this._rayCaster.ray.origin.z = 500;

        if (this._rayCaster.intersectObject(draggedObject).length === 0) {
            this._lockDrag = false;
            return;
        }

        newPosition = this._step3Helper.mountToWall(newPosition, this._selectedWall);
    }
    else {
        this._selectedWall = this.getIntersectedWall(draggedObject);
    }

    draggedObject.position.copy(newPosition);
    draggedObject.rotation.z = this._selectedWall ? this._selectedWall.rotation.y : draggedObject.rotation.z;

    this._step3Helper.highlightWallIfDefined(this._selectedWall, HighlightState.SELECTED);
};

//DragWindow2D.prototype.isCollide = function (draggedObject, staticObj) {

//    var onWall = true;

//    if (this._selectedWall) {
//        var obb = this._obbBuilder.create()
//                                  .build(draggedObject);
//        onWall = this.isObjectOnWall(this._selectedWall.userData.edgeRays, obb);
//    }

//    return Drag2D.prototype.isCollide.call(this, draggedObject, staticObj) || !onWall;
//};

DragWindow2D.prototype.getStaticObjects = function (draggedObject) {
    var self = this;
    return this._Scene2d.getChildren().filter(function (item) {
        return item !== draggedObject && self.isWallMounted(item.userData.entity)
    });
};

DragWindow2D.prototype.dragEnd = function (draggedObject) {

    if (this._selectedWall) {

        if (this._selectedWall.children.indexOf(draggedObject) === -1) {
            var position = this._step3Helper.mountToWall(draggedObject.position, this._selectedWall);
            if (position && !this._collisionSrv.isCollide(draggedObject, this._staticObjs, {
                draggedObjectPosition: position,
                upAxis: 'z'
            })) {
                draggedObject.position.copy(this._selectedWall.worldToLocal(position));
                draggedObject.rotation.set(THREE.Math.degToRad(-90), 0, 0);
                this._Scene2d.remove(draggedObject);
                this._selectedWall.add(draggedObject);
                draggedObject.userData.wall = this._selectedWall.userData.name;
            }
        }
    }else{
        this._Scene2d.remove(draggedObject);
        this._addObj = false;
    }
};

DragWindow2D.prototype.release = function () {
    this._lockDrag = false;
};

DragWindow2D.prototype.isWallMounted = function (entity) {
    return entity instanceof this._constants.RoomObject.Window ||
        entity instanceof this._constants.RoomObject.Door;
};

DragWindow2D.prototype.isObjectOnWall = function (wallEdgeRays, obb) {
    var i = 0,
        intersection,
        ray;
    for (; i < wallEdgeRays.length; i++) {
        ray = wallEdgeRays[i].ray;
        if (obb.isIntersectionRay(ray))
            return false;
    }
    return true;
};

DragWindow2D.prototype.isEnabled = function () {
    return Drag2D.prototype.isEnabled.call(this) &&
        this.isWallMounted(this.getSelectedObject().userData.entity);
};

export default DragWindow2D;