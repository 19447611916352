/**
 * 
 * @param {String} path 
 * @returns String
 * console.log(dotaReplaceAssets('./patch/imj'));    // Результат: "assets/patch/imj"
console.log(dotaReplaceAssets('../patch/imj'));   // Результат: "assets/patch/imj"
console.log(dotaReplaceAssets('assets/patch/imj')); // Результат: "assets/patch/imj" (без змін)
 */

const dotaReplaceAssets = function (path) {
  const str = path || "";
  if (/assets/i.test(str)) {
    return str; // Повернути рядок без змін, якщо вже містить слово "assets"
  } else {
    const newPath = str.replace(/^(\/)?(\.){0,2}/g, "assets/");
    //const newPath = str.replace(/^(\.){0,2}/g, "assets");
    return newPath; // Додати префікс "assets", якщо відсутнє слово "assets"
  }
};

export default dotaReplaceAssets;
