import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js';
import { scene3DService } from './scene3D.service';
//import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
//import * as THREE from '../core_/libs/three';
declare const v3d: any;
// declare const scene: any;

@Injectable({
  providedIn: 'root',
})
export class ExporterObjMtlService {

  constructor(private scene3DService: scene3DService) {}
    
  exporterModel(modelName) {
    const exporter = new OBJExporter();
    let objString = exporter.parse(this.scene3DService.scene);
   
    objString = `mtllib ${modelName}.mtl\n\t` + objString;
    objString = `#\n# object 30230\n#\n` + objString;
    return objString;
  }
 
  exporterMtl(): string {
    let mtlOutput = '';
    let scene = this.scene3DService.scene; 
    scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        let mat = child.material;        
        if (mat.name !== '') {
          mtlOutput += 'newmtl ' + mat.name + '\n';
        } else {
          mtlOutput += 'newmtl material' + mat.id + '\n';
        }

        mtlOutput += 'Ns 10.0000\n';
        mtlOutput += 'Ni 1.5000\n';
        mtlOutput += 'd 1.0000\n';
        mtlOutput += 'Tr 0.0000\n';
        mtlOutput += 'Tf 1.0000 1.0000 1.0000\n';
        mtlOutput += 'illum 2\n';
        mtlOutput +=
          'Ka ' +
          mat.color.r +
          ' ' +
          mat.color.g +
          ' ' +
          mat.color.b +
          ' ' +
          '\n';
        mtlOutput +=
          'Kd ' +
          mat.color.r +
          ' ' +
          mat.color.g +
          ' ' +
          mat.color.b +
          ' ' +
          '\n';
        mtlOutput += 'Ks 0.0000 0.0000 0.0000\n';
        mtlOutput += 'Ke 0.0000 0.0000 0.0000\n';

        if (mat.map && mat.map instanceof THREE.Texture) {
          /*need fix */
          var file = mat.map.image.currentSrc.slice(
            mat.map.image.currentSrc.slice.lastIndexOf('/'),
            mat.map.image.currentSrc.length - 1
          );

          mtlOutput += 'map_Ka ' + file + '\n';
          mtlOutput += 'map_Kd ' + file + '\n';
        }
      }
    });
    //  console.log(mtlOutput, '-----');
    return mtlOutput;
  }

}
