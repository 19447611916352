'use strict';
angular.module('valleyCraftApp').controller('selectVariantAccessory', selectVariantAccessory);

selectVariantAccessory.$inject = ['$scope'];

function selectVariantAccessory($scope) {
    $scope.selectedAccessory = { selected: null };
    $scope.selectedAccessory.selected = $scope.ngDialogData.selectedAccessory;
    $scope.accessories = $scope.ngDialogData.accessories;

    $scope.selectAccessory = function () {
        var accessoryId = $scope.selectedAccessory.selected;
        var accessory = _.find($scope.accessories, function (a) {
            return (a.accessory_id = accessoryId);
        });
        $scope.closeThisDialog(accessory);
    };

    $scope.close = function () {
        $scope.closeThisDialog();
    };
}
