import * as THREE from 'three';
import constants from '../../core_/services/constants';

export const getIntendBinFromPanel = (name) => {
  // console.log('name', name);
  let intend = 0;
  if(name === '1-0') {
    // console.log('PANEL_INDENT');
    intend = constants.PANEL_INDENT;
  }
  if(name === '30651') {
    // console.log('PANEL_RACK_INDENT_FULL');
    intend = constants.PANEL_RACK_INDENT_FULL;
  }
  if(name === '30661') {
    // console.log('PANEL_RACK_INDENT');
    intend = constants.PANEL_RACK_INDENT;
  }
  if(name === '30653') {
    // console.log('PANEL_RACK_BOTH_SIDES_INDENT');
    intend = constants.PANEL_RACK_BOTH_SIDES_INDENT;
  }
  if(name === '30676' || name === '30658') {
    // console.log('PANEL_RACK_BOTH_SIDES_INDENT');
    intend = constants.PANEL_RACK_SLIM_BOTH_SIDES_INDENT;
  }
  if(name === '30016') {
    // console.log('RAIL_BOTH_SIDES_INDENT');
    intend = constants.RAIL_BOTH_SIDES_INDENT;
  }
  if(name === '30008') {
    // console.log('RAIL_RACK_INDENT');
    intend = constants.RAIL_RACK_INDENT_2;
  }
  if(name === '30632' ) {
    // console.log('RAIL_INDENT');
    intend = constants.RAIL_INDENT_1;
  }
  if(name === '30148') {
    // console.log('RAIL_INDENT');
    intend = constants.RAIL_INDENT_2;
  }
  if(name === '30812') {
    // console.log('RAIL_RACK_BOTH_SIDES_INDENT');
    intend = constants.RAIL_RACK_BOTH_SIDES_INDENT;
  }
  return intend;
}

export const setZObjectPosition = (object, mainObjectBaseName, main_object_coords, scene) => {
  let intendBinFromPanel = getIntendBinFromPanel(mainObjectBaseName)
  let targetObject = object.children[0];
  const newBox = new THREE.Box3().setFromObject(targetObject);
  const itemLength = newBox.max.z - newBox.min.z;
  scene.traverse((child) => {
    if (child.uuid === targetObject.uuid) {
      child.position.z = targetObject.startObjectPosition.z;
      // child.position.z = main_object_coords.min.z + intendBinFromPanel + itemLength / 2;
    }
  });
}

