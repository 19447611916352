import ComponentBase from '../ComponentBase';
import * as THREE from '../../libs/three';

var WallLengthChangeBase = function (dependencyContainer) {
    ComponentBase.prototype.constructor.call(this);

    this._room2DBuilder = dependencyContainer.getService('room2DBuilder');
    this._Scene2d = dependencyContainer.getService('Scene2d');
    this._roomStateManager = dependencyContainer.getService('roomStateManager');
    this._roomSizeManager = dependencyContainer.getService('roomSizeManager');

    this._minWallLength = 20;
};

WallLengthChangeBase.prototype = Object.create(ComponentBase.prototype);

WallLengthChangeBase.prototype.constructor = WallLengthChangeBase;

WallLengthChangeBase.prototype._updateFloor = function () {
    var points = this._roomStateManager.getPoints();
    var floor = this._Scene2d.getFloor();
    this._room2DBuilder.updateFloor(floor, points);
    this._roomSizeManager.updateFloorArea(floor);
};

WallLengthChangeBase.prototype._isWallLengthValid = function (pointA, pointB) {
    var len = pointB.clone().sub(pointA).length();
    return len >= this._minWallLength;
};

WallLengthChangeBase.prototype._linesIntersects = function (lineA, lineB) {
    var ax1 = lineA.start.x,
        ay1 = lineA.start.y,
        ax2 = lineA.end.x,
        ay2 = lineA.end.y,
        bx1 = lineB.start.x,
        by1 = lineB.start.y,
        bx2 = lineB.end.x,
        by2 = lineB.end.y,
        v1 = (bx2 - bx1) * (ay1 - by1) - (by2 - by1) * (ax1 - bx1),
        v2 = (bx2 - bx1) * (ay2 - by1) - (by2 - by1) * (ax2 - bx1),
        v3 = (ax2 - ax1) * (by1 - ay1) - (ay2 - ay1) * (bx1 - ax1),
        v4 = (ax2 - ax1) * (by2 - ay1) - (ay2 - ay1) * (bx2 - ax1),
        v1V2 = v1 * v2,
        v3V4 = v3 * v4;

    return ((v1V2 < 0) && (v3V4 < 0)) || ((v1V2 === 0) && (v3V4 < 0)); 
};

WallLengthChangeBase.prototype._isIntersects = function (A, B, points) {
    var current = { start: A.point, end: B.point },
        count = points.length,
        i = 0,
        startIndex,
        endIndex;

    for (; i < count; i++) {

        startIndex = i;
        endIndex = (i + 1) % count;

        if (startIndex === A.index || startIndex === B.index || endIndex === A.index || endIndex === B.index)
            continue;

        if (this._linesIntersects(current, { start: points[startIndex], end: points[endIndex] }))
            return true;
    }

    return false;
};

WallLengthChangeBase.prototype._containsPoint = function (point, points) {
    var i = 0,
        p;
    for (; i < points.length; i++) {
        p = points[i];
        if (point.x === p.x && point.y === p.y)
            return true;
    }
    return false;
};

WallLengthChangeBase.prototype._hasSameAngle = function (wallA, wallB) {
    var a = THREE.Math.radToDeg(wallA.rotation.y);
    var b = THREE.Math.radToDeg(wallB.rotation.y);
    return b >= a - 3 && b <= a + 3;
    // wallA.rotation.y === wallB.rotation.y;
};

WallLengthChangeBase.prototype._wallObjectsNotOverlap = function (wall, wallLength) {

    var wallChildren = wall.children.filter(function (o) { return o instanceof THREE.Mesh; }),
        wallHalfLen = wallLength / 2,
        wallCenter = new THREE.Vector3(wallHalfLen, 0, 0),
        i = 0,
        pIndex,
        point,
        object,
        objectMatrixWorld,
        entity,
        points;

    wall.updateMatrixWorld();
    wall.localToWorld(wallCenter);

    var boundingSphere = new THREE.Sphere(wallCenter, wallHalfLen);

    for (; i < wallChildren.length; i++) {

        object = wallChildren[i];
        objectMatrixWorld = object.matrixWorld;
        entity = object.userData.entity;

        points = [
            new THREE.Vector3(-entity.length / 2, 0, 0),
            new THREE.Vector3(entity.length / 2, 0, 0)
        ];

        for (pIndex = 0; pIndex < points.length; pIndex++) {
            point = points[pIndex];
            point.applyMatrix4(objectMatrixWorld);
            point.z = 0;
            if (!boundingSphere.containsPoint(point))
                return false;
        }
    }

    return true;
};

export default WallLengthChangeBase;