import * as THREE from "../libs/three";
(function () {
  "use strict";

  var dependencies = [
    "objectCheckerSrvc",
    "collisionSrvc",
    "objectGapHelperSrvc",
    "constants",
    "scene3D",
    "wallCutHelper",
  ];

  var service = function (
    objectCheckerSrvc,
    collisionSrvc,
    objectGapHelperSrvc,
    constants,
    scene3D,
    wallCutHelper
  ) {
    var getObjectPosition = function (selectedObj) {
      if (objectCheckerSrvc.isWallMountable(selectedObj))
        return getWallMountableObjectPosition(selectedObj);
      else if (objectCheckerSrvc.isWallEmbeddable(selectedObj))
        return getWallEmbeddableObjectPosition(selectedObj);
      else return getFloorObjectPosition(selectedObj);
    };

    var getStaticObjects = function (currentObj) {
      var floor = scene3D.getFloor();
      return scene3D.getChildren().filter(function (obj) {
        return (
          obj.visible &&
          obj instanceof THREE.Mesh &&
          obj !== floor &&
          currentObj !== obj
        );
      });
    };

    var getFloorObjectPosition = function (originalObj) {
      originalObj.updateMatrixWorld();

      var bb = new THREE.Box3().setFromObject(originalObj),
        size = bb.size(),
        sceneObjects = getStaticObjects(originalObj),
        entity = originalObj.userData.entity,
        gap = objectGapHelperSrvc.getGapBetweenTwoObjects(entity, entity),
        originalPosition = originalObj.position,
        i = 0,
        positionToTest,
        newPosition,
        positions = false //entity.isWall
          ? [
              new THREE.Vector3(size.x + gap, 0, 0),
              new THREE.Vector3(-size.x - gap, 0, 0),
              new THREE.Vector3(0, 0, size.z + gap),
              new THREE.Vector3(0, 0, -size.z - gap),
            ]
          : !collisionHorizontalWall(originalObj, originalPosition, size, gap)
          ? [
              new THREE.Vector3(size.x + gap, 0, 0),
              new THREE.Vector3(-size.x - gap, 0, 0),
            ]
          : [
              new THREE.Vector3(0, 0, size.z + gap),
              new THREE.Vector3(0, 0, -size.z - gap),
            ];

      for (; i < positions.length; i++) {
        positionToTest = positions[i];
        newPosition = originalPosition.clone().add(positionToTest);        
        if (
          !collisionSrvc.isCollide(originalObj, sceneObjects, {
            draggedObjectPosition: newPosition,
            upAxis: "y",
          })
        ) {
          return newPosition;
        }
      }

      return null;
    };

    const collisionHorizontalWall = function (
      originalObj,
      originalPosition,
      size,
      gap
    ) {
      var allWalls = scene3D.getWalls();

      const vertical = collisionSrvc.isCollide(originalObj, allWalls, {
        draggedObjectPosition: originalPosition
          .clone()
          .add(new THREE.Vector3(size.x + gap, 0, 0)),
        upAxis: "y",
      });

      const horizontal = collisionSrvc.isCollide(originalObj, allWalls, {
        draggedObjectPosition: originalPosition
          .clone()
          .add(new THREE.Vector3(-size.x - gap, 0, 0)),
        upAxis: "y",
      });
      return !!vertical || !!horizontal;
    };
    const checkCatigoriesAkroMils = function (entity) {
      let category = ["0"]; //Shelving
      if (entity.category.toLowerCase() === "akro-mils") {
        if (category.includes(entity.dropId[0])) {
          return false;
        }
      }
      return true;
    };

    var getWallMountableObjectPosition = function (originalObj) {
      originalObj.updateMatrixWorld();

      var entity = originalObj.userData.entity,
        gap = objectGapHelperSrvc.getGapBetweenTwoObjects(entity, entity),
        sceneObjects = getStaticObjects(originalObj),
        originalPosition = originalObj.position,
        i = 0,
        isAboveRoof,
        newPosition,
        positions = [
          new THREE.Vector3(entity.length + gap, 0, 0),
          new THREE.Vector3(-entity.length - gap, 0, 0),
          new THREE.Vector3(0, entity.height + gap, 0),
          new THREE.Vector3(0, -entity.height - gap, 0),
        ];

      for (; i < positions.length; i++) {
        newPosition = positions[i].applyMatrix4(originalObj.matrixWorld);
        isAboveRoof = newPosition.y + entity.height / 2 > constants.wallHeight;
        if (isAboveRoof) continue;
        if (
          !collisionSrvc.isCollide(originalObj, sceneObjects, {
            draggedObjectPosition: newPosition,
            upAxis: "y",
          })
        ) {
          return newPosition;
        }
      }

      return null;
    };

    var getWallEmbeddableObjectPosition = function (originalObj) {
      originalObj.updateMatrixWorld();

      var entity = originalObj.userData.entity,
        gap = objectGapHelperSrvc.getGapBetweenTwoObjects(entity, entity),
        sceneObjects = getStaticObjects(originalObj),
        sceneObjectsWithoutThisWall = sceneObjects.filter(function (obj) {
          return obj.name !== originalObj.userData.wall;
        }),
        originalPosition = originalObj.position,
        i = 0,
        isAboveRoof,
        newPosition,
        positions = [
          new THREE.Vector3(entity.length + gap, 0, 0),
          new THREE.Vector3(-entity.length - gap, 0, 0),
          new THREE.Vector3(0, entity.height + gap, 0),
          new THREE.Vector3(0, -entity.height - gap, 0),
        ];

      for (; i < positions.length; i++) {
        newPosition = positions[i].applyMatrix4(originalObj.matrixWorld);
        isAboveRoof = newPosition.y + entity.height / 2 > constants.wallHeight;
        if (isAboveRoof) continue;
        if (
          !collisionSrvc.isCollide(originalObj, sceneObjectsWithoutThisWall, {
            draggedObjectPosition: newPosition,
            upAxis: "y",
          })
        ) {
          return newPosition;
        }
      }
      return null;
    };

    return {
      clone: function (selectedObj) {
        var position = getObjectPosition(selectedObj);

        if (position) {
          var newObj = selectedObj.clone(),
            entity = selectedObj.userData.entity;

          newObj.material = selectedObj.material.clone();
          newObj.position.copy(position);
          newObj.getObjectByName("selection").visible = false;
          newObj.userData.entity = entity;
          if (selectedObj.userData.wall)
            newObj.userData.wall = selectedObj.userData.wall;

          return newObj;
        }

        return null;
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("object3DCloneSrvc", service);
})();
