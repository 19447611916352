import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { MatDialog } from '@angular/material/dialog';
import { TermsModalComponent } from '../../modals/terms-modal/terms-modal.component';

@Component({
  selector: 'app-login-terms-checkbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-terms-checkbox.component.html',
  styleUrls: ['./login-terms-checkbox.component.scss'],
})
export class LoginTermsCheckboxComponent {
  @Input() accepted!: boolean;
  @Output() checkedChanged = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog) {}

  changeHandler(checked: boolean) {
    this.checkedChanged.emit(checked);
  }

  openDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(TermsModalComponent, {
      width: '800px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
}

angular.module('valleyCraftApp').directive(
  'appLoginTermsCheckbox',
  downgradeComponent({
    component: LoginTermsCheckboxComponent,
  }) as angular.IDirectiveFactory
);
