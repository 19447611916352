import WallObjectHandlerBase from './WallObjectHandlerBase';
import ObjectInWallRangev2 from '../Drag/Class/ObjectInWallRangev2';

var scene3D;

var CornerGroupObjectHandler = function (dependencyContainer) {
    WallObjectHandlerBase.prototype.constructor.call(this, dependencyContainer);
    scene3D = dependencyContainer.getService('scene3D');
    this._inWallRangeChecker = new ObjectInWallRangev2();
};

CornerGroupObjectHandler.prototype = Object.create(WallObjectHandlerBase.prototype);

CornerGroupObjectHandler.prototype.constructor = CornerGroupObjectHandler;

CornerGroupObjectHandler.prototype.canExecute = function (entity) {
    return this._objectCheckerSrvc.isSuite(entity) && entity.isCornerSuite;
};

CornerGroupObjectHandler.prototype.dragBegin = function (intersection, staticObjs) {
    var intersected = this._wallIntersection(intersection);

    if (intersected) {
        var xChange = 1; // interpolated data
        var zChange = 10.6; // interpolated data
        var entity = this._ghostObject.userData.entity;
        var wall = intersected.object;
        var walls = scene3D.getWalls();
        var currentWallIndex = scene3D.getWallIndex(wall);
        var secondWall = walls.find(function (w) {
            nextWallIndex = currentWallIndex === walls.length ? 1 : currentWallIndex + 1;
            return w.name === 'Wall ' + nextWallIndex;
        });

        var wallDirection = wall.getWorldDirection(),
            x = wallDirection.x,
            z = wallDirection.z;
        var wall2Direction = wallDirection.clone();
        wall2Direction.x = 1 * z;
        wall2Direction.z = -1 * x;
        var secondWallPosition = secondWall.position.clone();
        var firstToSecond = secondWallPosition.clone().sub(wall.position.clone());
        var intersectionPoint = intersected.point.clone().sub(wall.position.clone());
        intersectionPoint = intersectionPoint.projectOnVector(firstToSecond);
        intersectionPoint = wall.position.clone().add(intersectionPoint);
        var intersectionLength = Math.pow(
            Math.pow(wall.position.x - intersectionPoint.x, 2) +
                Math.pow(wall.position.z - intersectionPoint.z, 2),
            1 / 2
        );
        var offset = wallDirection.clone().multiplyScalar(entity.width / 2);
        var offset2 = wall2Direction
            .clone()
            .multiplyScalar(
                wall.userData.entity.length - entity.length / 2 - intersectionLength
            );

        intersectionPoint.add(offset).add(offset2);
        intersectionPoint.y = entity.height / 2;

        // if (
        //     this._colliderChecker.check(position, staticObjs, this._ghostObject) &&
        //     this._inWallRangeChecker.check(wall, entity, position, wall.rotation)
        // ) {
        //     this._ghostObject.rotation.copy(wall.rotation);
        //     return position;
        // }

        this._ghostObject.rotation.copy(wall.rotation);
        return intersectionPoint;
    }

    return null;
};

CornerGroupObjectHandler.prototype.hold = true;

CornerGroupObjectHandler.prototype.dragEnd = function () {
    this._colliderChecker.clearState();
};

CornerGroupObjectHandler.prototype.getStaticObjectPredicate = function () {
    var base = AddObjectHandlerBase.prototype.getStaticObjectPredicate.call(this);
    return function (obj) {
        return (
            base(obj) &&
            !this._objectCheckerSrvc.isFloor(obj) &&
            !this._objectCheckerSrvc.isWall(obj)
        );
    }.bind(this);
};

export default CornerGroupObjectHandler;