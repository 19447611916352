import * as THREE from '../../libs/three';
import ComponentBase from '../ComponentBase';
var ZoomCamera = function (dependencyContainer) {
    ComponentBase.prototype.constructor.call(this);

    this._Scene2d = dependencyContainer.getService('Scene2d');

    this._currentZoom = 1;
    this._zoomStep = 0.1;
    this._zoomMax = 5;
    this._zoomMin = 0.1;
};

ZoomCamera.prototype = Object.create(ComponentBase.prototype);

ZoomCamera.prototype.constructor = ZoomCamera;

ZoomCamera.prototype.mouseScroll = function (e) {

    e.preventDefault();

    var cam = this._Scene2d.getCamera();

    this._currentZoom = cam.scale.x;

    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

    var newZoom = delta > 0 ? this._currentZoom - this._zoomStep : this._currentZoom + this._zoomStep;

    if (newZoom >= this._zoomMin && newZoom <= this._zoomMax) {

        var point = this._Scene2d.screenToWorld(e.offsetX, e.offsetY),
            zoomK = newZoom / this._currentZoom,
            matrix = new THREE.Matrix4().makeTranslation(point.x, point.y, 0),
            scale = new THREE.Matrix4().makeScale(zoomK, zoomK, 1),
            translate = new THREE.Matrix4().makeTranslation(-point.x, -point.y, 0);

        this._currentZoom = newZoom;

        matrix.multiply(scale).multiply(translate);

        cam.applyMatrix(matrix);
        cam.updateMatrixWorld();
        cam.updateProjectionMatrix();
        this._Scene2d.render();

        this.fireEvent('zoom', newZoom);
    }
};

ZoomCamera.prototype.zoomInOut = function (direction) {
    var cam = this._Scene2d.getCamera();
    this._currentZoom = cam.scale.x;
    var newZoom = this._currentZoom + this._zoomStep * direction;
    this.setZoom(newZoom);
};

ZoomCamera.prototype.setZoom = function (newZoom) {
    if (newZoom >= this._zoomMin && newZoom <= this._zoomMax) {
        var camera = this._Scene2d.getCamera();
        camera.scale.set(newZoom, newZoom, 1);
        this._Scene2d.render();
        this.fireEvent('zoom', newZoom);
    }
};

ZoomCamera.prototype.fitRoom = function () {

    var floor = this._Scene2d.getFloor();

    if (floor) {

        floor.geometry.computeBoundingSphere();
        floor.geometry.computeBoundingBox();

        var camera = this._Scene2d.getCamera();
        var canvas = this._Scene2d.getCanvas();
        var size = floor.geometry.boundingBox.size();
        var canvasSize = canvas.getBoundingClientRect();
        var zoomW = Math.max(size.x, canvasSize.width) / Math.min(size.x, canvasSize.width);
        var zoomH = Math.max(size.y, canvasSize.height) / Math.min(size.y, canvasSize.height);
        var scale = Math.max(zoomH, zoomW) + 0.3;

        camera.position.copy(floor.geometry.boundingSphere.center);

        this.setZoom(scale);
    }
};

ZoomCamera.prototype.dispose = function () {
    ComponentBase.prototype.dispose.call(this);
    var camera = this._Scene2d.getCamera();
    camera.scale.set(1, 1, 1);
    this._Scene2d = null;
};

export default ZoomCamera;