"use strict";
import * as THREE from "../libs/three";
import Selection2D from "../component/2D/Selection2D";
(function () {
  var directive = function () {
    return {
      templateUrl: "./app/views/steps/step3.html",
      restrict: "E",
      scope: {},
      controller: controller,
    };
  };

  var dependencies = [
    "$rootScope",
    "$scope",
    "roomStuff",
    "Scene2d",
    "roomStuffFactory",
    "step3Helper",
    "constants",
    "roomStateManager",
    "step3DataSrv",
    "engine2DSrvc",
    "productDetailDialogSrvc",
    "objectCheckerSrvc",
  ];

  var controller = function (
    $rootScope,
    $scope,
    roomStuff,
    Scene2d,
    roomStuffFactory,
    step3Helper,
    constants,
    roomStateManager,
    step3DataSrv,
    engine2DSrvc,
    productDetailDialogSrvc,
    objectCheckerSrvc
  ) {
    var step3IsActive = false;

    var selection2d = engine2DSrvc.get().findComponentByType(Selection2D);

    $scope.windows = roomStuff.windows;
    $scope.doors = roomStuff.doors;
    $scope.stairs = roomStuff.stairs;
    // $scope.cabinets = roomStuff.cabinets;

    $scope.delObj = function () {
      var mesh = $scope.selectedMesh;
      mesh.parent.remove(mesh);
      roomStateManager.removeObjectColorScheme(mesh.uuid);
      selection2d.setSelected(null);
      Scene2d.render();
    };

    $scope.cabinets = [];

    $scope.abilities = constants.ItemOption;

    $scope.draggedEntity = null;
    $scope.selectedMesh = null;

    $scope.optionIsVisible = function (optionAbilities) {
      if (this.selectedMesh && this.selectedMesh.userData.entity) {
        var meshAbilities = this.selectedMesh.userData.entity.abilities;
        if (meshAbilities) {
          return meshAbilities.some(function (item) {
            return optionAbilities.indexOf(item) !== -1;
          });
        }
      }
      return false;
    };

    $scope.doorMenuItemClick = function (index) {
      var flip = $scope.selectedMesh.userData.flip,
        door,
        wall = $scope.selectedMesh.parent;

      if (index === 0) flip.x = !flip.x;
      else flip.y = !flip.y;

      $scope.selectedMesh.userData.entity.sprite = undefined;

      door = roomStuffFactory.buildRoomItem(
        $scope.selectedMesh.userData.entity,
        flip
      );

      door.position.copy($scope.selectedMesh.position);
      door.rotation.copy($scope.selectedMesh.rotation);
      door.userData.flip = flip;

      door.userData.wall = wall.userData.name;

      $scope.selectedMesh.userData.flip = null;
      wall.remove($scope.selectedMesh);

      wall.add(door);

      $scope.selectedMesh = door;

      selection2d.setSelected(door);

      Scene2d.render();
    };

    $scope.flipBtnClick = function () {
      var flip = !$scope.selectedMesh.userData.flip,
        stairs = roomStuffFactory.buildRoomItem(
          $scope.selectedMesh.userData.entity,
          flip
        );

      stairs.position.copy($scope.selectedMesh.position);
      stairs.rotation.copy($scope.selectedMesh.rotation);
      Scene2d.addModel(stairs);

      Scene2d.remove($scope.selectedMesh);

      $scope.selectedMesh = stairs;

      selection2d.setSelected(stairs);

      Scene2d.render();
    };

    // $scope.currentItemsSet = roomStuff.windows;
    //$scope.currentItemsSet = null;

    $scope.stuffMenuItems = [
      {
        text: "Windows",
        image: "assets/images/Window.png",
        items: roomStuff.windows,
      },
      {
        text: "Doors - Entry",
        image: "assets/images/DoorsEntry.png",
        items: roomStuff.doors,
      },
      {
        text: "Doors - Garage",
        image: "assets/images/DoorsGarage.png",
        items: roomStuff.garageDoors,
      },
      {
        text: "Stairs",
        image: "assets/images/Stairs.png",
        items: roomStuff.stairs,
      },
      {
        text: "Vehicles",
        image: "assets/images/Vehicle.png",
        items: roomStuff.vehicles,
      },
      {
        text: "Garden Tools",
        image: "assets/images/mover-icon.png",
        items: roomStuff.gardenTools,
      },
      { text: "Misc", image: "assets/images/Misc.png", items: roomStuff.misc },
    ];

    $scope.selectedStuffMenuItem = $scope.stuffMenuItems[0];

    $scope.stuffMenuItemChange = function (item) {
      $scope.draggedEntity = null;
      $scope.selectedStuffMenuItem = item;
      // $scope.currentItemsSet = item.items;
    };

    $scope.rotationAngle = 0;

    $scope.$watch("rotationAngle", function (val) {
      if ($scope.selectedMesh) {
        $scope.selectedMesh.rotation.z = THREE.Math.degToRad(val);
        Scene2d.render();
      }
    });

    $scope.productClick = function (entity) {
      selection2d.setActiveEntity(entity);
    };

    var cleanUp = function () {
      if (step3IsActive) {
        step3IsActive = false;
      }
    };

    var activeStepChangeUnregister = $rootScope.$on(
      "stepActive",
      function (events, step) {
        var points = roomStateManager.getPoints();

        if (step === "step3" && points.length !== 0) {
          if (step3IsActive) return;

          step3IsActive = true;

          $scope.showSideMenu = false;

          step3DataSrv.wallsData = step3Helper.preComputeWallsParameters(
            Scene2d.getWalls()
          );
          step3DataSrv.roomPolygonPoints = step3Helper.getRoomPolygon(
            points,
            constants.wallWidth
          );
        } else cleanUp();
      }
    );

    var routeChangeUnregister = $rootScope.$on("$routeChangeStart", cleanUp);

    $scope.itemClick = function (entity) {
      selection2d.setActiveEntity(entity);
    };

    var selectedObjectUnreg = selection2d.on("objectSelect", function (item) {
      if (item && item.userData.suiteId) item = null;
      $scope.selectedMesh = item;
      var entity = item ? item.userData.entity : null;
      if (entity && entity.color_scheme) {
        roomStateManager.saveObjectColorScheme({
          objectId: item.uuid,
          entityId: entity.id,
          scheme: entity.color_scheme[0] ? entity.color_scheme[0] : '',
          updateIfExist: false,
        });
      }
      $scope.showSideMenu = entity || false;
      $scope.rotationAngle = item ? THREE.Math.radToDeg(item.rotation.z) : 0;
      $scope.$applyAsync();
    });

    var dblClickedOdjectUnreg = selection2d.on(
      "objectDoubleClick",
      function (item) {
        if (!objectCheckerSrvc.isGrouppedSuite(item)) {
          productDetailDialogSrvc.show(item.userData.entity, item, "2d");
        }
      }
    );

    $scope.$on("$destroy", function () {
      engine2DSrvc.dispose();
      activeStepChangeUnregister();
      routeChangeUnregister();
      selectedObjectUnreg();
      dblClickedOdjectUnreg();
    });
  };

  controller.$inject = dependencies;

  angular.module("valleyCraftApp").directive("step3", directive);
})();
