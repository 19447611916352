import * as THREE from "../libs/three";

var sc3d;

(function () {
  "use strict";

  var dependencies = [
    "$window",
    "sceneHelper",
    "scene3dOverlay",
    "geometryHelper",
    "wallVisibilityManager",
    "roomBuilder",
    "constants",
    "wall3DDataManager",
    "orbitControl",
    "wallNumberSrvc",
    "objectCheckerSrvc",
    "roomStuff",
  ];

  var service = function (
    $window,
    sceneHelper,
    scene3dOverlay,
    geometryHelper,
    wallVisibilityManager,
    roomBuilder,
    constants,
    wall3DDataManager,
    orbitControl,
    wallNumberSrvc,
    objectCheckerSrvc,
    roomStuff
  ) {
    var scene,
      camera,
      renderer,
      aspect,
      elementWidth,
      elementHeight,
      canvas,
      destroyed = true,
      roomBoundingSphere,
      originalWalls,
      boundingRectangle;

    var render = function () {
      renderer.clear();
      renderer.render(scene, camera);
      renderer.clearDepth();
      scene3dOverlay.render(renderer);
    };

    var draw = function () {
      if (destroyed) return;
      requestAnimationFrame(draw);
      render();
    };

    var getPickingRay = function (x, y, rayCaster) {
      return sceneHelper.getPickingRay(
        x,
        y,
        elementWidth,
        elementHeight,
        camera,
        rayCaster
      );
    };

    var onWindowResize = function () {
      if (destroyed) return;

      var parent = canvas.parentNode;

      elementWidth = parent.clientWidth;
      elementHeight = parent.clientHeight;
      aspect = elementWidth / elementHeight;

      camera.aspect = aspect;
      camera.updateProjectionMatrix();

      scene3dOverlay.handeResize(elementWidth, elementHeight);

      renderer.setSize(elementWidth, elementHeight);

      boundingRectangle = parent.getBoundingClientRect();
    };

    var getChildrenByFilter = function (filterFn) {
      return scene.children.filter(filterFn);
    };

    var getWalls = function () {
      var entity;
      return getChildrenByFilter(function (item) {
        entity = item.userData.entity;
        return entity && entity.isWall;
      });
    };

    var getWallIndex = function (wall) {
      var regexp = new RegExp(/Wall (\d)+/);
      var index = null;
      var result = regexp.exec(wall.name);
      if (result) {
        index = parseInt(result[1], 10);
      }
      return index;
    };

    var getWallsObjects = function () {
      return getChildrenByFilter(function (item) {
        return item.userData.wall;
      });
    };

    var centralizeCamera = function () {
      var roomCenter = roomBoundingSphere.center,
        distance = Math.abs(
          roomBoundingSphere.radius / Math.sin(camera.fov / 2)
        );
      camera.position.copy(roomCenter);
      camera.position.y += distance;
      orbitControl.setTarget(roomCenter);
      camera.updateMatrixWorld();
      var walls = getWalls();
      var wallsObjects = getWallsObjects();
      wallVisibilityManager.reset(walls, wallsObjects);
    };

    var roomBuildCallBack = function (result) {
      originalWalls = result.originalWalls;

      this.add(result.floor);
      this.add(result.roomObjects);
      this.add(result.cuttedWalls);
      this.add(wallNumberSrvc.get(originalWalls));

      roomBoundingSphere =
        geometryHelper.computeBoundingSphereFromMeshes(originalWalls);
      centralizeCamera();

      destroyed = false;
      draw();
      this.resize();
    };

    return {
      init: function (width, height) {
        if (!renderer) {
          scene = new THREE.Scene();
          scene.name = "3D";

          aspect = width / height;

          wall3DDataManager.cameraFov = 45;

          camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 5000);

          elementWidth = width;
          elementHeight = height;

          renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
          }); ////  preserveDrawingBuffer   : true   // required to support .toDataURL()
          renderer.setSize(width, height);
          renderer.autoClear = false;

          canvas = renderer.domElement;

          var light = new THREE.HemisphereLight(0xffffff, 0xc0c0c0, 1.04);
          light.position.set(0, 1, 0);
          scene.add(light);

          orbitControl.init(camera, canvas);

          sc3d = scene;

          scene3dOverlay.init(width, height);

          $window.addEventListener("resize", onWindowResize, false);
        }

        return canvas;
      },
      add: function () {
        if (arguments.length > 0) {
          var firstArgument = arguments[0];

          if (angular.isArray(firstArgument)) {
            if (firstArgument.length > 0) scene.add.apply(scene, firstArgument);
            return;
          }

          if (firstArgument) scene.add(firstArgument);
        }
      },
      remove: function () {
        scene.remove.apply(scene, arguments);
        if (arguments.length === 1) {
          var obj = arguments[0];
          if (obj.parent && !(obj.parent instanceof THREE.Scene))
            obj.parent.remove(obj);
        }
      },
      getObject: function (name) {
        return scene.getObjectByName(name);
      },
      getObjectById: function (id) {
        return scene.getObjectById(id);
      },
      dispose: function () {
        destroyed = true;
        var children = scene.children,
          count = scene.children.length,
          i = count - 1,
          item;

        for (; i >= 0; i--) {
          item = children[i];

          if (!item) continue;

          if (item.userData.keepOnScene === false || item instanceof THREE.Mesh)
            scene.remove(item);
        }

        orbitControl.enable();
        wallNumberSrvc.clear();

        originalWalls = roomBoundingSphere = null;
        wall3DDataManager.clearAll();
      },
      getPickingRay: getPickingRay,
      resize: onWindowResize,
      isDestroyed: function () {
        return destroyed;
      },
      draw: function (roomData, objects) {
        roomBuilder
          .buildRoom(roomData, objects)
          .then(angular.bind(this, roomBuildCallBack));
      },
      hideCars: function (val) {
        angular.forEach(scene.children, function (obj) {
          if (obj.userData.entity) {
            if (obj.userData.entity.type === "vehicle") {
              obj.visible = !val;
            }
          }
        });
      },
      focusOnWall: function (wallName) {
        var wallData = wall3DDataManager.getScrollData(wallName);
        camera.position.copy(
          wallData.center.clone().add(wallData.currentDistanceFromWallToCamera)
        );
        camera.lookAt(wallData.center);
        camera.updateMatrixWorld();
        var walls = this.getWalls();
        var wallsObjects = this.getWallsObjects();
        wallVisibilityManager.reset(walls, wallsObjects);
        wallVisibilityManager.hideLookBlockWalls(
          walls,
          camera.getWorldDirection(),
          wallsObjects
        );
        orbitControl.setTarget(wallData.center);
      },
      getWallsObjects: getWallsObjects,
      getAspect: function () {
        return aspect;
      },
      getCameraFOV: function () {
        return camera.fov;
      },
      centralizeCamera: centralizeCamera,
      getChildren: function () {
        return scene.children;
      },
      exportChildren: function () {
        const sceneJson = scene.toJSON();
        return sceneJson;
      },
      getHtmlElement: function () {
        return canvas;
      },
      getCamera: function () {
        return camera;
      },
      getWalls: getWalls,
      getWallIndex: getWallIndex,
      getNonCuttedWallByName: function (name) {
        for (var i = 0; i < originalWalls.length; i++) {
          if (originalWalls[i].name === name) return originalWalls[i];
        }
      },
      getWallChildren: function (wallName) {
        return getChildrenByFilter(function (item) {
          return item.userData.wall === wallName;
        });
      },
      filterChildren: getChildrenByFilter,
      getFloor: function () {
        return scene.getObjectByName("floor");
      },
      replace: function (oldObj, newObj) {
        this.remove(oldObj);
        this.add(newObj);
      },
      render: render,
      toImage: function () {
        return renderer.domElement.toDataURL("image/png");
      },
      getSuitePositionForPublish: function () {
        var result = [];

        scene.traverse(function (obj) {
          if (obj.userData.entity && obj.userData.entity.left_menu_alias)
            result.push(obj);
        });
        var bb = geometryHelper.objectsBoundingBox(result);
        var position = bb.center();
        return {
          x: position.x,
          y: position.y,
          z: position.z,
        };
      },
      getSuitesSize: function () {
        var chldrn = this.getChildren().filter(function (item) {
            return (
              item.userData.entity && !objectCheckerSrvc.isWallOrFloor(item)
            );
          }),
          suiteSize = new THREE.Vector3(),
          leftSide = 5000,
          rightSide = -5000,
          backSide = 5000,
          frontSide = -5000,
          bottomSide = 1000,
          topSide = -1000,
          i = 0,
          obj,
          entity,
          length,
          
          width;
        for (; i < chldrn.length; i++) {
          obj = chldrn[i];
          entity = obj.userData.entity;
          length = entity.length;
          width = entity.width;
          if (
            (obj.rotation._y > Math.PI / 2 - 0.1 &&
              obj.rotation._y < Math.PI / 2 + 0.1) ||
            (obj.rotation._y > -Math.PI / 2 - 0.1 &&
              obj.rotation._y < -Math.PI / 2 + 0.1)
          ) {
            width = entity.length;
            length = entity.width;
          }
          if (obj.position.x - length / 2 < leftSide)
            leftSide = obj.position.x - length / 2;
          if (obj.position.x + length / 2 > rightSide)
            rightSide = obj.position.x + length / 2;
          if (obj.position.y - entity.height / 2 < bottomSide)
            bottomSide = obj.position.y - entity.height / 2;
          if (obj.position.y + entity.height / 2 > topSide)
            topSide = obj.position.y + entity.height / 2;
          if (obj.position.z - width / 2 < backSide)
            backSide = obj.position.z - width / 2;
          if (obj.position.z + width / 2 > frontSide)
            frontSide = obj.position.z + width / 2;
        }
        suiteSize.set(
          rightSide - leftSide,
          topSide - bottomSide,
          frontSide - backSide
        );
        return suiteSize;
      },
      getSuitableWalls: function (length, width) {
        var walls = this.getChildren().filter(function (item) {
            return objectCheckerSrvc.isWall(item);
          }),
          usableWallLength,
          i = 0,
          j = 0,
          suitableWalls = [],
          leftSide = [],
          w0,
          w1,
          w2;

        for (; i < walls.length; i++) {
          w0 = walls[i];
          w1 = i > 0 ? walls[i - 1] : walls[i - 1 + walls.length];
          w2 = walls[(i + 1) % walls.length];
          usableWallLength = w0.userData.entity.length;
          leftSide[i] = 0;

          if (
            (Math.PI - (w0.rotation._y - w2.rotation._y)) % (Math.PI * 2) <
            Math.PI / 2
          )
            usableWallLength -=
              (1 /
                Math.tan(
                  (Math.PI - (w0.rotation._y - w2.rotation._y)) % (Math.PI * 2)
                )) *
              width;
          if (
            (Math.PI - (w1.rotation._y - w0.rotation._y)) % (Math.PI * 2) <
            Math.PI / 2
          ) {
            usableWallLength -=
              (1 /
                Math.tan(
                  (Math.PI - (w1.rotation._y - w0.rotation._y)) % (Math.PI * 2)
                )) *
              width;
            leftSide[i] =
              (1 /
                Math.tan(
                  (Math.PI - (w1.rotation._y - w0.rotation._y)) % (Math.PI * 2)
                )) *
              width;
          }
          if (usableWallLength > length) {
            suitableWalls[j] = { wall: walls[i], leftSideStep: leftSide[i] };
            j++;
          }
        }

        return suitableWalls;
      },
      getSuitesNewPosition: function (entity, box, index, isCornerSuite) {
        var i = 0;
        var objects = [];
        var currentLeftSide;
        var currentBackSide;
        var originalLeftSide = 5000;
        var originalBackSide = -5000;
        var position = new THREE.Vector3(
          0,
          entity.objects[index].position.z,
          0
        );
        var obj1;
        var angle = box.rotation._y;
        var sn = Math.sin(angle);
        var cs = Math.cos(angle);
        var x;
        var z;

        for (; i < entity.objects.length; i++) {
          obj1 = roomStuff.getById(entity.objects[i].id);
          objects[i] = new THREE.Object3D();
          objects[i].position.set(
            entity.objects[i].position.x,
            entity.objects[i].position.z,
            entity.objects[i].position.y
          );

          if (objects[i].position.x - obj1.length / 2 < originalLeftSide) {
            originalLeftSide = objects[i].position.x - obj1.length / 2;
          }

          if (objects[i].position.z + obj1.width / 2 > originalBackSide) {
            originalBackSide = objects[i].position.z + obj1.width / 2;
          }
        }

        currentLeftSide = box.position.x - box.userData.entity.length / 2;
        currentBackSide = box.position.z + box.userData.entity.width / 2;

        position.x =
          currentLeftSide + entity.objects[index].position.x - originalLeftSide;
        position.z =
          currentBackSide -
          (originalBackSide - entity.objects[index].position.y);

        x = position.x - box.position.x;
        z = position.z - box.position.z;

        if (isCornerSuite) {
          if (Math.abs(Math.round(angle * 100) / 100) === 1.57) {
            position.x = -cs * x - sn * z + box.position.x;
            position.z = -sn * x + cs * z + box.position.z;
          } else {
            position.x = cs * x - sn * z + box.position.x;
            position.z = sn * x - cs * z + box.position.z;
          }
          return position;
        } else {
          var toRotateAngle = angle + Math.PI;
          if (Math.round(Math.abs(entity.objects[index].rotation._z)) > 0)
            toRotateAngle = angle;
          return box.position
            .clone()
            .add(
              new THREE.Vector3(x, 0, z).applyAxisAngle(
                new THREE.Vector3(0, 1, 0),
                toRotateAngle
              )
            )
            .setY(entity.objects[index].position.z);
        }
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("scene3D", service);
})();
