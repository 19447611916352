(function () {

    var directive = function () {
        return {
            template: '<div class="checkbox"><label><input type="checkbox" ng-model="carsHidden" ng-change="changed(carsHidden)">Hide cars</label></div>',
            restrict: 'E',
            replace: true,
            scope: true,
            controller: ['$scope', 'scene3D', '$rootScope', function ($scope, scene3D, $rootScope) {

                $scope.changed = function (val) {
                    scene3D.hideCars(val);
                    $rootScope.$emit('hideCarsChange', val);
                };

                var wallNumbersChangeUnregister = $rootScope.$on('hideCarsChange', function (event, val) {
                    $scope.carsHidden = val;
                });

                $scope.$on('$destroy', function () {
                    wallNumbersChangeUnregister();
                });
            }]
        }
    };

    angular.module('valleyCraftApp').directive('hideCarsCheckBox', directive);
})();