<header class="w-full h-full flex justify-between items-center">
  <div class="logo">
    <img src="assets/img/logo.jpg" class="h-16 w-auto" />
  </div>
  <div class="flex gap-4">
    <button
      matRipple
      class="bg-gray-400 hover:bg-gray-500 rounded-full w-12 h-12 text-white flex justify-center items-center"
    >
      <mat-icon>question_mark</mat-icon>
    </button>
    <button
      matRipple
      class="bg-white border border-gray-500 p4 rounded h-12 w-64"
    >
      Login
    </button>
  </div>
</header>
