'use strict';
import '../../app.module.ajs';
angular.module('valleyCraftApp').controller('AccessoriesController', initCtrl);

initCtrl.$inject = [
    '$scope',
    'ResourceService',
    'toastr',
    'Upload',
    'AuthenticationService',
    '$rootScope',
    '$q',
    'constants',
];

function initCtrl($scope, ResourceService, toastr, Upload, AuthenticationService, $rootScope, $q, constants) {
    var userName = AuthenticationService.userLogged();
  //  var apiUrl = 'http://138.197.129.42:9909';
  var apiUrl = constants.API_URL; //'http://dev-demo-plastic-bins.myers3d.com';
    // var apiUrl = 'http://localhost:7000';

    if (userName) {
        if (AuthenticationService.publisher()) {
            $rootScope.templateMaster = true;
        } else {
            $rootScope.templateMaster = false;
        }
        $rootScope.userLogged = true;
        $rootScope.labelUserLogged = userName.firstName + ' ' + userName.lastName;
    }
    $scope.filesForUpload;
    $scope.accessories = [];

    $scope.accessoriesModel = {
        name: '',
        description: '',
        category: 'Accessories',
        base_model_name: '',
        type_surface: null,
        weight_units: '',
        weight: null,
        dimension_units: '',
        width: null,
        length: null,
        height: null,
        msrp: null,
        hikashop_id: null,
        preview_img: null,
        alias: null
    };

    $scope.clearForm = function () {
        $scope.accessoriesModel = {
            name: '',
            description: '',
            category: 'Accessories',
            base_model_name: '',
            type_surface: null,
            weight_units: '',
            weight: null,
            dimension_units: '',
            width: null,
            length: null,
            height: null,
            msrp: null,
            hikashop_id: null,
            preview_img: null,
            alias: null
        };
        $scope.filesForUpload = null;
    };

    $scope.addFilesForUpload = function (file, imageFolder, newName, id) {
        var data = {
            file: file,
            imageFolder: imageFolder,
            newName: newName || file.name,
            id: id || null
        };
        $scope.filesForUpload = data;
    };

    function uploadFiles(instance) {
        if ($scope.filesForUpload) {
            $scope.filesForUpload.id = instance._id;
            return Upload.upload({
                url: apiUrl + '/accessories/upload',
                data: $scope.filesForUpload
            });
        } else {
            var deferred = $q.defer();
            deferred.resolve(true);
            return deferred.promise;
        }
    }

    $scope.publishModel = function () {
        var instance = $scope.accessoriesModel;
        if (!instance.hasOwnProperty('_id')) {
            // Create new Instance
            ResourceService.createAccessories(instance)
                .then(uploadFiles)
                .then(function (response) {
                    console.log(response);
                    toastr.success('Model published');
                    $scope.clearForm();
                    getAccessories();
                })
                .catch(function (err) {
                    console.log('Create Accessories instance error - ', err);
                    toastr.error(err);
                });
        } else {
            // Update instance
            ResourceService.updateAccessories(instance._id, instance)
                .then(function (response) {
                    console.log('Successful updateAccessories');
                    uploadFiles(instance).then(function (response) {
                        toastr.success('Model published');
                        $scope.clearForm();
                        getAccessories();
                    });
                })
                .catch(function (err) {
                    console.log('Create Accessories instance error - ', err);
                    toastr.error(err);
                });
        }
    };

    $scope._edit = function (model) {
        $scope.accessoriesModel = model;
    };

    $scope._delete = function (model) {
        ResourceService.deleteAccessories(model._id)
            .then(function (response) {
                console.log('Successful delete Accessory');
                toastr.success('Accessory deleted!');
                $scope.clearForm();
                getAccessories();
            })
            .catch(function (err) {
                console.log('Delete Accessory instance error - ', err);
                toastr.error(err);
            });
    };

    function getAccessories() {
        ResourceService.getAccessories()
            .then(function (result) {
                $scope.accessories = result;
            })
            .catch(function (err) {
                toastr.error(err);
            });
    }
    getAccessories();
}
