import * as THREE from "../libs/three";
(function () {
  var windowSprite = THREE.ImageUtils.loadTexture(
    "assets/images/window2DSprite.png"
  );
  var doorLeftSprite = THREE.ImageUtils.loadTexture(
    "assets/images/doorLeftSprite.png"
  );
  var doorRightSprite = THREE.ImageUtils.loadTexture(
    "assets/images/doorRightSprite.png"
  );
  var doorDouble = THREE.ImageUtils.loadTexture(
    "assets/images/doorDoubleSprite.png"
  );

  var dependencies = ["geometryHelper", "constants", "resourceLoader"];

  var service = function (geometryHelper, constants, resourceLoader) {
    var setSprite = function (mesh, sprite) {
      if (!sprite) return;

      if (sprite instanceof THREE.Texture) {
        sprite.anisotropy = constants.maxAnisotropy;
        mesh.material.map = sprite;
        sprite.needsUpdate = true;
        mesh.material.needsUpdate = true;
        return;
      }

      resourceLoader.load(sprite).then(function (img) {
        var texture = new THREE.Texture(img[0]);
        texture.anisotropy = constants.maxAnisotropy;
        texture.needsUpdate = true;
        mesh.material.map = texture;
        mesh.material.needsUpdate = true;
      });
    };

    var buildBorder = function (width, height) {
      var geometry = new THREE.EdgesGeometry(
          new THREE.PlaneBufferGeometry(width, height)
        ),
        material = new THREE.LineBasicMaterial({
          color: 0x000000,
          linewidth: 1,
          depthTest: false,
        }),
        border = new THREE.LineSegments(geometry, material);

      //border.position.z = 1;
      border.name = "border";
      border.visible = false;
      return border;
    };

    var buildDoor = function (entity, flip) {
      flip = flip || {
        x: false,
        y: false,
      };

      var length = entity.length,
        width = entity.width,
        halfOfLength = length / 2,
        halfOfWidth = width / 2,
        doorHole,
        border = buildBorder(length, width);

      if (entity.hasDoors === false) {
        doorHole = new THREE.Mesh(
          new THREE.PlaneBufferGeometry(length, width),
          new THREE.MeshBasicMaterial({
            depthTest: false,
          })
        );

        setSprite(doorHole, entity.sprite);
      } else {
        doorHole = new THREE.Mesh(
          new THREE.PlaneBufferGeometry(length, width),
          new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
          })
        );

        var mainGeometry = new THREE.PlaneBufferGeometry(
            length,
            entity.isDouble ? halfOfLength : length
          ),
          offset = entity.isDouble
            ? -halfOfLength / 2 + halfOfWidth
            : -halfOfLength + halfOfWidth,
          sprite =
            entity.sprite ||
            (entity.isDouble
              ? doorDouble //TODO
              : flip.x
              ? doorLeftSprite
              : doorRightSprite) ||
            null,
          mainMesh = new THREE.Mesh(
            mainGeometry,
            new THREE.MeshBasicMaterial({
              transparent: true,
              depthTest: false,
              side: THREE.DoubleSide,
            })
          );

        setSprite(mainMesh, sprite);

        mainMesh.position.set(0, flip.y ? -offset : offset, 0);
        mainMesh.rotation.x = flip.y ? Math.PI : 0;
        doorHole.add(mainMesh);

        //used to create wider bounding box for wall intersection
        doorHole.userData.boundingBox = new THREE.Box3().setFromCenterAndSize(
          new THREE.Vector3(0, flip.y ? -offset : offset, 0),
          new THREE.Vector3(
            length,
            entity.isDouble ? halfOfLength : length,
            entity.height
          )
        );
      }

      doorHole.add(border);
      doorHole.userData.flip = flip;
      return doorHole;
    };

    var create2DRectangle = function (entity, sprite) {
      var width = entity.length,
        height = entity.width,
        border = buildBorder(width, height),
        main = new THREE.Mesh(
          new THREE.PlaneBufferGeometry(width, height),
          new THREE.MeshBasicMaterial({
            color: 0xffffff,
            depthTest: false,
            transparent: false,
          })
        );

      border.renderOrder = 1;
      main.add(border);

      setSprite(main, sprite);

      return main;
    };

    return {
      buildRoomItem: function (entity, extra) {
        var object,
          floorOffset = 0,
          renderOrder = 1;

        if (entity instanceof constants.RoomObject.Window) {
          object = create2DRectangle(entity, windowSprite);
          floorOffset =
            entity.defaultHeightFromFloor || constants.windowFloorOffset;
        } else if (entity instanceof constants.RoomObject.Door) {
          object = entity.isOpening
            ? create2DRectangle(entity)
            : buildDoor(entity, extra);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.Stairs) {
          object = create2DRectangle(
            entity,
            extra ? entity.sprite_flip : entity.sprite
          );
          object.userData.flip = extra;
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.Configurator) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset =
            entity.defaultHeightFromFloor || constants.wallCabinetDefaultHeight;
        } else if (entity instanceof constants.RoomObject.AkroMils) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.Cabinet) {
          object = create2DRectangle(entity, entity.sprite);
          if (entity.category === "Wall Cabinets")
            floorOffset =
              entity.defaultHeightFromFloor ||
              constants.wallCabinetDefaultHeight;
        } else if (entity instanceof constants.RoomObject.Healthcare) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.MRO) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.Vehicle) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.Misc) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.Table) {
          object = create2DRectangle(entity, entity.sprite);
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else if (entity instanceof constants.RoomObject.WallPanel) {
          object = create2DRectangle(entity);
          renderOrder = 2;
          floorOffset = entity.defaultHeightFromFloor || 88.9;
        } else if (entity instanceof constants.RoomObject.Suite) {
          object = create2DRectangle(entity);
          entity.hotzones = {
            wall: 100 + 1e-15,
          };
          floorOffset = entity.defaultHeightFromFloor || 0;
        } else return null;

        object.position.z = floorOffset + entity.height / 2;

        object.renderOrder = renderOrder;
        object.userData.draggable = true;
        object.userData.entity = entity;
        if (object.userData.entity.defaultAccessory) {
          object.userData.selectedAccessoryId =
            object.userData.entity.defaultAccessory;
        }
        return object;
      },
    };
  };

  service.$inject = dependencies;

  angular.module("valleyCraftApp").service("roomStuffFactory", service);
})();
