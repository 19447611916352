/*jshint browser:true */
"use strict";

//require('./vendor.js')();

//Define the application
//var appModule = require('./app.js');
//require('../styles/main.scss');

require("./controllers/AccessoriesController");
require("./controllers/adminControler");
require("./polyfill");
require("./Classes/ShoppingList");

require("./component/EventDispatch");
require("./component/ComponentBase");
require("./component/Engine");
require("./component/DependencyContainer");
require("./component/TouchAndMouseMap");

require("./component/2D/Drag2D");
require("./component/2D/DragWindow2d");
require("./component/2D/Selection2D");
require("./component/2D/DragStairs2D");
require("./component/2D/DragCabinet2D");
require("./component/2D/DragVehicle2D");
require("./component/2D/MoveCamera");
require("./component/2D/ZoomCamera");
require("./component/2D/ShowSizesOnFloorClick");
require("./component/2D/ConnectionPointHover");
require("./component/2D/WallLengthChangeBase");
require("./component/2D/WallNodeBase");
require("./component/2D/MoveCorner");
require("./component/2D/MoveWall");
require("./component/2D/DrawCustomRoom");
require("./component/2D/WallHighlight");
require("./component/2D/SplitWall");
require("./component/2D/RemoveNode");
require("./component/2D/DragSuite2D");

require("./component/3D/Drag/Handler/DragHandlerBase");
require("./component/3D/Drag/Handler/AxisDragHandler");
require("./component/3D/Drag/Handler/SuiteAxisDragHandler");
require("./component/3D/Drag/Handler/SnapHandler");
require("./component/3D/Drag/Handler/WallObjectSmartDrag");
require("./component/3D/Drag/Handler/FloorObjectSmartDrag");

require("./component/3D/Drag/Class/VerticalOffsetValidator");
require("./component/3D/Drag/Class/ObjectInWallRange");
require("./component/3D/Drag/Class/ObjectInsideRoom");
require("./component/3D/Drag/Class/CollideChecker");
require("./component/3D/Drag/Class/CollideWithWallChecker");
require("./component/3D/Drag/Class/WallContainObject");
require("./component/3D/Drag/Class/ObjectInWallRangev2");
require("./component/3D/Drag/Behavior/DragBehaviorBase");
require("./component/3D/Drag/Behavior/FloorObjectDrag");
require("./component/3D/Drag/Behavior/WallObjectDrag");
require("./component/3D/Drag/Behavior/GroupObjectDrag");
require("./component/3D/Drag/Behavior/WallMountObjectDrag");

require("./component/3D/Select3D");
require("./component/3D/Drag/Drag3D");
require("./component/3D/Scene3DNavigationJoystick");
require("./component/3D/HideLookBlockWalls");
require("./component/3D/WallNumbersUpdate");
require("./component/3D/WallView");
require("./component/3D/ObjectControls/ObjectControls");
require("./component/3D/ObjectControls/ObjectControlsHandlerBase");
require("./component/3D/ObjectControls/RotateHandlerBase");
require("./component/3D/ObjectControls/ClockwiseRotateHandler");
require("./component/3D/ObjectControls/CounterClockwiseRotateHandler");

require("./component/3D/ObjectAdd/AddObjectHandlerBase");
require("./component/3D/ObjectAdd/FloorObjectHandler");
require("./component/3D/ObjectAdd/WallObjectHandlerBase");
require("./component/3D/ObjectAdd/WallEmbeddableObjectHandler");
require("./component/3D/ObjectAdd/WallMountObjectHandler");
require("./component/3D/ObjectAdd/GroupObjectHandler");
require("./component/3D/ObjectAdd/CornerGroupObjectHandler");
require("./component/3D/ObjectAdd/ObjectAdd");

require("./component/3D/ObjectControls/DeleteHandler");
require("./component/3D/ObjectControls/CloneHandler");
require("./component/3D/ObjectControls/InfoHandler");

require("./libs/Detector");
require("./libs/three");
require("./libs/BinaryLoader");
require("./libs/ThreeCSG");
require("./libs/OrbitControls");
require("./libs/datetimepicker");
require("./libs/datetimepicker.templates");
require("./libs/dateTimeInput");
require("./libs/OBB");
require("./libs/MTLLoader");
require("./libs/OBJLoader");
require("./controllers/adminControler");
require("./controllers/modelPublishController");
require("./controllers/materialPublishController");
require("./controllers/colorSchemePublishController");
require("./controllers/productDetailsDialog");
require("./controllers/AccessoriesController");
require("./controllers/selectCombinationMA");
require("./controllers/selectVariantAccessory");
require("./controllers/suitesDialogController");
require("./controllers/MultiDropDownControl");

require("./services/scene3D");
require("./services/roomStuff");
require("./services/materialStuff");
require("./services/roomStuffFactory");
require("./services/step3Helper");
require("./services/modelLoader");
require("./services/replaceModelSrvc");
require("./services/servicesAuth");
require("./services/geometryHelper");
require("./services/sceneHelper");
require("./services/scene3dOverlay");
require("./services/wallVisibilityManager");
require("./services/roomBuilder");
require("./services/constants");
require("./services/roomStateManager");
require("./services/socialShare");
require("./services/wall3DDataManager");
require("./services/screenShotStoreSrvc");
require("./services/shoppingListSrvc");
require("./services/sendingLog");
require("./services/googleAnalitycs");
require("./services/resourceCache");
require("./services/resourceLoader");
require("./services/materialBuilder");
require("./services/objectMaterial");
require("./services/materialSelector");
require("./services/materialConfigManager");
require("./services/keyState");
require("./services/step3DataSrv");
require("./services/object3DSelectionBox");
require("./services/engine2DSrvc");
require("./services/collisionSrvc");
require("./services/screenShotPreprocess");
require("./services/obbBuilder");
require("./services/orbitControl");
require("./services/objectGapHelperSrvc");
require("./services/engine3DSrvc");
require("./services/scene2DSyncSrvc");
require("./services/wallCutHelper");
require("./services/roomSizeManager");
require("./services/printService");
require("./services/saveStateService");
require("./services/scaleService");
require("./services/room2DBuilder");
require("./services/helperLines");
require("./services/wallConnectionSrvc");
require("./services/sizeHelper");
require("./services/dimensionalRadialsManager");
require("./services/objectControls");
require("./services/sceneSyncSrvc");
require("./services/objectCheckerSrvc");
require("./services/object3DCloneSrvc");
require("./services/wallNumberSrvc");
require("./services/scene3DScreenshotSrvc");
require("./services/objects2DSerializer");
require("./services/cursorBuilder");
require("./services/productDetailDialogSrvc");
require("./services/snappingDisableSrvc");
require("./services/object3DPositionHistory");
require("./services/rayHelper");
require("./services/sanitizeSrvc");
require("./services/suiteUtils2DSrvc");
require("./services/suiteUtilsSrvc");
require("./services/configuratorService");
require("./services/csv.service");
require("./services/csvExcel.service");
require("./services/pagination.service");
require("./controllers/main");
require("./controllers/signIn.signUp");
require("./controllers/ForgotPass");
require("./directives/step1");
require("./directives/step2");
require("./directives/ftin");
require("./directives/step3");
require("./services/scene2d");
require("./directives/webgl");
require("./directives/sliderarrow");
require("./directives/step4");
require("./directives/step5");
require("./directives/scene3d");
require("./directives/helpButton");
require("./directives/customSlider");
require("./directives/stepToolTip");
require("./directives/preloader");
require("./directives/wallIdCheckBox");
require("./directives/groupItemsCheckBox");
require("./directives/snappingCheckBox");
require("./directives/undoButton");
require("./directives/hideCarsCheckBox");
require("./directives/screenshot-button");
require("./directives/wall-scroll");
require("./directives/step4-bottom-toolbar");
require("./directives/shopping-list");
require("./directives/action-stepToolTip");
require("./directives/activityTracker");
require("./directives/materialList/materialList");
require("./directives/duplicate3DObject/duplicate3DObject");
require("./directives/align3DObject/align3DObject");
require("./directives/wallSizeDialog");
require("./directives/splitWallButton");
require("./validators/unitsValidator");
require("./validators/wallWidthValidator");
require("./directives/validationTooltipMessages");
require("./directives/deleteNodeButton");
require("./directives/products-list/productsList");

//if (MODE.production) { // jshint ignore:line
//    require('./config/production')(appModule);
//}
/*
angular.element(document).ready(function() {
    angular.bootstrap(document, [appModule.name]);
}); */
