import * as angular from "angular";
import moment from "moment";
import { unparse } from "papaparse";
import { saveAs } from "file-saver";

export class CsvService {
  static $inject = ["AuthenticationService"];

  constructor(AuthenticationService) {
    this.AuthenticationService = AuthenticationService;
  }

  save(data) {
    const { projectDetailsReport, projectDetails, products } = data;
    const id = projectDetailsReport.name.split(' ').join('_');
    // const id = moment().format("YYYY-MM-DD_HHmmss");
    const productsCount = products.reduce((acc, product) => {
      return acc + product.quantity;
    }, 0);

    const csvData = [];
    // Project Details Report block
    csvData.push(["Project Details Report"]);
    csvData.push(["Report ID", id]);
    csvData.push(["User Name(First Last)", projectDetailsReport.userName]);
    csvData.push(["User Email", projectDetailsReport.userEmail]);
    csvData.push(["Project Name", projectDetailsReport.name]);
    csvData.push(["Project Industry", projectDetailsReport.industry]);
    csvData.push(["Project Timeline", projectDetailsReport.timeline]);
    csvData.push(["Project URL", projectDetailsReport.projectUrl]);
    csvData.push(["Date Created", projectDetailsReport.created]);
    csvData.push(["Date Updated", projectDetailsReport.updated]);
    csvData.push(["Floor Area(sq. ft.)", projectDetailsReport.floorArea]);

    csvData.push([""]);
    csvData.push([""]);

    // Project Details block
    csvData.push(["Project Details"]);
    csvData.push([""]);
    csvData.push(["Customer Name", projectDetails.customerName]);
    csvData.push(["Street Number", projectDetails.streetNumber]);
    csvData.push(["City", projectDetails.city]);
    csvData.push(["State", projectDetails.state]);
    csvData.push(["Zip", projectDetails.zip]);
    csvData.push(["Customer Email", projectDetails.customerEmail]);
    csvData.push(["Tel", projectDetails.tel]);
    csvData.push(["Mobile", projectDetails.mobile]);

    csvData.push([""]);
    csvData.push([""]);

    // Shopping list block
    csvData.push(["SKU's on BOM/Shopping List", "SKU", "QTY"]);
    products.forEach((p) => {
      csvData.push(["", p.sku, p.quantity]);
    });
    csvData.push(["Total Number of Items on BOM", "", productsCount]);

    const csv = unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `project-details_${id}.csv`);
  }
}

angular.module("valleyCraftApp").service("CsvService", CsvService);
