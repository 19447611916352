"use strict";

angular.module("valleyCraftApp").service("printService", [
  "$rootScope",
  function ($rootScope) {
    var popup;
    var print = {
      createPrintPopup: function (html) {
        popup = window.open("", "_blank");
        popup.document.write(html);
        popup.document.close();
        popup.focus();
        popup.addEventListener("load", function (event) {
          popup.print();
          setTimeout(() => popup.close(), 500);
        });

        return true;
      },
    };
    $rootScope.$on("hidePrintPopup", function (event, param) {
      if (popup) {
        popup.close();
      }
    });
    return print;
  },
]);
