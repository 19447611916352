import * as THREE from '../libs/three';
; (function () {
    'use strict';

    var dependencies = ['constants', 'sizeHelper', 'geometryHelper', 'scene3D', 'rayHelper'];

    var service = function (constants, sizeHelper, geometryHelper, scene3D, rayHelper) {

        var ray, intesrsectWithSelf;

        var textToImage = function (distance, unit) {
            var canvas = document.createElement('canvas');
            canvas.setAttribute('width', 128);
            canvas.setAttribute('height', 64);
            var ctx = canvas.getContext("2d");
            ctx.font = "18px Arial";
            ctx.fillStyle = "black";
            ctx.textAlign = "center";
            ctx.fillText((Math.ceil(distance * 20) / 20).toFixed(2) + ' ' + unit, canvas.width / 2, (canvas.height / 2) + 9);
            return canvas;
        };

        var buildSizes = function (mesh, img, distance) {
            var spriteMaterial = new THREE.SpriteMaterial({ map: new THREE.Texture(img), color: 0xffffff });
            var sprite = new THREE.Sprite(spriteMaterial);
            sprite.material.map.needsUpdate = true;
            sprite.material.needsUpdate = true;
            sprite.scale.set(80, 80, 80);
            sprite.position.setY(distance / 2);
            mesh.add(sprite);
        };

        var convertCMToFT = function (v) {
            return v * 0.032808399;
        };

        var filterIsNotWall = function (mesh) {
            if (mesh.userData.entity)
                return !mesh.userData.entity.isWall;
            else return true;
        };

        var getDistance = function (raycaster, objects) {
            var intersects = rayHelper.intersectObjectsObb(raycaster.ray, objects);
            //raycaster.intersectObjects(objects);
            if (intersects.length > 0)
                return intersects[0].distance;
            else return 0;
        };

        // TO DO
        // break into simpler functions
        var getDimensianalRadialsForWallEmbeddableModel = function (mesh, modelsWithoutCurrent, boundingCube) {

            var meshOnly = modelsWithoutCurrent.filter(function (obj) {
                return (obj instanceof THREE.Mesh);
            });
            var modelsWithoutCurrentAndWalls = meshOnly.filter(filterIsNotWall);
            var lengthUnit = constants.wallUnitLength.M ? 'cm' : 'ft';

            var vFace = mesh.getWorldDirection();
            var axis = new THREE.Vector3(0, 1, 0);
            var angle = Math.PI / 2;
            var rayCaster = new THREE.Raycaster();
            var length = mesh.userData.length || mesh.userData.entity.length;
            var height = mesh.userData.height || mesh.userData.entity.height;

            var vRight = mesh.getWorldDirection().applyAxisAngle(axis, angle);

            angle = -(Math.PI / 2);
            var vLeft = mesh.getWorldDirection().applyAxisAngle(axis, angle);
            // var vLeft = mesh.getWorldDirection().applyAxisAngle(axis, angle).negate();

            var vTop = new THREE.Vector3(0, 1, 0),
                vBottom = new THREE.Vector3(0, -1, 0);

            rayCaster.set(boundingCube.position, vFace);
            intesrsectWithSelf = rayCaster.intersectObject(boundingCube, true);
            var positionFace = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            rayCaster.set(boundingCube.position, vRight);
            intesrsectWithSelf = rayCaster.intersectObject(boundingCube, true);
            var positionRight = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;
            var positionRightOnWallSurface =
                positionFace.clone().addScaledVector(vRight, length / 2); // second value is length if model was resized

            rayCaster.set(boundingCube.position, vLeft);
            intesrsectWithSelf = rayCaster.intersectObject(boundingCube, true);
            var positionLeft = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;
            var positionLeftOnWallSurface =
                positionFace.clone().addScaledVector(vLeft, length / 2); // second value is length if model was resized

            rayCaster.set(boundingCube.position, vTop);
            intesrsectWithSelf = rayCaster.intersectObject(boundingCube, true);
            var positionTopOnWallSurface =
                positionFace.clone().addScaledVector(vTop, height / 2); // second value is height if model was resized

            rayCaster.set(boundingCube.position, vBottom);
            intesrsectWithSelf = rayCaster.intersectObject(boundingCube, true);
            var positionBottom = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;
            var positionBottomOnWallSurface =
                positionFace.clone().addScaledVector(vBottom, height / 2); // second value is height if model was resized

            rayCaster.set(positionFace, vFace);
            var distanceFace = getDistance(rayCaster, meshOnly);

            rayCaster.set(positionRight, vRight);
            var distanceRight = getDistance(rayCaster, modelsWithoutCurrentAndWalls);
            if (distanceRight === 0) {
                rayCaster.set(positionRightOnWallSurface, vRight);
                distanceRight = getDistance(rayCaster, meshOnly);
            }

            rayCaster.set(positionLeft, vLeft);
            var distanceLeft = getDistance(rayCaster, modelsWithoutCurrentAndWalls);
            if (distanceLeft === 0) {
                rayCaster.set(positionLeftOnWallSurface, vLeft);
                distanceLeft = getDistance(rayCaster, meshOnly);
            }

            var distanceTop = constants.wallHeight -
                mesh.position.y -
                ((mesh.userData.height / 2) || (mesh.userData.entity.height / 2));


            rayCaster.set(positionBottom, vBottom);
            var distanceBottom = getDistance(rayCaster, modelsWithoutCurrentAndWalls);
            if (distanceBottom === 0) {
                rayCaster.set(positionBottomOnWallSurface, vBottom);
                distanceBottom = getDistance(rayCaster, meshOnly);
            }

            var arrowFace = new THREE.ArrowHelper(vFace, positionFace, distanceFace, 0x0000ff, 5, 5),
                arrowRight = new THREE.ArrowHelper(vRight, positionRightOnWallSurface, distanceRight, 0x0000ff, 5, 5),
                arrowLeft = new THREE.ArrowHelper(vLeft, positionLeftOnWallSurface, distanceLeft, 0x0000ff, 5, 5),
                arrowTop = new THREE.ArrowHelper(vTop, positionTopOnWallSurface, distanceTop, 0x0000ff, 5, 5),
                arrowBottom = new THREE.ArrowHelper(vBottom, positionBottomOnWallSurface, distanceBottom, 0x0000ff, 5, 5);

            if (lengthUnit === 'ft') {
                var imgFace = textToImage(convertCMToFT(distanceFace), lengthUnit);
                var imgRight = textToImage(convertCMToFT(distanceRight), lengthUnit);
                var imgLeft = textToImage(convertCMToFT(distanceLeft), lengthUnit);
                var imgTop = textToImage(convertCMToFT(distanceTop), lengthUnit);
                var imgBottom = textToImage(convertCMToFT(distanceBottom), lengthUnit);
            } else {
                var imgFace = textToImage(distanceFace, lengthUnit);
                var imgRight = textToImage(distanceRight, lengthUnit);
                var imgLeft = textToImage(distanceLeft, lengthUnit);
                var imgTop = textToImage(distanceTop, lengthUnit);
                var imgBottom = textToImage(distanceBottom, lengthUnit);
            }

            buildSizes(arrowFace, imgFace, distanceFace);
            buildSizes(arrowRight, imgRight, distanceRight);
            buildSizes(arrowLeft, imgLeft, distanceLeft);
            buildSizes(arrowTop, imgTop, distanceTop);
            buildSizes(arrowBottom, imgBottom, distanceBottom);

            var dimensionalRadials = new THREE.Object3D();
            dimensionalRadials.userData.type = 'arrowHelper';

            if (distanceFace > 5) // don't add arrows for too little distances
                dimensionalRadials.add(arrowFace);
            if (distanceRight > 5)
                dimensionalRadials.add(arrowRight);
            if (distanceLeft > 5)
                dimensionalRadials.add(arrowLeft);
            if (distanceTop > 5)
                dimensionalRadials.add(arrowTop);
            if (distanceBottom > 5)
                dimensionalRadials.add(arrowBottom);
            // dimensionalRadials.add(arrowFace, arrowRight, arrowLeft, arrowTop, arrowBottom);
            return dimensionalRadials;
        };

        // TO DO
        // break into simpler functions
        var getDimensianalRadialsForFloorModel = function (mesh, modelsWithoutCurrent, boundingCube) {

            modelsWithoutCurrent = modelsWithoutCurrent.filter(function (obj) {
                return (obj instanceof THREE.Mesh);
            });

            var lengthUnit = constants.wallUnitLength.M ? 'cm' : 'ft';;

            var vFace = mesh.getWorldDirection();

            var axis = new THREE.Vector3(0, 1, 0);
            var angle = Math.PI / 2;

            var vRight = mesh.getWorldDirection().applyAxisAngle(axis, angle);

            angle = Math.PI;
            var vBack = mesh.getWorldDirection().applyAxisAngle(axis, angle);

            angle = 3 * Math.PI / 2;
            var vLeft = mesh.getWorldDirection().applyAxisAngle(axis, angle);

            ray = new THREE.Raycaster(boundingCube.position, vFace);
            intesrsectWithSelf = ray.intersectObject(boundingCube, true);
            var positionFace = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            ray = new THREE.Raycaster(boundingCube.position, vRight);
            intesrsectWithSelf = ray.intersectObject(boundingCube, true);
            var positionRight = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            ray = new THREE.Raycaster(boundingCube.position, vBack);
            intesrsectWithSelf = ray.intersectObject(boundingCube), true;
            var positionBack = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            ray = new THREE.Raycaster(boundingCube.position, vLeft);
            intesrsectWithSelf = ray.intersectObject(boundingCube, true);
            var positionLeft = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            var raycaster = new THREE.Raycaster();

            raycaster.ray.set(positionFace, vFace);
            var distanceFace = getDistance(raycaster, modelsWithoutCurrent);

            raycaster.ray.set(positionRight, vRight);
            var distanceRight = getDistance(raycaster, modelsWithoutCurrent);

            raycaster.ray.set(positionBack, vBack);
            var distanceBack = getDistance(raycaster, modelsWithoutCurrent);

            raycaster.ray.set(positionLeft, vLeft);
            var distanceLeft = getDistance(raycaster, modelsWithoutCurrent);

            var arrowFace = new THREE.ArrowHelper(vFace, positionFace, distanceFace, 0x0000ff, 5, 5),
                arrowRight = new THREE.ArrowHelper(vRight, positionRight, distanceRight, 0x0000ff, 5, 5),
                arrowBack = new THREE.ArrowHelper(vBack, positionBack, distanceBack, 0x0000ff, 5, 5),
                arrowLeft = new THREE.ArrowHelper(vLeft, positionLeft, distanceLeft, 0x0000ff, 5, 5);

            if (lengthUnit === 'ft') {
                var imgFace = textToImage(convertCMToFT(distanceFace), lengthUnit);
                var imgRight = textToImage(convertCMToFT(distanceRight), lengthUnit);
                var imgBack = textToImage(convertCMToFT(distanceBack), lengthUnit);
                var imgLeft = textToImage(convertCMToFT(distanceLeft), lengthUnit);
            }
            else {
                var imgFace = textToImage(distanceFace, lengthUnit);
                var imgRight = textToImage(distanceRight, lengthUnit);
                var imgBack = textToImage(distanceBack, lengthUnit);
                var imgLeft = textToImage(distanceLeft, lengthUnit);
            }


            buildSizes(arrowFace, imgFace, distanceFace);
            buildSizes(arrowRight, imgRight, distanceRight);
            buildSizes(arrowBack, imgBack, distanceBack);
            buildSizes(arrowLeft, imgLeft, distanceLeft);

            var dimensionalRadials = new THREE.Object3D();
            dimensionalRadials.userData.type = 'arrowHelper';

            if (distanceFace > 5) // don't add arrows for too little distances
                dimensionalRadials.add(arrowFace);
            if (distanceRight > 5)
                dimensionalRadials.add(arrowRight);
            if (distanceBack > 5)
                dimensionalRadials.add(arrowBack);
            if (distanceLeft > 5)
                dimensionalRadials.add(arrowLeft);
            // dimensionalRadials.add(arrowFace, arrowRight, arrowBack, arrowLeft);

            return dimensionalRadials;
        }

        // TO DO
        // break into simpler functions
        var getDimensianalRadialsForWallMountableModel = function (mesh, modelsWithoutCurrent, boundingCube) {

            modelsWithoutCurrent = modelsWithoutCurrent.filter(function (obj) {
                return (obj instanceof THREE.Mesh);
            });

            var dimensionalRadials = getDimensianalRadialsForFloorModel(mesh, modelsWithoutCurrent, boundingCube),
                lengthUnit = constants.wallUnitLength.M ? 'cm' : 'ft';

            var vTop = new THREE.Vector3(0, 1, 0),
                vBottom = new THREE.Vector3(0, -1, 0),
                raycaster = new THREE.Raycaster();

            raycaster.set(boundingCube.position, vTop);
            intesrsectWithSelf = raycaster.intersectObject(boundingCube);
            var positionTop = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            raycaster.set(boundingCube.position, vBottom);
            intesrsectWithSelf = raycaster.intersectObject(boundingCube);
            var positionBottom = intesrsectWithSelf[intesrsectWithSelf.length - 1].point;

            // count distance to roof, because it can't be intersected
            var distanceTop = constants.wallHeight - mesh.position.y - (
                (mesh.userData.height / 2) || (mesh.userData.entity.height / 2)); // 

            raycaster.set(positionBottom, vBottom);
            var distanceBottom = getDistance(raycaster, modelsWithoutCurrent);

            var arrowTop = new THREE.ArrowHelper(vTop, positionTop, distanceTop, 0x0000ff, 30, 5),
                arrowBottom = new THREE.ArrowHelper(vBottom, positionBottom, distanceBottom, 0x0000ff, 30, 5);

            if (lengthUnit === 'ft') {
                var img5 = textToImage(convertCMToFT(distanceTop), lengthUnit);
                var img6 = textToImage(convertCMToFT(distanceBottom), lengthUnit);
            }
            else {
                var img5 = textToImage(distanceTop, lengthUnit);
                var img6 = textToImage(distanceBottom, lengthUnit);
            }

            buildSizes(arrowTop, img5, distanceTop);
            buildSizes(arrowBottom, img6, distanceBottom);

            if (distanceTop > 5)
                dimensionalRadials.add(arrowTop);
            if (distanceBottom > 5)
                dimensionalRadials.add(arrowBottom);
            // dimensionalRadials.add(arrowTop, arrowBottom);

            return dimensionalRadials;
        }

        return {
            getDimensionalRadials: function (mesh) {

                var wallInteraction = mesh.userData.entity.wallInteraction;

                var modelsWithoutCurrent = scene3D.filterChildren(function (item) {
                    return item.uuid !== mesh.uuid;
                });

                var geometry = new THREE.BoxGeometry(mesh.userData.length || mesh.userData.entity.length,
                    mesh.userData.height || mesh.userData.entity.height, mesh.userData.width || mesh.userData.entity.width),
                    material = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide }),
                    boundingCube = new THREE.Mesh(geometry, material);

                boundingCube.position.copy(mesh.position);
                boundingCube.rotation.copy(mesh.rotation);
                boundingCube.updateMatrixWorld();

                switch (wallInteraction) {
                    case 'embeddable': {
                        var dimensionalRadials = getDimensianalRadialsForWallEmbeddableModel(mesh, modelsWithoutCurrent, boundingCube);
                    }
                        break;
                    case 'mountable': {
                        var dimensionalRadials = getDimensianalRadialsForWallMountableModel(mesh, modelsWithoutCurrent, boundingCube);
                    }
                        break;
                    default: {
                        var dimensionalRadials = getDimensianalRadialsForFloorModel(mesh, modelsWithoutCurrent, boundingCube);
                    }
                }

                return dimensionalRadials;
            },
            deleteDimensionalRadials: function () {
                var arrows = scene3D.filterChildren(function (obj) {
                    return obj.userData.type === "arrowHelper";
                });
                for (var i = 0; i < arrows.length; i++) {
                    scene3D.remove(arrows[i]);
                }
            },
        }

    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('dimensionalRadialsManager', service);

})();
