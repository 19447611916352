import * as angular from "angular";
import moment from "moment";
import { saveAs } from "file-saver";
const ExcelJS = require("exceljs");

export class CsvExcelService {
  static $inject = ["AuthenticationService"];

  constructor(AuthenticationService) {
    this.AuthenticationService = AuthenticationService;
  }

  sanitizeWorksheetNameExcel(name) {
    const invalidCharsRegex = /[\/\\?*\[\]:]/g; // Regular expression for invalid characters
    const maxLength = 31; // Maximum allowed length for worksheet name

    // Replace invalid characters with underscores and truncate the name if necessary
    let sanitizedName = name
      .replace(invalidCharsRegex, "_")
      .substring(0, maxLength);

    // Trim any leading or trailing spaces
    sanitizedName = sanitizedName.trim();
    return sanitizedName;
  }

  save(data) {
    const workbook = new ExcelJS.Workbook();

    data.forEach((dataItem, index) => {
      const { projectDetailsReport, projectDetails, products } = dataItem;
      const id = projectDetailsReport.name.split(" ").join("_");
      /*  const productsCount = products.reduce((acc, product) => {
        return acc + product.quantity;
      }, 0); */

      // Створюємо робочий аркуш для поточної ітерації
      const namePage = `${this.sanitizeWorksheetNameExcel(id)}_${index + 1}`;
      const worksheet = workbook.addWorksheet(namePage);

      // Додаємо дані до робочого аркуша
      worksheet.addRow(["Project Details Report"]);
      worksheet.addRow(["Report ID", id]);
      worksheet.addRow([
        "User Name(First Last)",
        projectDetailsReport.userName,
      ]);
      worksheet.addRow(["User Email", projectDetailsReport.userEmail]);
      worksheet.addRow(["Project Name", projectDetailsReport.name]);
      worksheet.addRow(["Project Industry", projectDetailsReport.industry]);
      worksheet.addRow(["Project Timeline", projectDetailsReport.timeline]);
      worksheet.addRow(["Project URL", projectDetailsReport.projectUrl]);
      worksheet.addRow(["Date Created", projectDetailsReport.created]);
      worksheet.addRow(["Date Updated", projectDetailsReport.updated]);
      worksheet.addRow(["Floor Area(sq. ft.)", projectDetailsReport.floorArea]);

      worksheet.addRow([]);

      worksheet.addRow(["Project Details"]);
      worksheet.addRow([]);
      worksheet.addRow(["Customer Name", projectDetails.customerName]);
      worksheet.addRow(["Street Number", projectDetails.streetNumber]);
      worksheet.addRow(["City", projectDetails.city]);
      worksheet.addRow(["State", projectDetails.state]);
      worksheet.addRow(["Zip", projectDetails.zip]);
      worksheet.addRow(["Customer Email", projectDetails.customerEmail]);
      worksheet.addRow(["Tel", projectDetails.tel]);
      worksheet.addRow(["Mobile", projectDetails.mobile]);

      worksheet.addRow([]);

      worksheet.addRow(["SKU's on BOM/Shopping List", "SKU", "QTY"]);
      const uniqueItems = {}; // Об'єкт для відстеження унікальних рядків та їх кількостей

      // Пройдемось по products та підрахуємо кількість входжень для кожного рядка
      products.forEach((item) => {
        const hikId = item;
        if (!uniqueItems[hikId]) {
          uniqueItems[hikId] = { hikId, count: 1 }; // Виправлено item на hikId
        } else {
          uniqueItems[hikId].count++;
        }
      });

      // uniqueItems містить унікальні рядки та їхні кількості
      // Тепер можна створити масив унікальних рядків разом з їхньою кількістю
      const uniqueItemsArray = Object.values(uniqueItems);


      uniqueItemsArray.forEach(({ hikId, count }) => {
        console.log(hikId);
        console.log(`Кількість входжень: ${count}`);
        worksheet.addRow(["", hikId, count]);
      });

      /* const itemHikId = [];
      products.forEach((product) => {
        product.forEach((item, index) => {
          const { hikId } = item;
          console.log(hikId);
          push
          //worksheet.addRow(["", hikId, index]);
        });
      }); */
      const totalUniqueCount = uniqueItemsArray.reduce(
        (total, { count }) => total + count,
        0
      );
      worksheet.addRow(["Total Number of Items on BOM", "", totalUniqueCount]);
      worksheet.addRow([
        "Total Value of Items on BOM",
        "",
        projectDetails.value ? "$" + projectDetails.value : "",
      ]);

    });

    // Зберігаємо файл XLSX
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "project-details.xlsx");
    });
  }
}

angular.module("valleyCraftApp").service("CsvExcelService", CsvExcelService);
