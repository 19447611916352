import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import 'angular-animate';
import 'angular-bootstrap';
import 'angular-cookies';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-touch';
import 'angular-local-storage';
import 'angular-toastr';
import 'ng-dialog';
import 'bootstrap-slider';
import 'angular-bootstrap-slider';
import 'ng-file-upload';
import 'angular-bootstrap-colorpicker';
import 'bootstrap-table';
import 'moment';
import 'datatables';
import 'crypto-js';
import 'lodash';
import './app/app.module.ajs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './app/core_/bootstrap';

setAngularJSGlobal(angular);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
