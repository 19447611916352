import * as THREE from '../libs/three';
(function () {
    var getTrueMouseCoords = function (x, y, width, height) {
        return {
            x: (x / width) * 2 - 1,
            y: -(y / height) * 2 + 1
        };
    };

    var colorSlots = {
        0: 0xff0000, // red
        1: 0x00ff00, // green
        2: 0x0000ff, // blue
        3: 0x000000, // black
        4: 0xff00ff, // pink
        5: 0x654321, // brown
        6: 0xbff00 // lime
    };

    var TestPoint = function (position, name, color) {
        THREE.Object3D.call(this);
        // var geometry = new THREE.CircleGeometry(1, 32);
        var material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            color: color
        });
        var geometry = new THREE.SphereGeometry(5, 32, 32);
        var mesh = new THREE.Mesh(geometry, material);
        mesh.renderOrder = 10;
        // mesh.rotation.x = Math.PI / 2;
        mesh.visible = true;
        mesh.layers.enable(2);
        mesh.layers.enable(3);
        mesh.layers.enable(6);
        mesh.layers.enable(7);
        this.add(mesh);
        this.position.copy(position);
        this.name = name;
        this.visible = true;
        this.renderOrder = 10;
    };

    TestPoint.prototype = Object.create(THREE.Object3D.prototype, {});

    TestPoint.prototype.constructor = TestPoint;

    var service = function ($rootScope, roomStuffFactory) {
        return {
            getPickingRay: function (x, y, width, height, camera, raycaster) {
                raycaster = raycaster || new THREE.Raycaster();
                var coords = getTrueMouseCoords(x, y, width, height);
                raycaster.setFromCamera(new THREE.Vector2(coords.x, coords.y), camera);
                return raycaster;
            },

            avtoZoomCameraIn2dProjection: function (frustum, startPosition, movePosition, camera) {
                var position = movePosition;
                var diff = new THREE.Vector3().subVectors(position, startPosition);
                diff.normalize().multiplyScalar(50);
                position.add(diff);
                var inside = frustum.containsPoint(position);

                if (!inside) {
                    var zoom = camera.zoom - 0.02;
                    $rootScope.$broadcast('wallBeyondCamera', { zoom: zoom, sign: '-' });
                }
            },
            translateWorldCoordinateToDisplay: function (object, base) {
                //object - coordinate what need translate; base - canvas
                var BoundingClientRect, X, Y;
                BoundingClientRect = base[0].getBoundingClientRect();
                X =
                    object.x * (BoundingClientRect.width / 2) +
                    BoundingClientRect.width / 2 +
                    BoundingClientRect.left;
                Y =
                    -(object.y * (BoundingClientRect.height / 2)) +
                    BoundingClientRect.height / 2 +
                    BoundingClientRect.top;
                return { x: X, y: Y };
            },
            getCanvasClientXY: function (e, canvas) {
                if (e.touches) {
                    var touch = e.touches.length > 0 ? e.touches[0] : e.changedTouches[0],
                        boundingRectangle =
                            boundingRectangle || canvas.parentNode.getBoundingClientRect();
                    return {
                        x: touch.clientX - boundingRectangle.left,
                        y: touch.clientY - boundingRectangle.top
                    };
                } else {
                    return {
                        x: e.offsetX,
                        y: e.offsetY
                    };
                }
            },
            build2DTestPointAndUpdate: function (position, colorNumber, name) {
                if (!name) name = JSON.stringify(position);
                if (!colorNumber) colorNumber = 0;
                position = position.clone();
                var color = colorSlots[colorNumber];
                var customRoomTestPoint = window['sc'].getObjectByName(name);
                if (customRoomTestPoint) {
                    customRoomTestPoint.position.copy(position);
                } else {
                    customRoomTestPoint = new TestPoint(position, name, color);
                    window['sc'].add(customRoomTestPoint);
                }
            },
            build3DTestPointAndUpdate: function (position, colorNumber, name) {
                if (!name) name = JSON.stringify(position);
                if (!colorNumber) colorNumber = 0;
                position = position.clone();
                var color = colorSlots[colorNumber];
                var customRoomTestPoint = window['sc3d'].getObjectByName(name);
                if (customRoomTestPoint) {
                    customRoomTestPoint.position.copy(position);
                } else {
                    customRoomTestPoint = new TestPoint(position, name, color);
                    window['sc3d'].add(customRoomTestPoint);
                }
            }
        };
    };

    angular
        .module('valleyCraftApp')
        .service('sceneHelper', ['$rootScope', 'roomStuffFactory', service]);
})();
