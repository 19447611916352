import angular from 'angular';
declare var angular: angular.IAngularStatic;
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-default-layout',
    standalone: true,
  imports: [CommonModule, RouterModule, MatToolbarModule, HeaderComponent],  
  templateUrl: './default-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultLayoutComponent {}

/* angular.module('valleyCraftApp').directive(
  'appDefaultLayout',
  downgradeComponent({
    component: DefaultLayoutComponent,
  }) as angular.IDirectiveFactory
); */
