import * as THREE from '../libs/three';
; (function () {
    'use strict';

    var dependencies = ['constants', 'roomSizeManager', 'wallConnectionSrvc'];

    var service = function (constants, roomSizeManager, wallConnectionSrvc) {

        var wallMaterial = new THREE.MeshBasicMaterial({
            color: 0x000000
        });

        var createFloor2D = function (points) {

            var shape = new THREE.Shape();

            for (var i = 0; i < points.length; i++) {

                var p = points[i];

                if (i == 0) {
                    shape.moveTo(p.x, p.y);
                }
                else {
                    shape.lineTo(p.x, p.y);
                }
            }

            var material = new THREE.MeshBasicMaterial({
                color: 0x83ACF2,
                name: 'FLOOR'
            }),
               geometry = new THREE.ShapeGeometry(shape),
               mesh = new THREE.Mesh(geometry, material);

            mesh.floor = true;
            mesh.name = 'floor';
            mesh.position.z = 1;
            mesh.userData.isFloor = true;

            return mesh;
        };

        var createConnectionPoint = function (point, index) {
            var circle = new THREE.CircleBufferGeometry(9, 32);
            var mesh = new THREE.Mesh(circle, new THREE.MeshBasicMaterial({ color: 0x000000, depthWrite: false, depthTest: false }));
            mesh.position.copy(point);
            mesh.name = 'connectionPoint ' + (index + 1);
            mesh.userData.isConnectionPoint = true;
            mesh.userData.index = index;
            mesh.renderOrder = 1;
            return mesh;
        };

        return {

            build: function (points) {

                var i = 0,
                    count = points.length,
                    a, b,
                    result = [],
                    walls = [],
                    wall;

                for (; i < count; i++) {
                    a = points[i];
                    b = points[(i + 1) % count];

                    a = new THREE.Vector3(a.x, a.y, 0);
                    b = new THREE.Vector3(b.x, b.y, 0);

                    var depth = //i === 1 || i === 3 ? 20 : 15;//
                    points[i].depth;

                    wall = this.buildWall(a, b, i, depth);

                    walls.push(wall);

                    result.push(createConnectionPoint(a, i));
                }

                wallConnectionSrvc.connectAllWalls2D(walls);

                roomSizeManager.buildAll(walls);

                var floor = createFloor2D(points);
                roomSizeManager.buildFloorSize(floor);
                result.push(floor);

                return result.concat(walls);
            },

            buildWall: function (pointA, pointB, index, width) {

                width = width || constants.wallWidth;

                var wallLen = pointA.distanceTo(pointB);
                var angle = Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x);

                var geometry = new THREE.BoxGeometry(wallLen, constants.wallHeight, width);
                geometry.applyMatrix(new THREE.Matrix4().makeTranslation(wallLen / 2, constants.wallHeight / 2, -width / 2));

                var wall = new THREE.Mesh(geometry, wallMaterial.clone());
                wall.name = 'Wall ' + (index + 1);

                //wall.scale.set(wallLen, constants.wallHeight, constants.wallWidth);
                wall.rotation.set(Math.PI / 2, angle, 0);
                wall.position.copy(pointA);

                wall.userData.index = index;
                wall.userData.isWall = true;
                wall.userData.width = width;

                //  wall.renderOrder = 0.1;
                //wall.updateMatrixWorld();

                return wall;
            },

            buildFloor: createFloor2D,

            createConnectionPoint: createConnectionPoint,

            updateWallLength: function (wall, startPoint, endPoint) {
                var geometry = wall.geometry,
                    vertx = geometry.vertices,
                    newLength = startPoint.distanceTo(endPoint),
                    rotation = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

                vertx[0].x = newLength;
                vertx[2].x = newLength;

                wall.rotation.set(Math.PI / 2, rotation, 0);
                wall.position.copy(startPoint);
                wall.userData.obb = null;

                geometry.verticesNeedUpdate = true;
                geometry.boundingSphere = null;
                geometry.boundingBox = null;
            },

            updateWallWidth: function (wall, newWidth) {
                var geometry = wall.geometry,
                   vertx = geometry.vertices;

                vertx[1].z = -newWidth;
                vertx[3].z = -newWidth;
                vertx[4].z = -newWidth;
                vertx[6].z = -newWidth;

                wall.userData.width = newWidth;
                wall.userData.obb = null;

                geometry.verticesNeedUpdate = true;
                geometry.boundingSphere = null;
                geometry.boundingBox = null;
            },

            updateFloor: function (floor, points) {
                var geometry = floor.geometry,
                    vertx = geometry.vertices,
                    faces = geometry.faces,
                    i = 0,
                    trianglesIndexes = THREE.ShapeUtils.triangulateShape(points, []),
                    triangleIndexes,
                    face;

                for (; i < points.length; i++) {
                    vertx[i].copy(points[i]);
                }

                for (i = 0; i < faces.length; i++) {
                    triangleIndexes = trianglesIndexes[i];
                    face = faces[i];
                    face.a = triangleIndexes[0];
                    face.b = triangleIndexes[1];
                    face.c = triangleIndexes[2];
                }

                geometry.verticesNeedUpdate = true;
                geometry.elementsNeedUpdate = true;

                geometry.boundingSphere = null;
                geometry.boundingBox = null;
            }
        }

    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('room2DBuilder', service);

})();
