(function () {
  var directive = function () {
    return {
      restrict: "E",
      templateUrl: "./app/views/previewSPL.html",
      replace: false,
      scope: {
        count: "=",
        save: "&",
        total: "=",
        report: "@",
        isProjectSaved: "="
      },
      controller: [
        "$scope",
        "ngDialog",
        "constants",
        "Scene2d",
        "scene3D",
        "$rootScope",
        "roomStateManager",
        "shoppingListSrvc",
        "$http",
        "localStorageService",
        "$window",
        "printService",
        "ResourceService",
        "SaveStateService",
        "roomStuff",
        "replaceModelSrvc",
        "sizeHelper",
        "CsvService",
        "AuthenticationService",
        "toastr",
        function (
          $scope,
          ngDialog,
          constants,
          Scene2d,
          scene3D,
          $rootScope,
          roomStateManager,
          shoppingListSrvc,
          $http,
          localStorageService,
          $window,
          printService,
          ResourceService,
          SaveStateService,
          roomStuff,
          replaceModelSrvc,
          sizeHelper,
          CsvService,
          AuthenticationService,
          toastr
        ) {
          var total = 0;
          $scope.checkSubfloorSPL = true;
          $scope.UnitFT = constants.wallUnitLength.FT;
          $scope.projectData = {};
          $scope.setSubFloor = true;
          $scope.productData = [];
          $scope.extStyle = {};
          $scope.intStyle = {};
          $scope.isTemplateMaster = $rootScope.templateMaster;
          console.log('$rootScope.isProjectSaved', $rootScope.isProjectSaved);

          $scope.getUserProductSizes = sizeHelper.getUserProductSizes;

          $scope.chooseColor = function (color) {
            return "assets/images/colors/" + color + ".png";
          };

          $scope.getProductHikaId = function (product) {
            if (product.isSuite) {
              var hikashop_id = product.hikId;
            } else {
              var hikashop_id = product.color
                ? product.hikashop_id_to_color_scheme[product.color]
                : product.hikId;
              // last chance (look in variants)
              var variant = _.find(product.variants, {
                colorScheme: { name: product.color },
              });
              if (!hikashop_id && variant) hikashop_id = variant.hikId;
            }
            return hikashop_id;
          };

          $rootScope.$on("calculateSPL", function (event, param) {
            if (!param) return;
            $scope.productData = shoppingListSrvc.calculateProductData(param);
            $scope.count = $scope.productData.length;
            $scope.projectData.totalAmount = shoppingListSrvc.getTotal(
              $scope.productData
            );
            $scope.total = $scope.projectData.totalAmount;

            if (location.hash.valueOf() === "#/account") {
              $scope.isShowChecked = false;
              $scope.isShowTypePanels = false;
            } else {
              $scope.isShowChecked = true;
              $scope.isShowTypePanels = true;
            }
            // $scope.buildList(param);
            shoppingListSrvc.setProductData($scope.projectData);
          });

          $scope.buildList = function (apart) {
            $scope.resetSPLValua();
            //if(!apart.wallCollection){return;}
            ShoppingList.list = CreateShoppingList(roomStateManager, constants);

            // console.warn(ShoppingList.list);

            $scope.dataList = ShoppingList.list;
            if (constants.wallUnitLength.FT) {
              $scope.projectData.LinearLength = convertMToFT(
                $scope.dataList.roomLinearLength
              );
              $scope.projectData.FloorArea = convertM2ToFT2(
                $scope.dataList.floorArea
              );
              $scope.projectData.WallArea = convertM2ToFT2(
                $scope.dataList.wallArea
              );
            } else {
              $scope.projectData.LinearLength =
                $scope.dataList.roomLinearLength.toFixed(2);
              $scope.projectData.FloorArea =
                $scope.dataList.floorArea.toFixed(2);
              $scope.projectData.WallArea = $scope.dataList.wallArea.toFixed(2);
            }
            $scope.projectData.countWall = $scope.dataList.countWall;
            $scope.projectData.totalAmount = shoppingListSrvc.getTotal(
              $scope.productData
            );
          };

          $rootScope.$on("changeUnitInSPL", function () {
            if (constants.wallUnitLength.FT) {
              if ($scope.dataList) {
                $scope.projectData.LinearLength = convertMToFT(
                  $scope.dataList.roomLinearLength
                );
                $scope.projectData.FloorArea = convertM2ToFT2(
                  $scope.dataList.floorArea
                );
                $scope.projectData.WallArea = convertM2ToFT2(
                  $scope.dataList.wallArea
                );
              }
              $scope.UnitFT = constants.wallUnitLength.FT;
            } else {
              $scope.UnitFT = false;
              if ($scope.dataList) {
                $scope.projectData.LinearLength =
                  $scope.dataList.roomLinearLength.toFixed(2);
                $scope.projectData.FloorArea =
                  $scope.dataList.floorArea.toFixed(2);
                $scope.projectData.WallArea =
                  $scope.dataList.wallArea.toFixed(2);
              }
            }
          });

          $scope.$watch("projectData", function (val) {
            if (val) {
              shoppingListSrvc.setProductData(val);
            }
          });

          $scope.resetSPLValua = function () {
            $scope.dataList = 0;
            $scope.projectData = {};
          };

          $scope.getItemCount = function () {
            var count = 0;
            for (var i = 0; i < $scope.productData.length; i++) {
              count += $scope.productData[i].quantity;
            }
            return count;
          };

          $scope.downloadCSV = function (productData) {
            console.log('productData', productData);
            if (!productData.length) {
              toastr.error("No products selected");
              console.warn("No products selected");
              return;
            }
            // console.log("downloadCSV", $rootScope.editedRoomData);

            function convertConstToStr(str) {
              if (!str) {
                console.warn("No string provided");
                return;
              }
              return str
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                );
            }

            const user = AuthenticationService.userLogged();
            const projectDetailsData =
              $rootScope?.editedRoomData?.projectDetails || {};
            const projectUrl =
            constants.API_URL +
              "/#/browsing/" +
              $rootScope.editedRoomData._id;

            const projectDetailsReport = {
              userName: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
              userEmail: AuthenticationService.mail() || "",
              name: projectDetailsData?.title || "",
              industry:
                convertConstToStr(projectDetailsData?.project?.industry) ||
                null,
              timeline:
                convertConstToStr(projectDetailsData?.project?.timelines) ||
                null,
              projectUrl: projectUrl,
              created: $rootScope.editedRoomData.date,
              updated: $rootScope.editedRoomData.lastEdited,
              floorArea: projectDetailsData?.project.FloorArea,
            };

            const projectDetails = {
              customerName: projectDetailsData?.address?.clientName || "",
              streetNumber: projectDetailsData?.address?.street || "",
              city: projectDetailsData?.address?.city || "",
              state: projectDetailsData?.address?.state || "",
              zip: projectDetailsData?.address?.zip || "",
              customerEmail: projectDetailsData?.contact?.email || "",
              tel: projectDetailsData?.contact?.tel || "",
              mobile: projectDetailsData?.contact?.mobile || "",
            };

            const csvData = {
              products: productData.map((product) => ({
                sku: product.hikId,
                quantity: product.quantity,
              })),
              projectDetailsReport: projectDetailsReport,
              projectDetails: projectDetails,
            };

            CsvService.save(csvData);
          };

          $scope.sendToCart = function () {
            if ($scope.report) {
              SaveStateService.saveRoute("/account");
            } else {
              $scope.save({
                stepObj: {
                  index: 4,
                  step: "Price & Purchase",
                },
              });
            }
            var sid = localStorageService.get("ce_sid_id");
            var sidName = localStorageService.get("ce_sid_name");
            ResourceService.sendToCart(sid, sidName, $scope.productData)
              .then(function () {
                // window.parent.location = document.referrer;
              })
              .catch(function (err) {
                console.debug("Err", err);
              });
          };

          $scope.printSPL = function () {
            var toPrint = angular.element("#sl")[0].innerHTML;
            var text = angular.element("#text")[0].innerHTML;
            var prompt = angular.element("#prompt")[0].innerHTML;
            var html =
              '<html><head><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Didact+Gothic">';
            html +=
              '<link rel="stylesheet" href="assets/styles/forPrint.css"></head>';
            html +=
              '<body><img src="assets/images/ceg-horizontal-logo.jpg" alt="logo" class="logo"';
            html += text + prompt;
            html += toPrint + "</body></html>";
            //   var popup = window.open('', '_blank', 'width=600,height=400');
            //   popup.document.write(html);
            printService.createPrintPopup(html);
          };

          $scope.deleteProductFromShoppingList = function (product) {
            if (product && $scope.productData.length) {
              $scope.productData = $scope.productData.filter(function (pd) {
                return pd._id !== product._id;
              });
            }
          };

          $scope.$emit("shopping list ready", null);
        },
      ],
    };
  };

  angular.module("valleyCraftApp").directive("shoppingList", directive);
})();
