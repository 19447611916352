import ComponentBase from '../ComponentBase';
import * as THREE from '../../libs/three';

var MoveCamera = function (dependencyContainer) {
    ComponentBase.prototype.constructor.call(this);

    this._keyState = dependencyContainer.getService('keyState');
    this._Scene2d = dependencyContainer.getService('Scene2d');

  //  this.disable();

    this._clickPosition = null;
    this._raycaster = new THREE.Raycaster(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, -1));
};

MoveCamera.prototype = Object.create(ComponentBase.prototype);

MoveCamera.prototype.constructor = MoveCamera;

MoveCamera.prototype.mouseDown = function (e) {

    this._clickPosition = this._Scene2d.screenToWorld(e.offsetX, e.offsetY);
    this._clickPosition.z = 500;

    this._raycaster.ray.origin.copy(this._clickPosition);

    var children = this._Scene2d.getChildren(),
        intersection = this._raycaster.intersectObjects(children, true)[0],
        i = 0;

    if (intersection) {
        var data = intersection.object.userData;
        if (data.isConnectionPoint || data.isWall || data.entity)
            this._clickPosition = null;       
    }
};

MoveCamera.prototype.mouseMove = function (e) {
    if (this._keyState.isMouseHold && this._clickPosition !== null) {
        var currentMousePosition = this._Scene2d.screenToWorld(e.offsetX, e.offsetY),
            camera = this._Scene2d.getCamera(),
            delta = this._clickPosition.clone().sub(currentMousePosition);
        camera.position.add(delta);
        this._Scene2d.render();
        this.fireEvent('move');
        return false;
    }
};

MoveCamera.prototype.mouseUp = function () {
    this._clickPosition = null;
};

export default MoveCamera;