import WallHighlight from "../component/2D/WallHighlight";
import MoveWall from "../component/2D/MoveWall";
import ZoomCamera from "../component/2D/ZoomCamera";
import DrawCustomRoom  from '../component/2D/DrawCustomRoom';

; (function () {
    'use strict';

    var directive = function () {
        return {
            templateUrl: './app/views/steps/step2.html',
            restrict: 'E',
            controller: controller
        }
    };

    var dependencies = ['$scope', '$rootScope', 'Scene2d', 'sceneHelper', '$window',
        'constants', '$timeout', 'engine2DSrvc', 'roomStateManager', 'roomSizeManager'];

    var controller = function ($scope, $rootScope, Scene2d, sceneHelper, $window,
        constants, $timeout, engine2DSrvc, roomStateManager, roomSizeManager) {

        var popup = angular.element('.popup-info'); //popup info when selected wall
        var popupPoint = angular.element('.popup-info-point'); //popup info when selected point
        $rootScope.checkPopupInfoHidden = false;
        var timerId;

        var wallHighlight = engine2DSrvc.get().findComponentByType(WallHighlight);
        var moveWall = engine2DSrvc.get().findComponentByType(MoveWall);
        var zoomCamera = engine2DSrvc.get().findComponentByType(ZoomCamera);

        var selectPointUnsubscribe = $rootScope.$on('selectPoint', function () {
            var popupPoint = angular.element('.popup-info-point');
            popupPoint.fadeIn('slow');
        });

        $scope.popupInfoPointHidden = function () {
            var popupPoint = angular.element('.popup-info-point');
            popupPoint.fadeOut('slow');
        };

        var hiddenPopupInfoUnsubscribe = $rootScope.$on('hiddenPopupInfo', function () {
            //popup.hide();
            popupPoint.hide();
        });

        var changeFloorAreaUnsubscribe = $rootScope.$on('changeFloorArea', function () {
            //if ($scope.VisibleStep2)
            roomSizeManager.updateFloorArea(Scene2d.getObjectByName('floor'));
        });

        var changeUnitLengthForWallUnsubscribe = $rootScope.$on('changeUnitLengthForWall', function (e, unit) {

            roomSizeManager.changeCurrentSizeUnits(unit);

            var walls = Scene2d.getWalls();
            roomSizeManager.updateWallsSizes(walls);

            $rootScope.$broadcast('changeFloorArea');

            Scene2d.render();
        });

        $scope.fitScreen = function () {
            zoomCamera.fitRoom();
        };

        var zoomUnsubscribe = zoomCamera.on('zoom', function (zoom) {
            if ($scope.VisibleStep2) {
                roomSizeManager.scaleAllSizes(Scene2d.getFloor(), Scene2d.getWalls(), zoom);
                $rootScope.$broadcast('updateWallSizeDialog');
                Scene2d.render();
            }
        });

        var wallClickUnsubscribe = wallHighlight.on('select', function (wall) {
            if (wall) {
                $rootScope.$broadcast('updateWallSizeDialog', wall.name);
            }
            else {
                $rootScope.$broadcast('updateWallSizeDialog');
                $rootScope.$broadcast('hiddenPopupInfo');
            }
        });

        var drawCustomRoom = engine2DSrvc.get().findComponentByType(DrawCustomRoom);
        var roomCreateUnsubscribe = drawCustomRoom.on('created', function (points) {
            zoomCamera.fitRoom();
            roomStateManager.setPoints(points);
            $rootScope.$broadcast('changeFloorArea');
        });

        drawCustomRoom.on("zoomOut", function(){
            zoomCamera.zoomInOut(1);
        });

        var updateWallSizeInput = function () {
            $rootScope.$broadcast('updateWallSizeDialog');
        };

        $window.addEventListener('resize', updateWallSizeInput, false);

        var activeStepChangeUnregister = $rootScope.$on('stepActive', function (events, step) {
            roomSizeManager.setFloorAreaVisibility(Scene2d.getFloor(), step === 'step2');
            Scene2d.setObjectsVisibilityByPredicate(function (obj) {
                return obj.userData.isConnectionPoint;
            }, step === 'step2');
            if (step === 'step2' || step === 'step3') {
                $timeout(function () {
                    Scene2d.setCanvas(step);
                    zoomCamera.fitRoom();
                    Scene2d.render();
                }, 200);
            }
        });

        $scope.$on('$destroy', function () {
            zoomUnsubscribe();
            wallClickUnsubscribe();
            roomCreateUnsubscribe();
            selectPointUnsubscribe();
            hiddenPopupInfoUnsubscribe();
            changeFloorAreaUnsubscribe();
            changeUnitLengthForWallUnsubscribe();
            $window.removeEventListener('resize', updateWallSizeInput, false);
            activeStepChangeUnregister();
        });
    };

    controller.$inject = dependencies;

    angular.module('valleyCraftApp').directive('step2', directive);

})();
