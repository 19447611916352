<mat-accordion>
  <!--  <mat-expansion-panel-->
  <!--    class="products-menu mat-expansion-panel-spacing border border-gray-300"-->
  <!--  >-->
  <!--    <mat-expansion-panel-header>-->
  <!--      <mat-panel-title>Choose item</mat-panel-title>-->
  <!--    </mat-expansion-panel-header>-->
  <!--    <div class="flex flex-col gap-4">-->
  <!--      <pb-table-->
  <!--        *ngFor="let table of tables"-->
  <!--        [item]="table"-->
  <!--        [isActive]="table.name === activeTable.name"-->
  <!--        (click)="setActiveTable(table)"-->
  <!--      ></pb-table>-->
  <!--    </div>-->
  <!--  </mat-expansion-panel>-->

  <!--  <mat-expansion-panel-->
  <!--    class="products-menu mat-expansion-panel-spacing border border-gray-300"-->
  <!--  >-->
  <!--    <mat-expansion-panel-header>-->
  <!--      <mat-panel-title>Choose object type</mat-panel-title>-->
  <!--    </mat-expansion-panel-header>-->
  <!--    <div class="flex flex-col gap-4">-->
  <!--      <pb-object-type-->
  <!--        *ngFor="let objectType of objectTypes"-->
  <!--        [item]="objectType"-->
  <!--        [isActive]="objectType === activeObjectType"-->
  <!--        (click)="setActiveObjectType(objectType)"-->
  <!--      ></pb-object-type>-->
  <!--    </div>-->
  <!--  </mat-expansion-panel>-->

  <!-- <mat-expansion-panel
    class="products-menu mat-expansion-panel-spacing border border-gray-300"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Choose bins</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="grid grid-cols-2">
      <pb-bin *ngFor="let bin of bins" [item]="bin"></pb-bin>
    </div>
  </mat-expansion-panel> -->
  <p>
    <input
      type="checkbox"
      [checked]="mountable"
      (change)="changeCheckMountable($event)"
    /><label>Mountable</label>
  </p>
  <mat-expansion-panel
    class="products-menu mat-expansion-panel-spacing border border-gray-300"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>AkroBin® Hanging and Stacking Bin</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="grid grid-cols-2">
      <ng-container *ngFor="let bin of modelBins">
        <pb-bin  *ngIf="bin.dropId === '8-0'" [item]="bin"></pb-bin>
      </ng-container>
    </div>
  </mat-expansion-panel>

  <!-- <mat-expansion-panel
    class="products-menu mat-expansion-panel-spacing border border-gray-300"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>ShelfMax® Bins</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="grid grid-cols-2">
      <ng-container *ngFor="let bin of modelBins">
        <pb-bin *ngIf="bin.dropId === '8-1'" [item]="bin"></pb-bin>
      </ng-container>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    class="products-menu mat-expansion-panel-spacing border border-gray-300"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Shelf Bins</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="grid grid-cols-2">
      <ng-container *ngFor="let bin of modelBins">
        <pb-bin *ngIf="bin.dropId === '8-2'" [item]="bin"></pb-bin>
      </ng-container>
    </div>
  </mat-expansion-panel> -->

  <mat-expansion-panel
    class="products-menu mat-expansion-panel-spacing border border-gray-300"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Choose colors</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex flex-wrap gap-4">
      <pb-bin-color
        *ngFor="let binColor of  dataSubjectColorVarians$ | async"
        [item]="binColor"
      ></pb-bin-color>
    </div>
  </mat-expansion-panel>

  <!--  <mat-expansion-panel-->
  <!--    class="products-menu mat-expansion-panel-spacing border border-gray-300"-->
  <!--  >-->
  <!--    <mat-expansion-panel-header>-->
  <!--      <mat-panel-title>Bins</mat-panel-title>-->
  <!--    </mat-expansion-panel-header>-->
  <!--    <div class="grid grid-cols-2">-->
  <!--      <pb-bin *ngFor="let bin of modelBins" [item]="bin"></pb-bin>-->
  <!--    </div>-->
  <!--  </mat-expansion-panel>-->
</mat-accordion>
