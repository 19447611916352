import TouchAndMouseMap from '../TouchAndMouseMap';
var Scene3DNavigationJoystick = function (dependencyContainer) {
    TouchAndMouseMap.prototype.constructor.call(this);

    this._scene3D = dependencyContainer.getService('scene3D');
    this._orbitControl = dependencyContainer.getService('orbitControl');
    this._scene3dOverlay = dependencyContainer.getService('scene3dOverlay');
    this._sceneHelper = dependencyContainer.getService('sceneHelper');

    this._navigationTimeout = null;
    this._speed = 3;
    this._joystickClick = false;
};

Scene3DNavigationJoystick.prototype = Object.create(TouchAndMouseMap.prototype);

Scene3DNavigationJoystick.prototype.constructor = Scene3DNavigationJoystick;

Scene3DNavigationJoystick.prototype._pan = function (direction) {
    this._orbitControl.pan(direction.x, direction.y);
};

Scene3DNavigationJoystick.prototype.hold = function (e) {

    var xy = this._sceneHelper.getCanvasClientXY(e, this._scene3D.getHtmlElement());
    var button = this._scene3dOverlay.handleNavigation(xy.x, xy.y);

    clearInterval(this._navigationTimeout);

    this._joystickClick = false;

    switch (button) {
        case 'up':
            this._navigationTimeout = setInterval(angular.bind(this, this._pan, new THREE.Vector2(0, this._speed)), 1);
            break;
        case 'down':
            this._navigationTimeout = setInterval(angular.bind(this, this._pan, new THREE.Vector2(0, -this._speed)), 1);
            break;
        case 'left':
            this._navigationTimeout = setInterval(angular.bind(this, this._pan, new THREE.Vector2(this._speed, 0)), 1);
            break;
        case 'right':
            this._navigationTimeout = setInterval(angular.bind(this, this._pan, new THREE.Vector2(-this._speed, 0)), 1);
            break;
        case 'center': this._scene3D.centralizeCamera();
            break;
    }

    if (button) {
        this._joystickClick = true;
        this.fireEvent('buttonClick', button);
        return false;
    }
};

Scene3DNavigationJoystick.prototype.release = function () {
    clearInterval(this._navigationTimeout);
    this._navigationTimeout = null;
    if (this._joystickClick) 
        return false;   
};

Scene3DNavigationJoystick.prototype.dblclick = function (e) {
    if (this._joystickClick) {
        e.stopImmediatePropagation(); //need to not enter wall mode
        return false;
    }
};

export default Scene3DNavigationJoystick;