import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import constants from 'src/app/core_/services/constants';

@Injectable({
  providedIn: 'root',
})
export class RootService {
  protected apiUrl = constants.API_URL;

  // constructor(@Inject(HttpClient) http: HttpClient) {}
  constructor(http: HttpClient) {}
}
