import angular from 'angular';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import {ChangeDetectionStrategy,  Component } from '@angular/core';

@Component({
  selector: 'pb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  title = 'angularjs-to-angular';
}

/* propagateDigest */
angular.module('valleyCraftApp').directive(
  'pbRoot',
  downgradeComponent({
    component: AppComponent, propagateDigest: false
  }) as angular.IDirectiveFactory
);