(function () {
  var service = function ($http, toastr, constants) {
    return {
      sendEmail: function (data) {
        //console.log(data);
        $http({
          method: "POST",
          url: constants.API_URL + "/share/send-email",
          data: data,
        }).then(
          function (result) {
            console.log("_ajaxRequest success");
            toastr.success("Email sent");
          },
          function (result) {
            console.log("_ajaxRequest error");
          }
        );
        // .success()
        // .error();
      },

      share: function (img) {
        var data = {
          img: img,
        };
        $http({
          method: "POST",
          url: constants.API_URL + "/share/social",
          data: data,
        }).then(
          function (result) {
            //console.log("_ajaxRequest success");
            console.log(result);
            //toastr.success('Email sent');
            //location.href = result.redirect;
            window.open(constants.API_URL + result.data.redirect, "_blank");
          },
          function (result) {
            console.log("_ajaxRequest error", result);
            //console.log(result);
            //deferred.reject(data);
          }
        );
        // .success()
        // .error();
      },
    };
  };

  angular
    .module("valleyCraftApp")
    .service("SocialShare", ["$http", "toastr", "constants", service]);
})();
