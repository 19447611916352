(function () {
    'use strict';

    var dependencies = ['roomStuff'];

    var service = function (roomStuff) {
        var getDefaultSuiteObject3dColorScheme = function (obj) {
            var entity = obj.userData.entity;
            var fallBackColorScheme = entity.color_scheme ? entity.color_scheme[0] : null;
            var category = entity.left_menu_alias;
            var suite = roomStuff.getById(obj.userData.suiteId);
            var suiteColorSchemeObj = suite.color_schemes ? suite.color_schemes[0] : null;
            if (suiteColorSchemeObj && category) {
                var colorScheme = suiteColorSchemeObj.color_scheme[category];
                if (colorScheme) {
                    return colorScheme;
                }
            }
            return fallBackColorScheme;
        };

        return {
            getDefaultSuiteObject3dColorScheme: getDefaultSuiteObject3dColorScheme
        };
    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('suiteUtilsSrvc', service);
})();
