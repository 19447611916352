import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AddEventListenerService {
  addListenerAll(domElement, callback) {
    domElement.addEventListener('mousedown', callback, false);
    domElement.addEventListener('touchstart', callback, false);
    domElement.addEventListener('mousemove', callback, false);
    domElement.addEventListener('touchmove', callback, false);
    domElement.addEventListener('touchmove', callback, false);
    document.addEventListener('mouseup', callback, false);
    domElement.addEventListener('touchend', callback, false);
    domElement.addEventListener('touchcancel', callback, false);
    domElement.addEventListener('touchleave', callback, false);
  }

  removeListenerAll(domElement, callback) {
    domElement.removeEventListener('mousedown', callback);
    domElement.removeEventListener('touchstart', callback);
    domElement.removeEventListener('mousemove', callback);
    domElement.removeEventListener('touchmove', callback);
    domElement.removeEventListener('touchmove', callback);
    document.removeEventListener('mouseup', callback);
    domElement.removeEventListener('touchend', callback);
    domElement.removeEventListener('touchcancel', callback);
    domElement.removeEventListener('touchleave', callback);
  }
  /**
   *
   * @param domElement
   * @param callback (event) =>{}
   */
  addListenerMouseMove(domElement, callback) {
    domElement.addEventListener('mousemove', callback, false);
  }

  /**
   *
   * @param domElement
   * @param callback (event) =>{}
   */
  addListenerMouseDown(domElement, callback) {
    domElement.addEventListener('mousedown', callback, false);
  }

    /**
   *
   * @param domElement
   * @param callback (event) =>{}
   */
     addListenerMouseUp(domElement, callback) {
      domElement.addEventListener('mouseup', callback, false);
    }


}
