import * as THREE from 'three';
import {getIntendBinFromPanel} from "./zPosition";
import {getStepForBinRowPosition} from "./getStepForBinRowPosition";
import {getIntersectionObjectHeight} from "./checkIntersection";

export const setStartBinPosition = (
  object,
  mainObjectName,
  mainObjectDropId,
  mainObjectBaseName,
  main_object_coords,
  objects,
  scene,
  camera,
  productForShoppingList
) => {
  // shelf
  if (mainObjectName.toLowerCase().includes('shelv') || mainObjectName.toLowerCase().includes('shelf')) {
    setStartBinPositionForShelf(object, main_object_coords, objects, scene, productForShoppingList);
  }
  // panel
  if (mainObjectDropId === '1-0') {
    setStartBinPositionForPanel(object, mainObjectDropId, main_object_coords, objects, scene, productForShoppingList);
  }
  // panel rack
  if (mainObjectBaseName === '30651' || mainObjectBaseName === '30661') {
    setStartBinPositionForPanel(object, mainObjectBaseName, main_object_coords, objects, scene, productForShoppingList);
  }
  // rail
  if (mainObjectBaseName === '30632' || mainObjectBaseName === '30148' || mainObjectBaseName === '30008') {
    setStartBinPositionForPanel(object, mainObjectBaseName, main_object_coords, objects, scene, productForShoppingList);
  }
  // panel rack for both sides
  if (mainObjectBaseName === '30658' || mainObjectBaseName === '30676' || mainObjectBaseName === '30653') {
    setStartBinPositionForBothSides(object, mainObjectBaseName, main_object_coords, objects, scene, camera, productForShoppingList);
  }
  // rail for both sides
  if (mainObjectBaseName === '30016') {
    setStartBinPositionForBothSides(object, mainObjectBaseName, main_object_coords, objects, scene, camera, productForShoppingList);
  }
  // rail rack for both sides
  if (mainObjectBaseName === '30812') {
    setStartBinPositionForBothSides(object, mainObjectBaseName, main_object_coords, objects, scene, camera, productForShoppingList);
  }
}

function setStartBinPositionForPanel(object, dropId = '', main_object_coords, objects, scene, productForShoppingList) {
  // console.log('setStartBinPosition dropId', dropId);
  let isRemove = true;
  let intendBinFromPanel = getIntendBinFromPanel(dropId);

  let step = getStepForBinRowPosition(dropId);
  // console.log('setStartBinPosition start object', object);
  let targetObject = object.children[0];
  const testObject = new THREE.Mesh(
    targetObject.geometry,
    targetObject.materials
  );
  const newBox = new THREE.Box3().setFromObject(targetObject);
  const itemWidth = newBox.max.x - newBox.min.x;
  const itemHeight = newBox.max.y - newBox.min.y;
  const itemLength = newBox.max.z - newBox.min.z;
  let startParams = {
    x: main_object_coords.min.x + itemWidth / 2,
    y: main_object_coords.max.y - itemHeight / 2,
    z: main_object_coords.min.z + intendBinFromPanel + itemLength / 2,
  };
  testObject.position.set(startParams.x, startParams.y, startParams.z);
  if (getIntersectionObjectHeight(testObject, objects)) {
    let intersectionObjectHeight = getIntersectionObjectHeight(testObject, objects);
    for (
      // let yPosition = startParams.y - intersectionObjectHeight - 0.01;
      let yPosition = startParams.y - step - 0.01;
      // yPosition - intersectionObjectHeight / 2 > main_object_coords.min.y;
      yPosition + intersectionObjectHeight / 2 > main_object_coords.min.y;
      // yPosition = yPosition - intersectionObjectHeight - 0.01
      yPosition = yPosition - step - 0.01
    ) {
      testObject.position.y = yPosition;
      if (!getIntersectionObjectHeight(testObject, objects)) {
        startParams.y = yPosition;
        isRemove = false;
        break;
      }
    }``
  } else {
    isRemove = false;
  }

  if (isRemove) {
    // if all variants are fail then delete object from scene
    const selectedObject = scene.getObjectByProperty('uuid', targetObject.uuid);
    // console.log('selectedObject', selectedObject);
    if(selectedObject) {
      productForShoppingList.splice(productForShoppingList.indexOf(targetObject.uuid))
      scene.remove(selectedObject.parent)
    }
  } else {
    objects.push(object);
    scene.traverse((child) => {
      if (child.uuid === targetObject.uuid) {
        child.position.z = startParams.z;
        child.position.x = startParams.x;
        child.position.y = startParams.y;
        child.side = 'FRONT';
        child.startObjectPosition = {
          x: startParams.x,
          y: startParams.y,
          z: startParams.z
        };
      }
    });
  }
}

function setStartBinPositionForBothSides(object, dropId = '', main_object_coords, objects, scene, camera, productForShoppingList) {
  // console.log('setStartBinPositionForBothSides dropId', dropId);
  let isRemove = true;
  let isOtherSide = false;
  let intendBinFromPanel = getIntendBinFromPanel(dropId);
  let step = getStepForBinRowPosition(dropId);
  // console.log('setStartBinPosition start object', object);
  let targetObject = object.children[0];
  const testObject = new THREE.Mesh(
    targetObject.geometry,
    targetObject.materials
  );
  const newBox = new THREE.Box3().setFromObject(targetObject);
  const itemWidth = newBox.max.x - newBox.min.x;
  const itemHeight = newBox.max.y - newBox.min.y;
  const itemLength = newBox.max.z - newBox.min.z;
  let startParams = {
    x: main_object_coords.min.x + itemWidth / 2,
    y: main_object_coords.max.y - itemHeight / 2,
    z: (main_object_coords.max.z + main_object_coords.min.z)/2 + intendBinFromPanel + itemLength / 2,
  };
  let startParamsOtherSide = {
    x: main_object_coords.max.x - itemWidth / 2,
    y: main_object_coords.max.y - itemHeight / 2,
    z: (main_object_coords.max.z + main_object_coords.min.z)/2 - intendBinFromPanel - itemLength / 2,
  };
  // console.log('startParams', startParams);
  // console.log('startParamsOtherSide', startParamsOtherSide);
  testObject.position.set(startParams.x, startParams.y, startParams.z);
  if (getIntersectionObjectHeight(testObject, objects)) {
    let intersectionObjectHeight = getIntersectionObjectHeight(testObject, objects);
    for (
      // let yPosition = startParams.y - intersectionObjectHeight - 0.01;
      let yPosition = startParams.y - step - 0.01;
      // yPosition - intersectionObjectHeight / 2 > main_object_coords.min.y;
      yPosition + intersectionObjectHeight / 2 > main_object_coords.min.y;
      // yPosition = yPosition - intersectionObjectHeight - 0.01
      yPosition = yPosition - step - 0.01
    ) {
      testObject.position.y = yPosition;
      if (!getIntersectionObjectHeight(testObject, objects)) {
        startParams.y = yPosition;
        isRemove = false;
        break;
      }
    }

    // filling other side
    if (isRemove) {
      isOtherSide = true;
      // camera.position.set(0, 0, -200)
      // scene.traverse(child => {if(child.userData.name === 'root'){
      //   // console.log('child', child);
      //   child.rotation.y = Math.PI
      // }})
      testObject.position.set(startParamsOtherSide.x, startParamsOtherSide.y, startParamsOtherSide.z);
      if (getIntersectionObjectHeight(testObject, objects)) {
        for (
          // let yPosition = startParams.y - intersectionObjectHeight - 0.01;
          let yPosition = startParamsOtherSide.y - step - 0.01;
          // yPosition - intersectionObjectHeight / 2 > main_object_coords.min.y;
          yPosition + intersectionObjectHeight / 2 > main_object_coords.min.y;
          // yPosition = yPosition - intersectionObjectHeight - 0.01
          yPosition = yPosition - step - 0.01
        ) {
          testObject.position.y = yPosition;
          // console.log('testObject', testObject.position);
          if (!getIntersectionObjectHeight(testObject, objects)) {
            startParamsOtherSide.y = yPosition;
            isRemove = false;
            break;
          }
        }
      } else {
        isRemove = false;
      }
    }
  } else {
    isRemove = false;
  }

  if (isRemove) {
    // if all variants are fail then delete object from scene
    const selectedObject = scene.getObjectByProperty('uuid', targetObject.uuid);
    if(selectedObject) {
      productForShoppingList.splice(productForShoppingList.indexOf(targetObject.uuid))
      scene.remove(selectedObject.parent)
    }
    // this.scene.traverse((child) => {
    //   if (child.uuid === targetObject.uuid) {
    //     // console.log('child', child);
    //     this.scene.remove(child.parent);
    //     // this.objects = this.objects.filter(el => el.children[0].uuid !== child.uuid)
    //     // console.log('this.objects', this.objects);
    //   }
    // });
  } else {
    // camera rotation
    isOtherSide ? camera.position.set(0, 0, -200) : camera.position.set(0, 0, 200)
    //
    let resultParams = isOtherSide ? startParamsOtherSide : startParams
    objects.push(object);
    scene.traverse((child) => {
      if (child.uuid === targetObject.uuid) {
        if (isOtherSide) {
          child.rotation.y = Math.PI
        }
        child.position.z = resultParams.z;
        child.position.x = resultParams.x;
        child.position.y = resultParams.y;
        child.side = isOtherSide ? 'BACK' : 'FRONT';
        child.startObjectPosition = {
          x: resultParams.x,
          y: resultParams.y,
          z: resultParams.z
        };
      }
    });
  }
}

function setStartBinPositionForShelf(object, main_object_coords, objects, scene, productForShoppingList) {
  let isRemove = true;
  let shelfsArr: any[] = [];
  scene.traverse((child) => {
    if (String(child.name).toLowerCase().includes('shelf')) {
      const childPosition = new THREE.Box3().setFromObject(child);
      child.positionMaxY = childPosition.max.y
      shelfsArr = [child, ...shelfsArr]
    }
  })
  shelfsArr = shelfsArr.sort((a, b) => b.positionMaxY - a.positionMaxY).slice(1);
  let targetObject = object.children[0];
  const testObject = new THREE.Mesh(
    targetObject.geometry,
    targetObject.materials
  );
  const newBox = new THREE.Box3().setFromObject(targetObject);
  // const newBoxFirstShelf = new THREE.Box3().setFromObject(shelfsArr[0]);
  // console.log('newBoxFirstShelf', newBoxFirstShelf);
  const itemWidth = newBox.max.x - newBox.min.x;
  const itemHeight = newBox.max.y - newBox.min.y;
  const itemLength = newBox.max.z - newBox.min.z;
  let startParams = {
    x: main_object_coords.min.x + itemWidth / 2,
    y: main_object_coords.max.y + itemHeight / 2 + 0.01,
    z: main_object_coords.min.z + 1 + itemLength / 2,
  };
  // console.log('startParams', startParams);
  testObject.position.set(startParams.x, startParams.y, startParams.z);
  for (let shelf of shelfsArr) {
    // console.log('yPosition',   yPosition);
    const newBoxShelf = new THREE.Box3().setFromObject(shelf);
    testObject.position.y = newBoxShelf.max.y + itemHeight / 2 + 0.01;
    if (!getIntersectionObjectHeight(testObject, objects)) {
      startParams.y = newBoxShelf.max.y + itemHeight / 2 + 0.01;
      isRemove = false;
      break;
    }
  }

  if (isRemove) {
    // if all variants are fail then delete object from scene
    const selectedObject = scene.getObjectByProperty('uuid', targetObject.uuid);
    if(selectedObject) {
        productForShoppingList.splice(productForShoppingList.indexOf(targetObject.uuid))
        scene.remove(selectedObject.parent);
        // objects = objects.filter(el => el.children[0].uuid !== child.uuid)
    }
  } else {
    objects.push(object);
    scene.traverse((child) => {
      if (child.uuid === targetObject.uuid) {
        child.position.z = startParams.z;
        child.position.x = startParams.x;
        child.position.y = startParams.y;
        child.side = 'FRONT';
        child.startObjectPosition = {
          x: startParams.x,
          y: startParams.y,
          z: startParams.z
        };
      }
    });
  }
}

// function setStartBinPositionForRail(object, dropId) {
//   let isRemove = true;
//   let intendBinFromPanel = getIntendBinFromPanel(dropId);
//   let targetObject = object.children[0];
//   const testObject = new THREE.Mesh(
//     targetObject.geometry,
//     targetObject.materials
//   );
//   const newBox = new THREE.Box3().setFromObject(targetObject);
//   const itemWidth = newBox.max.x - newBox.min.x;
//   const itemHeight = newBox.max.y - newBox.min.y;
//   const itemLength = newBox.max.z - newBox.min.z;
//   let startParams = {
//     x: main_object_coords.min.x + itemWidth / 2,
//     y: main_object_coords.max.y - itemHeight / 2,
//     z: main_object_coords.min.z + intendBinFromPanel + itemLength / 2,
//     // z: (main_object_coords.max.z + main_object_coords.min.z) / 2 + 1 + itemLength / 2,
//   };
//   console.log('startParams', startParams);
//   testObject.position.set(startParams.x, startParams.y, startParams.z);
//   if (this.getIntersectionObjectHeight(testObject)) {
//     let intersectionObjectHeight = this.getIntersectionObjectHeight(testObject);
//     for (
//       let yPosition = startParams.y - constants.RAIL_STEP_INDENT - 0.01;
//       yPosition - intersectionObjectHeight / 2 >
//       main_object_coords.min.y;
//       yPosition = yPosition - constants.RAIL_STEP_INDENT - 0.01
//     ) {
//       // console.log('yPosition',   yPosition);
//       testObject.position.y = yPosition;
//       if (!this.getIntersectionObjectHeight(testObject)) {
//         startParams.y = yPosition;
//         isRemove = false;
//         break;
//       }
//     }
//   } else {
//     isRemove = false;
//   }
//
//   if (isRemove) {
//     // if all variants are fail then delete object from scene
//     this.scene.traverse((child) => {
//       if (child.uuid === targetObject.uuid) {
//         // console.log('child', child);
//         this.scene.remove(child.parent);
//         // this.objects = this.objects.filter(el => el.children[0].uuid !== child.uuid)
//         // console.log('this.objects', this.objects);
//       }
//     });
//   } else {
//     this.objects.push(object);
//     this.scene.traverse((child) => {
//       if (child.uuid === targetObject.uuid) {
//         child.position.z = startParams.z;
//         child.position.x = startParams.x;
//         child.position.y = startParams.y;
//         child.side = -1;
//         child.startObjectPosition = {
//           x: startParams.x,
//           y: startParams.y,
//           z: startParams.z
//         };
//       }
//     });
//   }
// }
