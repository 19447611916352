<div
  style="display:flex; align-items: center"
  class="group hover:cursor-pointer"
  *ngIf="item"
>
  <div
    class="flex mr-1.5 cursor-pointer hover:bg-lime-400 transition duration-300 border-2 border-transparent group-hover:border-lime-400 rounded overflow-hidden"
    style="display:flex"
  >
    <img
      src="assets{{item.productDetailsImage}}"
      class="w-14 h-14 object-contain"
      style="width: 32px; margin-right: 5px"
      [alt]="item.colorSchemeName || ''"
      (click)="setColorBin(item)"
    />
  </div>
  <div>{{ item.colorSchemeName }}</div>
</div>
