'use strict';
/**
 * @ngdoc service
 * @name valleyCraftApp.service
 * @description
 * # service
 * Service in the valleyCraftApp.
 */
import * as THREE from '../libs/three';
var sc;
angular.module('valleyCraftApp').service('Scene2d', [
    '$window',
    'sceneHelper',
    'roomStuff',
    'geometryHelper',
    function ($window, sceneHelper, roomStuff, geometryHelper) {
        var domElement,
            camera,
            scene,
            activeStepCanvasWrappers = {},
            renderer,
            canvasHeight,
            canvasWidth,
            initialized = false,
            onWindowResize = function () {
                var parent = renderer.domElement.parentNode;
                //console.debug("Resize", renderer.domElement.parentNode);
                canvasWidth = parent.clientWidth;
                canvasHeight = parent.clientHeight;
                renderer.setSize(canvasWidth, canvasHeight);
                camera.left = -canvasWidth / 2;
                camera.right = canvasWidth / 2;
                camera.top = canvasHeight / 2;
                camera.bottom = -canvasHeight / 2;
                camera.updateProjectionMatrix();
                rend();
            },
            frustum = new THREE.Frustum(),
            rend = function () {
                renderer.render(scene, camera);
            };

        var axisHelper;

        var service = {
            scene: null,
            camera: null,
            domElement: null,
            init: function (width, height, step, canvasContainer) {
                canvasWidth = width;
                canvasHeight = height;

                if (!initialized) {
                    initialized = true;

                    scene = new THREE.Scene();
                    scene.name = '2D';

                    camera = new THREE.OrthographicCamera(
                        width / -2,
                        width / 2,
                        height / 2,
                        height / -2,
                        -1000,
                        1000
                    );
                    camera.position.x = 0;
                    camera.position.y = 0;

                    sc = scene;

                    var grid = new THREE.GridHelper(4000, 160, 0xe8e8e8, 0xe8e8e8);
                    grid.name = 'grid';
                    grid.rotation.x = Math.PI / 2;
                    scene.add(grid);

                    //axisHelper = new THREE.AxisHelper(40)
                    //axisHelper.position.z = 500;
                    //scene.add(axisHelper);

                    renderer = new THREE.WebGLRenderer({
                        antialias: true,
                        preserveDrawingBuffer: true
                    });
                    renderer.setPixelRatio(1);
                    renderer.setSize(width, height);
                    renderer.setClearColor(0xffffff, 1);

                    this.domElement = renderer.domElement;
                    this.scene = scene;
                    this.camera = camera;

                    $window.addEventListener('resize', onWindowResize, false);
                }

                activeStepCanvasWrappers[step] = canvasContainer;

                //console.debug("init", this.domElement);
                return this.domElement;
            },
            setCanvas: function (step) {
                //console.debug("set before", renderer.domElement);
                var wrapper = activeStepCanvasWrappers[step];

                if (wrapper) {
                    wrapper.append(renderer.domElement);

                    var tmpZoom = service.camera.zoom;

                    var parent = renderer.domElement.parentNode;

                    canvasWidth = parent.clientWidth
                        ? parent.clientWidth
                        : Number(parent.children[0].getAttribute('width'));
                    canvasHeight = parent.clientHeight
                        ? parent.clientHeight
                        : Number(parent.children[0].getAttribute('height'));

                    var canvasSize = { width: canvasWidth, height: canvasHeight };
                    renderer.setSize(canvasWidth, canvasHeight);

                    camera.left = -canvasWidth / 2;
                    camera.right = canvasWidth / 2;
                    camera.top = canvasHeight / 2;
                    camera.bottom = -canvasHeight / 2;
                    camera.updateProjectionMatrix();
                    camera.updateProjectionMatrix();

                    //var meshFloor;
                    //for (var i = 0; i < service.scene.children.length; i++) {
                    //    var item = service.scene.children[i];
                    //    if (item.floor) {
                    //        meshFloor = item;
                    //        break;
                    //    }
                    //}

                    //if (meshFloor)
                    // sceneHelper.calculateZoomCamera(meshFloor, service.camera, tmpZoom, canvasSize);
                    // console.debug("set after", renderer.domElement);
                    service.render();
                }
            },
            animate: function () {
                requestAnimationFrame(service.animate);
            },
            addModel: function (model) {
                scene.add(model);
            },
            remove: function () {
                scene.remove.apply(scene, arguments);
                if (arguments.length === 1) {
                    var obj = arguments[0];
                    if (obj.parent && !(obj.parent instanceof THREE.Scene)) obj.parent.remove(obj);
                }
            },
            render: function () {
                rend();
            },
            getFrustum: function () {
                camera.updateMatrix();
                camera.updateMatrixWorld();
                camera.matrixWorldInverse.getInverse(camera.matrixWorld);
                frustum.setFromMatrix(
                    new THREE.Matrix4().multiplyMatrices(
                        camera.projectionMatrix,
                        camera.matrixWorldInverse
                    )
                );
                return frustum;
            },
            worldToScreen: function (vector3) {
                vector3.project(camera);
                var boundingClientRect = renderer.domElement.getBoundingClientRect();
                vector3.x = ((vector3.x + 1) / 2) * canvasWidth;
                vector3.y = (-(vector3.y - 1) / 2) * canvasHeight;
                //var boundingClientRect = renderer.domElement.getBoundingClientRect(),
                //    x = (vector3.x * (boundingClientRect.width / 2)) + (boundingClientRect.width / 2) + boundingClientRect.left,
                //    y = -(vector3.y * (boundingClientRect.height / 2)) + (boundingClientRect.height / 2) + boundingClientRect.top;
                //vector3.set(x, y, 0);
            },
            getSizeCanvas: function () {
                return { width: canvasWidth, height: canvasHeight };
            },
            getObjectByName: function (name) {
                return scene.getObjectByName(name);
            },
            resize: onWindowResize,
            cameraRay: function (x, y) {
                return sceneHelper.getPickingRay(x, y, canvasWidth, canvasHeight, camera);
            },
            getSnapShots: function () {
                renderer.render(scene, camera);
                var result = renderer.domElement.toDataURL('image/png');
                return result;
            },
            screenToWorld: function (x, y) {
                var position = new THREE.Vector3(
                    (x / canvasWidth) * 2 - 1,
                    -(y / canvasHeight) * 2 + 1,
                    0
                );
                position.unproject(camera);
                return position;
            },
            getChildren: function () {
                return scene.children;
            },
            getCamera: function () {
                return camera;
            },
            clean: function () {
                var children = scene.children,
                    i = children.length - 1,
                    item;

                for (; i >= 0; i--) {
                    item = children[i];

                    if (!item || item.name === 'grid' || item === axisHelper) continue;

                    scene.remove(item);
                }
            },
            addMany: function (objects) {
                scene.add.apply(scene, objects);
            },
            getWalls: function () {
                return scene.children.filter(function (item) {
                    return item.userData.isWall;
                });
            },
            getWallByIndex: function (index) {
                return this.getObjectByName('Wall ' + index);
            },
            getConnectionPointByIndex: function (index) {
                return this.getObjectByName('connectionPoint ' + index);
            },
            getFloor: function () {
                return this.getObjectByName('floor');
            },
            getCanvas: function () {
                return renderer.domElement;
            },
            setObjectsVisibilityByPredicate: function (predicate, isVisible) {
                scene.traverse(function (item) {
                    if (predicate(item)) item.visible = isVisible;
                });
            },
            removeMany: function (objects) {
                scene.remove.apply(scene, objects);
            },
            getConnectionPoints: function () {
                return scene.children.filter(function (item) {
                    return item.userData.isConnectionPoint;
                });
            },
            getObjectByUUID: function (uuid) {
                var mesh;

                scene.traverse(function (obj) {
                    if (obj.uuid === uuid) {
                        mesh = obj;
                        return;
                    }
                });

                return mesh;
            },
            getEntityObjects: function () {
                var result = [];

                scene.traverse(function (obj) {
                    if (obj.userData.entity) result.push(obj);
                });

                return result;
            },
            getSuitesNewPosition: function (entity, box, index) {
                var i = 0;
                var objects = [];
                var currentLeftSide;
                var currentBackSide;
                var originalLeftSide = 5000;
                var originalBackSide = -5000;
                var position = new THREE.Vector3(0, 0, entity.objects[index].position.z);
                var obj = roomStuff.getById(entity.objects[index].id);
                var obj1;
                var sn = Math.sin(box.rotation._z);
                var cs = Math.cos(box.rotation._z);
                var x;
                var y;

                for (; i < entity.objects.length; i++) {
                    obj1 = roomStuff.getById(entity.objects[i].id);
                    objects[i] = new THREE.Object3D();
                    objects[i].position.set(
                        entity.objects[i].position.x,
                        entity.objects[i].position.y,
                        entity.objects[i].position.z
                    );

                    if (objects[i].position.x - obj1.length / 2 < originalLeftSide) {
                        originalLeftSide = objects[i].position.x - obj1.length / 2;
                    }

                    if (objects[i].position.y + obj1.width / 2 > originalBackSide) {
                        originalBackSide = objects[i].position.y + obj1.width / 2;
                    }
                }

                currentLeftSide = box.position.x - box.userData.entity.length / 2;
                currentBackSide = box.position.y + box.userData.entity.width / 2;

                position.x = entity.objects[index].position.x - originalLeftSide + currentLeftSide;
                position.y = entity.objects[index].position.y - originalBackSide + currentBackSide;

                x = position.x - box.position.x;

                // VCB-42
                // y = position.y - box.position.y - 3;
                y = position.y - box.position.y;

                position.x = cs * x - sn * y + box.position.x;
                position.y = sn * x + cs * y + box.position.y;

                return position;
            }
        };

        return service;
    }
]);
