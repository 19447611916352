import WallLengthChangeBase from './WallLengthChangeBase';
var WallNodeBase = function (dependencyContainer) {
    WallLengthChangeBase.prototype.constructor.call(this, dependencyContainer);
};

WallNodeBase.prototype = Object.create(WallLengthChangeBase.prototype);

WallNodeBase.prototype.constructor = WallNodeBase;

WallNodeBase.prototype._sorter = function (a, b) {
    return a.userData.index - b.userData.index
};

WallNodeBase.prototype.getWallsSorted = function () {
    var walls = this._Scene2d.getWalls();
    walls.sort(this._sorter);
    return walls;
};

WallNodeBase.prototype.getNodesSorted = function () {
    var nodes = this._Scene2d.getConnectionPoints();
    nodes.sort(this._sorter);
    return nodes;
};

WallNodeBase.prototype.rebuildFloor = function (points) {
    var newFloor = this._room2DBuilder.buildFloor(points);
    var oldFloor = this._Scene2d.getFloor();
    newFloor.add.apply(newFloor, oldFloor.children);
    this._roomSizeManager.updateFloorArea(newFloor);
    this._Scene2d.remove(oldFloor);
    this._Scene2d.addModel(newFloor);
};

WallNodeBase.prototype.changeWallsAndNodesNumbering = function (walls, connectionPoints) {

    var i = 0,
        wall,
        connectionPoint,
        number;

    for (; i < walls.length; i++) {

        number = i + 1;

        connectionPoint = connectionPoints[i];
        connectionPoint.userData.index = i;
        connectionPoint.name = 'connectionPoint ' + number;

        wall = walls[i];
        wall.name = 'Wall ' + number;
        wall.userData.index = i;
    }
};

export default WallNodeBase;