(function () {
    'use strict';

    var dependencies = ['geometryHelper', 'roomStuff', 'Scene2d', 'sceneHelper'];

    var service = function (geometryHelper, roomStuff, Scene2d, sceneHelper) {
        var getObjectsBySuiteId = function (suiteId, groupId) {
            var objects = [];
            Scene2d.scene.traverse(function (obj) {
                if (
                    obj.userData.entity &&
                    obj.userData.suiteId &&
                    obj.userData.suiteId.valueOf() === suiteId.valueOf() &&
                    obj.userData.groupId &&
                    obj.userData.groupId === groupId
                )
                    objects.push(obj);
            });
            return objects;
        };

        var getSuiteEntityById = function (suiteId) {
            var suites = roomStuff.suites();
            return _.find(suites, function (s) {
                return s.id.valueOf() === suiteId.valueOf();
            });
        };

        var getSuiteCenter = function (suiteId, groupId) {
            var objects = getObjectsBySuiteId(suiteId, groupId);

            var aabb = geometryHelper.objectsBoundingBox(
                _.map(objects, function (o) {
                    return o.children[0];
                })
            );

            var center = aabb.center();

            return center;
        };

        return {
            isSuite: function (objOrEntity) {
                return objOrEntity && objOrEntity.isSuite;
            },
            getObjectsBySuiteId: getObjectsBySuiteId,
            getSuiteCenter: getSuiteCenter,
            getSuiteEntityById: getSuiteEntityById
        };
    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('suiteUtils2DSrvc', service);
})();
