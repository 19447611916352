import { Injectable } from '@angular/core';

/*firebase.google.com */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  get token() {
    return localStorage.getItem('auth_token');
  }

  isAuthenticated() {
    return !!this.token;
  }
}
