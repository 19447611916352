import * as THREE from "../../libs/three";
import ComponentBase from "../ComponentBase";

var Select3D = function (dependencyContainer) {
  ComponentBase.prototype.constructor.call(this);

  this._scene3D = dependencyContainer.getService("scene3D");
  this._constants = dependencyContainer.getService("constants");
  this._dimensionalRadialsManager = dependencyContainer.getService(
    "dimensionalRadialsManager"
  );
  this._rayHelper = dependencyContainer.getService("rayHelper");
  this._objectCheckerSrvc = dependencyContainer.getService("objectCheckerSrvc");
  this._collisionSrvc = dependencyContainer.getService("collisionSrvc");
  this._scene2DSyncSrvc = dependencyContainer.getService("scene2DSyncSrvc");

  this._raycaster = new THREE.Raycaster();

  this.selectedObjects = [];
};

Select3D.prototype = Object.create(ComponentBase.prototype);

Select3D.prototype.constructor = Select3D;

Select3D.prototype.mouseDown = function (e) {
  this.mouseX = e.offsetX;
  this.mouseY = e.offsetY;
};

Select3D.prototype._alreadySelected = function (pickedObj) {
  var index = this.selectedObjects.indexOf(pickedObj);
  if (this.selectedObjects.length === 1 && index !== -1) {
    this.selectedObjects[index].getObjectByName("selection").visible = false;
    this.selectedObjects.splice(index, 1);
    this.fireEvent("select", this.selectedObjects);
    return true;
  }
  return false;
};

Select3D.prototype.mouseUp = function (e) {
  this._dimensionalRadialsManager.deleteDimensionalRadials();

  //check is mouse position change during mousedown
  if (e.offsetX !== this.mouseX && e.offsetY !== this.mouseY) return;

  this._scene3D.getPickingRay(e.offsetX, e.offsetY, this._raycaster);

  var sceneObjs = this._scene3D.getChildren().filter(function (obj) {
    return (obj.visible && obj instanceof THREE.Mesh) /* || (obj.visible && obj instanceof THREE.Group) */;
  });
  var intersections = this._rayHelper.intersectObjectsObb(
      this._raycaster.ray,
      sceneObjs
    ),
    intersection;
  for (var i = 0; i < intersections.length; i++) {
    if (!intersections[i].object.material.visible) {
      continue;
    } else {
      intersection = intersections[i];
      break;
    }
  }

  // intersection = this._rayHelper.intersectObjectsObb(this._raycaster.ray, sceneObjs)[0];
  // if (!intersection.object.material.visible) intersection = this._rayHelper.intersectObjectsObb(this._raycaster.ray, sceneObjs)[1];

  if (intersection) {
    var selectedObject = intersection.object;

    if (!this._objectCheckerSrvc.isWallOrFloor(selectedObject)) {
      this._scene3D.add(
        this._dimensionalRadialsManager.getDimensionalRadials(selectedObject)
      );
      // this._scene3D.drawDimensionalRadials(selectedObject);
    }

    var groupId = selectedObject.userData.groupId;
    if (groupId) {
      this.fireEvent("groupSelect", true);

      this.setSelectionVisibility(false);
      this.selectedObjects.length = 0;

      for (var i = 0; i < sceneObjs.length; i++) {
        var chld = sceneObjs[i];
        if (chld.userData && chld.userData.groupId === groupId) {
          this.selectedObjects.push(chld);
          // this.fireEvent('select', this.selectedObjects);
        }
      }

      this.setSelectionVisibility(true);
      if (selectedObject.userData.suiteId) {
        this.fireEvent("select", this.selectedObjects);
      }
    } else {
      this.fireEvent("groupSelect", false);

      if (this._alreadySelected(selectedObject)) return;

      if (
        e.shiftKey &&
        this._objectCheckerSrvc.sameWallInteraction(
          selectedObject,
          this.selectedObjects[0]
        ) &&
        !this._objectCheckerSrvc.isWallOrFloor(selectedObject) &&
        this.selectedObjects.length !== 0 &&
        !this._objectCheckerSrvc.isWallOrFloor(this.selectedObjects[0])
      ) {
        this.selectedObjects.push(selectedObject);
        this.setSelectionVisibility(true);
        this.fireEvent("select", this.selectedObjects);
      } else {
        this.setSelectionVisibility(false);
        this.selectedObjects.length = 0;
        this.selectedObjects.push(selectedObject);
        this.fireEvent("select", this.selectedObjects);
        this.setSelectionVisibility(true);
      }
    }
  } else {
    this.setSelectionVisibility(false);
    this.selectedObjects.length = 0;
    this.fireEvent("select", this.selectedObjects);
  }
};

Select3D.prototype.dblclick = function (e) {
  this._dimensionalRadialsManager.deleteDimensionalRadials();

  this._scene3D.getPickingRay(e.offsetX, e.offsetY, this._raycaster);

  var sceneObjects = this._scene3D.getChildren().filter(
    function (obj) {
      return (
        obj.visible &&
        obj instanceof THREE.Mesh &&
        !this._objectCheckerSrvc.isWallOrFloor(obj)
      );
    }.bind(this)
  );
  var intersection = this._rayHelper.intersectObjectsObb(
    this._raycaster.ray,
    sceneObjects
  );
  //this._raycaster.intersectObjects(sceneObjects);

  if (intersection.length !== 0) {
    this.fireEvent("objectDoubleClick", intersection);
    return false;
  }
};

Select3D.prototype.clearSelection = function () {
  this.setSelectionVisibility(false);
  this.selectedObjects.length = 0;
};

Select3D.prototype.setSelectionVisibility = function (bool) {
  var i = 0,
    selection,
    selected = this.selectedObjects;
  for (; i < selected.length; i++) {
    selection = selected[i].getObjectByName("selection");
    if (selection) selection.visible = bool;
  }
};

Select3D.prototype.disable = function () {
  ComponentBase.prototype.disable.call(this);
  this.clearSelection();
};

Select3D.prototype.getSelected = function () {
  return this.selectedObjects;
};

Select3D.prototype.keyDown = function (e) {
  if (
    e.ctrlKey === true &&
    this.selectedObjects.length > 0 &&
    (e.keyCode === 37 ||
      e.keyCode === 38 ||
      e.keyCode === 39 ||
      e.keyCode === 40)
  ) {
    for (var i = 0; i < this.selectedObjects.length; i++) {
      var selectedObject = this.selectedObjects[i];

      if (!this._objectCheckerSrvc.isWallOrFloor(selectedObject)) {
        var axis = new THREE.Vector3(0, 1, 0);
        var position = selectedObject.position;
        var staticObjects = this._scene3D.getChildren().filter(function (item) {
          return (
            item.userData.entity &&
            item !== selectedObject &&
            item.name !== "ghostObject" &&
            item.uuid !== "floor"
          );
        });

        switch (e.keyCode) {
          case 37:
            {
              // left
              var angle = Math.PI / 2;
              var direction = selectedObject
                .getWorldDirection()
                .applyAxisAngle(axis, angle);
              var newPosition = position
                .clone()
                .addScaledVector(direction, 0.635);
            }
            break;
          case 38:
            {
              // up
              var direction = selectedObject.getWorldDirection();
              var newPosition = position
                .clone()
                .addScaledVector(direction, 0.635);
            }
            break;
          case 39:
            {
              // right
              var angle = 3 * (Math.PI / 2);
              var direction = selectedObject
                .getWorldDirection()
                .applyAxisAngle(axis, angle);
              var newPosition = position
                .clone()
                .addScaledVector(direction, 0.635);
            }
            break;
          case 40:
            {
              // down
              var angle = Math.PI;
              var direction = selectedObject
                .getWorldDirection()
                .applyAxisAngle(axis, angle);
              var newPosition = position
                .clone()
                .addScaledVector(direction, 0.635);
            }
            break;
        }

        if (
          !this._collisionSrvc.isCollide(selectedObject, staticObjects, {
            draggedObjectPosition: newPosition,
            upAxis: "y",
          })
        ) {
          position.copy(newPosition);
          this._dimensionalRadialsManager.deleteDimensionalRadials();
          this._scene3D.add(
            this._dimensionalRadialsManager.getDimensionalRadials(
              selectedObject
            )
          );
          this._sync2DObjects();
        } else {
          e.stopPropagation();
          return;
        }
      }
    }
    e.stopPropagation();
  }
};

Select3D.prototype._sync2DObjects = function () {
  var selected = this.selectedObjects,
    i = 0,
    obj3D;
  for (; i < selected.length; i++) {
    obj3D = selected[i];

    if (obj3D.userData.wall) {
      this._scene2DSyncSrvc.moveWallObject(obj3D, obj3D.userData.wall);
    } else {
      this._scene2DSyncSrvc.moveObject(obj3D);
    }
  }
};
export default Select3D;
