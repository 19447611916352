<mat-dialog-content>
  <div class="flex justify-between flex-wrap">
<!--    <pb-bin-color-->
<!--      *ngFor="let binColor of  dataSubjectColorVarians$ | async"-->
<!--      [item]="binColor"-->
<!--    ></pb-bin-color>-->
      <img
        class="w-14 h-14 object-contain mr-1.5 cursor-pointer hover:bg-lime-400 transition duration-300 border-2 border-transparent group-hover:border-lime-400 rounded overflow-hidden"
        *ngFor="let binColor of  dataSubjectColorVarians$ | async"
        src="assets{{binColor.productDetailsImage}}"
        style="width: 32px; margin-right: 5px"
        [alt]="binColor.colorSchemeName || ''"
        (click)="setColorBin(binColor)"
      />
  </div>
</mat-dialog-content>

