<label>
  <input
    type="checkbox"
    [checked]="accepted"
    (change)="changeHandler($event.target.checked)"
  />
  I agree to
</label>
<span (click)="openDialog('100ms', '200ms')" class="terms-link"
  >Terms and Conditions</span
>
