angular.module("valleyCraftApp").service("configuratorService", [
  "constants",
  "$http",
  "$q",
  "AuthenticationService",
  function (constants, $http, $q, AuthenticationService) {
    var userName;
    var userNameScene = "MasterScene";

    var userName = AuthenticationService.userLogged();   
       
    if (userName) {
      userNameScene = userName.firstName + userName.lastName;
    }

    var apiUrl = constants.API_URL;
    var _ajaxRequest = function (method, URL, data, key) {
      //<-- removed $http dependency from here
      console.log("HERE");
      var URL = apiUrl + URL;
      $http({ method: method, url: URL, data: { data } }).then(
        function (response) {
          console.log(response.data);
          return response.data;
        },
        function (error) {
          errorCallback(error, "URL ", URL);
        }
      );
    };

    const _getJson = function (urlJson) {
      var deferred = $q.defer();
      $http.get(urlJson).then(
        function (response) {
          console.log(response.data);
          deferred.resolve(response.data);
        },
        function (error) {
          deferred.reject(data);
          errorCallback(error, "URL ", URL);
        }
      );
      return deferred.promise;
    };

    return {
      getJson: function (url) {
        return _getJson(url);
      },
      saveSceneJson: function (data) {
        return _ajaxRequest(
          "POST",
          `/configurator/configurator-js/${userNameScene}scene`,
          data,
          null
        );
      },
      deleteSceneOBJMTLJson: function (file) {
        return _ajaxRequest(
          "DELETE",
          "/configurator/files" + file,
          "data",
          null
        );
      },
    };
  },
]);
