import WallObjectDrag from "./WallObjectDrag";
import ObjectInWallRange from "../Class/ObjectInWallRange";

var WallMountObjectDrag = function (dependencyContainer, handlers) {
    WallObjectDrag.prototype.constructor.call(this, dependencyContainer, handlers);

    this._wallCutHelper = dependencyContainer.getService('wallCutHelper');
    this._objectCheckerSrvc = dependencyContainer.getService('objectCheckerSrvc');
    this._scene3D = dependencyContainer.getService('scene3D');

    this._curentlyDraggedObject = null;
    this._objectWallRangeChecker = new ObjectInWallRange(dependencyContainer);
};

WallMountObjectDrag.prototype = Object.create(WallObjectDrag.prototype);

WallMountObjectDrag.prototype.constructor = WallMountObjectDrag;

WallMountObjectDrag.prototype.canExecute = function (selected) {

    var i = 0,
        wall,
        obj,
        userData;

    for (; i < selected.length; i++) {
        obj = selected[i];
        userData = obj.userData;
        wall = i === 0 ? userData.wall : wall;
        if (userData.wall === wall && this._objectCheckerSrvc.isWallEmbeddable(obj))
            continue;
        else
            return false;
    }

    return true;
};

WallMountObjectDrag.prototype._updateWall = function (curentlyDraggedObject) {
    var wallName = curentlyDraggedObject.userData.wall,
        nonCuttedWall = this._scene3D.getNonCuttedWallByName(wallName),
        oldWall = this._scene3D.getObject(wallName),
        wallObjects = this._scene3D.getWallChildren(wallName),
        newWall = this._wallCutHelper.cutHolesInWall(nonCuttedWall, wallObjects, []);

    newWall.add.apply(newWall, oldWall.children);
   // oldWall.remove.apply(oldWall, oldWall.children);

    this._scene3D.replace(oldWall, newWall);
};

WallMountObjectDrag.prototype.drag = function (staticObjects, allDraggedObjects,
    curentlyDraggedObject, oldPos, newPos, dragState) {

    this._curentlyDraggedObject = curentlyDraggedObject;

    var resultingPosition = WallObjectDrag.prototype.drag.apply(this, arguments);

    if (!this._objectWallRangeChecker.check(curentlyDraggedObject, resultingPosition)) 
        resultingPosition = null;
    
    this._updateWall(curentlyDraggedObject);

    return resultingPosition;
};

WallMountObjectDrag.prototype.dragEnd = function () {
    this._objectWallRangeChecker.clearState();
    WallObjectDrag.prototype.dragEnd.call(this);
    this._updateWall(this._curentlyDraggedObject);
};

WallMountObjectDrag.prototype.getStaticObjectsFilter = function (draggedObjs) {
    var base = WallObjectDrag.prototype.getStaticObjectsFilter.call(this, draggedObjs);
    return function (obj) {
        return base(obj) &&
           !this._objectCheckerSrvc.isWall(obj);
    }.bind(this);
};

export default WallMountObjectDrag;