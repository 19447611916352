
<canvas
  #canvasContainer
  id="pb-configurator"
  style="width: 100%; height: 100%; position: relative"
  class="w-full h-full"
>
</canvas>
<!--<div-->
<!--  *ngIf="isPreloaderVisible()"-->
<!--  style="-->
<!--      font-size: 32px;-->
<!--      width: 100%;-->
<!--      height: 100%;-->
<!--      background-color: grey;-->
<!--      position: absolute;-->
<!--      left: 0;-->
<!--      top: 0;-->
<!--      display: flex;-->
<!--      justify-content: center;-->
<!--      align-items: center;-->
<!--      color: #fff;-->
<!--    "-->
<!--&gt;-->
<!--&lt;!&ndash;  <span>{{ getPreloaderProgress() + "%" }}</span>&ndash;&gt;-->
<!--</div>-->
<p>
<!--   <b>Mouse Action:</b>
  <label *ngFor="let item of radioButton">
    <input type="radio" [id]="item.id" [checked]="item.ischeck" />
    {{ item.name }}
  </label> -->
  <button id="goWithoutSaving">Cancel – Don’t Save</button>
<!--   <button id="download">DownloadModel</button> -->
  <button id="template" [disabled]="!upload">Save Configuration</button>
</p>
