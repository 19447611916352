import WallLengthChangeBase from './WallLengthChangeBase';
import * as THREE from '../../libs/three';
import ShowSizesOnFloorClick from './ShowSizesOnFloorClick';
import WallHighlight from './WallHighlight';
var MoveWall = function (dependencyContainer) {
    WallLengthChangeBase.prototype.constructor.call(this, dependencyContainer);

    this._keyState = dependencyContainer.getService('keyState');
    this._wallConnectionSrvc = dependencyContainer.getService('wallConnectionSrvc');
    
    this._raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, -1));
    this._wall = null;
};

MoveWall.prototype = Object.create(WallLengthChangeBase.prototype);

MoveWall.prototype.constructor = MoveWall;

MoveWall.prototype.init = function (engine) {
    WallLengthChangeBase.prototype.init.call(this, engine);
    this._showSizesOnFloorClick = this._engine.findComponentByType(ShowSizesOnFloorClick);
    this._wallHover = this._engine.findComponentByType(WallHighlight);
};

MoveWall.prototype.mouseDown = function (e) {
    this._clickTime = new Date().getTime();

    var p = this._Scene2d.screenToWorld(e.offsetX, e.offsetY);
    p.z = 0;

    this._raycaster.ray.set(new THREE.Vector3(p.x, p.y, 500), new THREE.Vector3(0, 0, -1));

    var walls = this._Scene2d.getWalls();
    var intersection = this._raycaster.intersectObjects(walls)[0];

    if (intersection) {
        this._wall = intersection.object;
        this._lastPosition = p;
    }
};

MoveWall.prototype.mouseMove = function (e) {

    var fastClick = new Date().getTime() - this._clickTime < 150;

    if (!fastClick && this._wall && this._keyState.isMouseHold) {

        this._wallHover.deselect();

        this._drag = true;

        this.fireEvent('move');

        var currentMousePosition = this._Scene2d.screenToWorld(e.offsetX, e.offsetY);
        currentMousePosition.z = 0;
        var movedDelta = currentMousePosition.clone().sub(this._lastPosition);

        this._lastPosition = currentMousePosition;

        this._shiftWall(this._wall, movedDelta);

        this._showSizes(true);

        this._Scene2d.render();
        this._Scene2d.domElement.style.cursor = 'move';

        return false;
    }
};

MoveWall.prototype._showSizes = function (bool) {
    var walls = this._Scene2d.getWalls(),
        i = 0;
    for (; i < walls.length; i++)
        this._roomSizeManager.setWallSizeVisibility(walls[i], bool);
};


MoveWall.prototype._movePoint = function (a, b, dragDistance, delta) {

    var dir = a.clone().sub(b).normalize();
    var moveDir = delta.clone().normalize();
    var dot = moveDir.dot(dir);

    if (dot < 0)
        dir.negate();

    dir.multiplyScalar(dragDistance);

    return a.clone().add(dir);
};

MoveWall.prototype._updateConnectionPoint = function (pointAIndex, pointBIndex) {
    var connectionPointA = this._Scene2d.getConnectionPointByIndex(pointAIndex + 1),
        connectionPointB = this._Scene2d.getConnectionPointByIndex(pointBIndex + 1),
        pointA = this._roomStateManager.getPoint(pointAIndex),
        pointB = this._roomStateManager.getPoint(pointBIndex);

    connectionPointA.position.copy(pointA);
    connectionPointB.position.copy(pointB);
    connectionPointB.position.z = connectionPointA.position.z = 500;
};

MoveWall.prototype._shiftWall = function (draggedWall, movedDelta) {

    var  points = this._roomStateManager.getPoints(),
         currentWallIndex = draggedWall.userData.index,
         nextWallIndex = this._roomStateManager.getNextPointIndex(currentWallIndex),
         prevWallIndex = this._roomStateManager.getPrevPointIndex(currentWallIndex),
         prevPrevIndex = this._roomStateManager.getPrevPointIndex(prevWallIndex),
         nextNextIndex = this._roomStateManager.getNextPointIndex(nextWallIndex),

         nextWall = this._Scene2d.getWallByIndex(nextWallIndex + 1),
         prevWall = this._Scene2d.getWallByIndex(prevWallIndex + 1),
         prevPrevWall = this._Scene2d.getWallByIndex(prevPrevIndex + 1),
         nextNextWall = this._Scene2d.getWallByIndex(nextNextIndex + 1),

         draggedWallDirection = draggedWall.getWorldDirection(),
         nextWallDirection = nextWall.getWorldDirection(),
         prevWallDirection = prevWall.getWorldDirection(),

         prevPoint = points[prevWallIndex],
         nextNextPoint = points[nextNextIndex],
         dragDistance = movedDelta.length(),
         moveDeltaAlongWallDirection = movedDelta.clone().projectOnVector(draggedWallDirection),
         currentPoint = this._movePoint(points[currentWallIndex], prevPoint, dragDistance, moveDeltaAlongWallDirection),
         nextPoint = this._movePoint(points[nextWallIndex], nextNextPoint, dragDistance, moveDeltaAlongWallDirection);

    if (
        !this._isWallLengthValid(currentPoint, nextPoint)
        || !this._isWallLengthValid(prevPoint, currentPoint)
        || !this._isWallLengthValid(nextPoint, nextNextPoint)
        || this._isIntersects({ point: currentPoint, index: currentWallIndex }, { point: nextPoint, index: nextWallIndex }, points)
        || this._hasSameAngle(draggedWall, prevWall)
        || this._hasSameAngle(draggedWall, nextWall)
        ) {
        return false;
    }

    this._roomStateManager.updatePoint(currentWallIndex, currentPoint);
    this._roomStateManager.updatePoint(nextWallIndex, nextPoint);

    this._room2DBuilder.updateWallLength(prevWall, prevPoint,currentPoint);
    this._room2DBuilder.updateWallLength(draggedWall, currentPoint,nextPoint);
    this._room2DBuilder.updateWallLength(nextWall, nextPoint,nextNextPoint);

    this._updateConnectionPoint(currentWallIndex, nextWallIndex);

    this._wallConnectionSrvc.connectTwoWalls2D(prevWall, draggedWall, currentPoint);
    this._wallConnectionSrvc.connectTwoWalls2D(draggedWall, nextWall, nextPoint);
    this._wallConnectionSrvc.connectTwoWalls2D(prevPrevWall, prevWall, prevPoint);
    this._wallConnectionSrvc.connectTwoWalls2D(nextWall, nextNextWall, nextNextPoint);

    this._roomSizeManager.updateWallSizes(prevWall);
    this._roomSizeManager.updateWallSizes(draggedWall);
    this._roomSizeManager.updateWallSizes(nextWall);

    this._updateFloor();
};

MoveWall.prototype.setWallLength = function (newLength,wall) {
    var currentWallIndex = wall.userData.index,
        nextWallIndex = this._roomStateManager.getNextPointIndex(currentWallIndex),
        currentPoint = this._roomStateManager.getPoint(currentWallIndex),
        nextPoint = this._roomStateManager.getPoint(nextWallIndex),
        nextWall = this._Scene2d.getWallByIndex(nextWallIndex + 1),
        nextWallDirection = nextWall.getWorldDirection(),
        currentWallLength = wall.geometry.vertices[2].x,
        lengthDelta = currentWallLength - newLength,
        dir = nextPoint.clone().sub(currentPoint).normalize(),
        sign = Math.sign(lengthDelta),
        dot = dir.dot(nextWallDirection);

    lengthDelta = Math.abs(lengthDelta);

    if ((sign === -1 && dot < 0) || (sign === 1 && dot > 0)) 
        nextWallDirection.negate();

    nextWallDirection.multiplyScalar(lengthDelta);
    this._shiftWall(nextWall, nextWallDirection);
};

MoveWall.prototype.mouseUp = function () {
    if (this._wall && this._drag) {
        this._wall.material.color.setHex(0x000000);
        this._showSizes(false);
        this._Scene2d.render();
    }
    this._drag = false;
    this._wall = null;
    this._Scene2d.domElement.style.cursor = 'auto';
};

export default MoveWall;