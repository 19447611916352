import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js';
import { scene3DService } from './scene3D.service';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
//import * as THREE from '../core_/libs/three';
declare const v3d: any;
// declare const scene: any;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageExporterObjMtlService {
  cloneScene: THREE.Scene;
  constructor(private scene3DService: scene3DService) {}

  createCloneScene() {
    this.cloneScene = new THREE.Object3D();
    this.scene3DService.scene.traverse((child) => {
      let newchild;
      if (child.parent instanceof THREE.Group) {
        newchild = child.clone();
        this.cloneScene.add(newchild);
      }
    });
    return this.cloneScene;
  }
  
  exportModelLocalSrorage(position?) {
    this.cloneScene = this.createCloneScene();
    this.cloneScene.traverse((child) => {
      if (child.parent instanceof THREE.Group) {
        if (position) {
          child.position.x = position.x;
          child.position.y = position.y;
          child.position.z = position.z;
        }
      }
    });
    //this.cloneScene.toJSON();
    window.localStorage.setItem(
      'model',
      JSON.stringify(this.cloneScene.toJSON())
    );
    console.log('hotExport', this.scene3DService.scene);
  }
  
  importModelLocalSrorage() {
    let modelLocal: any = window.localStorage.getItem('model_root');
    let loader = new THREE.ObjectLoader();
    var jsonModel = JSON.parse(modelLocal);
    console.log(jsonModel);
    loader.parse(
      jsonModel,
      function (obj) {
        obj.children.forEach((child) => {
          if (child  instanceof THREE.Mesh ) {
             console.log("hotT", child)
            this.scene3DService.scene.add(child);
          }
        });
      }.bind(this)
    );
  }

  textToBinary(text) {
    let binary = '';
    for (let i = 0; i < text.length; i++) {
      let charCode = text.charCodeAt(i).toString(2);
      binary += ('00000000' + charCode).slice(-8) + ' '; // add leading zeroes to ensure 8 bits per character
    }
    return binary.trim();
  }

  exporterBlobdObjectModel(data) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const objURL = URL.createObjectURL(blob);
    //URL.revokeObjectURL(objURL);
    // console.log(objURL);
    return objURL;
  }

  saveFile(data, name = 'modelObject', fileExtention = 'obj') {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    let fileName = name + '.' + fileExtention;
    let link = document.createElement('a');
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  }

  saveTextFile(data, name = 'motlObject', fileExtention = 'mtl') {
    let link = document.createElement('a');
    let fileName = name + '.' + fileExtention;
    link.download = fileName;
    let blob = new Blob([data], { type: 'text/plain' });
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  }
}
