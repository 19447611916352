import * as THREE from 'three';
import constants from '../../core_/services/constants';

export const getMainObjectParams = (root, mainObjectBaseName) => {
  // const rootParams = {
  //   isBox3: true,
  //   max: { x: 0, y: 0, z: 0 },
  //   min: { x: 0, y: 0, z: 0 },
  // };
  let rootParams = new THREE.Box3().setFromObject(root);
  // console.log('getMainObjectParams objectCoord', rootParams);
  // root.traverse((child) => {
  //   if (child.isMesh) {
  //     let newBox = new THREE.Box3().setFromObject(child);
  //     if (newBox.max.x > rootParams.max.x) rootParams.max.x = newBox.max.x;
  //     if (newBox.max.y > rootParams.max.y) rootParams.max.y = newBox.max.y;
  //     if (newBox.max.z > rootParams.max.z) rootParams.max.z = newBox.max.z;
  //     if (newBox.min.x < rootParams.min.x) rootParams.min.x = newBox.min.x;
  //     if (newBox.min.y < rootParams.min.y) rootParams.min.y = newBox.min.y;
  //     if (newBox.min.z < rootParams.min.z) rootParams.min.z = newBox.min.z;
  //   }
  // });
  rootParams.max.x = rootParams.max.x - constants.BORDER_INDENT
  rootParams.min.x = rootParams.min.x + constants.BORDER_INDENT
  rootParams.max.y = rootParams.max.y - constants.BORDER_INDENT
  if(mainObjectBaseName === '30661' || mainObjectBaseName === '30658' || mainObjectBaseName === '30676') {
    rootParams.min.y = rootParams.min.y + constants.PANEL_RACK_BOTTOM_INDENT;
  }
  if(mainObjectBaseName === '30651' || mainObjectBaseName === '30653') {
    rootParams.min.y = rootParams.min.y + constants.PANEL_RACK_BOTTOM_INDENT_FULL;
  }
  if(mainObjectBaseName === '30812') {
    rootParams.min.y = rootParams.min.y + constants.RAIL_RACK_BOTTOM_INDENT;
    rootParams.max.y = rootParams.max.y - constants.RAIL_RACK_TOP_INDENT;
    rootParams.min.x = rootParams.min.x + constants.RAIL_RACK_LEFT_INDENT;
    rootParams.max.x = rootParams.max.x - constants.RAIL_RACK_RIGHT_INDENT;
  }

  // rail many row
  if(mainObjectBaseName === '30016') {
    rootParams.min.y = rootParams.min.y + constants.RAIL_BOTTOM_INDENT;
    rootParams.max.y = rootParams.max.y - constants.RAIL_TOP_BORDER_INDENT_1;
    rootParams.max.x = rootParams.max.x - constants.RAIL_SIDES_BORDER_INDENT;
    rootParams.min.x = rootParams.min.x + constants.RAIL_SIDES_BORDER_INDENT;
  }
  if(mainObjectBaseName === '30008') {
    rootParams.min.y = rootParams.min.y + constants.RAIL_BOTTOM_INDENT;
    rootParams.max.y = rootParams.max.y - constants.RAIL_TOP_BORDER_INDENT_4;
    rootParams.max.x = rootParams.max.x - constants.RAIL_SIDES_BORDER_INDENT;
    rootParams.min.x = rootParams.min.x + constants.RAIL_SIDES_BORDER_INDENT;
  }
  //

  // rail one row
  if(mainObjectBaseName === '30632') {
    rootParams.max.y = rootParams.max.y - constants.RAIL_TOP_BORDER_INDENT_2;
  }
  if(mainObjectBaseName === '30148') {
    rootParams.max.y = rootParams.max.y + constants.RAIL_TOP_BORDER_INDENT_3;
  }
  //
  // console.log('rootParams', rootParams);
  return rootParams;
}
