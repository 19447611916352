// REFACTOR
"use strict";

import dotaReplaceAssets from "../libs/replace";

angular
  .module("valleyCraftApp")
  .run([
    "$anchorScroll",
    function ($anchorScroll) {
      $anchorScroll.yOffset = 50; // always scroll by 50 extra pixels
    },
  ])
  .controller("ModelPublCtrl", [
    "$anchorScroll",
    "$scope",
    "ResourceService",
    "$timeout",
    "$location",
    "toastr",
    "Upload",
    "AuthenticationService",
    "$rootScope",
    "ngDialog",
    "constants",
    function (
      $anchorScroll,
      $scope,
      ResourceService,
      $timeout,
      $location,
      toastr,
      Upload,
      AuthenticationService,
      $rootScope,
      ngDialog,
      constants
    ) {
      var userName = AuthenticationService.userLogged();
      //  var apiUrl = 'http://138.197.129.42:9909';
      var apiUrl = constants.API_URL; //'http://dev-demo-plastic-bins.myers3d.com';
      // var apiUrl = 'http://localhost:7000';

      $scope.gotoAnchor = function (x) {
        var newHash = x;
        if ($location.hash() !== newHash) {
          $location.hash(x);
        } else {
          $anchorScroll();
        }
      };

      ResourceService.getAccessoriesPermission()
        .then(function (data) {
          if (data.length > 0) {
            $scope.enableAccessories = data[0].enable_accessories;
          }
        })
        .catch(function (err) {
          console.debug(err);
        });

      if (userName) {
        if (AuthenticationService.publisher()) {
          $rootScope.templateMaster = true;
        } else {
          $rootScope.templateMaster = false;
        }
        $rootScope.userLogged = true;
        $rootScope.labelUserLogged =
          userName.firstName + " " + userName.lastName;
      }
      $scope.textView = constants.textView;
      $scope.searchIsGeneric = "";
      $scope.colorScheme = "";
      $scope.hikashop_id_to_color_scheme = {};
      $scope.hikashopId = "";
      $scope.filesForUpload = [];
      $scope.models = [];
      $scope.caption = "Publish model";
      $scope.swapGroups = [];
      $scope.formVisible = false;
      $scope.objectType = "CEG";
      $scope.CEGModels = [];
      $scope.genericModels = [];
      $scope.leftMenuAliasVC = [
        "Tool Storage",
        "High Capacity",
        "Wall Panel",
        "Windows",
      ];
      $scope.leftMenuAliasGeneric = [
        "Windows",
        "Doors - Entry",
        "Doors - Garage",
        "Stairs",
        "Healthcare",
        "MRO",
        "Vehicles",
        "Garden Tools",
        "Misc",
      ];

      $scope.accessories = [];
      $scope.selectedAccessory = null;

      $scope.onRemoveAccessoryFromVariant = function (variant) {
        delete variant.accessory;
      };

      $scope.onSelectVariantAccessory = function (variant) {
        if (!variant.accessory) variant.accessory = { useProductHikId: false };
        var dialogCombination = ngDialog.open({
          disableAnimation: true,
          template: "./app/views/selectVariantAccessory.html",
          className: "ngdialog-theme-default model-combination-popup",
          data: {
            accessories: $scope.currentModel.accessories,
            selectedAccessory: variant.accessory.id,
          },
          controller: "selectVariantAccessory",
          closeByDocument: false,
        });

        dialogCombination.closePromise.then(function (data) {
          console.log("data", data.value);
          variant.accessory.id = data.value.accessory_id;
        });
      };

      $scope.onAddVariant = function () {
        $scope.currentModel.variants.push({
          hikId: "",
          colorScheme: {},
          accessory: { useProductHikId: false },
        });
      };

      $scope.onRemoveVariant = function (variant) {
        var idx = $scope.currentModel.variants.indexOf(variant);
        if (idx !== -1) $scope.currentModel.variants.splice(idx, 1);
      };

      $scope.getAccessoryById = function (id) {
        return _.find($scope.accessories, function (accessory) {
          return accessory._id === id;
        });
      };

      $scope.objectTypeChange = function () {
        if ($scope.objectType === "CEG") {
          $scope.models = $scope.CEGModels;
        } else {
          $scope.models = $scope.genericModels;
        }
      };

      $scope.generateAssetPath = function (path) {
        return path ? dotaReplaceAssets(path) : "";
      };

      function generatePaths(product) {
        product.img = `assets/images/ProductImage/${product.base_model_name}${product.color_scheme[0]}.png`;
        product.leftMenuImg = `assets/images/LeftMenuThumbnails/${product.base_model_name}.png`;
        product.swapImg = `assets/images/SwapPictogram/${product.base_model_name}.png`;
        product.sprite = `assets/images/PlanView/${product.base_model_name}${product.color_scheme[0]}.png`;
      }

      function generatePathsdotaReplaceAssets(product) {
        product.img = dotaReplaceAssets(product.productImage);
        product.leftMenuImg = dotaReplaceAssets(product.leftMenuImg);
        product.productImage = dotaReplaceAssets(product.productImage);
        product.previewImage = dotaReplaceAssets(product.previewImage);
        product.swapImage = dotaReplaceAssets(product.swapImage);
        product.sprite = dotaReplaceAssets(product.sprite);
        product.swapImg = dotaReplaceAssets(product.swapImage);
      }

      var getProducts = function () {
        ResourceService.getProducts().then(function (data) {
          angular.forEach(data, function (product) {
            if (product.visible) {
              switch (product.left_menu_alias) {
                /*  case "Akro-Mils":
                  {
                    product.img =
                      "assets/images/ProductImage/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                    product.leftMenuImg =
                      "assets/images/LeftMenuThumbnails/" +
                      product.base_model_name +
                      ".png";
                    product.swapImg =
                      "assets/images/SwapPictogram/" +
                      product.base_model_name +
                      ".png";
                    product.sprite =
                      "assets/images/PlanView/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                  }
                  break;
                case "Tool Storage":
                  {
                    product.img =
                      "assets/images/ProductImage/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                    product.leftMenuImg =
                      "assets/images/LeftMenuThumbnails/" +
                      product.base_model_name +
                      ".png";
                    product.swapImg =
                      "assets/images/SwapPictogram/" +
                      product.base_model_name +
                      ".png";
                    product.sprite =
                      "assets/images/PlanView/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                  }
                  break;
                case "Wall Cabinets":
                  {
                    product.img =
                      "assets/images/ProductImage/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                    product.leftMenuImg =
                      "assets/images/LeftMenuThumbnails/" +
                      product.base_model_name +
                      ".png";
                    product.swapImg =
                      "assets/images/SwapPictogram/" +
                      product.base_model_name +
                      ".png";
                    product.sprite =
                      "assets/images/PlanView/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                  }
                  break;
                case "High Capacity":
                  {
                    product.img =
                      "assets/images/ProductImage/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                    product.leftMenuImg =
                      "assets/images/LeftMenuThumbnails/" +
                      product.base_model_name +
                      ".png";
                    product.swapImg =
                      "assets/images/SwapPictogram/" +
                      product.base_model_name +
                      ".png";
                    product.sprite =
                      "assets/images/PlanView/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                  }
                  break;
                case "Work Tables":
                  {
                    product.img =
                      "assets/images/ProductImage/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                    product.leftMenuImg =
                      "assets/images/LeftMenuThumbnails/" +
                      product.base_model_name +
                      ".png";
                    product.swapImg =
                      "assets/images/SwapPictogram/" +
                      product.base_model_name +
                      ".png";
                    product.sprite =
                      "assets/images/PlanView/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                  }
                  break;
                case "Wall Panels":
                  {
                    product.img =
                      "assets/images/ProductImage/" +
                      product.base_model_name +
                      ".png";
                    product.leftMenuImg =
                      "assets/images/LeftMenuThumbnails/" +
                      product.base_model_name +
                      ".png";
                    product.swapImg =
                      "assets/images/SwapPictogram/" +
                      product.base_model_name +
                      ".png";
                    product.sprite =
                      "assets/images/PlanView/" +
                      product.base_model_name +
                      product.color_scheme[0] +
                      ".png";
                  }
                  break; */
                case "Akro-Mils":
                case "Tool Storage":
                case "Wall Cabinets":
                case "High Capacity":
                case "Work Tables":
                case "Wall Panels":
                  generatePaths(product);
                  break;
                case "Windows":
                case "Doors - Entry":
                case "Stairs":
                case "Healthcare":
                case "MRO":
                case "Vehicles":
                case "Misc":
                case "Doors - Garage":
                  generatePathsdotaReplaceAssets(product);
                  break;
                /*     case "Windows":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.productImage = dotaReplaceAssets(
                      product.productImage
                    );
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                  }
                  break;
                case "Doors - Entry":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.productImage = dotaReplaceAssets(
                      product.productImage
                    );
                    product.swapImage = dotaReplaceAssets(product.swapImage);
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                  }
                  break;
                case "Stairs":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImg);
                    product.model = dotaReplaceAssets(product.model);
                  }
                  break;

                case "Healthcare":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                    product.model = dotaReplaceAssets(product.model);
                  }
                  break;
                case "MRO":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                    product.model = dotaReplaceAssets(product.model);
                  }
                  break;
                case "Vehicles":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                    product.model = dotaReplaceAssets(product.model);
                  }

                  break;
                case "Misc":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.sprite = dotaReplaceAssets(product.sprite);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                    product.model = dotaReplaceAssets(product.model);
                  }
                  break;
                case "Doors - Garage":
                  {
                    product.img = dotaReplaceAssets(product.productImage);
                    product.leftMenuImg = dotaReplaceAssets(
                      product.leftMenuImg
                    );
                    product.swapImage = dotaReplaceAssets(product.swapImage);
                    product.swapImg = dotaReplaceAssets(product.swapImage);
                    product.model = dotaReplaceAssets(product.model);
                  }
                  break; */

                default: {
                }
              }
            }
          });
          $scope.models = data;
          getSwapGroups($scope.models);
        });
      };

      var getSwapGroups = function (a) {
        var seen = {},
          out = [],
          genOut = [],
          len = a.length,
          j = 0,
          g = 0;

        for (var i = 0; i < len; i++) {
          var item = a[i].swap_group_name;

          if (seen[item] !== 1) {
            seen[item] = 1;
            if (a[i].isGeneric) {
              genOut[g++] = item;
            } else {
              out[j++] = item;
            }
          }
        }
        $scope.swapGroups = out;
        $scope.genericSwapGroups = genOut;
      };

      $scope.clearForm = function (objectType) {
        $scope.previewImage = [];
        $scope.filesForUpload = [];
        $scope.setFormVisible(true);
        $scope.objectType = objectType;
        $rootScope.multiDropDownModel = {};

        if ($scope.objectType === "Akro-Mils") {
          $scope.currentModel.dropId = null;
          $scope.currentModel.dropName = null;
        }

        if ($scope.objectType === "generic") {
          $scope.currentModel = {
            isGeneric: true,
            disableAutoOrientation: false,
            width: null,
            length: null,
            height: null,
            defaultHeightFromFloor: null,
            color_scheme: [],
            isOpening: false,
            isDouble: false,
            swap_group_name: "",
            left_menu_alias: "",
            borders: { wall: null, default: null },
            visible: true,
            leftMenuImg: "",
            swapImage: "",
            productImage: "",
            defaultRotationDegree: 10,
            texture: [],
            defaultAccessory: null,
          };
        } else {
          $scope.currentModel = {
            isGeneric: false,
            disableAutoOrientation: false,
            name: "",
            description: "",
            category: "",
            weight: null,
            dimension_units: "",
            width: null,
            length: null,
            height: null,
            defaultHeightFromFloor: null,
            msrp: null,
            base_model_name: "",
            color_scheme: [],
            hikashop_id_to_color_scheme: {},
            swap_group_name: "",
            left_menu_alias: "",
            borders: {},
            hotzones: { wall: null },
            visible: true,
            defaultRotationDegree: 10,
            texture: [],
            has_accessories: false,
            configurable: false,
            accessories: [],
            variants: [
              {
                hikId: "",
                colorScheme: {},
                accessory: {},
              },
            ],
          };
        }

        $scope.caption = "Publish model";
        $scope.formVisible = true;
      };

      $scope.setFormVisible = function (val) {
        $scope.formVisible = val;
      };

      $scope.deleteModel = function (model) {
        $scope.filesForUpload = [];
        ResourceService.deleteProduct(model._id).then(function (data) {
          getProducts();
        });
      };

      $scope.deleteColorScheme = function (id) {
        $scope.currentModel.color_scheme.splice(id, 1);
      };

      /*
       * Clear form after click button "Publish model and Update model"
       */

      $scope.editModel = function (model) {
        console.debug(model);
        if (model.isGeneric) {
          $scope.objectType = "generic";
        } else {
          $scope.objectType = "CEG";
        }

        if (model.category === "Akro-Mils") {
          $scope.objectType = "Akro-Mils";
        }

        //   $scope.clearForm($scope.objectType);
        $scope.hikashop_id_to_color_scheme = model.hikashop_id_to_color_scheme;
        $scope.currentModel = model;
        $scope.currentModel.variants = $scope.currentModel.variants || [
          {
            hikId: "",
            colorScheme: {},
            accessory: {},
          },
        ];

        if (
          $scope.currentModel.hasOwnProperty("hotzones") &&
          !_.isNull($scope.currentModel.hotzones) &&
          _.isString($scope.currentModel.hotzones.wall)
        ) {
          $scope.currentModel.hotzones.wall = Number(
            $scope.currentModel.hotzones.wall
          );
        }
        if (
          $scope.currentModel.left_menu_alias === "Wall Panels" ||
          $scope.currentModel.left_menu_alias === "Accessories"
        ) {
          $scope.SpecialObject = true;
        } else {
          $scope.SpecialObject = false;
        }

        if (
          $scope.objectType === "CEG" &&
          ($scope.currentModel.left_menu_alias === "Tool Storage" ||
            $scope.currentModel.left_menu_alias === "Work Tables" ||
            $scope.currentModel.left_menu_alias === "Suites" ||
            $scope.currentModel.left_menu_alias === "High Capacity")
        ) {
          if (!$scope.currentModel.hasOwnProperty("has_accessories")) {
            $scope.currentModel.has_accessories = false;
          }

          if (!$scope.currentModel.hasOwnProperty("accessories")) {
            $scope.currentModel.accessories = [];
          } else {
            _.forEach($scope.currentModel.accessories, function (item) {
              item.data = _.find($scope.accessories, {
                _id: item.accessory_id,
              });
            });
          }
        }

        $scope.caption = "Update model";
        $scope.gotoAnchor("anchor");
      };

      /* preview file */
      var previewFile = function (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = (event) => resolve(event.target.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      /*Create*/
      $scope.addFilesForUpload = function (
        file,
        imageFolder,
        newName,
        id,
        color
      ) {
        var data = {
          file: file,
          imageFolder: imageFolder,
          newName: newName || file.name,
          id: id || null,
        };

        $scope.filesForUpload.push(data);

        previewFile(file).then((url) => {
          $scope.$apply(function () {
            if (color) {
              $scope.previewImage[imageFolder + color] = url;
              console.log("---", imageFolder, color);
            } else {
              $scope.previewImage[imageFolder] = url;
              console.log("---", imageFolder, color);
            }
          });
        });
      };

      /*
       *
       * Upload */

      $scope.addGenericFilesForUpload = function (
        file,
        imageFolder,
        isImage,
        id,
        category,
        model
      ) {
        var data = {
          file: file,
          imageFolder: imageFolder,
          newName: file.name,
          isImage: isImage,
          id: id,
          category: category,
          model: model,
        };

        $scope.filesForUpload.push(data);

        previewFile(file).then((url) => {
          $scope.$apply(function () {
            $scope.previewImage[imageFolder] = url;
          });
        });
        //debugger;
      };

      var uploadFilesAkroMils = function (id) {
        // debugger;
        if ($scope.filesForUpload && $scope.filesForUpload.length) {
          angular.forEach($scope.filesForUpload, function (fileSet) {
            if (fileSet.file) {
              Upload.upload({
                url: apiUrl + "/products/upload-akro-mils",
                data: {
                  file: fileSet.file,
                  imageFolder: fileSet.imageFolder,
                  newName: fileSet.newName,
                  id: fileSet.id || id,
                },
              });
            }
          });
        }
      };

      var uploadFiles = function (id) {
        //debugger;
        if ($scope.filesForUpload && $scope.filesForUpload.length) {
          angular.forEach($scope.filesForUpload, function (fileSet) {
            if (fileSet.file) {
              Upload.upload({
                url: apiUrl + "/products/upload",
                data: {
                  file: fileSet.file,
                  imageFolder: fileSet.imageFolder,
                  newName: fileSet.newName,
                  id: fileSet.id || id,
                },
              });
            }
          });
        }
      };

      var uploadGenericFiles = function (id) {
        if ($scope.filesForUpload && $scope.filesForUpload.length) {
          angular.forEach($scope.filesForUpload, function (fileSet) {
            if (fileSet.file) {
              Upload.upload({
                url: apiUrl + "/products/uploadGeneric",
                data: {
                  file: fileSet.file,
                  imageFolder: fileSet.imageFolder,
                  newName: fileSet.newName,
                  isImage: fileSet.isImage,
                  id: fileSet.id || id,
                  category: fileSet.category,
                  model: fileSet.model,
                },
              });
            }
          });
        }
      };

      $scope.addColorScheme = function () {
        if (!$scope.currentModel.color_scheme)
          $scope.currentModel.color_scheme = [];
        $scope.currentModel.color_scheme.push($scope.colorScheme);
        if ($scope.hikashop_id_to_color_scheme)
          $scope.hikashop_id_to_color_scheme[$scope.colorScheme] = "";
        $scope.colorScheme = "";
        console.log($scope.currentModel.color_scheme);
        console.trace();
      };

      $scope.addSwap = function () {
        $scope.swapGroups.push($scope.swapCEG);
        $scope.swapCEG = "";
      };

      $scope.addGenericSwap = function () {
        $scope.genericSwapGroups.push($scope.swapGeneric);
        $scope.swapGeneric = "";
      };

      var updateHikashopIdToColorScheme = function () {
        $scope.currentModel.hikashop_id_to_color_scheme =
          $scope.hikashop_id_to_color_scheme;
      };

      /**
       * publish Model
       */

      $scope.publishModel = function (modelForm) {
        /*  if(!modelForm.$valid){
                // действия по сохранению
                alert(modelForm.$valid + ", ваш ответ сохранен");
                return;
            } */

        if ($scope.objectType === "Akro-Mils") {
          /* Add new CE model */ /* CREATE MODEL */
          if (
            modelForm.$invalid ||
            (!$rootScope.multiDropDownModel.itemsName &&
              !$scope.currentModel.dropName)
          ) {
            // действия по сохранению
            alert(
              JSON.stringify(modelForm),
              !$rootScope.multiDropDownModel.itemsName + ", not valide form"
            );
            console.log(modelForm);
            return;
          }
          if (
            !$scope.currentModel.accessories ||
            ($scope.currentModel.accessories &&
              $scope.currentModel.accessories.length === 0)
          ) {
            $scope.currentModel.has_accessories = false;
            $scope.currentModel.accessories = [];
          } else {
            _.forEach($scope.currentModel.accessories, function (item) {
              delete item["data"];
            });
          }

          $scope.currentModel.category = "Akro-Mils";
          $scope.currentModel.left_menu_alias = "Akro-Mils";
          if ($rootScope.multiDropDownModel.itemsName) {
            $scope.currentModel.dropId =
              $rootScope.multiDropDownModel.itemsName;
            $scope.currentModel.dropName = $rootScope.multiDropDownModel.text;
          }

          updateHikashopIdToColorScheme();

          if ($scope.caption.valueOf() === "Publish model") {
            // REFACTOR
            if (
              $scope.currentModel.color_scheme.includes("RB") &&
              $scope.currentModel.color_scheme.includes("BS") &&
              $scope.currentModel.color_scheme.includes("WW") &&
              $scope.currentModel.color_scheme.length === 3
            ) {
              $scope.currentModel.color_scheme = ["RB", "BS", "WW"];
            }
            console.log($scope.currentModel);
            ResourceService.saveProduct($scope.currentModel).then(
              function (data) {
                toastr.success("Model published");
                uploadFilesAkroMils(data._id);
                $scope.clearForm();
              },
              function (err) {
                toastr.error(err.message);
              }
            );
            $scope.caption = "Update model";
          } else {
            if (
              $scope.currentModel.color_scheme.includes("RB") &&
              $scope.currentModel.color_scheme.includes("BS") &&
              $scope.currentModel.color_scheme.includes("WW") &&
              $scope.currentModel.color_scheme.length === 3
            ) {
              $scope.currentModel.color_scheme = ["RB", "BS", "WW"];
            }
            ResourceService.updateProduct(
              $scope.currentModel._id,
              $scope.currentModel
            ).then(
              function (data) {
                getProducts();
                toastr.success("Model updated");
                $scope.clearForm();
              },
              function (err) {
                toastr.error(err.message);
              }
            );
            console.log("before uploadFilesAkroMils");
            uploadFilesAkroMils();
            $scope.caption = "Publish model";
          }
        } else if ($scope.objectType === "CEG") {
          /* Add new CE model */ /* CREATE MODEL */
          if (
            !$scope.currentModel.accessories ||
            ($scope.currentModel.accessories &&
              $scope.currentModel.accessories.length === 0)
          ) {
            $scope.currentModel.has_accessories = false;
            $scope.currentModel.accessories = [];
          } else {
            _.forEach($scope.currentModel.accessories, function (item) {
              delete item["data"];
            });
          }

          updateHikashopIdToColorScheme();
          if ($scope.caption.valueOf() === "Publish model") {
            // REFACTOR
            if (
              $scope.currentModel.color_scheme.includes("RB") &&
              $scope.currentModel.color_scheme.includes("BS") &&
              $scope.currentModel.color_scheme.includes("WW") &&
              $scope.currentModel.color_scheme.length === 3
            ) {
              $scope.currentModel.color_scheme = ["RB", "BS", "WW"];
            }
            console.log($scope.currentModel);
            ResourceService.saveProduct($scope.currentModel).then(
              function (data) {
                toastr.success("Model published");
                uploadFiles(data._id);
                $scope.clearForm();
              },
              function (err) {
                toastr.error(err.message);
              }
            );
            $scope.caption = "Update model";
          } else {
            if (
              $scope.currentModel.color_scheme.includes("RB") &&
              $scope.currentModel.color_scheme.includes("BS") &&
              $scope.currentModel.color_scheme.includes("WW") &&
              $scope.currentModel.color_scheme.length === 3
            ) {
              $scope.currentModel.color_scheme = ["RB", "BS", "WW"];
            }
            ResourceService.updateProduct(
              $scope.currentModel._id,
              $scope.currentModel
            ).then(
              function (data) {
                getProducts();
                toastr.success("Model updated");
                $scope.clearForm();
              },
              function (err) {
                toastr.error(err.message);
              }
            );
            uploadFiles();
            $scope.caption = "Publish model";
          }
        } else {
          /* Add new generic model */
          if ($scope.caption.valueOf() === "Publish model") {
            console.log("Publish model");
            ResourceService.saveProduct($scope.currentModel).then(
              function (data) {
                toastr.success("Model published");
                uploadGenericFiles(data._id);

                $scope.clearForm();
              },
              function (data) {
                toastr.error(data.message);
              }
            );
          } else {
            ResourceService.updateProduct(
              $scope.currentModel._id,
              $scope.currentModel
            ).then(
              function (data) {
                getProducts();
                toastr.success("Model updated");

                $scope.clearForm();
              },
              function (data) {
                toastr.error(data.message);
              }
            );
            uploadGenericFiles();
            $scope.caption = "Publish model";
          }
        }
        $scope.setFormVisible(false);
        console.log(802);
      };

      $scope.selectedAccessoryDialog = function () {
        var accessories_arr = $scope.currentModel.hasOwnProperty("accessories")
          ? $scope.currentModel.accessories
          : [];
        var selected_accessories = [];
        _.forEach(accessories_arr, function (item) {
          selected_accessories.push(item.accessory_id);
        });
        var dialogCombination = ngDialog.open({
          disableAnimation: true,
          template: "./app/views/selectCombinationMA.html",
          className: "ngdialog-theme-default model-combination-popup",
          data: {
            all_accessories: $scope.accessories,
            selected_accessories: selected_accessories,
          },
          controller: "selectCombinationMA",
          closeByDocument: false,
        });

        dialogCombination.closePromise.then(function (data) {
          if (data.value && _.isArray(data.value)) {
            var selected_accessories = data.value;
            var _selected = [];
            _.forEach(selected_accessories, function (item) {
              if (
                _.find($scope.currentModel.accessories, { accessory_id: item })
              ) {
                _selected.push(
                  _.find($scope.currentModel.accessories, {
                    accessory_id: item,
                  })
                );
              } else {
                var obj = {};
                var _a = _.find($scope.accessories, { _id: item });
                obj.data = _a;
                obj.accessory_id = _a._id;
                obj.model_url = "";
                obj.texture = [];
                obj.products_img = {};
                _.forEach($scope.currentModel.color_scheme, function (color) {
                  obj.products_img[color] = "";
                });
                _selected.push(obj);
              }
            });

            $scope.product_with_accessories = _selected;
            $scope.currentModel.accessories = _selected;
          }
          console.log(data.id + " has been dismissed.");
        });
      };

      $scope.removeModelAccessory = function (item) {
        _.remove($scope.currentModel.accessories, function (n) {
          return n.accessory_id === item.accessory_id;
        });
        if ($scope.currentModel.variants) {
          _.forEach($scope.currentModel.variants, function (variant) {
            if (
              variant.accessory &&
              variant.accessory.id === item.accessory_id
            ) {
              delete variant.accessory.id;
            }
          });
        }
      };

      // Toggle cheked flag "has_accessories"
      $scope.toggleHasAccessories = function () {
        if (!$scope.currentModel.has_accessories) {
          $scope.currentModel.accessories = [];
        }
      };
      $scope.toggleIsConfigurable = function () {
        if ($scope.configurable) {
          $scope.currentModel.configurable = true;
        }
      };
      $scope.uploadAccessoryProductImageAkroMils = function (
        file,
        folderName,
        fileName,
        color,
        accessory_data
      ) {
        Upload.upload({
          url: apiUrl + "/products/uploadAccessoryProductImageAkroMils",
          data: {
            file: file,
            folderName: folderName,
            fileName: fileName,
          },
        }).then(
          function (resp) {
            console.log("Success " + resp.config.data.file.name + "uploaded.");
            //                console.log('Response: ', resp.data);
            accessory_data.products_img[color] = resp.data.img_url;
          },
          function (resp) {
            console.log("Error status: " + resp.status);
          },
          function (evt) {
            var progressPercentage = parseInt((100.0 * evt.loaded) / evt.total);
            console.log(
              "progress: " +
                progressPercentage +
                "% " +
                evt.config.data.file.name
            );
          }
        );
      };

      $scope.uploadModelFilesAkroMils = function (
        files,
        folderName,
        fileName,
        accessory_data
      ) {
        //            console.log(files);
        if (files.length) {
          if (folderName === "model") {
            var warning = checkFileTypeUpload(files);
            if (warning) {
              toastr.warning(warning);
              return;
            }
          }
          toastr.info("Please wait while model is uploaded akro-mils");
          Upload.upload({
            url: apiUrl + "/products/uploadAccessoryProductModel/akro-mils",
            data: {
              file: files,
              folderName: folderName,
              fileName: fileName,
            },
          }).then(
            function (resp) {
              console.log(
                "Success " + resp.config.data.file.name + "uploaded."
              );
              //                    console.log('Response: ', resp.data);
              if (folderName === "model") {
                accessory_data.model_url = resp.data.model;
                toastr.success("Model is loaded!");
              }

              if (folderName === "texture") {
                accessory_data.texture = resp.data.texture;
                toastr.success("Texture is loaded!");
              }
            },
            function (resp) {
              console.log("Error status: " + resp.status);
              toastr.success(resp.statusText);
            },
            function (evt) {
              var progressPercentage = parseInt(
                (100.0 * evt.loaded) / evt.total
              );
              console.log(
                "progress: " +
                  progressPercentage +
                  "% " +
                  evt.config.data.file.name
              );
            }
          );
        }
      };

      $scope.uploadAccessoryProductImage = function (
        file,
        folderName,
        fileName,
        color,
        accessory_data
      ) {
        Upload.upload({
          url: apiUrl + "/products/uploadAccessoryProductImage",
          data: {
            file: file,
            folderName: folderName,
            fileName: fileName,
          },
        }).then(
          function (resp) {
            console.log("Success " + resp.config.data.file.name + "uploaded.");
            //                console.log('Response: ', resp.data);
            accessory_data.products_img[color] = resp.data.img_url;
          },
          function (resp) {
            console.log("Error status: " + resp.status);
          },
          function (evt) {
            var progressPercentage = parseInt((100.0 * evt.loaded) / evt.total);
            console.log(
              "progress: " +
                progressPercentage +
                "% " +
                evt.config.data.file.name
            );
          }
        );
      };

      $scope.uploadModelFiles = function (
        files,
        folderName,
        fileName,
        accessory_data
      ) {
        //            console.log(files);
        if (files.length) {
          if (folderName === "model") {
            var warning = checkFileTypeUpload(files);
            if (warning) {
              toastr.warning(warning);
              return;
            }
          }
          toastr.info("Please wait while model is uploaded");
          Upload.upload({
            url: apiUrl + "/products/uploadAccessoryProductModel",
            data: {
              file: files,
              folderName: folderName,
              fileName: fileName,
            },
          }).then(
            function (resp) {
              console.log(
                "Success " + resp.config.data.file.name + "uploaded."
              );
              //                    console.log('Response: ', resp.data);
              if (folderName === "model") {
                accessory_data.model_url = resp.data.model;
                toastr.success("Model is loaded!");
              }

              if (folderName === "texture") {
                accessory_data.texture = resp.data.texture;
                toastr.success("Texture is loaded!");
              }
            },
            function (resp) {
              console.log("Error status: " + resp.status);
              toastr.success(resp.statusText);
            },
            function (evt) {
              var progressPercentage = parseInt(
                (100.0 * evt.loaded) / evt.total
              );
              console.log(
                "progress: " +
                  progressPercentage +
                  "% " +
                  evt.config.data.file.name
              );
            }
          );
        }
      };

      function checkFileTypeUpload(files) {
        var warningText = "";
        if (files.length === 1 || files.length > 2) {
          warningText = "You need to select 2 files!";
        } else if (files.length === 2) {
          var names = [files[0].name, files[1].name];

          if (_.includes(names[0], ".obj") && _.includes(names[1], ".obj")) {
            warningText = "One file must be .obj, the other - .mtl!";
          }
          if (_.includes(names[0], ".mtl") && _.includes(names[1], ".mtl")) {
            warningText = "One file must be .obj, the other - .mtl!";
          }
        }
        return warningText.length ? warningText : false;
      }

      var getAccessories = function () {
        ResourceService.getAccessories()
          .then(function (result) {
            $scope.accessories = result;
          })
          .catch(function (err) {
            toastr.error(err);
          });
      };

      getProducts();
      getAccessories();
      $scope.clearForm();
      $scope.toggleAccessories = function () {
        ResourceService.toggleAccessories();
      };
    },
  ]);
/* {left_menu_alias:"Akro-Mils"} */
