import angular from "angular";
/* import 'angular-animate';
    import 'angular-bootstrap';
    import 'angular-cookies';
    import 'angular-resource';
    import 'angular-route';
    import 'angular-sanitize';
    import 'angular-touch';
    import 'angular-local-storage';
    import 'angular-toastr';
    import 'ng-dialog';
    import 'bootstrap-slider';
    import 'angular-bootstrap-slider';
    import 'ng-file-upload';
    import 'angular-bootstrap-colorpicker';
    import 'bootstrap-table';
    //import './libs/device';
    import 'moment';
    import 'datatables';
    import 'crypto-js';
    import 'lodash'; */
/**
 * @ngdoc overview
 * @name valleyCraftApp
 * @description
 * # valleyCraftApp
 *
 * Main module of the application.
 */
angular.module("checkboxExample", []).controller("ExampleController", [
  "$scope",
  function ($scope) {
    $scope.checkboxModel = {
      value1: true,
      value2: "YES",
    };
  },
]);

angular.module("valleyCraftApp.driCoreServices", []);

export default angular
  .module("valleyCraftApp", [
    "valleyCraftApp.driCoreServices",
    "ngAnimate",
    "ngCookies",
    "ngResource",
    "ngRoute",
    "ngSanitize",
    "ngTouch",
    "toastr",
    "LocalStorageModule",
    "ngDialog",
    "ui.bootstrap-slider",
    "ui.dateTimeInput",
    "ui.bootstrap.datetimepicker",
    "ngFileUpload",
    "colorpicker.module",
    "ui.bootstrap",
  ])
  .config([
    "$locationProvider",
    function ($locationProvider) {
      $locationProvider.hashPrefix("");
    },
  ])
  .config([
    "$routeProvider",
    function ($routeProvider) {
      angular.element(document).ready(function () {
        // console.log('document ready');
        angular.element("body").tooltip({ selector: "[data-toggle=tooltip]" });
      });

      $routeProvider
        .when("/", {
          // templateUrl: './app/views/main.html',
          // templateUrl: './app/views/planning_room.html',
          // controller: 'MainCtrl'
          redirectTo: "planning",
        })
        .when("/planning", {
          templateUrl: "./app/views/planning_room.html",
          controller: "MainCtrl",
        })
        .when("/models_publish", {
          templateUrl: "./app/views/modelsCRUD.html",
          controller: "ModelPublCtrl",
          access: { requiredModelPublisher: true },
        })
        .when("/material_publish", {
          templateUrl: "./app/views/materialsCRUD.html",
          controller: "MaterialPublCtrl",
          access: { requiredMaterialPublisher: true },
        })
        .when("/color_sheme_publish", {
          templateUrl: "./app/views/colorSchemesCRUD.html",
          controller: "ColorSchemePublCtrl",
          access: { requiredColorSchemePublisher: true },
        })
        .when("/accessories_publish", {
          templateUrl: "./app/views/accessoriesCRUD.html",
          controller: "AccessoriesController",
          access: { requiredAccessoriesPublisher: true },
        })
        .when("/templates/:room_id/:configurator/:model_id/:patch", {
          templateUrl: "./app/views/planning_room.html",
          controller: "MainCtrl",
          resolve: {
            loadRoom: function (
              $route,
              $rootScope,
              $timeout,
              ResourceService,
              toastr,
              $routeParams,
              roomStuff
            ) {
              $rootScope.notStep = true;
              $rootScope.configurator = true;
              if (!$routeParams.noReload) {
                roomStuff.waitLoad().then(function () {
                  $timeout(function () {
                    if ($route.current.params.room_id !== "undefined") {
                      const method =
                        $routeParams.patch !== "planning"
                          ? ResourceService.getOneTemplate($routeParams.room_id)
                          : ResourceService.getOne($routeParams.room_id);
                      method.then($route.current.params.room_id).then(
                        function (data) {
                          $rootScope.editedRoomData = data;
                          $rootScope.$broadcast("loadRoom", data);
                          $rootScope.notStep = false;
                          $rootScope.configurator = false;
                        },
                        function (data) {
                          toastr.error(data);
                        }
                      );
                    } else {
                      const data = { data: {} };
                      //  data.data = {};
                      // $rootScope.editedRoomData = data;
                      $rootScope.$broadcast("loadRoom", data);
                      $rootScope.notStep = false;
                      $rootScope.configurator = false;
                    }
                  }, 1000);
                  roomStuff.getProductConfigurator();
                });
              }
            },
          },
        })
        .when("/templates/:room_id", {
          templateUrl: "./app/views/planning_room.html",
          controller: "MainCtrl",
          resolve: {
            loadRoom: function (
              $rootScope,
              $timeout,
              ResourceService,
              toastr,
              $routeParams,
              roomStuff
            ) {
              $rootScope.isTemplate = true;
              $rootScope.notStep = true;
              if (!$routeParams.noReload) {
                roomStuff.waitLoad().then(function () {
                  $timeout(function () {
                    ResourceService.getOneTemplate($routeParams.room_id).then(
                      function (data) {
                        $rootScope.editedRoomData = data;
                        // delete default data for project timelines and industry
                        $rootScope.editedRoomData.projectDetails.project.timelines =
                          "";
                        $rootScope.editedRoomData.projectDetails.project.industry =
                          "";
                        //
                        $rootScope.$broadcast("loadRoom", data);
                        $rootScope.notStep = false;
                      },
                      function (data) {
                        toastr.error(data);
                      }
                    );
                  }, 1000);
                  roomStuff.getProductConfigurator();
                });
              }
            },
          },
        })
        .when("/planning/:room_id", {
          templateUrl: "./app/views/planning_room.html",
          controller: "MainCtrl",
          resolve: {
            loadRoom: function (
              $rootScope,
              $timeout,
              ResourceService,
              toastr,
              $routeParams,
              roomStuff,
              AngularService
            ) {
              $rootScope.notStep = true;
              //Change
              $rootScope.isTemplate = false;
              AngularService.setData({ patch: "/planning" });
              if (!$routeParams.noReload) {
                roomStuff.waitLoad().then(function () {
                  $timeout(function () {
                    ResourceService.getOne($routeParams.room_id).then(
                      function (data) {
                        $rootScope.editedRoomData = data;
                        $rootScope.$broadcast("loadRoom", data);
                        $rootScope.notStep = false;
                      },
                      function (data) {
                        toastr.error(data);
                      }
                    );
                  }, 1000);
                  roomStuff.getProductConfigurator();
                });
              }
            },
          },
        })
        .when("/browsing/:room_id", {
          templateUrl: "./app/views/planning_room.html",
          controller: "MainCtrl",
          resolve: {
            loadRoom: function (
              $route,
              $rootScope,
              $timeout,
              ResourceService,
              toastr,
              $routeParams,
              roomStuff
            ) {
              if (!$routeParams.noReload) {
                roomStuff.waitLoad().then(function () {
                  $timeout(function () {
                    ResourceService.getOne($route.current.params.room_id).then(
                      function (data) {
                        $rootScope.editedRoomData = data;
                        $rootScope.$broadcast("loadRoom", data);
                      },
                      function (data) {
                        toastr.error(data);
                      }
                    );
                  }, 1000);
                  roomStuff.getProductConfigurator();
                });
              }
            },
          },
        })
        .when("/login", {
          templateUrl: "./app/views/auth/login.html",
          controller: "AuthCtrl",
          access: { requiredLogin: false },
        })
        .when("/registration", {
          templateUrl: "./app/views/auth/registration.html",
          controller: "AuthCtrl",
          access: { requiredLogin: false },
        })
        .when("/account", {
          templateUrl: "./app/views/auth/userAccount.html",
          controller: "AuthCtrl",
          access: { requiredLogin: true },
        })
        .when("/forgot", {
          templateUrl: "./app/views/auth/forgotPassword.html",
          controller: "ForgotPass",
          access: { requiredLogin: false },
        })
        .when("/reset/:user_id", {
          templateUrl: "./app/views/auth/formResetPassword.html",
          controller: "ForgotPass",
          access: { requiredLogin: false },
        })
        .when("/admin/login", {
          templateUrl: "./app/views/admin/login.html",
          controller: "AdminControl",
          //access: {requiredLogin: false}
        })
        .when("/admin/main", {
          templateUrl: "./app/views/admin/main.html",
          controller: "AdminControl",
          //access: {requiredLogin: false}
        })
        .when("/anj-test", {
          template: "<app-test></app-test>",
        })
        .when("/configurator", {
          template: "<pb-home></pb-home>",
        })
        .otherwise({
          redirectTo: "/planning",
        });
    },
  ])

  .config([
    "localStorageServiceProvider",
    function (localStorageServiceProvider) {
      localStorageServiceProvider.setPrefix("");
    },
  ])

  // .config(function ($httpProvider) {
  //   let auth = window.btoa('ck_8a8f17c8822436cf3593c7c035175d09868acd5b:cs_60b8d88bc309c5c96e76edb8d37cd6c9610e2dd6')
  //   $httpProvider.defaults.headers.common.Authorization = 'Basic ' + auth;
  //   console.log("$httpProvider = ", $httpProvider);
  // })

  .config([
    "toastrConfig",
    function (toastrConfig) {
      angular.extend(toastrConfig, {
        allowHtml: false,
        autoDismiss: true,
        closeButton: false,
        closeHtml: "<button>&times;</button>",
        containerId: "toast-container",
        extendedTimeOut: 2000,
        iconClasses: {
          error: "toast-error",
          info: "toast-info",
          success: "toast-success",
          warning: "toast-warning",
        },
        maxOpened: 1,
        messageClass: "toast-message",
        newestOnTop: true,
        onHidden: null,
        onShown: null,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        progressBar: true,
        tapToDismiss: true,
        target: "body",
        templates: {
          toast: "directives/toast/toast.html",
          progressbar: "directives/progressbar/progressbar.html",
        },
        timeOut: 4000,
        titleClass: "toast-title",
        toastClass: "toast",
      });
    },
  ])

  .config([
    "ngDialogProvider",
    function (ngDialogProvider) {
      ngDialogProvider.setDefaults({
        className: "ngdialog-theme-default",
        plain: false,
        showClose: true,
        closeByDocument: true,
        closeByEscape: true,
        appendTo: false,
      });
    },
  ])

  .run([
    "$rootScope",
    "$location",
    "AuthenticationService",
    "toastr",
    function ($rootScope, $location, AuthenticationService, toastr) {
      $rootScope.$on(
        "$routeChangeStart",
        function (event, nextRoute, currentRoute) {
          if (nextRoute.access)
            if (nextRoute.access === undefined) {
              $location.path(nextRoute.$$route.originalPath);
            } else if (
              nextRoute.access.requiredLogin &&
              !AuthenticationService.isLogged()
            ) {
              $location.path("/login");
            } else if (
              AuthenticationService.isLogged() &&
              nextRoute.access.requiredLogin === false
            ) {
              $location.path("/account");
            } else if (
              nextRoute.access.requiredModelPublisher &&
              !AuthenticationService.publisher()
            ) {
              $location.path("/planning");
              toastr.warning("You don't have privileges to view this page");
            } /*else if (
              nextRoute.access.requiredMaterialPublisher &&
              !AuthenticationService.publisher()
            )  {
              $location.path("/planning");
              toastr.warning("You don't have privileges to view this page");
            } else if (
              nextRoute.access.requiredSuitePublisher &&
              !AuthenticationService.publisher()
            ) {
              $location.path("/planning");
              toastr.warning("You don't have privileges to view this page");
            } else if (
              nextRoute.access.requiredColorSchemePublisher &&
              !AuthenticationService.publisher()
            ) {
              $location.path("/planning");
              toastr.warning("You don't have privileges to view this page");
            } else if (
              nextRoute.access.requiredAccessoriesPublisher &&
              !AuthenticationService.publisher()
            ) {
              $location.path("/planning");
              toastr.warning("You don't have privileges to view this page");
            } */
        }
      );
    },
  ])
  .run([
    "$route",
    "$rootScope",
    "$location",
    function ($route, $rootScope, $location) {
      var original = $location.path;
      $location.path = function (path, reload) {
        if (reload === false) {
          var lastRoute = $route.current;
          var un = $rootScope.$on("$locationChangeSuccess", function () {
            $route.current = lastRoute;
            un();
          });
        }
        return original.apply($location, [path]);
      };
    },
  ]);
