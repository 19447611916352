import WallObjectHandlerBase from './WallObjectHandlerBase';
import AddObjectHandlerBase from './AddObjectHandlerBase';
import ObjectInWallRangev2 from '../Drag/Class/ObjectInWallRangev2';

var GroupObjectHandler = function (dependencyContainer) {
    WallObjectHandlerBase.prototype.constructor.call(this, dependencyContainer);

    this._inWallRangeChecker = new ObjectInWallRangev2();
};

GroupObjectHandler.prototype = Object.create(WallObjectHandlerBase.prototype);

GroupObjectHandler.prototype.constructor = GroupObjectHandler;

GroupObjectHandler.prototype.canExecute = function (entity) {
    return this._objectCheckerSrvc.isSuite(entity) && !entity.isCornerSuite;
};

GroupObjectHandler.prototype.dragBegin = function (intersection, staticObjs) {

    var intersected = this._wallIntersection(intersection);

    if (intersected) {

        var entity = this._ghostObject.userData.entity;
        var wall = intersected.object;
        var wallDirection = wall.getWorldDirection();
        var offset = wallDirection.clone().multiplyScalar(entity.width / 2).add(wallDirection.multiplyScalar(0.1));
        var position = intersected.point.add(offset);
        position.y =  entity.height / 2;

        if (
                this._colliderChecker.check(position, staticObjs, this._ghostObject) &&
                this._inWallRangeChecker.check(wall, entity, position, wall.rotation)
            ) {
            this._ghostObject.rotation.copy(wall.rotation);
            return position;
        }
    }

    return null;
};

GroupObjectHandler.prototype.hold = true;

GroupObjectHandler.prototype.dragEnd = function () {
    this._colliderChecker.clearState();
};

GroupObjectHandler.prototype.getStaticObjectPredicate = function () {
    var base = AddObjectHandlerBase.prototype.getStaticObjectPredicate.call(this);
    return function (obj) {
        return base(obj) && !this._objectCheckerSrvc.isFloor(obj) && !this._objectCheckerSrvc.isWall(obj);
    }.bind(this);
};

export default  GroupObjectHandler;