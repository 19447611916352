import Select3D from "../component/3D/Select3D";
import WallView from "../component/3D/WallView";
import ObjectAdd from "../component/3D/ObjectAdd/ObjectAdd";

angular.module('valleyCraftApp').directive('step4', [
    'scene3D',
    'constants',
    'roomStateManager',
    'objectMaterial',
    'orbitControl',
    'engine3DSrvc',
    'ngDialog',
    '$timeout',
    'wall3DDataManager',
    'Scene2d',
    'productDetailDialogSrvc',
    'toastr',
    'objectCheckerSrvc',
    function (
        scene3D,
        constants,
        roomStateManager,
        objectMaterial,
        orbitControl,
        engine3DSrvc,
        ngDialog,
        $timeout,
        wall3DDataManager,
        Scene2d,
        productDetailDialogSrvc,
        toastr,
        objectCheckerSrvc
    ) {
        return {
            templateUrl: './app/views/steps/step4.html',
            restrict: 'E',
            scope: {
                flipHelpBtn: '='
            },
            controller: function ($scope, $rootScope) {
                var step4IsActive = false,
                    newStep,
                    defaultOption = 'Change View',
                    cabinets,
                    mouseX,
                    mouseY;

                var select3DComponent = engine3DSrvc.getEngine().findComponentByType(Select3D);
                var viewWallComponent = engine3DSrvc.getEngine().findComponentByType(WallView);
                var addObjectComponent = engine3DSrvc.getEngine().findComponentByType(ObjectAdd);

                $scope.walls = [];
                $scope.selectedWall = defaultOption;
                $scope.isInWallMode = false;
                $scope.freeCamera;
                $scope.areCars = false;

                var p, r, t;

                $scope.onWallChange = function (wall) {
                    if (!$scope.freeCamera) {
                        $scope.freeCamera = true;
                        var c = scene3D.getCamera();
                        p = c.position.clone();
                        r = c.rotation.clone();
                        t = orbitControl.getTarget().clone();
                    }
                    $scope.selectedWall = wall;
                    var isInWallMode = $scope.selectedWall !== defaultOption;

                    if (isInWallMode) {
                        select3DComponent.clearSelection();
                        viewWallComponent.enter($scope.selectedWall);
                    } else {
                        var c = scene3D.getCamera();
                        c.position.copy(p);
                        c.rotation.copy(r);
                        c.updateMatrixWorld();
                        orbitControl.setTarget(t);
                        $scope.freeCamera = false;
                        p = null;
                        r = null;
                        t = null;

                        viewWallComponent.leave();
                    }

                    $scope.isInWallMode = isInWallMode;
                    $scope.flipHelpBtn = isInWallMode;
                };

                $scope.freeLookBtnClick = function () {
                    $scope.onWallChange(defaultOption);
                };

                $scope.goFullScreen = function (el) {
                    var elem = el.currentTarget.parentElement.querySelector('#scene3d');
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen();
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen();
                    } else if (elem.webkitRequestFullscreen) {
                        elem.webkitRequestFullscreen();
                    }
                };

                $scope.changeWall = function (dir) {
                    var wall = viewWallComponent.changeWall(dir);
                    this.onWallChange(wall);
                };

                var cleanUp = function () {
                    if (step4IsActive) {
                        step4IsActive = false;
                        viewWallComponent.leave();
                        if (newStep !== 'step5') scene3D.dispose();
                    }
                };

                var openProductDetails = function (item, mesh, sender, item2d) {
                    if (!item) return;
                    ngDialog.open({
                        disableAnimation: true,
                        template: './app/views/productDetails.html',
                        className: 'ngdialog-theme-default product-details-popup',
                        data: {
                            item: item,
                            mesh: mesh,
                            sender: sender,
                            item2d: item2d
                        },
                        controller: 'ProductDetailsController'
                    });
                };

                var stepChangeUnregister = $rootScope.$on('stepActive', function (events, step) {
                    newStep = step;

                    if (step === 'step4') {
                        function checkCars() {
                            var chdrn = scene3D.getChildren();
                            for (var i = 0; i < chdrn.length; i++) {
                                if (chdrn[i].userData.entity) {
                                    if (chdrn[i].userData.entity.type === 'vehicle') {
                                        $scope.areCars = true;
                                        break;
                                    } else {
                                        $scope.areCars = false;
                                    }
                                }
                            }
                        }

                        if (step4IsActive) return;

                        step4IsActive = true;

                        $scope.walls.length = 0;

                        $scope.selectedWall = defaultOption;

                        $scope.walls.push(defaultOption);

                        for (var j = 0; j < roomStateManager.getPoints().length; j++)
                            $scope.walls.push('Wall ' + (j + 1));

                        // setTimeout(checkCars, 500);
                        $timeout(checkCars, 500);
                        // setTimeout(function(){
                        //     $scope.$apply(checkCars);
                        // }, 500);
                    } else {
                        $scope.flipHelpBtn = false;
                        $scope.isInWallMode = false;
                        cabinets = null;
                        cleanUp();
                    }
                });

                var routeChangeUnregister = $rootScope.$on('$routeChangeStart', cleanUp);

                var wallDbClickHandler = viewWallComponent.on(
                    'wallDoubleClick',
                    function (wallName) {
                        $scope.onWallChange(wallName);
                        $scope.$applyAsync();
                    }
                );

                $scope.itemClick = function (entity) {
                    if (entity.isSuite) {
                        toastr.info('Please hover over wall to place suite');
                        //     var suitableWalls = scene3D.getSuitableWalls(entity.length, entity.width);
                        //     var objects = scene3D.getSuitesNewPosition(entity, suitableWalls[1]);
                    }
                    // else {
                    addObjectComponent.setEntity(entity);
                    // }
                };

                var dblClickedOdjectUnreg = select3DComponent.on(
                    'objectDoubleClick',
                    function (item) {
                        if (item[0] && item[0].object) {
                            if (!objectCheckerSrvc.isGrouppedSuite(item[0].object)) {
                                var model2D = Scene2d.getObjectByUUID(item[0].object.uuid);
                                productDetailDialogSrvc.show(
                                    item[0].object.userData.entity,
                                    item[0].object,
                                    '3d',
                                    model2D
                                );
                            }
                        }
                    }
                );

                $scope.$on('$destroy', function () {
                    stepChangeUnregister();
                    routeChangeUnregister();
                    wallDbClickHandler();
                    dblClickedOdjectUnreg();
                    engine3DSrvc.dispose();
                });
            }
        };
    }
]);
