/**Зміна матеріалу підлоги та/чи стелі */
import Select3D from "../../component/3D/Select3D";
(function () {
  var directive = function () {
    return {
      restrict: "E",
      templateUrl: "./app/views/materialList.html",
      replace: true,
      scope: {},
      controller: controller,
    };
  };

  var dependencies = [
    "$scope",
    "scene3D",
    "objectMaterial",
    "constants",
    "roomStateManager",
    "materialSelector",
    "materialStuffService",
    "engine3DSrvc",
    "roomStuff",
    "$rootScope",
  ];

  var controller = function (
    $scope,
    scene3D,
    objectMaterial,
    constants,
    roomStateManager,
    materialSelector,
    materialStuffService,
    engine3DSrvc,
    roomStuff,
    $rootScope
  ) {
    $scope.applyToAll = false;
    $scope.isWallOrFloor = false;
    
    var select3DComponent = engine3DSrvc
      .getEngine()
      .findComponentByType(Select3D);

    var changeObjectMaterial = function (mesh, colorScheme) {
      console.log("colorScheme", colorScheme);
      objectMaterial
        .setMaterial(mesh, colorScheme)
        .then(function () {
          console.log("mesh.userData.entity.img", mesh);
          if (colorScheme.length === 2) {
            mesh.userData.entity.img =
              "assets/images/ProductImage/" +
              mesh.userData.entity.base_model_name +
              colorScheme +
              ".png";
          }

          roomStateManager.saveObjectMaterial(mesh);
          roomStateManager.saveObjectColorScheme({
            objectId: mesh.uuid,
            entityId: mesh.userData.entity.id,
            scheme: colorScheme,
          });
        })
        .catch(function (err) {
          console.debug("Err", err);
        });
    };

    var changeAllObjectsMaterial = function (mesh, colorScheme) {
      var category = mesh.userData.entity.category;
      var children = scene3D.filterChildren(function (item) {
          return (
            item.userData.entity && item.userData.entity.category === category
          );
        }),
        i = 0,
        entity,
        mesh,
        materials;
      for (; i < children.length; i++) {
        mesh = children[i];
        entity = mesh.userData.entity;
        if (!mesh.userData.isSuite) {
          if (mesh.userData.entity.isWall) {
            changeObjectMaterial(mesh, colorScheme);
          } else if (mesh.userData.entity.isFloor) {
            changeObjectMaterial(mesh, colorScheme);
          } else {
            materials = materialSelector.getMaterials(entity);
            if (materials.indexOf(colorScheme) !== -1)
              changeObjectMaterial(mesh, colorScheme);
          }
        }
      }
    };

    var changeSuiteMaterial = function (mesh, colorScheme) {
      var suiteId = mesh.userData.suiteUniqueId;
      var children = scene3D.filterChildren(function (item) {
        return item.userData.entity && item.userData.suiteUniqueId === suiteId;
      });
      var i = 0;
      var entity;
      var newMesh;
      var materials;
      var item = roomStuff.getById(mesh.userData.suiteId);
      var colorIndex;
      var ColorScheme;
      for (; i < children.length; i++) {
        newMesh = children[i];
        entity = newMesh.userData.entity;
        for (var j = 0; j < item.color_schemes.length; j++) {
          if (item.color_schemes[j].short_key === colorScheme) {
            colorIndex = j;
          }
        }
        if (entity.type === 8) {
          ColorScheme =
            item.color_schemes[colorIndex].color_scheme[
              entity.left_menu_alias + ": Corner"
            ];
        } else if (entity.left_menu_alias === "Work Tables") {
          ColorScheme =
            item.color_schemes[colorIndex].color_scheme[
              entity.left_menu_alias + ": Adjustable"
            ];
        } else {
          ColorScheme =
            item.color_schemes[colorIndex].color_scheme[entity.left_menu_alias];
        }
        changeObjectMaterial(newMesh, ColorScheme);
      }
    };

    $scope.getColorSchemeSwapImageUrl = function (colorSchemeName) {
      var allColorSchemes = roomStuff.getAllColorSchemes();
      if (allColorSchemes.length) {
        var colorScheme = _.find(allColorSchemes, function (cs) {
          return cs.colorSchemeName === colorSchemeName;
        });
        if (!colorScheme) {
          return "";
        } else {
          return colorScheme.colorSchemeImage;
        }
      } else {
        return "";
      }
    };

    $scope.showMaterialGroups = function () {
      $scope.groupMaterials = null;
    };

    $scope.onColorSchemeChange = function (colorScheme) {
      if ($scope.mesh.userData.suiteId) {
        changeSuiteMaterial($scope.mesh, colorScheme);
      } else {
        if ($scope.applyToAll) {
          //Apply to all
          changeAllObjectsMaterial($scope.mesh, colorScheme);
        } else {
          console.log("changeObjectMaterial");
          changeObjectMaterial($scope.mesh, colorScheme);
        }
      }
      $scope.selectedColorScheme = colorScheme;
    };

    $scope.onMaterialGroupChange = function (materialCategory) {
      var oldCamera;
      var chdrn = scene3D.getChildren();
      for (var i = 0; i < chdrn.length; i++) {
        if (chdrn[i].type === "PerspectiveCamera") {
          oldCamera = chdrn[i];
          break;
        }
      }
      if ($scope.mesh.userData.entity.isWall) {
        $scope.groupMaterials =
          materialStuffService.getWallMaterialsByGroup(materialCategory);
      } else {
        $scope.groupMaterials =
          materialStuffService.getFloorMaterialsByGroup(materialCategory);
      }
      $scope.materialCategory = materialCategory;
    };

    var unsubscribeColorChange = $rootScope.$on(
      "cegObjectColorSchemeChanged",
      function (e, colorScheme) {
        $scope.selectedColorScheme = colorScheme;
      }
    );

    var unsubscribe = select3DComponent.on("select", function (selection) {
      if (selection.length > 0) {
        $scope.mesh = selection[0];
        var entity = $scope.mesh.userData.entity;
        $scope.isWallOrFloor = entity.isWall || entity.isFloor;
        $scope.groupMaterials = null;
        if (entity.isWall) {
          $scope.wallsSelected = true; // REFACTOR
          $scope.floorSelected = false;
          $scope.colorScheme = materialStuffService.wallGroupNames;
        } else if (entity.isFloor) {
          $scope.wallsSelected = false;
          $scope.floorSelected = true;
          $scope.colorScheme = materialStuffService.floorGroupNames;
        } else if ($scope.mesh.userData.suiteId) {
          var item = roomStuff.getById($scope.mesh.userData.suiteId);
          $scope.colorScheme = [];
          for (var i = 0; i < item.color_schemes.length; i++) {
            $scope.colorScheme[i] = item.color_schemes[i].short_key;
          }
        } else {
          $scope.colorScheme = materialSelector.getMaterials(
            $scope.mesh.userData.entity
          );
        }

        var objectColorSchemeRecord = roomStateManager.getColorSchemeByObjectId(
          $scope.mesh.uuid
        );
        if (objectColorSchemeRecord)
          $scope.selectedColorScheme = objectColorSchemeRecord.scheme;

        $scope.$applyAsync();
      }
    });

    $scope.$on("$destroy", function () {
      unsubscribe();
      unsubscribeColorChange();
    });
  };

  controller.$inject = dependencies;

  angular.module("valleyCraftApp").directive("materialList", directive);
})();
