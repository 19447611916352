import * as THREE from 'three';

export const getMinMaxParams = (root) => {
  const rootParams = {
    max: { x: 0, y: 0, z: 0 },
    min: { x: 0, y: 0, z: 0 },
  };
  root.traverse((child) => {
    if (child.isMesh) {
      let newBox = new THREE.Box3().setFromObject(child);
      if (newBox.max.x > rootParams.max.x) rootParams.max.x = newBox.max.x;
      if (newBox.max.y > rootParams.max.y) rootParams.max.y = newBox.max.y;
      if (newBox.max.z > rootParams.max.z) rootParams.max.z = newBox.max.z;
      if (newBox.min.x < rootParams.min.x) rootParams.min.x = newBox.min.x;
      if (newBox.min.y < rootParams.min.y) rootParams.min.y = newBox.min.y;
      if (newBox.min.z < rootParams.min.z) rootParams.min.z = newBox.min.z;
    }
  });
  return rootParams;
}
