
; (function () {

    'use strict';

    var dependencies = [];

    var service = function () {

        var isSnappingDisabled = false;

        return {
            setIsDisableSnapping: function (val) {
                isSnappingDisabled = val;
            },
            getIsDisableSnapping: function () {
                return isSnappingDisabled;
            }
        }
    };

    service.$inject = dependencies;

    angular.module('valleyCraftApp').service('snappingDisableSrvc', service);

})();
