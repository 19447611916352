"use strict";

angular.module("valleyCraftApp").controller("MultiDropDownControl", [
  "$rootScope",
  "$scope",
  "$http",
  "$injector",
  function ($rootScope, $scope, $http, $injector) {
    var textDropDownSelect = "Category";

    $scope.clickHandler = function ($event) {
      // $event.preventDefault();
      $rootScope.multiDropDownModel.itemsName =
        $event.target.getAttribute("attr-itemsName");
      $rootScope.multiDropDownModel.text = $event.target.textContent;
    };

    $rootScope.multiDropDownModel = {
      itemsName: null,
    };

    function compile(element) {
      var el = angular.element(element);
      $scope = el.scope();
      $injector = el.injector();
      $injector.invoke(function ($compile) {
        $compile(el)($scope);
      });
    }
    let AccordionMenu = function (selector) {
      this.colMenu = document.querySelectorAll(`${selector} > li`);
      let This = this;
      this.colMenu.forEach(function (items) {
        console.log(items);
        accordionMenuItem(items, This);
      });
    };
    /* 
        var itemCurrentModel = function (dataItemsName) {
            $scope.multiDropDownModel.itemsName = dataItemsName.querySelector('a').textContent;
            console.log(dataItemsName, $scope.multiDropDownModel);
        }; */

    var accordionMenuItem = function (items, This) {
      if (items.querySelector("ul")) {
        items.firstElementChild.onclick = function (e) {
          //  e.preventDefault();
          let isTrue = this.parentElement.classList.toggle("open");
          if (isTrue) {
            This.show(this.nextElementSibling);
          } else {
            This.hide(this.nextElementSibling);
          }
        };

        angular.forEach(
          items.querySelector("ul").querySelectorAll("li"),
          function (value, key) {
            if (
              value.querySelector("p").getAttribute("attr-itemsName") &&
              !value.getAttribute("ng-click")
            ) {
              console.log(value);
              value.setAttribute("ng-click", "clickHandler($event)");
              compile(value);
            } /* else {
                            value.onclick = function (e) {
                                e.preventDefault();
                            };
                        } */
            accordionMenuItem(value, This);
          }
        );
      }
    };
    // Show an element
    AccordionMenu.prototype.show = function (elem) {
      // Get the natural height of the element
      var getHeight = function () {
        elem.style.display = "block"; // Make it visible
        var height = elem.scrollHeight + "px"; // Get it's height
        return height;
      };

      var height = getHeight(); // Get the natural height
      elem.style.height = height; // Update the height

      setTimeout(function () {
        elem.style.height = "auto";
      }, 350);
    };

    // Hide an element
    AccordionMenu.prototype.hide = function (elem) {
      // Give the element a height to change from
      elem.style.height = elem.scrollHeight + "px";

      // Set the height back to 0
      setTimeout(function () {
        elem.style.height = "0";
      }, 110);

      setTimeout(function () {
        elem.style.display = "";
      }, 700);
    };
    var svg =
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 451.847 451.847" xml:space="preserve"> <g> <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751 c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0 c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"></path> </g> </svg>';

    var getMenuItem = function (itemData) {
      var item = angular.element("<li>").append(
        angular
          .element("<p>", {
            html: itemData.text,
          })
          .attr("attr-itemsName", itemData.itemsName)
      );
      //  if (itemData.itemsName) {
      //    item.attr('attr-itemsName', itemData.itemsName);
      //  item.setAttribute('ng-click', 'clickHandler($event)')
      //  }

      if (itemData.items) {
        item.find("p").append(angular.element(svg));
        var itemsList = angular.element("<ul>");
        angular.forEach(itemData.items, function (value, key) {
          itemsList.append(getMenuItem(value));
        });
        item.append(itemsList);
      }
      return item;
    };

    var selectCategory = angular.element(
      document.querySelector("#selectCategory")
    );

    /*  // создадим новый элемент div
        var newDiv = angular.element('<div>');
        // установим у него стили
         newDiv.css('width', '80px');
        newDiv.css('height', '50px');
        newDiv.css('background-color', 'blue');
        // добавим в div.quest
        selectCategory.append(newDiv); */

    // Инициализируем страницу
    function init() {
      //Провинция
      $http.get("assets/json/dropDownMenu.json").then(
        function successCallback(data) {
          const li = [
            {
              text: textDropDownSelect,
              items: data.data.menu,
            },
          ];

          angular.forEach(li, function (value, key) {
            console.log(value, key);

            selectCategory.append(getMenuItem(value));
          });
          new AccordionMenu(".drop-down");
        },
        function errorCallback(response) {
          // Запрос не выполняет код
        }
      );
    }

    // Инициализация
    init();
  },
]);
