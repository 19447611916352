import * as THREE from 'three';

export const setCenterPosition = (object) => {
  if(object.children.length === 1) {
    let objectCoord = new THREE.Box3().setFromObject(object.children[0]);
    let x = (objectCoord.max.x + objectCoord.min.x) / 2 * -1
    let y = (objectCoord.max.y + objectCoord.min.y) / 2 * -1
    let z = (objectCoord.max.z + objectCoord.min.z) / 2 * -1
    object.children[0].position.set(x, y, z)
  } else {
    const rootParams = {
      isBox3: true,
      max: {x: 0, y: 0, z: 0},
      min: {x: 0, y: 0, z: 0},
    };
    // console.log('setCenterPosition object', object);
    // console.log('setCenterPosition object params', new THREE.Box3().setFromObject(object));
    let objectCoord = new THREE.Box3().setFromObject(object);
    // console.log('objectCoord', objectCoord);
    let x = (objectCoord.max.x + objectCoord.min.x) / 2 * -1
    let y = (objectCoord.max.y + objectCoord.min.y) / 2 * -1
    let z = (objectCoord.max.z + objectCoord.min.z) / 2 * -1
    object.position.set(x, y, z)
    // console.log('setCenterPosition object', object.position);

    // object.children.forEach(child => {
    //   let newBox = new THREE.Box3().setFromObject(child);
    //   if (newBox.max.x > rootParams.max.x) rootParams.max.x = newBox.max.x;
    //   if (newBox.max.y > rootParams.max.y) rootParams.max.y = newBox.max.y;
    //   if (newBox.max.z > rootParams.max.z) rootParams.max.z = newBox.max.z;
    //   if (newBox.min.x < rootParams.min.x) rootParams.min.x = newBox.min.x;
    //   if (newBox.min.y < rootParams.min.y) rootParams.min.y = newBox.min.y;
    //   if (newBox.min.z < rootParams.min.z) rootParams.min.z = newBox.min.z;
    // })
    // let x = (rootParams.max.x + rootParams.min.x) / 2 * -1
    // let y = (rootParams.max.y + rootParams.min.y) / 2 * -1
    // let z = (rootParams.max.z + rootParams.min.z) / 2 * -1
    // // object.position.set(x, y, z)
    // // }
    // console.log('rootParams', rootParams);
    // console.log('object', object);
  }
}
