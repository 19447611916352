import Drag2D from './Drag2D';
var DragStairs2D = function (dependencyContainer) {
    Drag2D.prototype.constructor.call(this, dependencyContainer);

    this._constants = dependencyContainer.getService('constants');
    this._step3Helper = dependencyContainer.getService('step3Helper');
    this._obbBuilder = dependencyContainer.getService('obbBuilder');
    this._collisionSrv = dependencyContainer.getService('collisionSrvc');
};

DragStairs2D.prototype = Object.create(Drag2D.prototype);

DragStairs2D.prototype.constructor = DragStairs2D;

DragStairs2D.prototype.dragging = function (newPosition, draggedObject) {

    this._selectedWall = this.getIntersectedWall(draggedObject);

    if (this._selectedWall && !draggedObject.userData.entity.disableAutoOrientation)
        draggedObject.rotation.z = this._selectedWall.rotation.y;

    draggedObject.position.copy(newPosition);
};

DragStairs2D.prototype.dragEnd = function (draggedObject) {

    var wallName = null;

    if (this._selectedWall) {

        var entity = draggedObject.userData.entity,
            position;

        if (entity.type === this._constants.StairsType.WALL_CUT) {
            position = this._step3Helper.cutSnapWall(draggedObject, this._selectedWall);
            wallName = this._selectedWall.userData.name;
        } else {
            position = this._step3Helper.snapToWallWithGap(draggedObject, this._selectedWall);

            var allWalls = this._Scene2d.getWalls();
            var firstWallIndex = allWalls.indexOf(this._selectedWall);
            allWalls.splice(firstWallIndex, 1);
            var collidedWithWall = this._collisionSrv.isCollideWithWall(draggedObject, allWalls, {
                draggedObjectPosition: position,
                upAxis: 'z'
            });
            var collided = this._collisionSrv.isCollide(draggedObject, this._staticObjs, {
                draggedObjectPosition: position,
                upAxis: 'z'
            })
        }

        if (!collided && !collidedWithWall) {
            draggedObject.position.copy(position);
        }
        else {
            if (collidedWithWall)
                this.edgeSnapWall(draggedObject, collidedWithWall);
            else
                wallName = null;
        }
    } else {
        if (draggedObject.userData.entity.type === this._constants.StairsType.WALL_CUT
            || draggedObject.userData.entity.type === this._constants.StairsType.WALL_SNAP) {
            this._Scene2d.remove(draggedObject);
            this._addObj = false;
        }
    }

    return wallName;
};

DragStairs2D.prototype.edgeSnapWall = function (draggedObject, collided) {

    collidedPosition = this._step3Helper.snapToWallWithGap(draggedObject, this._selectedWall);
    draggedObject.position.copy(collidedPosition);

    var collidedPosition = this._step3Helper.snapToWallWithGap(draggedObject, collided, true);
    draggedObject.position.copy(collidedPosition);
};

DragStairs2D.prototype.isEnabled = function () {
    return Drag2D.prototype.isEnabled.call(this) &&
        this.getSelectedObject().userData.entity instanceof this._constants.RoomObject.Stairs;
};
export default DragStairs2D 